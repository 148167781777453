import React from 'react';
import ReactDOM from 'react-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import App from './App';
import Main from './routes/Main'
import registerServiceWorker from './registerServiceWorker';
import { BrowserRouter,HashRouter } from "react-router-dom";

function onUpdateHandler(registration) {

    // Make sure that any new version of a service worker will take over the page 
    // and become activated immediately.
    const waitingServiceWorker = registration.waiting;
    if (waitingServiceWorker) {
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
    }
  
    const link = document.createElement("a");
    link.classList.add("update-notification");
    link.setAttribute("href", "#");
    link.innerHTML = "Update is available. Click here to install.";
  
    link.addEventListener("click", e => {
      e.preventDefault();
      window.location.reload();
    });
  
    document.querySelector("body").appendChild(link);
  }

ReactDOM.render(<BrowserRouter ><App /></BrowserRouter>, document.getElementById('root'));
registerServiceWorker(
    {
        onUpdate: onUpdateHandler
       }
);
