import React, { Component } from "react";
import { Switch, Route } from 'react-router-dom'
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import Dashboard from '../views/Dashboard/Dashboard.jsx'

import "./main.css";

import Footprints from '../views/Footprints/Footprints';
import Catagories from '../views/Catagories/Catagories.jsx';
import LoginPage from '../views/LoginPage/Login.jsx';
import Products from '../views/Products/Products.jsx';
import Subcatagories from '../views/Subcatagories/Subcatagories.jsx';
import Users from '../views/Users/Users.jsx';
import Videos from '../views/Videos/Videos.jsx';
import AddCatagories from '../views/Catagories/Addcatagory.jsx';
import Case from '../views/Case/Case.jsx';
import Value from '../views/Value/Value.jsx';
import Renewables from "../views/Case/Renewables.jsx";
import Infra from "../views/Case/Infra.jsx";
import Utility from "../views/Case/Utility.jsx";
import Railways from "../views/Case/Transport/Railways.jsx";
import Marine from "../views/Case/Transport/Marine.jsx";
import Automotive from "../views/Case/Industry/Automotive.jsx";
import Aluminium from "../views/Case/Industry/Aluminium.jsx";
import Food from "../views/Case/Industry/Food.jsx";
import Country from "../views/Footprints/Country.jsx";
import Overview from "../views/Overview/Overview.js";
import Industry from "../views/Case/Industry.jsx";
import Transport from "../views/Case/Transport.jsx";



	

 

 
class Main extends Component{
  render(){
 
    return(
      
   
      <Switch>
      <Route exact path='/' component={Dashboard}/>
      <Route exact path='/home' component={Dashboard}/>
     <Route exact path='/case_study' component={Case}/>
     <Route exact path='/footprints' component={Footprints}/>
     <Route exact path='/solutions' component={Catagories}/>
     <Route exact path='/value' component={Value}/>
     <Route exact path='/renewables' component={Renewables}/>
     <Route exact path='/infra' component={Infra}/>
     <Route exact path='/utility' component={Utility}/>
     <Route exact path='/causes' component={Subcatagories}/>
     <Route exact path='/railways' component={Railways}/>
     <Route exact path='/marine' component={Marine}/>
     <Route exact path='/automotive' component={Automotive}/>
     <Route exact path='/aluminium' component={Aluminium}/>
     <Route exact path='/f&b' component={Food}/>
     <Route exact path='/country' component={Country}/>
     <Route exact path='/overview' component={Overview}/>
     <Route exact path='/industry' component={Industry}/>
     <Route exact path='/transport' component={Transport}/>
      </Switch>
    
    )
  }
}
  
 
 







export default Main


  