import React, { Component } from "react";
import ReactTooltip from 'react-tooltip'
import { MDBNavbar, MDBDropdownItem,MDBDropdownMenu,  MDBIcon, MDBTable, MDBTableBody, MDBCardBody, MDBCardText,MDBRow,
  MDBCol,
  MDBDropdown,
  MDBDropdownToggle,
  MDBCardHeader, MDBModal, MDBModalBody, MDBCard,MDBModalFooter,MDBCardImage,MDBCardTitle, MDBPopoverBody, MDBTooltip,  } from 'mdbreact';
  import dashboardRoutes from "../../routes/dashboard";
  import swal from 'sweetalert'
  import { Grid, Row, Col,Image,ListGroup,ListGroupItem,Button,Form,FormControl,Checkbox,ControlLabel, } from "react-bootstrap";
import './subcatagories.css'
import "../../../node_modules/video-react/dist/video-react.css";
import { Player } from 'video-react';
import ScrollAnimation from 'react-animate-on-scroll';
import { HalfCircleSpinner } from 'react-epic-spinners'
import YouTube from 'react-youtube';
class Subcatagories extends Component {
  constructor(props) {
    super(props);
  this.state={
    loading:false,info_heads:[],add_head:false,clients:[],
    new_name:"",new_des:"",new_hsn:"",new_type:"Select Cost Type",selected_id:"",
    selected_language:[],subcatagories:[],edit_sub:false,
    selected_sub:null,languages:[],
    add_sub:false,sub_name:'',description:'',superadmin:false,admin:false,
    cause_image:'',edit_image:'',videolink:'',video_modal:false,selected_video:'',
    heading:'Causes of poor power quality',videoText:'KNOW MORE',nextText:'Solutions',mobileMenu:false,showExit:false,exit:false,
    showBanner:true,sidebar:[],youtube_modal:false,closeText:'CLOSE',
    errored:false,showAdded:false
  }
  }

  exitApp(){
    var userAgent = navigator.userAgent.toLowerCase();
  if (userAgent.indexOf(' electron/') > -1) {
    const remote = window.require('electron').remote
    let w = remote.getCurrentWindow()
    w.close()
  }
  
  
  }
     renderExitModal(){
  
  
     
      if(this.state.exit==true){
    
    
      return(
        <MDBModal size='md' isOpen={this.state.exit} centered toggle={this.toggleExit} >
     
           
     <MDBModalBody style={{    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',flexDirection:'column',paddingTop:'4vh',paddingBottom:'4vh'}}>
              <img onError={()=>this.setState({showAdded:true})} alt='Loading...' src={require('../../assets/Exit.jpg')} className='catGif'/>
              <p>Are you sure you want to exit this application?</p>
           <div style={{display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-evenly'}}>      <text onClick={()=>this.exitApp()} style={{backgroundColor:'#ff000f',color:'white'}} className='seriesText'>Exit</text>

              
              <text onClick={()=>this.setState({exit:false})} className='seriesText'>Cancel</text>

         
            </div>
             
              </MDBModalBody>
  
          </MDBModal>
      )
    }
    }
    
    toggleExit = () => {
     
      this.setState({
        exit: !this.state.exit,
       });
    }

  toggleAdd = () => {
   
    this.setState({
      add_sub: !this.state.add_sub,
     });
  }
  toggleEdit = () => {
   
    this.setState({
      edit_sub: !this.state.edit_sub,
     });
  }
  reload = ()=>{
    const current = this.props.location.pathname;
    this.props.history.replace(`/reload`);
       setTimeout(() => {
         this.props.history.replace(current);
       });
   }

  componentDidMount(){
//this.loadF()

    var userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf(' electron/') > -1) {
       this.setState({showExit:true})
    }
    this.getuserdata()
  }
  renderExitButton(){
    if(this.state.showExit){
     return(<div
       className="sideInActive"
       
       onClick={this.toggleExit}                  
       >
       <img onError={()=>this.setState({showAdded:true})} src={require('../../assets/Exit.jpg')} style={{width:35}}/>
       <text style={{marginTop:5}}>Exit</text>
       </div>)
    }
   
   }
  getuserdata(){
    var responseJson=[{"language":"English","language_id":1,"active":0,"key":1,"label":"English",selected:0},
    {"language":"German","language_id":2,"active":0,"key":2,"label":"German",selected:0},
    {"language":"Chinese","language_id":3,"active":0,"key":3,"label":"Chinese",selected:0},
    {"language":"Spanish","language_id":4,"active":0,"key":4,"label":"Spanish",selected:0},
    {"language":"French","language_id":5,"active":0,"key":5,"label":"French",selected:0}]
            for(var v=0;v<responseJson.length;v++){
              responseJson[v].selected=0
            }
       var defaultLanguage=window.localStorage.getItem('admindefault')
       if(defaultLanguage==null){
        responseJson[0].selected=1
        this.setState({
          languages:responseJson,
          loading:false,
          selected_language:responseJson[0]
        },()=>this.getSubcatagories())
       }
       else{
        if(JSON.parse(defaultLanguage).language=='German' || JSON.parse(defaultLanguage).language=='Deutsche' ||
        JSON.parse(defaultLanguage).language=='德语' || JSON.parse(defaultLanguage).language=='alemán' || JSON.parse(defaultLanguage).language=='allemand'){
         responseJson[0].language='Englisch'
         responseJson[1].language='Deutsche'
         responseJson[2].language='Chinesisch'
         responseJson[3].language='Spanisch'
         responseJson[4].language='Französisch'
         this.setState({logoutText:'Ausloggen',videoText:'MEHR WISSEN',nextText:'Lösungen',heading:'Ursachen für schlechte Stromqualität',languages:responseJson,closeText:'Schließen'})
    
        }
        else if(JSON.parse(defaultLanguage).language=='Chinese' || JSON.parse(defaultLanguage).language=='Chinesisch' ||
        JSON.parse(defaultLanguage).language=='中文' || JSON.parse(defaultLanguage).language=='chino' || JSON.parse(defaultLanguage).language=='chinois'){
        
          responseJson[0].language='英语'
          responseJson[1].language='德语'
          responseJson[2].language='中文'
          responseJson[3].language='西班牙语'
          responseJson[4].language='法国'
         
          this.setState({logoutText:'登出',videoText:'了解更多',nextText:'解决方案',heading:'电能质量差的原因',languages:responseJson,closeText:'关'})
    
     
         }
         else if(JSON.parse(defaultLanguage).language=='Spanish' || JSON.parse(defaultLanguage).language=='Spanisch' ||
         JSON.parse(defaultLanguage).language=='西班牙语' || JSON.parse(defaultLanguage).language=='Español' || JSON.parse(defaultLanguage).language=='Espanol'){
          responseJson[0].language='Inglés'
          responseJson[1].language='alemán'
          responseJson[2].language='chino'
          responseJson[3].language='Español'
          responseJson[4].language='francés'
          this.setState({logoutText:'Cerrar sesión',videoText:'SABER MÁS',nextText:'Soluciones',heading:'Causas de la mala calidad de la energía',languages:responseJson,closeText:'Cerrar'})
    
     
         }
         else if(JSON.parse(defaultLanguage).language=='French' || JSON.parse(defaultLanguage).language=='Französisch' ||
     JSON.parse(defaultLanguage).language=='法国' || JSON.parse(defaultLanguage).language=='francés' || JSON.parse(defaultLanguage).language=='français'){
      responseJson[0].language='Anglais'
      responseJson[1].language='allemand'
      responseJson[2].language='chinois'
      responseJson[3].language='Espanol'
      responseJson[4].language='français'
           
         
          this.setState({logoutText:'Connectez - Out',videoText:'SAVOIR PLUS',nextText:'Solutions',heading:`Causes de la mauvaise qualité de l'énergie`,languages:responseJson,closeText:'Fermer'})
    
     
         }
        responseJson[JSON.parse(defaultLanguage).key-1].selected=1
        this.setState({
          languages:responseJson,
          loading:false,
          selected_language:JSON.parse(defaultLanguage)
        },()=>this.getSubcatagories())
       }
          
  }
  componentWillMount(){
  
  }
  getSidebar=async()=>{
         
    if(!navigator.onLine){
var offline_content=window.localStorage.getItem('sidebar')
if(offline_content==null){

 this.setState({
   loading:false
 })
}
else{
 console.log(JSON.parse(JSON.parse(offline_content).content),'cccc')
 dashboardRoutes[0].name=JSON.parse(JSON.parse(offline_content).content)[0].name
 dashboardRoutes[1].name=JSON.parse(JSON.parse(offline_content).content)[1].name
 dashboardRoutes[2].name=JSON.parse(JSON.parse(offline_content).content)[2].name
 dashboardRoutes[3].name=JSON.parse(JSON.parse(offline_content).content)[4].name
 dashboardRoutes[4].name=JSON.parse(JSON.parse(offline_content).content)[5].name
 dashboardRoutes[5].name=JSON.parse(JSON.parse(offline_content).content)[6].name
 this.setState({
   sidebar:JSON.parse(offline_content).content,
 loading:false
 })
}
    }
    else{
     this.setState({loading:true})
     let details = {
       language_id:this.state.selected_language.language_id
     };
     let formBody = [];
     for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
     }
     formBody = formBody.join("&");
     this.setState({
       response: fetch('https://www.mobilitysolution.info/get_sidebar', {
         method: 'POST',
         headers: {
          
           'Content-Type': 'application/x-www-form-urlencoded',
           'Cache-Control': 'max-age=31536000'
       },
     body:formBody
         
       }).then((response) => response.json())
       .then((responseJson)=>{
         console.log(JSON.parse(responseJson.content),'sidebar')
        window.localStorage.removeItem('sidebar')
        window.localStorage.setItem('sidebar',JSON.stringify(responseJson))
dashboardRoutes[0].name=JSON.parse(responseJson.content)[0].name
dashboardRoutes[1].name=JSON.parse(responseJson.content)[1].name
dashboardRoutes[2].name=JSON.parse(responseJson.content)[2].name
dashboardRoutes[3].name=JSON.parse(responseJson.content)[4].name
dashboardRoutes[4].name=JSON.parse(responseJson.content)[5].name
dashboardRoutes[5].name=JSON.parse(responseJson.content)[6].name
         this.setState({
           sidebar:(responseJson.content).sidebar,
 loading:false
         })
           })
           .catch((error) => {
            var offline_content=window.localStorage.getItem('sidebar')
            if(offline_content==null){
            
             this.setState({
               loading:false
             })
            }
            else{
             console.log(JSON.parse(JSON.parse(offline_content).content),'cccc')
             dashboardRoutes[0].name=JSON.parse(JSON.parse(offline_content).content)[0].name
             dashboardRoutes[1].name=JSON.parse(JSON.parse(offline_content).content)[1].name
             dashboardRoutes[2].name=JSON.parse(JSON.parse(offline_content).content)[2].name
             dashboardRoutes[3].name=JSON.parse(JSON.parse(offline_content).content)[4].name
             dashboardRoutes[4].name=JSON.parse(JSON.parse(offline_content).content)[5].name
             dashboardRoutes[5].name=JSON.parse(JSON.parse(offline_content).content)[6].name
             this.setState({
               sidebar:JSON.parse(offline_content).content,
             loading:false
             })
            }
               })
              })
    }
             }
             selectLanguage(data,key){
              window.localStorage.removeItem('admindefault')
                window.localStorage.setItem('admindefault',JSON.stringify(data))
             
          this.setState({
            selected_language:data
          },()=>window.location.reload())
            }
            showUpdateBar() {
              if(this.state.showAdded){
                
                
                  return(
                    <ScrollAnimation animateOnce={false}   animateIn="fadeIn"  className='fadeIn toast2' animationOut="fadeOut" 
                     isVisible={true} >
                   <div className="snack">Go online and <text onClick={()=>this.reload()} style={{cursor:'pointer',textDecoration:'underline'}}>click here</text> to refresh</div>
                </ScrollAnimation>
                
                   
                  )
                
             
              }
              }
              trigger() {
     
                setTimeout(() => { 
                    this.setState({
                        showAdded: false
                    })
                }, 5000);
              }
  mobileMenu(){
    var style={opacity:1,backgroundColor:'#fff',height:'100vh',borderStyle:'solid',borderColor:'#d2d2d2',borderWidth:1,
    overflow:'auto',padding:'2.5%',display:'flex',flexDirection:'column',alignItems:'flex-start',paddingLeft:10,paddingRight:10}
  
   
    if(this.state.mobileMenu){
      return(
        <div className='mobileMenu'>
          <ScrollAnimation animateOnce={false}  animateIn="slideInLeft"  className='slideInLeft' animationOut="slideOutLeft" 
       isVisible={true}  style={style}>
           {dashboardRoutes.map((prop, key) => {
                    if(prop.path=='/causes'){
                      return (
                       
                       <div
                       key={key}
        className="sideActiveMobile"
        
         onClick={()=>this.props.history.push(`${prop.path}`)}                
        >
        <img onError={()=>this.setState({showAdded:true})} alt='Loading...' src={require('../../assets/navbar/Cause.png')} alt='Loading...' style={{width:20,marginRight:10}}/>
        
        <text style={{marginTop:5,fontSize:12}}>{prop.name}</text>
        </div>
                       
                     );
                     return null;
                    }
                    else{
                      return (
                       
                       <div
        className="sideInActiveMobile"
        
         onClick={()=>this.props.history.push(`${prop.path}`)}                
        >
        <img onError={()=>this.setState({showAdded:true})} alt='Loading...' src={require('../../assets/navbar/'+prop.icon)} style={{width:20,marginRight:10}}/>
        <text style={{marginTop:5,fontSize:12}}>{prop.name}</text>
        </div>
                       
                     );
                     return null; 
                    }
                   
  
                  })}
                 
                  </ScrollAnimation>
        </div>
      )
    }
  }
  renderYouTubeModal(){


   
    if(this.state.youtube_modal==true){
  
  
    return(
      <MDBModal size='lg' isOpen={this.state.youtube_modal} toggle={this.toggleyoutube} centered>
   
         
              <MDBModalBody>
              <YouTube
        videoId={this.state.selected_video}
        opts={{
          height: '500',
      width: '100%',
      playerVars: { 
        autoplay: 1
      }
        }}
        onReady={this._onReady}
      />
  
              </MDBModalBody>
              <MDBModalFooter style={{padding:5}}>
              
              <text className="videoButton" style={{color:'#fff'}}  onClick={()=>this.setState({youtube_modal:false})}>{this.state.closeText}</text>
              
               
              </MDBModalFooter>
        </MDBModal>
    )
  }
  }
  toggleYoutube = () => {
   
    this.setState({
      youtube_modal: !this.state.youtube_modal,
     });
  }
  renderLanguages(){

    if(this.state.loading==false){
      if(this.state.languages.length>0){
        return(<div  
      
           
          
          style={{backgroundColor:'rgb(255, 255, 255, 0.1)',cursor:'pointer',display:'flex',alignItems:'center',marginRight:100,marginTop:'.5%'}}>
        <MDBIcon size="lg" style={{marginLeft:10,marginRight:20}}   className="grey-text menuBar" icon="bars" onClick={()=>this.setState({mobileMenu:!this.state.mobileMenu})}/>
        <MDBIcon size="lg" style={{marginLeft:10}} onClick={()=>this.setState({lang_mod:true})}   className="grey-text" icon="globe-africa" />
        <MDBDropdown className='languageDiv' >
       <MDBDropdownToggle caret color="#004080" size="md" >
       <text className='languageText'>{this.state.languages[this.state.selected_language.key-1].language}</text>
       </MDBDropdownToggle>
       <MDBDropdownMenu style={{margin:0,padding:0,borderRadius:0}}> 
       <MDBDropdownItem style={{backgroundColor:'#d2d2d2',color:'black',textAlign:'center',padding:12}}  >ENGLISH</MDBDropdownItem>
       {/* { (this.state.languages).map((data,key) => {
    
  if(data.selected==0){
  return ( 
    <MDBDropdownItem style={{backgroundColor:'#fff',color:'#6e6e6e',textAlign:'center',padding:12}} className='dropButton'  onClick={()=>this.selectLanguage(data)}>{data.language}</MDBDropdownItem>
        
         )
  }
  else{
  return ( 
    <MDBDropdownItem style={{backgroundColor:'#d2d2d2',color:'black',textAlign:'center',padding:12}}  >{data.language}</MDBDropdownItem>
        
         )
  }
    })
    } */}
    </MDBDropdownMenu>
    </MDBDropdown>
      
          </div>)
       }
     
    }
       
      }
  

  getSubcatagories=async()=>{
             
    if(!navigator.onLine){
      var offline_content=window.localStorage.getItem('causes')
      if(offline_content==null){
        swal('Warning','Check your network and refresh','warning')
        this.setState({
          loading:false
        })
      }
      else{
        this.setState({
          subcatagories:JSON.parse(offline_content),
          
         },()=> this.getSidebar())
      }
     
    }
    else{
      this.setState({
        loading:true
      })
    
      let details = {
      language_id:this.state.selected_language.language_id
    };
    let formBody = [];
    for (let property in details) {
     let encodedKey = encodeURIComponent(property);
     let encodedValue = encodeURIComponent(details[property]);
     formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    
    this.setState({
     response: fetch('https://www.mobilitysolution.info/get_sub_catagories', {
       method: 'POST',
       headers: {
        
         'Content-Type': 'application/x-www-form-urlencoded',
         'Cache-Control': 'max-age=31536000'
     },
    body:formBody
      
       
     }).then((response) => response.json())
     .then((responseJson)=>{
      window.localStorage.removeItem('causes')
      window.localStorage.setItem('causes',JSON.stringify(responseJson))
       this.setState({
        subcatagories:responseJson,
         loading:false
       },()=> this.getSidebar())
     
     }
    
     )
       .catch((error) => {
      this.setState({
        loading:false
      })
      var offline_content=window.localStorage.getItem('causes')
      if(offline_content==null){
        swal('Warning','Check your network and refresh','warning')
        this.setState({
          loading:false
        })
      }
      else{
        this.setState({
          subcatagories:JSON.parse(offline_content),
          
         },()=> this.getSidebar())
      }
    console.log(error)
        })
       })
    }

  
  }
   imgToURI(image) {
    var canvasTemp = document.createElement('canvas');
    canvasTemp.width = image.naturalWidth; // or 'width' if you want a special/scaled size
    canvasTemp.height = image.naturalHeight; // or 'height' if you want a special/scaled size
    canvasTemp.getContext('2d').drawImage(image, 0, 0);
    var dataUri = canvasTemp.toDataURL('image/gif');
    // Modify Data URI beginning
    dataUri = 'data:image/gif;' + dataUri.substring(15);
    return dataUri;
  }
  
  
  loadF () {
   var img = new Image();  
    img.addEventListener('load', function() {
      localStorage.setItem('test', this.imgToURI(img));
    }, false);
    img.src = 'http://www.powerquality.app/static/media/dash.72c170e4.gif';
  }
    
  
  
  
  editSub=async()=>{
   
    if(this.state.edit_image.length==0){
      if(this.state.sub_name=='' || this.state.description=='' || this.state.videolink==''){
        swal('Warning','All fields are mandatory','warning')
      }
      else{
        let details = {
          sub_catagory_id:this.state.selected_sub.sub_catagory_id,
          language_id:this.state.selected_language.language_id,
          sub_catagory_name:this.state.sub_name,
          description:this.state.description,
          videolink:this.state.videolink,
      
        };
     console.log(details)
        let formBody = [];
        for (let property in details) {
         let encodedKey = encodeURIComponent(property);
         let encodedValue = encodeURIComponent(details[property]);
         formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        
        this.setState({
         response: fetch('https://www.mobilitysolution.info/edit_sub_catagory', {
           method: 'POST',
           headers: {
            
             'Content-Type': 'application/x-www-form-urlencoded',
             'Cache-Control': 'max-age=31536000'
         },
        body:formBody
          
           
         }).then((response) => response.json())
         .then((responseJson)=>{
          console.log(responseJson)
        window.location.reload()
         }
        
         )
           .catch((error) => {
          this.setState({
            loading:false
          })
          swal("Warning!", "Check your network!", "warning");
        console.log(error)
            })
           })
      }
    }
    else{
      if(this.state.sub_name=='' || this.state.description=='' || this.state.videolink=='' ){
        swal('Warning','All fields are mandatory','warning')
      }
      
       else{
        this.setState({
          loading:true,
          edit_sub:false
        })
        var formData = new FormData()
          formData.append('language_id',this.state.selected_language.language_id)
          formData.append('file',this.state.edit_image)
          formData.append('sub_catagory_name',this.state.sub_name)
          formData.append('description',this.state.description)
          formData.append('filename',this.state.selected_sub.filename)
          formData.append('videolink',this.state.videolink)
          formData.append('sub_catagory_id',this.state.selected_sub.sub_catagory_id)
          for (var key of formData.entries()) {
            console.log(key[0] + ', ' + key[1]);
        }
      
       
        this.setState({
         response: fetch('https://www.mobilitysolution.info/edit_sub_catagory', {
           method: 'POST',
         body: formData
          
           
         }).then((response) => response.json())
         .then((responseJson)=>{
  window.location.reload()

   
       
     }
         )
           .catch((error) => {
        
             this.setState({
               loading:false
             })
     console.log(error)
            })
           })
    }
  
  
  
   }
  }
  confirmDelete=async(data)=>{
    console.log(data)
    swal({
      title: "Are you sure?",
      text:'Delete this Cause?',
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        this.deleteSubCatagory(data)} else {
        }
    });

   
   }
   deleteSubCatagory=async(data)=>{
    
  
    
     let details = {
     sub_catagory_id:data.sub_catagory_id
 
   };
   let formBody = [];
   for (let property in details) {
    let encodedKey = encodeURIComponent(property);
    let encodedValue = encodeURIComponent(details[property]);
    formBody.push(encodedKey + "=" + encodedValue);
   }
   formBody = formBody.join("&");
   
   this.setState({
    response: fetch('https://www.mobilitysolution.info/delete_sub_catagory', {
      method: 'POST',
      headers: {
       
        'Content-Type': 'application/x-www-form-urlencoded',
        'Cache-Control': 'max-age=31536000'
    },
   body:formBody
     
      
    }).then((response) => response.json())
    .then((responseJson)=>{
     console.log(responseJson)
   window.location.reload()
    }
   
    )
      .catch((error) => {
     this.setState({
       loading:false
     })
     swal("Warning!", "Check your network!", "warning");
   console.log(error)
       })
      })
   
    
   }
  addSub=async()=>{
    if(this.state.sub_name=='' || this.state.description=='' || this.state.videolink=='' || this.state.sub_name=='' ){
      swal('Warning','All fields are mandatory','warning')
    }
    else{
     this.setState({
       loading:true,
       add_sub:false
     })
     var formData = new FormData()
       formData.append('language_id',this.state.selected_language.language_id)
      formData.append('file',this.state.cause_image)
       formData.append('sub_catagory_name',this.state.sub_name)
       formData.append('description',this.state.description)
       formData.append('videolink',this.state.videolink)
       for (var key of formData.entries()) {
         console.log(key[0] + ', ' + key[1]);
     }
   
     
     this.setState({
      response: fetch('https://www.mobilitysolution.info/add_sub_catagory', {
        method: 'POST',
      body: formData
       
        
      }).then((response) => response.json())
      .then((responseJson)=>{
window.location.reload()
 

    
  }
      )
        .catch((error) => {
     
          this.setState({
            loading:false,
          })
  console.log(error)
         })
        })
  
   
    }
   }
get_heads=async(data)=>{

  let details = {
    
  };
  
  let formBody = [];
  for (let property in details) {
   let encodedKey = encodeURIComponent(property);
   let encodedValue = encodeURIComponent(details[property]);
   formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  
  this.setState({
   response: fetch('http://52.15.32.193:3000/get_heads', {
     method: 'GET',
     headers: {
      
       'Content-Type': 'application/x-www-form-urlencoded',
     
   },
  
    
     
   }).then((response) => response.json())
   .then((responseJson)=>{
  console.log(responseJson,"get_heads")
  this.setState({info_heads:responseJson})
   
   }
  
   )
     .catch((error) => {
    this.setState({
      loading:false
    })
    alert("Warning!", "Check your network!", "warning");
  console.log(error)
      })
     })
  }

  
 
  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  convert_costType(type){
    if(type===1){return("One Time Cost")}
    else if(type===2){return("Recurring Cost")}
    else{return("INVALID")}
      }
  
editHead(data){

  var t="";
  

  if(data.type==1){t="One Time Cost"}
  else if(data.type==2){t="Recurring Cost"}
  else{t="Select Cost Type"}

this.setState({
new_name:data.name,
new_des:data.description,
new_hsn:data.hsn,
new_type:t,
selected_id:data.head_id,
add_head: !this.state.add_head,
})



}

selectSubCatagory(data,key){
  console.log(data)
}
paneDidMount = (node) => {    
  if(node) {      
    node.addEventListener("scroll", this.handleScroll.bind(this));      
  }
}

handleScroll = (event) => {    
  var node = event.target;
  const bottom = node.scrollHeight - node.scrollTop === node.clientHeight;
  if (bottom) {      
    console.log("BOTTOM REACHED:",bottom); 
    this.setState({showBanner:!this.isInViewport()})
  }    
}


renderScroll(){
  if(this.state.showBanner==true){
    return(<div className='scrollCause' onClick={()=>document.getElementById('last').scrollIntoView()}><text style={{backgroundColor:'white',fontWeight:900,boxShadow:'1px 1px 10px 1px #d2d2d2'}} className='arrow'></text></div>)
  }
}
isInViewport(offset = 0) {
  if (!this.yourElement) return false;
  const top = this.yourElement.getBoundingClientRect().top;
  return (top + offset) >= 0 && (top - offset) <= window.innerHeight;
}
onError = () => {
  if (!this.state.errored) {
    this.setState({
     
      errored: true,
    });
  }
}
  renderList(){

    if(this.state.loading){
   
      return(<div className="loadingSpin"><HalfCircleSpinner color="#ff000f"/></div>)
    }
    else{
      let columns=[];
      this.state.subcatagories.forEach((data,key) => {
    
        columns.push(
          <div id={key+1} className='causeDiv' onClick={()=>this.selectSubCatagory(data,key)}>
         
        <div >
        <h5 className='causeName' >{data.sub_catagory_name}
       
        </h5>

                <img onError={()=>this.setState({showAdded:true})} alt='Loading...'
                  src={data.image}
                
                className='causeGif'
                />
      
        </div>        
                <div className='descriptiondiv'>
                <text  className='descriptionText'>
              {data.description}
          </text>

          
            <button onClick={()=>this.setState({selected_video:data.videolink},this.toggleYoutube)} className='knowMore'>{this.state.videoText}</button>
              
                </div>   
                 </div>
  
        )
          
    
          // force wrap to next row every 4 columns
          if ((key+1)%4===0) {columns.push(<div className="w-100"></div>)}
      })
      return (
  
        <div className="row rowC">
        {columns}
        </div>
      )
    }
   


  }




  


  returnTitle(){
if(this.state.selected_id===""){return(<text>Add Head</text>)}
else{return(<text>Edit Head</text>)}

  }

editSubCatagory(data){
  this.setState({
    selected_sub:data,
    sub_name:data.sub_catagory_name,
    description:data.description,
    videolink:data.videolink

  },this.toggleEdit)
}
addSubCatagory(data){
  this.setState({
    sub_name:'',
    description:''
  },this.toggleAdd)
}
  renderEditModal(){


   
    if(this.state.edit_sub==true){

 
    return(
      <MDBModal isOpen={this.state.edit_sub} toggle={this.toggleEdit} >
   
          <MDBCardHeader style={{backgroundColor:'#FF000F',display:'flex',justifyContent:'center'}}>
                      <text  style={{color:'white',padding:10,fontSize:20,fontWeight:'bold'}}>
                        <MDBIcon icon="edit-square" /> 
                        
                        Edit
                      </text>
                      
                    </MDBCardHeader>
              <MDBModalBody>
          
      
                       <div style={{display:"flex",alignItems:'center',justifyContent:'space-between',width:'100%'}}>
                  <text style={{margin:10,width:"30%"}}>Name</text>
          <input
                 value={this.state.sub_name}
        onChange={this.handleChangename}
          placeholder="Name"
          icon="money-check-alt"
            type="text"
            id="new_name"
            className="form-control"
            required
            
          /></div>



<div style={{display:"flex",alignItems:'center',justifyContent:'space-between',width:'100%'}}>
                  <text style={{margin:10,width:"30%"}}>Description</text>
          <input
                 value={this.state.description}
        onChange={this.handleChangedes}
          placeholder="Description"
          icon="money-check-alt"
            type="text"
            id="new_des"
            className="form-control"
            required
            
          /></div>
<div style={{display:"flex",alignItems:'center',justifyContent:'space-between',width:'100%'}}>
                  <text style={{margin:10,width:"30%"}}>Video Link</text>
          <input
                 value={this.state.videolink}
        onChange={(text)=>this.setState({videolink:text.target.value})}
          placeholder="Video Link"
          icon="money-check-alt"
            type="text"
            id="new_des"
            className="form-control"
            required
            
          /></div>
         
         {this.renderEditImage()}



              </MDBModalBody>
              <MDBModalFooter style={{padding:5}}>
              <Button className="confirm" style={{backgroundColor:'#FF000F'}} onClick={()=>this.editSub()}>Confirm</Button>
              <Button className="confirm" style={{backgroundColor:'#FF000F'}} onClick={()=>this.setState({edit_sub:false})}>Cancel</Button>
              
               
              </MDBModalFooter>
        </MDBModal>
    )
  }
}
renderCauseImage(){
  console.log()
  if(this.state.cause_image.length==0){
    return(<div className="upload-btn-wrapper"  
    style={{cursor:'pointer',display:'flex',justifyContent:'space-between',width:'fit-content',alignItems:'center'}}>
    <text  style={{margin:10}} >Image</text>
    <input type="file"  onChange={this.handleChangeimage}/>
  </div>)
  }
  else{
    return(
      <div  style={{cursor:'pointer',display:'flex',justifyContent:'space-between',width:'-webkit-fill-available',alignItems:'center'}}>
    <text style={{color:'black',margin:10}}>{this.state.cause_image.name}</text>
    <MDBIcon onClick={()=>this.setState({cause_image:''})} icon="times-circle" />
    </div>
    )
  }
}
renderEditImage(){
  console.log()
  if(this.state.cause_image.length==0){
    return(<div className="upload-btn-wrapper"  
    style={{cursor:'pointer',display:'flex',justifyContent:'space-between',width:'fit-content',alignItems:'center'}}>
    <text  style={{margin:10}} >Image</text>
    <input type="file"  onChange={this.handleChangeEditimage}/>
  </div>)
  }
  else{
    return(
      <div  style={{cursor:'pointer',display:'flex',justifyContent:'space-between',width:'-webkit-fill-available',alignItems:'center'}}>
    <text style={{color:'black',margin:10}}>{this.state.edit_image.name}</text>
    <MDBIcon onClick={()=>this.setState({edit_image:''})} icon="times-circle" />
    </div>
    )
  }
}
renderAddModal(){


   
  if(this.state.add_sub==true){


  return(
    <MDBModal isOpen={this.state.add_sub} toggle={this.toggleAdd} >
 
        <MDBCardHeader style={{backgroundColor:'#FF000F',display:'flex',justifyContent:'center'}}>
                    <text  style={{color:'white',padding:10,fontSize:20,fontWeight:'bold'}}>
                      <MDBIcon icon="plus-square" className='white-text'/> 
                      &nbsp;
                         Add Cause
                    </text>
                    
                  </MDBCardHeader>
            <MDBModalBody>
        
    
                     <div style={{display:"flex",alignItems:'center',justifyContent:'space-between',width:'100%'}}>
                <text style={{margin:10,width:"30%"}}>Name</text>
        <input
               value={this.state.sub_name}
      onChange={this.handleChangename}
        placeholder="Name"
        icon="money-check-alt"
          type="text"
          id="new_name"
          className="form-control"
          required
          
        /></div>



<div style={{display:"flex",alignItems:'center',justifyContent:'space-between',width:'100%'}}>
                <text style={{margin:10,width:"30%"}}>Description</text>
        <input
               value={this.state.description}
      onChange={this.handleChangedes}
        placeholder="Description"
        icon="money-check-alt"
          type="text"
          id="new_des"
          className="form-control"
          required
          
        /></div>
<div style={{display:"flex",alignItems:'center',justifyContent:'space-between',width:'100%'}}>
                  <text style={{margin:10,width:"30%"}}>Video Link</text>
          <input
                 value={this.state.videolink}
        onChange={(text)=>this.setState({videolink:text.target.value})}
          placeholder="Video Link"
          icon="money-check-alt"
            type="text"
            id="new_des"
            className="form-control"
            required
            
          /></div>
               
                {this.renderCauseImage()}
          
      




            </MDBModalBody>
            <MDBModalFooter style={{padding:5}}>
            <Button className="confirm" style={{backgroundColor:'#FF000F'}}  onClick={()=>this.addSub()}>Confirm</Button>
            <Button className="confirm" style={{backgroundColor:'#FF000F'}}  onClick={()=>this.setState({add_sub:false})}>Cancel</Button>
            
             
            </MDBModalFooter>
      </MDBModal>
  )
}
}

handleChangename=(e)=>{
  this.setState({sub_name:e.target.value});
}
handleChangedes=(e)=>{
  this.setState({description:e.target.value});
}
handleChangeimage=(e)=>{
  this.setState({cause_image:e.target.files[0]});
}
handleChangeEditimage=(e)=>{
  this.setState({edit_image:e.target.files[0]});
}
logout(){
  window.localStorage.clear()
window.location.reload()

}
toggleVideo = () => {

  this.setState({
    video_modal: !this.state.video_modal,
   },()=>{
if(this.refs.player){
this.refs.player.load()
}

   });
}
renderVideoModal(){


   
  if(this.state.video_modal==true){


  return(
    <MDBModal size='lg' isOpen={this.state.video_modal} toggle={this.toggleYoutube} centered>
 
       
            <MDBModalBody>
            <Player ref="player"
            autoPlay={true}
      playsInline
      
      poster="../assets/abbhome.png"
      src={this.state.selected_video}
    />
{/* <video height="256" loop autoplay muted controls id="vid">
        
         <source type="video/aspx" src={this.state.selected_video}></source>
</video> */}

            </MDBModalBody>
            <MDBModalFooter style={{padding:5}}>
           
           
            <text className="videoButton" style={{color:'#fff'}}  onClick={()=>this.setState({video_modal:false})}>{this.state.closeText}</text>   
             
            </MDBModalFooter>
      </MDBModal>
  )
}
}
renderoverLay(){
  if(this.state.mobileMenu){
  
    return (<div id='overlayCat' onClick={()=>this.setState({mobileMenu:false})}></div>)
  }
}
toggleLang = () => {
   
  this.setState({
    lang_mod: !this.state.lang_mod,
   });
}
renderlangModal(){


   
  if(this.state.lang_mod==true){


  return(
    <MDBModal className='mobileLang' size='md' isOpen={this.state.lang_mod} toggle={this.toggleLang} centered>
 
       
            <MDBModalBody>
            {/* { (this.state.languages).map((data,key) => {
    
    if(data.selected==0){
     return ( 
       <MDBDropdownItem style={{backgroundColor:'#fff',color:'#6e6e6e',textAlign:'center',padding:12}} className='dropButton'  onClick={()=>this.selectLanguage(data)}>{data.language}</MDBDropdownItem>
           
            )
    }
    else{
     return ( 
       <MDBDropdownItem style={{backgroundColor:'#d2d2d2',color:'black',textAlign:'center',padding:12}}  >{data.language}</MDBDropdownItem>
           
            )
    }
       })
       } */}
       <MDBDropdownItem style={{backgroundColor:'#d2d2d2',color:'black',textAlign:'center',padding:12}}  >ENGLISH</MDBDropdownItem>
                       </MDBModalBody>
          
      </MDBModal>
  )
}
}
  render() {
var that  = this
    
    const sidebarBackground = {
      backgroundColor: "#1C2231"
    };
    if(this.state.loading){
   
      return(<div className="loadingSpin"><HalfCircleSpinner color="#ff000f"/></div>)
    }
    else{

    return (
      <div className="bodyDivCl" onScroll={()=>this.setState({showBanner:!this.isInViewport()})} ref={that.paneDidMount}>

   <div
      id="sidebar"
      className="sideSub"
      data-color="#1C2231"
     
    >
      <div className="sidebar-background" style={sidebarBackground} />
  
      <div className="sidebar-wrapper" style={{display:'flex',flexDirection:'column',justifyContent:'flex-start',alignItems:'center',marginTop:10}}>
       
      {dashboardRoutes.map((prop, key) => {
            if(prop.path=='/causes'){
              return (
               
               <div
               key={key}
className="sideActive"

 onClick={()=>this.props.history.push(`${prop.path}`)}                
>
<img onError={()=>this.setState({showAdded:true})} alt='Loading...'  src={require('../../assets/navbar/Cause_Hover.png')} style={{width:25}}/>

<text style={{marginTop:5}}>{prop.name}</text>
</div>
               
             );
             return null;
            }
            else{
              return (
               
               <div
className="sideInActive"

 onClick={()=>this.props.history.push(`${prop.path}`)}                
>
<img onError={()=>this.setState({showAdded:true})} alt='Loading...' src={require('../../assets/navbar/'+prop.icon)} style={{width:25}}/>
<text style={{marginTop:5}}>{prop.name}</text>
</div>
               
             );
             return null; 
            }
           
          })}
          {this.renderExitButton()}
      </div>
    </div>
{this.mobileMenu()}
      <div className="conTable">
 
      <div style={{backgroundColor:'white'}}>
      <div className="topMenu">
      
    
      <div style={{display:'flex',justifyContent:'center'}}>{this.renderLanguages()}</div>
       
      <div style={{display:'flex',justifyContent:'center'}}><img onError={()=>this.setState({showAdded:true})} alt='Loading...'  src={require('../../assets/abb_logo.png')} className='abb_logo'/></div>
         
       
              
               
                </div>
    
              <h1 className='causeHead'>
                     
                      
                      {this.state.heading}
                    
                    </h1>
      </div>
   <div className='rowCont'>

   <MDBRow >

{this.renderList()}
</MDBRow>
 <div className='next' style={{marginTop:10}} id='nextb' ref={(el) => this.yourElement = el}>
       <h5>{dashboardRoutes[2].name}</h5> <text onClick={()=>this.props.history.push('solutions')} className='arrowRed'></text></div>
      {this.renderoverLay()}
{this.renderAddModal()}
{this.renderYouTubeModal()}   
{this.renderEditModal()}
{this.renderExitModal()}
{this.renderScroll()}
{this.renderlangModal()}
               </div>
       <div id='last'></div>       
      
</div>
 

      
   
     
{this.showUpdateBar()}




    


    
 
 
               </div>
              
    );

        }
  }
}
export default Subcatagories