import React, { Component } from "react";
import ReactTooltip from 'react-tooltip'
import { MDBNavbar, MDBDropdownItem,MDBDropdownMenu,  MDBIcon, MDBTable, MDBTableBody, MDBTableHead, MDBBtn,MDBRow,
  MDBCol,
  MDBDropdown,
  MDBDropdownToggle,
  MDBCardHeader, MDBModal, MDBModalBody, MDBInput, MDBModalFooter,MDBCollapse,MDBPopover, MDBPopoverBody, MDBTooltip,  } from 'mdbreact';
  import dashboardRoutes from "../../routes/dashboard";
  import swal from 'sweetalert'
  import { Grid, Row, Col,Image,ListGroup,ListGroupItem,Button,Form,FormControl,Checkbox,ControlLabel, } from "react-bootstrap";
import './footprints.css'
import Select from 'react-select'
import CryptoJS from 'crypto'
import posed, { PoseGroup } from 'react-pose'
const Item = posed.div({
  enter: { opacity: 1 },
  exit: { opacity: 0 }
})
const items = ['LV capacitors','LV capacitors','LV capacitors']
const ItemList = ({ items }) => (
  <PoseGroup>
    {items.map((item) => <Item key={item.id} />)}
  </PoseGroup>
)
class Country extends Component {
  constructor(props) {
    super(props);
  this.state={
    loading:false,info_heads:[],add_head:false,clients:[],
    new_name:"",new_des:"",new_hsn:"",new_type:"Select Cost Type",selected_id:"",
    selected_language:[],users:[],new_pass:'',add_user:false,exit:false,
    new_name:'',new_email:'',new_password:'',edit_name:'',edit_email:'',edit_password:'',
    languages:[],new_user_languages:[],edit_user:false,user_to_edit:null,list_modal:false,
    edit_user_languages:[],superadmin:false,admin:false,super_pass:'',continent:'',selected_country:null,selected_contintent_name:'',
    list:[]
  }
  }
  componentWillMount(){

  }
  togglelist = () => {
   
    this.setState({
      list_modal: !this.state.list_modal,
     });
  }
  exitApp(){
    var userAgent = navigator.userAgent.toLowerCase();
  if (userAgent.indexOf(' electron/') > -1) {
    const remote = window.require('electron').remote
    let w = remote.getCurrentWindow()
    w.close()
  }
  
  
  }
     renderExitModal(){
  
  
     
      if(this.state.exit==true){
    
    
      return(
        <MDBModal size='md' isOpen={this.state.exit} centered toggle={this.toggleExit} >
     
           
     <MDBModalBody style={{    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',flexDirection:'column',paddingTop:'4vh',paddingBottom:'4vh'}}>
              <img alt='Loading...' src={require('../../assets/Exit.jpg')} className='catGif'/>
              <p>Are you sure you want to exit this application?</p>
           <div style={{display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-evenly'}}>      <text onClick={()=>this.exitApp()} style={{backgroundColor:'#ff000f',color:'white'}} className='seriesText'>Exit</text>

              
              <text onClick={()=>this.setState({exit:false})} className='seriesText'>Cancel</text>

         
            </div>
             
              </MDBModalBody>
  
          </MDBModal>
      )
    }
    }
    
    toggleExit = () => {
     
      this.setState({
        exit: !this.state.exit,
       });
    }
 
  // renderListModal(){


   
  //   if(this.state.list_modal==true){
  
  
  //   return(
  //     <MDBModal isOpen={this.state.list_modal} centered toggle={this.togglelist}  size='md'>
  //     <MDBModalBody  >      <div  className="listBar">
  //            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'90%',alignItems:'center'}}> <h5  style={{color:'#000',margin:5,}}>{this.state.list[0].name}</h5> 
  //            <text style={{fontFamily:'ABBicons',cursor:'pointer'}}  onClick={()=>this.setState({list_modal:false})} ></text>
  //        </div>
         
                   
  //                   { (this.state.list[0].data).map((data,key) => {
  
  // return ( 
  //   <li className='lightext' style={{fontSize:16,}}>{data}</li>
      
  //      )
  // })
  // }
  //            </div>
  //            </MDBModalBody>

  //            </MDBModal>
  //   )
  // }
  // }
  renderListModalAmerica(){

if(this.state.selected_country){
   
    if(this.state.selected_country=='San'){
      return(
        <div  className="listBarSan">
              <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'98%',alignItems:'center'}}> <h5  style={{color:'#000',margin:5,}}>{this.state.list[0].name}</h5> 
              <text style={{fontFamily:'ABBicons',cursor:'pointer'}}  onClick={()=>this.setState({selected_country:null})} ></text>
          </div>
          
                    
                     { (this.state.list[0].data).map((data,key) => {
   
   return ( 
     <li className='lightext' style={{fontSize:15,paddingLeft:10,margin:4}}>{data}</li>
       
        )
   })
   }
              </div>
            
     )
    }
  else if(this.state.selected_country=='Cubic'){
      return(
        <div  className="listBarCubic">
              <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'98%',alignItems:'center'}}> <h5  style={{color:'#000',margin:5,}}>{this.state.list[0].name}</h5> 
              <text style={{fontFamily:'ABBicons',cursor:'pointer'}}  onClick={()=>this.setState({selected_country:null})} ></text>
          </div>
          
                    
                     { (this.state.list[0].data).map((data,key) => {
   
   return ( 
     <li className='lightext' style={{fontSize:15,paddingLeft:10,margin:4}}>{data}</li>
       
        )
   })
   }
              </div>
            
     )
    }
   else if(this.state.selected_country=='Guar'){
      return(
        <div  className="listBarGuar">
              <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'98%',alignItems:'center'}}> <h5  style={{color:'#000',margin:5,}}>{this.state.list[0].name}</h5> 
              <text style={{fontFamily:'ABBicons',cursor:'pointer'}}  onClick={()=>this.setState({selected_country:null})} ></text>
          </div>
          
                    
                     { (this.state.list[0].data).map((data,key) => {
   
   return ( 
     <li className='lightext' style={{fontSize:15,paddingLeft:10,margin:4}}>{data}</li>
       
        )
   })
   }
              </div>
            
     )
    }
   
  }

  }
  renderListModalAfrica(){

    if(this.state.selected_country){
       
        if(this.state.selected_country=='Modder'){
          return(
            <div  className="listBarModder">
                  <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'98%',alignItems:'center'}}> <h5  style={{color:'#000',margin:5,}}>{this.state.list[0].name}</h5> 
                  <text style={{fontFamily:'ABBicons',cursor:'pointer'}}  onClick={()=>this.setState({selected_country:null})} ></text>
              </div>
              
                        
                         { (this.state.list[0].data).map((data,key) => {
       
       return ( 
         <li className='lightext' style={{fontSize:15,paddingLeft:10,margin:4}}>{data}</li>
           
            )
       })
       }
                  </div>
                
         )
        }
      else if(this.state.selected_country=='Cairo'){
          return(
            <div  className="listBarCairo">
                  <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'98%',alignItems:'center'}}> <h5  style={{color:'#000',margin:5,}}>{this.state.list[0].name}</h5> 
                  <text style={{fontFamily:'ABBicons',cursor:'pointer'}}  onClick={()=>this.setState({selected_country:null})} ></text>
              </div>
              
                        
                         { (this.state.list[0].data).map((data,key) => {
       
       return ( 
         <li className='lightext' style={{fontSize:15,paddingLeft:10,margin:4}}>{data}</li>
           
            )
       })
       }
                  </div>
                
         )
        }
    
       
      }
    
      }
      renderListModalOceana(){

        if(this.state.selected_country){
           
            if(this.state.selected_country=='Lily'){
              return(
                <div  className="listBarLily">
                      <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'98%',alignItems:'center'}}> <h5  style={{color:'#000',margin:5,}}>{this.state.list[0].name}</h5> 
                      <text style={{fontFamily:'ABBicons',cursor:'pointer'}}  onClick={()=>this.setState({selected_country:null})} ></text>
                  </div>
                  
                            
                             { (this.state.list[0].data).map((data,key) => {
           
           return ( 
             <li className='lightext' style={{fontSize:15,paddingLeft:10,margin:4}}>{data}</li>
               
                )
           })
           }
                      </div>
                    
             )
            }
       
        
           
          }
        
          }
  renderListModalEurope(){
    console.log(this.state.selected_country)
    if(this.state.selected_country){
   
   if(this.state.selected_country=='Jumet'){
        return(
          <div  className="listBarJumet">
                <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'98%',alignItems:'center'}}> <h5  style={{color:'#000',margin:5,}}>{this.state.list[0].name}</h5> 
                <text style={{fontFamily:'ABBicons',cursor:'pointer'}}  onClick={()=>this.setState({selected_country:null})} ></text>
            </div>
            
                      
                       { (this.state.list[0].data).map((data,key) => {
     
     return ( 
       <li className='lightext' style={{fontSize:15,paddingLeft:10,margin:4}}>{data}</li>
         
          )
     })
     }
                </div>
              
       )
      }
     else if(this.state.selected_country=='Sevl'){
        return(
          <div  className="listBarSevl">
                <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'98%',alignItems:'center'}}> <h5  style={{color:'#000',margin:5,}}>{this.state.list[0].name}</h5> 
                <text style={{fontFamily:'ABBicons',cursor:'pointer'}}  onClick={()=>this.setState({selected_country:null})} ></text>
            </div>
            
                      
                       { (this.state.list[0].data).map((data,key) => {
     
     return ( 
       <li className='lightext' style={{fontSize:15,paddingLeft:10,margin:4}}>{data}</li>
         
          )
     })
     }
                </div>
              
       )
      }
      else if(this.state.selected_country=='Brom'){
        return(
          <div  className="listBarBrom">
                <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'98%',alignItems:'center'}}> <h5  style={{color:'#000',margin:5,}}>{this.state.list[0].name}</h5> 
                <text style={{fontFamily:'ABBicons',cursor:'pointer'}}  onClick={()=>this.setState({selected_country:null})} ></text>
            </div>
            
                      
                       { (this.state.list[0].data).map((data,key) => {
     
     return ( 
       <li className='lightext' style={{fontSize:15,paddingLeft:10,margin:4}}>{data}</li>
         
          )
     })
     }
                </div>
              
       )
      }
     else if(this.state.selected_country=='Ludvika'){
        return(
          <div  className="listBarLudvika">
                <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'98%',alignItems:'center'}}> <h5  style={{color:'#000',margin:5,}}>{this.state.list[0].name}</h5> 
                <text style={{fontFamily:'ABBicons',cursor:'pointer'}}  onClick={()=>this.setState({selected_country:null})} ></text>
            </div>
            
                      
                       { (this.state.list[0].data).map((data,key) => {
     
     return ( 
       <li className='lightext' style={{fontSize:15,paddingLeft:10,margin:4}}>{data}</li>
         
          )
     })
     }
                </div>
              
       )
      }
    }
  }
  renderListModalAsia(){
    console.log(this.state.selected_country)
    if(this.state.selected_country){
   
   if(this.state.selected_country=='Dammam'){
        return(
          <div  className="listBarDammam">
                <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'98%',alignItems:'center'}}> <h5  style={{color:'#000',margin:5,}}>{this.state.list[0].name}</h5> 
                <text style={{fontFamily:'ABBicons',cursor:'pointer'}}  onClick={()=>this.setState({selected_country:null})} ></text>
            </div>
            
                      
                       { (this.state.list[0].data).map((data,key) => {
     
     return ( 
       <li className='lightext' style={{fontSize:15,paddingLeft:10,margin:4}}>{data}</li>
         
          )
     })
     }
                </div>
              
       )
      }
     else if(this.state.selected_country=='Bankok'){
        return(
          <div  className="listBarBankok">
                <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'98%',alignItems:'center'}}> <h5  style={{color:'#000',margin:5,}}>{this.state.list[0].name}</h5> 
                <text style={{fontFamily:'ABBicons',cursor:'pointer'}}  onClick={()=>this.setState({selected_country:null})} ></text>
            </div>
            
                      
                       { (this.state.list[0].data).map((data,key) => {
     
     return ( 
       <li className='lightext' style={{fontSize:15,paddingLeft:10,margin:4}}>{data}</li>
         
          )
     })
     }
                </div>
              
       )
      }
      else if(this.state.selected_country=='Bengaluru'){
        return(
          <div  className="listBarBengaluru">
                <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'98%',alignItems:'center'}}> <h5  style={{color:'#000',margin:5,}}>{this.state.list[0].name}</h5> 
                <text style={{fontFamily:'ABBicons',cursor:'pointer'}}  onClick={()=>this.setState({selected_country:null})} ></text>
            </div>
            
                      
                       { (this.state.list[0].data).map((data,key) => {
     
     return ( 
       <li className='lightext' style={{fontSize:15,paddingLeft:10,margin:4}}>{data}</li>
         
          )
     })
     }
                </div>
              
       )
      }
     else if(this.state.selected_country=='Xiam'){
        return(
          <div  className="listBarXiam">
                <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'98%',alignItems:'center'}}> <h5  style={{color:'#000',margin:5,}}>{this.state.list[0].name}</h5> 
                <text style={{fontFamily:'ABBicons',cursor:'pointer'}}  onClick={()=>this.setState({selected_country:null})} ></text>
            </div>
            
                      
                       { (this.state.list[0].data).map((data,key) => {
     
     return ( 
       <li className='lightext' style={{fontSize:15,paddingLeft:10,margin:4}}>{data}</li>
         
          )
     })
     }
                </div>
              
       )
      }
    }
  }
  componentDidMount(){

  }
  getuserdata=async()=>{
    this.setState({
      loading:true
    })
  
 console.log('getVideo')

var responseJson=[{"language":"English","language_id":1,"active":0,"key":1,"label":"English",selected:0},
{"language":"German","language_id":2,"active":0,"key":2,"label":"German",selected:0},
{"language":"Chinese","language_id":3,"active":0,"key":3,"label":"Chinese",selected:0},
{"language":"Spanish","language_id":4,"active":0,"key":4,"label":"Spanish",selected:0},
{"language":"French","language_id":5,"active":0,"key":5,"label":"French",selected:0}]

  for(var v=0;v<responseJson.length;v++){
    responseJson[v].label=responseJson[v].language
    responseJson[v].value=responseJson[v].language_id
  }
  var defaultLanguage=window.localStorage.getItem('admindefault')
  if(defaultLanguage==null){
    responseJson[0].selected=1
   this.setState({
     languages:responseJson,
     selected_language:responseJson[0]
   },()=> this.getContent())
  }
  else{
    if(JSON.parse(defaultLanguage).language=='German'){
      dashboardRoutes[0].name='Zuhause'
      dashboardRoutes[1].name='Ursachen'
      dashboardRoutes[2].name='Lösungen'
      dashboardRoutes[3].name='Wertversprechen'
      dashboardRoutes[4].name='Fußabdruck'
      dashboardRoutes[5].name='Fallstudien'
   
     this.setState({logoutText:'Ausloggen',edit_content:'Inhalt bearbeiten',videoText:'Video',nextText:'Ursache für schlechte Stromqualität'})

    }
    else if(JSON.parse(defaultLanguage).language=='Chinese'){
      dashboardRoutes[0].name='家'
      dashboardRoutes[1].name='原因'
      dashboardRoutes[2].name='解决方案'
      dashboardRoutes[3].name='价值主张'
      dashboardRoutes[4].name='脚印'
      dashboardRoutes[5].name='实例探究'
    
      this.setState({logoutText:'登出',edit_content:'编辑内容',videoText:'视频',nextText:'电能质量差的原因'})

 
     }
     else if(JSON.parse(defaultLanguage).language=='Spanish'){
      dashboardRoutes[0].name='Casa'
      dashboardRoutes[1].name='Causas'
      dashboardRoutes[2].name='Soluciones'
      dashboardRoutes[3].name='Propuesta de valor'
      dashboardRoutes[4].name='Huella'
      dashboardRoutes[5].name='Estudios de caso'
      this.setState({logoutText:'Cerrar sesión',edit_content:'Contenido editado',videoText:'Vídeo',nextText:'Causa de mala calidad eléctrica'})

 
     }
     else if(JSON.parse(defaultLanguage).language=='French'){
      dashboardRoutes[0].name='Accueil'
      dashboardRoutes[1].name='Les causes'
      dashboardRoutes[2].name='Solutions'
      dashboardRoutes[3].name='Proposition de valeur'
      dashboardRoutes[4].name='Empreinte'
      dashboardRoutes[5].name='Études de cas'
       
     
      this.setState({logoutText:'Connectez - Out',edit_content:'Modifier le contenu',videoText:'Vidéo',nextText:`Cause de mauvaise qualité d'alimentation`})

 
     }
    console.log(JSON.parse(defaultLanguage),'selected_language')
    responseJson[JSON.parse(defaultLanguage).key-1].selected=1
   this.setState({
     languages:responseJson,
    
     selected_language:JSON.parse(defaultLanguage)
   },()=> this.getContent())
  }
  
 }
 getContent=async()=>{
  if(!navigator.onLine){
    var offline_content=window.localStorage.getItem('footprint')
    if(offline_content==null){
      swal('Warning','Check your network and refresh','warning')
      this.setState({
        loading:false
      })
    }
    else{
     
      this.setState({
       
        heading:JSON.parse(JSON.parse(offline_content)[0].heading),
        paragraph:JSON.parse(JSON.parse(offline_content)[0].paragraph),
        data:JSON.parse(offline_content),
         loading:false
        },()=>console.log(this.state.heading,this.state.paragraph))
    }
         }
         else{
           
  this.setState({loading:true})
   let details = {
     language_id:this.state.selected_language.language_id
   };
   let formBody = [];
   for (let property in details) {
    let encodedKey = encodeURIComponent(property);
    let encodedValue = encodeURIComponent(details[property]);
    formBody.push(encodedKey + "=" + encodedValue);
   }
   formBody = formBody.join("&");
   console.log(details)
   this.setState({
     response: fetch('https://www.nativeapps.in/get_content_footprint', {
       method: 'POST',
       headers: {
        
         'Content-Type': 'application/x-www-form-urlencoded',
         'Cache-Control': 'max-age=31536000'
     },
   body:formBody
       
     }).then((response) => response.json())
     .then((responseJson)=>{
      window.localStorage.removeItem('footprint')
      window.localStorage.setItem('footprint',JSON.stringify(responseJson))
    
       this.setState({
       
       heading:JSON.parse(responseJson[0].heading),
       paragraph:JSON.parse(responseJson[0].paragraph),
       data:responseJson,
        loading:false
       },()=>console.log(this.state.heading,this.state.paragraph))
         })
         .catch((error) => {
           this.setState({
             loading:false
           })
           swal("Warning!", "Check your network!", "warning");
         console.log(error)
             })
            })

         }
       }

  getContinent(){
  var continent=  window.localStorage.getItem('selected_cont')
  console.log(continent)
   if(JSON.parse(continent)==null){
    this.props.history.push('footprints')
   }
   else{
     if(JSON.parse(continent).countinent=='america'){
      this.setState({continent:JSON.parse(continent).countinent,selected_contintent_name:'Americas'})
     }
   else if(JSON.parse(continent).countinent=='africa'){
    this.setState({continent:JSON.parse(continent).countinent,selected_contintent_name:'Africa'})
   }
   else if(JSON.parse(continent).countinent=='asia'){
    this.setState({continent:JSON.parse(continent).countinent,selected_contintent_name:'Asia'})
   }
   else if(JSON.parse(continent).countinent=='europe'){
    this.setState({continent:JSON.parse(continent).countinent,selected_contintent_name:'Europe'})
   }
   else if(JSON.parse(continent).countinent=='australia'){
    this.setState({continent:JSON.parse(continent).countinent,selected_contintent_name:'Oceania'})
   }
   }
  
  }


  
 
  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

 



 

  selectCountry(data){
  //   if(data=='Brom'){
  //     this.setState({
  //       list:[{name:'Bromborough – UK',data:['LV capacitor banks','LV stepless var compensators',
  //       'LV harmonic filters','Battery energy storage solutions']}],
  //     selected_country:data },this.togglelist)
  //   }
  //   else if(data=='Sevl'){
  //     this.setState({
  //       list:[{name:'Sevlievo – Bulgaria',data:['MV / HV capacitor banks']}],
  //     selected_country:data },this.togglelist)
  //   }
  // else if(data=='Ludvika'){
  //     this.setState({
  //       list:[{name:'Ludvika – Sweden',data:['DC capacitors','MV / HV capacitor banks','Accessories']}],
  //     selected_country:data },this.togglelist)
  //   }
  //   else if(data=='Jumet'){
  //     this.setState({
  //       list:[{name:'Jumet – Belgium',data:['LV capacitors','LV capacitor banks','LV stepless var compensators',
  //       'LV harmonic filters','Battery energy storage solutions','Accessories']}],
  //     selected_country:data },this.togglelist)
  //   }
  //   else if(data=='Modder'){
  //     this.setState({
  //       list:[{name:'Modderfontein – South Africa',data:['LV capacitor banks','LV stepless var compensators',
  //       'LV harmonic filters','Battery energy storage solutions']}],
  //     selected_country:data },this.togglelist)
  //   }
  //   else if(data=='Cairo'){
  //     this.setState({
  //       list:[{name:'Cairo – Egypt',data:['LV capacitors','LV stepless var compensators',
  //       'LV harmonic filters','Battery energy storage solutions']}],
  //     selected_country:data },this.togglelist)
  //   }
  //   else if(data=='Cubic'){
  //     this.setState({
  //       list:[{name:'Québec – Canada',data:['MV / HV capacitor banks','MV / HV filters','LV capacitors','LV stepless var compensators',
  //       'LV harmonic filters','Battery energy storage solutions']}],
  //     selected_country:data },this.togglelist)
  //   }
  //   else if(data=='San'){
  //     this.setState({
  //       list:[{name:'San Luis Potosi – Mexico',data:['MV / HV capacitor banks','MV / HV harmonic filters','LV capacitors','LV capacitor banks',]}],
  //     selected_country:data },this.togglelist)
  //   }
  //   else if(data=='Guar'){
  //     this.setState({
  //       list:[{name:'Guarulhos – Brazil',data:['MV / HV capacitor banks','MV / HV capacitor filters','LV capacitor banks',]}],
  //     selected_country:data },this.togglelist)
  //   }
  //   else if(data=='Lily'){
  //     this.setState({
  //       list:[{name:'Lilydale – Australia',data:['MV / HV capacitor banks','MV / HV filters','LV stepless var compensators',
  //       'LV harmonic filters','Battery energy storage solutions','Accessories']}],
  //     selected_country:data },this.togglelist)
  //   }
  //   else if(data=='Dammam'){
  //     this.setState({
  //       list:[{name:'Dammam – Saudi Arabia',data:['LV capacitor banks','LV stepless var compensators',
  //       'LV harmonic filters','Battery energy storage solutions']}],
  //     selected_country:data },this.togglelist)
  //   }
  //   else if(data=='Xiam'){
  //     this.setState({
  //       list:[{name:'Xi’an – China',data:['MV / HV capacitors','MV / HV capacitor banks','MV / HV harmonic filters','LV stepless var compensators',
  //       'LV harmonic filters','Battery energy storage solutions']}],
  //     selected_country:data },this.togglelist)
  //   }
  //   else if(data=='Bankok'){
  //     this.setState({
  //       list:[{name:'Bangkok – Thailand',data:['LV capacitors','LV capacitor banks','LV stepless var compensators',
  //       'LV harmonic filters','Battery energy storage solutions']}],
  //     selected_country:data },this.togglelist)
  //   }
  //   else if(data=='Bengaluru'){
  //     this.setState({
  //       list:[{name:'Bengaluru – India',data:['MV / HV capacitors','MV / HV capacitor banks','LV capacitors','LV capacitor ßbanks',
  //       'LV stepless var compensators',
  //       'LV harmonic filters','Battery energy storage solutions']}],
  //     selected_country:data },this.togglelist)
  //   }
  }



selectLanguage(data,key){
  window.localStorage.removeItem('admindefault')
    window.localStorage.setItem('admindefault',JSON.stringify(data))
 
this.setState({
selected_language:data
},()=>window.location.reload())
}
renderLanguages(){
  console.log(this.state.languages)
      if(this.state.loading==false){
        if(this.state.languages.length>0){
          return(<div  
        
             
            
            style={{backgroundColor:'rgb(255, 255, 255, 0.1)',cursor:'pointer',display:'flex',alignItems:'center',marginRight:100,marginTop:'.5%'}}>
          <MDBIcon size="2x"   className="grey-text" icon="globe-africa" />
          <MDBDropdown className='languageDiv' >
         <MDBDropdownToggle caret color="#004080" size="md" >
         <text className='languageText'>{this.state.selected_language.language}</text>
         </MDBDropdownToggle>
         <MDBDropdownMenu style={{margin:0,padding:0,borderRadius:0}}> 
         { (this.state.languages).map((data,key) => {
      
   if(data.selected==0){
    return ( 
      <MDBDropdownItem style={{backgroundColor:'#fff',color:'#6e6e6e',textAlign:'center',padding:12}} className='dropButton'  onClick={()=>this.selectLanguage(data)}>{data.language}</MDBDropdownItem>
          
           )
   }
   else{
    return ( 
      <MDBDropdownItem style={{backgroundColor:'#d2d2d2',color:'black',textAlign:'center',padding:12}}  >{data.language}</MDBDropdownItem>
          
           )
   }
      })
      }
      </MDBDropdownMenu>
      </MDBDropdown>
        
            </div>)
         }
       
      }
         
        }
  render() {

    
    const sidebarBackground = {
      backgroundColor: "#1C2231"
    };
    
    return(<div></div> )

    
  }
}
export default Country