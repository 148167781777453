import React, { Component } from "react";
import ReactTooltip from 'react-tooltip'
import { MDBNavbar, MDBDropdownItem,MDBDropdownMenu,  MDBIcon, MDBTable, MDBTableBody, MDBTableHead, MDBBtn,MDBRow,
  MDBCol,
  MDBDropdown,
  MDBDropdownToggle,
  MDBCardHeader, MDBModal, MDBModalBody, MDBInput, MDBModalFooter,MDBCardBody,MDBPopover, MDBPopoverBody, MDBTooltip,  } from 'mdbreact';
  import dashboardRoutes from "../../routes/dashboard";
  import swal from 'sweetalert'
  import { Grid, Row, Col,Image,ListGroup,ListGroupItem,Button,Form,FormControl,Checkbox,ControlLabel, } from "react-bootstrap";
import './videos.css'


class Videos extends Component {
  constructor(props) {
    super(props);
  this.state={
    loading:false,info_heads:[],add_head:false,clients:[],
    new_name:"",new_des:"",new_hsn:"",new_type:"Select Cost Type",selected_id:"",add_product:false,
    selected_language:{"language":"English","language_id":1,"active":0},Videos:[],superadmin:false,admin:false
  }
  }



  toggleAdd = () => {
   
    this.setState({
      add_head: !this.state.add_head,
      new_name:"",new_des:"",new_hsn:"",new_type:"Select Cost Type",selected_id:"",
    });
  }

  componentDidMount(){
// this.getVideos()

  }
  componentWillMount(){

  }
    getDetails(){
      var userlogin=window.localStorage.getItem('userlogin')
      var adminlogin=window.localStorage.getItem('superadmin')
      if(userlogin==null && adminlogin==null){
        this.props.history.push('login')
      }
      else if(userlogin==null && adminlogin!=null){
        this.setState({superadmin:true})
      }
      else if(userlogin!=null && adminlogin==null){
        this.setState({admin:true})
      }
        }

  getVideos=async()=>{
    this.setState({
      loading:true
    })
  
    let details = {
    language_id:1
  };
  let formBody = [];
  for (let property in details) {
   let encodedKey = encodeURIComponent(property);
   let encodedValue = encodeURIComponent(details[property]);
   formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  
  this.setState({
   response: fetch('http://18.191.5.172:3000/get_all_admins', {
     method: 'GET',
     headers: {
      
       'Content-Type': 'application/x-www-form-urlencoded',
       'Cache-Control': 'max-age=31536000'
   },
  
    
     
   }).then((response) => response.json())
   .then((responseJson)=>{
  
     this.setState({
      Videos:responseJson,
       loading:false
     })
   
   }
  
   )
     .catch((error) => {
    this.setState({
      loading:false
    })
    swal("Warning!", "Check your network!", "warning");
  console.log(error)
      })
     })
  
  }
  
get_heads=async(data)=>{

  let details = {
    
  };
  
  let formBody = [];
  for (let property in details) {
   let encodedKey = encodeURIComponent(property);
   let encodedValue = encodeURIComponent(details[property]);
   formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  
  this.setState({
   response: fetch('http://52.15.32.193:3000/get_heads', {
     method: 'GET',
     headers: {
      
       'Content-Type': 'application/x-www-form-urlencoded',
     
   },
  
    
     
   }).then((response) => response.json())
   .then((responseJson)=>{
  console.log(responseJson,"get_heads")
  this.setState({info_heads:responseJson})
   
   }
  
   )
     .catch((error) => {
    this.setState({
      loading:false
    })
    alert("Warning!", "Check your network!", "warning");
  console.log(error)
      })
     })
  }

  
 
  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  convert_costType(type){
    if(type===1){return("One Time Cost")}
    else if(type===2){return("Recurring Cost")}
    else{return("INVALID")}
      }
  
editHead(data){

  var t="";
  

  if(data.type==1){t="One Time Cost"}
  else if(data.type==2){t="Recurring Cost"}
  else{t="Select Cost Type"}

this.setState({
new_name:data.name,
new_des:data.description,
new_hsn:data.hsn,
new_type:t,
selected_id:data.head_id,
add_head: !this.state.add_head,
})



}

  

  renderList(){

    if(this.state.loading){
      return(<div className="loadingSpin"><div className="loader border-top-primary border-right-warning border-bottom-success border-left-danger"></div></div>)
    }
    else{
      return (
        <div className="wrapper">
  
        
        
        <MDBTable striped responsive>
            <MDBTableHead color="unique-color-dark">
  
            <tr >
        <th style={{color:'white',textAlign:'center'}}>#</th>
        <th style={{color:'white',textAlign:'center'}}>Name</th>
        <th style={{color:'white',textAlign:'center'}}>Email</th>
        <th style={{color:'white',textAlign:'center'}}>Language Access</th>
        <th style={{color:'white',textAlign:'center'}}>Password</th>
      </tr>
            </MDBTableHead>
  
            <MDBTableBody>
  
            { this.state.Videos.map((data,key) => {
  
  return (   
  <tr key={key}>
  <td style={{textAlign:'center'}}>{key+1}</td>
  <td style={{textAlign:'center'}}>{data.admin_username}</td>
  <td style={{textAlign:'center'}}>{data.email}</td>
  <td style={{textAlign:'center'}}>Language Access</td>
  <td style={{textAlign:'center'}}>Password</td>

  
       
  </tr>     )
  
  
  })}
  
  
            </MDBTableBody>
  
  
          </MDBTable>
        
        
        </div>
      )
    }
   


  }



  addHead=async()=>{


if(this.state.selected_id===""){




    if(this.state.new_name.length==0||this.state.new_des.length==0||this.state.new_hsn.length==0||this.state.new_type=="Select Cost Type"){
      swal("Warning!", "Enter all fields!", "warning");

    }
    else{
     this.setState({new_name:"",new_des:"",new_hsn:"",new_type:"Select Cost Type",loading:true,add_head:false})
       var t="";
     if(this.state.new_type=="One Time Cost"){t=1;}
     if(this.state.new_type=="Recurring Cost"){t=2;}
     else{t=0;}

     console.log(t,"in add")

    let details = {
    name:this.state.new_name,
    description:this.state.new_des,
    hsn:this.state.new_hsn,
    type:t
    };
    
    let formBody = [];
    for (let property in details) {
    let encodedKey = encodeURIComponent(property);
    let encodedValue = encodeURIComponent(details[property]);
    formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    
    this.setState({
    response: fetch('http://52.15.32.193:3000/add_head', {
     method: 'POST',
     headers: {
      
       'Content-Type': 'application/x-www-form-urlencoded',
     
    },
    body:formBody
    
     
    }).then((response) => response.json())
    .then((responseJson)=>{
      window.location.reload()
    this.setState({loading:false})
    
    }
    
    )
     .catch((error) => {
    this.setState({
      loading:false
    })
    alert("Warning!", "Check your network!", "warning");
    console.log(error)
      })
     })





    }
    
    }


    else{

      

      if(this.state.new_name.length==0||this.state.new_des.length==0||this.state.new_hsn.length==0||this.state.new_type=="Select Cost Type"){
        swal("Warning!", "Enter all fields!", "warning");
  
      }
      else{


        this.setState({new_name:"",new_des:"",new_hsn:"",new_type:"Select Cost Type",loading:true,add_head:false})
        var t="";
      if(this.state.new_type=="One Time Cost"){t=1;}
      if(this.state.new_type=="Recurring Cost"){t=2;}
      else{t=0;}
 
      console.log(t,"in edit")
 
     let details = {
     name:this.state.new_name,
     description:this.state.new_des,
     hsn:this.state.new_hsn,
     type:t,
     head_id:this.state.selected_id
     };
     
     let formBody = [];
     for (let property in details) {
     let encodedKey = encodeURIComponent(property);
     let encodedValue = encodeURIComponent(details[property]);
     formBody.push(encodedKey + "=" + encodedValue);
     }
     formBody = formBody.join("&");
     
     this.setState({
     response: fetch('http://52.15.32.193:3000/edit_head', {
      method: 'POST',
      headers: {
       
        'Content-Type': 'application/x-www-form-urlencoded',
      
     },
     body:formBody
     
      
     }).then((response) => response.json())
     .then((responseJson)=>{
       window.location.reload()
     this.setState({loading:false})
     
     }
     
     )
      .catch((error) => {
     this.setState({
       loading:false
     })
     alert("Warning!", "Check your network!", "warning");
     console.log(error)
       })
      })





      }




    }

  }


  returnTitle(){
if(this.state.selected_id===""){return(<text>Add Head</text>)}
else{return(<text>Edit Head</text>)}

  }


  renderAddModal(){


   
    if(this.state.add_head==true){

 
    return(
      <MDBModal isOpen={this.state.add_head} toggle={this.toggleAdd} >
   
          <MDBCardHeader style={{backgroundColor:'#004080',display:'flex',justifyContent:'center'}}>
                      <h3 className="my-3" style={{color:'white'}}>
                        <MDBIcon icon="edit-square" /> 
                        
                        {this.returnTitle()}
                        
                        
                      </h3>
                      
                    </MDBCardHeader>
              <MDBModalBody>
          
      
                       <div style={{display:"flex",alignItems:'center',justifyContent:'space-between',width:'100%'}}>
                  <text style={{margin:10,width:"30%"}}>Name</text>
          <input
                 value={this.state.new_name}
        onChange={this.handleChangename}
          placeholder="Name"
          icon="money-check-alt"
            type="text"
            id="new_name"
            className="form-control"
            required
            
          /></div>



<div style={{display:"flex",alignItems:'center',justifyContent:'space-between',width:'100%'}}>
                  <text style={{margin:10,width:"30%"}}>Description</text>
          <input
                 value={this.state.new_des}
        onChange={this.handleChangename}
          placeholder="Description"
          icon="money-check-alt"
            type="text"
            id="new_des"
            className="form-control"
            required
            
          /></div>


<div style={{display:"flex",alignItems:'center',justifyContent:'space-between',width:'100%'}}>
                  <text style={{margin:10,width:"30%"}}>HSN</text>
          <input
                 value={this.state.new_hsn}
        onChange={this.handleChangename}
          placeholder="HSN"
          icon="money-check-alt"
            type="number"
            id="new_hsn"
            className="form-control"
            required
            
          /></div>

<div style={{display:"flex",alignItems:'center',justifyContent:'space-between',width:'100%'}}>
                  <text style={{margin:10,width:"27%"}}>Type</text>
                  <div style={{width:"100%"}}>                  <MDBDropdown>
                  <MDBDropdownToggle caret color="#004080" className="dropmenu" style={{width:'100%'}}>
    {this.state.new_type}
   </MDBDropdownToggle>
<MDBDropdownMenu style={{width:"100%"}}>
  <MDBDropdownItem onClick={()=>this.setState({new_type:"One Time Cost"})} style={{cursor:"pointer"}}>One Time Cost</MDBDropdownItem>
  <MDBDropdownItem divider />
  <MDBDropdownItem onClick={()=>this.setState({new_type:"Recurring Cost"})} style={{cursor:"pointer"}}>Recurring Cost</MDBDropdownItem>
</MDBDropdownMenu>
</MDBDropdown>
</div>

          </div>

         




              </MDBModalBody>
              <MDBModalFooter style={{padding:5}}>
              <Button className="confirm"  onClick={()=>this.addHead()}>Confirm</Button>
              <Button className="confirm"  onClick={()=>this.setState({add_head:false})}>Cancel</Button>
              
               
              </MDBModalFooter>
        </MDBModal>
    )
  }
}


handleChangename=(e)=>{
  this.setState({[e.target.id]:e.target.value});
}
toggle = () => {
  this.setState({
    add_product: !this.state.add_product,
  });
}
logout(){
  window.localStorage.clear()
window.location.reload()

}
renderAddProduct(){
  if(this.state.add_product){
    return(<MDBModal isOpen={this.state.add_product} toggle={this.toggle} fullHeight position="right">
    <MDBCardHeader style={{backgroundColor:'#004080',display:'flex',justifyContent:'center'}}>
                <h3 className="my-3" style={{color:'white'}}>
                  <MDBIcon icon="user-plus" /> Add Product
                </h3>
              </MDBCardHeader>
        <MDBModalBody>
        <MDBRow>
        <MDBCol >
        
        <MDBCardBody>
              <form>
              
                <div className="grey-text">
                  <MDBInput
                    label="Product Name"
                    icon="map-marked"
                    group
                    type="text"
                    value={this.state.product_name} 
         onChange={this.handleChangepname}
                    error="wrong"
                    success="right"
                  />
                   <MDBInput
                    label="Description"
                    icon="flag"
                    group
                    type="text"
                    value={this.state.description} 
         onChange={this.handleChangepdescription}
                    error="wrong"
                    success="right"
                  />
                </div>
               
              </form>
            </MDBCardBody>
         
        </MDBCol>
      </MDBRow>
        </MDBModalBody>
        <MDBModalFooter>
          <Button className="confirm"  onClick={()=>this.addProduct()}>Confirm</Button>
          <MDBBtn color="elegant" onClick={this.toggle}>Close</MDBBtn>
        </MDBModalFooter>
      </MDBModal>)
  }
}

  render() {

 
    const sidebarBackground = {
      backgroundColor: "#1C2231"
    };
    
    return (
      <div className="bodyDivCl" >

   <div
      id="sidebar"
      className="sideV"
      data-color="#1C2231"
     
    >
      <div className="sidebar-background" style={sidebarBackground} />
      <div className="logo" style={{fontSize:30,fontWeight:'bold',display:'flex',justifyContent:'center',alignItems:'center',position:'relative',zIndex:4}}>
     
     Videos
  
   </div>
      <div className="sidebar-wrapper" style={{display:'flex',flexDirection:'column',justifyContent:'flex-start',alignItems:'center',marginTop:10}}>
       
      {dashboardRoutes.map((prop, key) => {
            if(prop.path=='/videos'){
              return (
               
               <div
               key={key}
className="active"
style={{color:'white',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',marginBottom:20}}
 onClick={()=>this.props.history.push(`${prop.path}`)}                
>
<MDBIcon style={{marginRight:5,width:'100%',display:'flex',justifyContent:'center',alignItems:'center',color:'#FC0107'}} size="3x" icon={prop.icon} />
<text>{prop.name}</text>
</div>
               
             );
             return null;
            }
            else{
              return (
               
               <div
className="active"
style={{color:'white',flexDirection:'column',justifyContent:'center',display:'flex',alignItems:'center',marginBottom:20,cursor:'pointer'}}
 onClick={()=>this.props.history.push(`${prop.path}`)}                
>
<MDBIcon style={{marginRight:5,width:'100%',display:'flex',justifyContent:'center',alignItems:'center'}} className="grey-text" size="3x"    icon={prop.icon} />
<text>{prop.name}</text>
</div>
               
             );
             return null; 
            }
           
          })}
        
      </div>
    </div>

      <div className="conTable">
 
 <MDBNavbar color="unique-color-dark"  dark>
    
 <div style={{display:'flex',alignItems:'center',justifyContent:'flex-end',width:'100%'}}>
 
 <div style={{display:'flex',justifyContent:'center'}}><img src={require('../../assets/abb_logo.png')} style={{height:40,width:130,margin:5}}/></div>
    
  
         
          
           </div>
   </MDBNavbar>
   <div >
       


{this.renderList()}
{this.renderAddProduct()}




               </div>
          
</div>
 

      
   
     





    


    
 
 
               </div>
              
    );

    
  }
}
export default Videos