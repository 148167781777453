import React, { Component } from "react";
import ReactTooltip from 'react-tooltip'
import { MDBContainer, MDBDropdownItem,MDBDropdownMenu,  MDBIcon, MDBTable, MDBTableBody, MDBTableHead, MDBBtn,MDBRow,
  MDBCol,
  MDBDropdown,
  MDBDropdownToggle,
  MDBCardHeader, MDBModal, MDBModalBody, MDBInput, MDBModalFooter,MDBCollapse,MDBPopover, MDBPopoverBody, MDBTooltip,  } from 'mdbreact';
  import dashboardRoutes from "../../routes/dashboard";
  import swal from 'sweetalert'
  import '../../routes/main.css'
  import { Grid, Row, Col,Image,ListGroup,ListGroupItem,Button,Form,FormControl,Checkbox,ControlLabel, } from "react-bootstrap";
import './dash.css'
import "../../../node_modules/video-react/dist/video-react.css";
import { HalfCircleSpinner } from 'react-epic-spinners'
import { Player } from 'video-react';
import ScrollAnimation from 'react-animate-on-scroll';
import YouTube from 'react-youtube';
import Catagories from "../Catagories/Catagories";
import Subcatagories from "../Subcatagories/Subcatagories";
require('es6-promise').polyfill();
require('isomorphic-fetch');

class Dashboard extends Component {
  constructor(props) {
    super(props);
  this.state={
    loading:false,info_heads:[],add_head:false,clients:[],
    new_name:"",new_des:"",new_hsn:"",new_type:"Select Cost Type",selected_id:"",
    selected_language:[],users:[],new_pass:'',add_user:false,
    new_name:'',new_email:'',new_password:'',edit_name:'',edit_email:'',edit_password:'',
    languages:[],new_user_languages:[],edit_user:false,user_to_edit:null,
    edit_user_languages:[],superadmin:false,admin:false,super_pass:'',
    video_modal:false,video:'',exit:false,logoutText:'Logout',videolink:'',list:[],paragraph:[],
    heading:'',edit_content:'Edit Content',videoText:'VIDEO',nextText:'Causes of poor power quality',edit:false,
    edit_heading:'',para1:'',para2:'',list_header:'',list1:'',list2:'',list3:'',list4:'',read_more:false,read_more_text:'READ MORE...',mobileMenu:false,videolink:'',showExit:false,lang_mod:false,read_less_text:'READ LESS',sidebar:[],youtube_modal:false,closeText:'CLOSE',all_images:[]
  }
  }
 
  trackClick(e){
console.log(e)
  }

  componentWillMount() {
  
    this.getVideo()

  }
  getProSub(){
    if(navigator.onLine){
    let details = {
      language_id:this.state.selected_language.language_id
    };
    let formBody = [];
    for (let property in details) {
     let encodedKey = encodeURIComponent(property);
     let encodedValue = encodeURIComponent(details[property]);
     formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    
    this.setState({
     response: fetch('https://www.mobilitysolution.info/get_product_sub', {
       method: 'POST',
       headers: {
        
         'Content-Type': 'application/x-www-form-urlencoded',
         'Cache-Control': 'max-age=31536000'
     },
    body:formBody
      
       
     }).then((response) => response.json())
     .then((responseJson)=>{  window.localStorage.removeItem('product_sub')
     window.localStorage.setItem('product_sub',JSON.stringify(responseJson))
    
     
     }
    
     )
       .catch((error) => {
      this.setState({
        loading:false
      })
      //swal("Warning!", "Check your network!", "warning");
    console.log(error)
        })
       })
      }
      else{
        
      }
  }
  getFoot(){
    if(navigator.onLine){
    let details = {
      language_id:this.state.selected_language.language_id
    };
    let formBody = [];
    for (let property in details) {
     let encodedKey = encodeURIComponent(property);
     let encodedValue = encodeURIComponent(details[property]);
     formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    console.log(details)
    this.setState({
      response: fetch('https://www.mobilitysolution.info/get_content_footprint', {
        method: 'POST',
        headers: {
         
          'Content-Type': 'application/x-www-form-urlencoded',
          'Cache-Control': 'max-age=31536000'
      },
    body:formBody
        
      }).then((response) => response.json())
      .then((responseJson)=>{
        console.log(JSON.parse(responseJson[0].heading))
       window.localStorage.removeItem('footprint')
       window.localStorage.setItem('footprint',JSON.stringify(responseJson))
     
  
          })
          .catch((error) => {
            this.setState({
              loading:false
            })
            //swal("Warning!", "Check your network!", "warning");
          console.log(error)
              })
             })
            }
  }
  getCase(){
    if(navigator.onLine){
    let details = {
      language_id:this.state.selected_language.language_id
    };
    let formBody = [];
    for (let property in details) {
     let encodedKey = encodeURIComponent(property);
     let encodedValue = encodeURIComponent(details[property]);
     formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    console.log(details)
    this.setState({
      response: fetch('https://www.mobilitysolution.info/get_content_case', {
        method: 'POST',
        headers: {
         
          'Content-Type': 'application/x-www-form-urlencoded',
          'Cache-Control': 'max-age=31536000'
      },
    body:formBody
        
      }).then((response) => response.json())
      .then((responseJson)=>{
        window.localStorage.removeItem('case')
        window.localStorage.setItem('case',JSON.stringify(responseJson))
      
       
          })
          .catch((error) => {
            this.setState({
              loading:false
            })
          
          console.log(error)
              })
             })
            }
  }
  getIndustry1(){
    if(navigator.onLine){
      let details = {
        language_id:this.state.selected_language.language_id
      };
      let formBody = [];
      for (let property in details) {
       let encodedKey = encodeURIComponent(property);
       let encodedValue = encodeURIComponent(details[property]);
       formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      console.log(details)
      this.setState({
        response: fetch('https://www.mobilitysolution.info/get_content_renewables', {
          method: 'POST',
          headers: {
           
            'Content-Type': 'application/x-www-form-urlencoded',
            'Cache-Control': 'max-age=31536000'
        },
      body:formBody
          
        }).then((response) => response.json())
        .then((responseJson)=>{
          window.localStorage.removeItem('renewables')
          window.localStorage.removeItem('infra')
          window.localStorage.removeItem('food')
          window.localStorage.removeItem('marine')
          window.localStorage.removeItem('utility')
          window.localStorage.removeItem('railways')
          window.localStorage.removeItem('aluminium')
          window.localStorage.removeItem('automotive')
          window.localStorage.setItem('renewables',JSON.stringify(responseJson))
          window.localStorage.setItem('infra',JSON.stringify(responseJson))
          window.localStorage.setItem('food',JSON.stringify(responseJson))
          window.localStorage.setItem('marine',JSON.stringify(responseJson))
  
          window.localStorage.setItem('utility',JSON.stringify(responseJson))
          window.localStorage.setItem('railways',JSON.stringify(responseJson))
          window.localStorage.setItem('aluminium',JSON.stringify(responseJson))
          window.localStorage.setItem('automotive',JSON.stringify(responseJson))
            })
            .catch((error) => {
              this.setState({
                loading:false
              })
              //("Warning!", "Check your network!", "warning");
            console.log(error)
                })
               })
    }
   
  }
  getOver(){
    let details = {
      language_id:this.state.selected_language.language_id
    };
    let formBody = [];
    for (let property in details) {
     let encodedKey = encodeURIComponent(property);
     let encodedValue = encodeURIComponent(details[property]);
     formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    console.log(details)
    this.setState({
      response: fetch('https://www.mobilitysolution.info/get_content_overview', {
        method: 'POST',
        headers: {
         
          'Content-Type': 'application/x-www-form-urlencoded',
          'Cache-Control': 'max-age=31536000'
      },
    body:formBody
        
      }).then((response) => response.json())
      .then((responseJson)=>{

       window.localStorage.removeItem('overview')
       window.localStorage.setItem('overview',JSON.stringify(responseJson))
    
          })
          .catch((error) => {
            this.setState({
              loading:false
            })
            //swal("Warning!", "Check your network!", "warning");
          console.log(error)
              })
             })
  }

  getSubcatagories(){
             
    if(navigator.onLine){
   
      let details = {
      language_id:this.state.selected_language.language_id
    };
    let formBody = [];
    for (let property in details) {
     let encodedKey = encodeURIComponent(property);
     let encodedValue = encodeURIComponent(details[property]);
     formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    
    this.setState({
     response: fetch('https://www.mobilitysolution.info/get_sub_catagories', {
       method: 'POST',
       headers: {
        
         'Content-Type': 'application/x-www-form-urlencoded',
         'Cache-Control': 'max-age=31536000'
     },
    body:formBody
      
       
     }).then((response) => response.json())
     .then((responseJson)=>{
      window.localStorage.removeItem('causes')
      window.localStorage.setItem('causes',JSON.stringify(responseJson))
  
  
     }
    
     )
       .catch((error) => {
      this.setState({
        loading:false
      })
      //swal("Warning!", "Check your network!", "warning");
    console.log(error)
        })
       })
    }
   

  
  }
 getCategories(){
   if(navigator.onLine){
   
  
    let details = {
    language_id:this.state.selected_language.language_id
  };
  
  let formBody = [];
  for (let property in details) {
   let encodedKey = encodeURIComponent(property);
   let encodedValue = encodeURIComponent(details[property]);
   formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  
  this.setState({
   response: fetch('https://www.mobilitysolution.info/get_all_catagories', {
     method: 'POST',
     headers: {
      
       'Content-Type': 'application/x-www-form-urlencoded',
       'Cache-Control': 'max-age=31536000'
   },
  body:formBody
    
     
   }).then((response) => response.json())
   .then((responseJson)=>{
     console.log('sss')
    window.localStorage.removeItem('solutions')
    window.localStorage.setItem('solutions',JSON.stringify(responseJson))
  
   
   }
  
   )
     .catch((error) => {
    this.setState({
      loading:false
    })
    //swal("Warning!", "Check your network!", "warning");
  console.log(error)
      })
     })
   }
 }
 getBenefits(){
  if(navigator.onLine){
  let details = {
    language_id:this.state.selected_language.language_id
  };
  let formBody = [];
  for (let property in details) {
   let encodedKey = encodeURIComponent(property);
   let encodedValue = encodeURIComponent(details[property]);
   formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  
  this.setState({
    response: fetch('https://www.mobilitysolution.info/get_content_value', {
      method: 'POST',
      headers: {
       
        'Content-Type': 'application/x-www-form-urlencoded',
        'Cache-Control': 'max-age=31536000'
    },
  body:formBody
      
    }).then((response) => response.json())
    .then((responseJson)=>{
      window.localStorage.removeItem('value')
      window.localStorage.setItem('value',JSON.stringify(responseJson))
   
        })
        .catch((error) => {
          this.setState({
            loading:false
          })
          
        console.log(error)
            })
           })
          }
 }
 getAllProducts(){
 
  if(navigator.onLine){
  let details = {
  language_id:this.state.selected_language.language_id
};
let formBody = [];
for (let property in details) {
 let encodedKey = encodeURIComponent(property);
 let encodedValue = encodeURIComponent(details[property]);
 formBody.push(encodedKey + "=" + encodedValue);
}
formBody = formBody.join("&");

this.setState({
 response: fetch('https://www.mobilitysolution.info/get_all_products', {
   method: 'POST',
   headers: {
    
     'Content-Type': 'application/x-www-form-urlencoded',
     'Cache-Control': 'max-age=31536000'
 },
body:formBody
  
   
 }).then((response) => response.json())
 .then((responseJson)=>{
  window.localStorage.removeItem('products')
  window.localStorage.setItem('products',JSON.stringify(responseJson))
  
  this.getSubcatagories()
 
 }

 )
   .catch((error) => {
  this.setState({
    loading:false
  })
  //swal("Warning!", "Check your network!", "warning");
console.log(error)
    })
   })
  }
 }
 getAllImages(){
 
  
if(navigator.onLine){

this.setState({
  response: fetch('https://www.mobilitysolution.info/getfiles', {
    method: 'POST',
    headers: {
     
      'Content-Type': 'application/x-www-form-urlencoded',
      'Cache-Control': 'max-age=31536000'
  },
   body:{}
    
  }).then((response) => response.json())
  .then((responseJson)=>{
    window.localStorage.removeItem('all_images')
    window.localStorage.setItem('all_images',JSON.stringify(responseJson))
 this.setState({
   all_images:responseJson
 }) 
 console.log(this.state.all_images,'aaaa')
  }
 
  )
    .catch((error) => {
   this.setState({
     loading:false
   })
   //swal("Warning!", "Check your network!", "warning");
 console.log(error)
     })
    })
}
else
{
  var images =window.localStorage.getItem('all_images')
  console.log('offline')
  this.setState({
    all_images:JSON.parse(images)
  })
}
  
 }
  updateContent=async()=>{
   
   this.setState({loading:true})
    let details = {
      language_id:this.state.selected_language.language_id,
      paragraph:JSON.stringify([{"paragraph":this.state.para1},{"paragraph":this.state.para2}]),
      list:JSON.stringify([{"header":this.state.list_header,values:[this.state.list1,this.state.list2,this.state.list3,this.state.list4]}]),
      heading:this.state.edit_heading
    };
    let formBody = [];
    for (let property in details) {
     let encodedKey = encodeURIComponent(property);
     let encodedValue = encodeURIComponent(details[property]);
     formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
   
    this.setState({
      response: fetch('http://18.191.5.172:3000/update_content_home', {
        method: 'POST',
        headers: {
         
          'Content-Type': 'application/x-www-form-urlencoded',
          'Cache-Control': 'max-age=31536000'
      },
    body:formBody
        
      }).then((response) => response.json())
      .then((responseJson)=>{
        
       window.location.reload()
          })
          .catch((error) => {
            this.setState({
              loading:false
            })
            //swal("Warning!", "Check your network!", "warning");
          console.log(error)
              })
             })
        }
        getSidebar=async()=>{
         
          if(!navigator.onLine){
      var offline_content=window.localStorage.getItem('sidebar')
      if(offline_content==null){
      
       this.setState({
         loading:false
       })
      }
      else{
       console.log(JSON.parse(JSON.parse(offline_content).content),'cccc')
       dashboardRoutes[0].name=JSON.parse(JSON.parse(offline_content).content)[0].name
       dashboardRoutes[1].name=JSON.parse(JSON.parse(offline_content).content)[1].name
       dashboardRoutes[2].name=JSON.parse(JSON.parse(offline_content).content)[2].name
       dashboardRoutes[3].name=JSON.parse(JSON.parse(offline_content).content)[4].name
       dashboardRoutes[4].name=JSON.parse(JSON.parse(offline_content).content)[5].name
       dashboardRoutes[5].name=JSON.parse(JSON.parse(offline_content).content)[6].name
       this.setState({
         sidebar:JSON.parse(offline_content).content,
       loading:false
       })
      }
          }
          else{
           this.setState({loading:true})
           let details = {
             language_id:this.state.selected_language.language_id
           };
           let formBody = [];
           for (let property in details) {
            let encodedKey = encodeURIComponent(property);
            let encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + "=" + encodedValue);
           }
           formBody = formBody.join("&");
           this.setState({
             response: fetch('https://www.mobilitysolution.info/get_sidebar', {
               method: 'POST',
               headers: {
                
                 'Content-Type': 'application/x-www-form-urlencoded',
                 'Cache-Control': 'max-age=31536000'
             },
           body:formBody
               
             }).then((response) => response.json())
             .then((responseJson)=>{
               console.log(JSON.parse(responseJson.content),'sidebar')
              window.localStorage.removeItem('sidebar')
              window.localStorage.setItem('sidebar',JSON.stringify(responseJson))
     dashboardRoutes[0].name=JSON.parse(responseJson.content)[0].name
     dashboardRoutes[1].name=JSON.parse(responseJson.content)[1].name
     dashboardRoutes[2].name=JSON.parse(responseJson.content)[2].name
     dashboardRoutes[3].name=JSON.parse(responseJson.content)[4].name
     dashboardRoutes[4].name=JSON.parse(responseJson.content)[5].name
     dashboardRoutes[5].name=JSON.parse(responseJson.content)[6].name
               this.setState({
                 sidebar:(responseJson.content).sidebar,
       loading:false
               })
                 })
                 .catch((error) => {
                  var offline_content=window.localStorage.getItem('sidebar')
                  if(offline_content==null){
                  
                   this.setState({
                     loading:false
                   })
                  }
                  else{
                   console.log(JSON.parse(JSON.parse(offline_content).content),'cccc')
                   dashboardRoutes[0].name=JSON.parse(JSON.parse(offline_content).content)[0].name
                   dashboardRoutes[1].name=JSON.parse(JSON.parse(offline_content).content)[1].name
                   dashboardRoutes[2].name=JSON.parse(JSON.parse(offline_content).content)[2].name
                   dashboardRoutes[3].name=JSON.parse(JSON.parse(offline_content).content)[4].name
                   dashboardRoutes[4].name=JSON.parse(JSON.parse(offline_content).content)[5].name
                   dashboardRoutes[5].name=JSON.parse(JSON.parse(offline_content).content)[6].name
                   this.setState({
                     sidebar:JSON.parse(offline_content).content,
                   loading:false
                   })
                  }
                  
                 console.log(error)
                     })
                    })
          }
                   }
      
        getContent=async()=>{
         
     if(!navigator.onLine){
var offline_content=window.localStorage.getItem('dashboard')
if(offline_content==null){
  swal('Warning','Check your network and refresh','warning')
  this.setState({
    loading:false
  })
}
else{
  console.log(offline_content)
  this.setState({
    paragraph:JSON.parse(JSON.parse(offline_content).paragraph),
            list:JSON.parse(JSON.parse(offline_content).list),
            heading:JSON.parse(offline_content).heading,

  },()=> this.getSidebar())
}
     }
     else{
      this.setState({loading:true})
      let details = {
        language_id:this.state.selected_language.language_id
      };
      let formBody = [];
      for (let property in details) {
       let encodedKey = encodeURIComponent(property);
       let encodedValue = encodeURIComponent(details[property]);
       formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      console.log(details)
      this.setState({
        response: fetch('https://www.mobilitysolution.info/get_content_home', {
          method: 'POST',
          headers: {
           
            'Content-Type': 'application/x-www-form-urlencoded',
            'Cache-Control': 'max-age=31536000'
        },
      body:formBody
          
        }).then((response) => response.json())
        .then((responseJson)=>{

         window.localStorage.removeItem('dashboard')
         window.localStorage.setItem('dashboard',JSON.stringify(responseJson))
          this.setState({
          
            paragraph:JSON.parse(responseJson.paragraph),
            list:JSON.parse(responseJson.list),
            heading:responseJson.heading,
            videolink:responseJson.videolink,
 
          },()=> this.getSidebar())
         
            })
            .catch((error) => {
              var offline_content=window.localStorage.getItem('dashboard')
              if(offline_content==null){
                swal('Warning','Check your network and refresh','warning')
                this.setState({
                  loading:false
                })
              }
              else{
                console.log(offline_content)
                this.setState({
                  paragraph:JSON.parse(JSON.parse(offline_content).paragraph),
                          list:JSON.parse(JSON.parse(offline_content).list),
                          heading:JSON.parse(offline_content).heading,
              
                },()=> this.getSidebar())
              }
            console.log(error)
                })
               })
     }
              }
  renderVideoModal(){

   
    if(this.state.video_modal==true){
  
      console.log(this.state.videolink)
    return(
      <MDBModal size='lg' isOpen={this.state.video_modal} toggle={this.toggleVideo} centered>
   
         
              <MDBModalBody>
              <Player ref="player"
              autoPlay
        playsInline
        poster="../../assets/abb_logo.png"
        src={this.state.videolink}
      />
 
  
              </MDBModalBody>
              <MDBModalFooter style={{padding:5}}>
             
              <text className="videoButton" style={{color:'#fff'}}  onClick={()=>this.setState({video_modal:false})}>{this.state.closeText}</text>   
              
               
              </MDBModalFooter>
        </MDBModal>
    )
  }
}
renderYouTubeModal(){


   
  if(this.state.youtube_modal==true){


  return(
    <MDBModal size='lg' isOpen={this.state.youtube_modal} toggle={this.toggleyoutube} centered >
 
       
            <MDBModalBody>
            <YouTube
      videoId={this.state.videolink}
      opts={{
        height: '500',
    width: '100%',
    playerVars: { 
      autoplay: 1
    }
      }}
      onReady={this._onReady}
    />

            </MDBModalBody>
            <MDBModalFooter style={{padding:5}}>
            
            <text className="videoButton" style={{color:'#fff'}}  onClick={()=>this.setState({youtube_modal:false})}>{this.state.closeText}</text>
            
             
            </MDBModalFooter>
      </MDBModal>
  )
}
}
toggleYoutube = () => {
 
  this.setState({
    youtube_modal: !this.state.youtube_modal,
   });
}
toggleLang = () => {
   
  this.setState({
    lang_mod: !this.state.lang_mod,
   });
}
renderlangModal(){


   
  if(this.state.lang_mod==true){


  return(
    <MDBModal className='mobileLang' size='md' isOpen={this.state.lang_mod} toggle={this.toggleLang} centered>
 
       
            <MDBModalBody>
            {/* { (this.state.languages).map((data,key) => {
    
    if(data.selected==0){
     return ( 
       <MDBDropdownItem style={{backgroundColor:'#fff',color:'#6e6e6e',textAlign:'center',padding:12}} className='dropButton'  onClick={()=>this.selectLanguage(data)}>{data.language}</MDBDropdownItem>
           
            )
    }
    else{
     return ( 
       <MDBDropdownItem style={{backgroundColor:'#d2d2d2',color:'black',textAlign:'center',padding:12}}  >{data.language}</MDBDropdownItem>
           
            )
    }
       })
       } */}
       <MDBDropdownItem style={{backgroundColor:'#d2d2d2',color:'black',textAlign:'center',padding:12}}  >ENGLISH</MDBDropdownItem>
                       </MDBModalBody>
          
      </MDBModal>
  )
}
}
exitApp(){
  var userAgent = navigator.userAgent.toLowerCase();
if (userAgent.indexOf(' electron/') > -1) {
  const remote = window.require('electron').remote
  let w = remote.getCurrentWindow()
  w.close()
}


}
   renderExitModal(){


   
    if(this.state.exit==true){
  
  
    return(
      <MDBModal  isOpen={this.state.exit} centered toggle={this.toggleExit} >
   
         
              <MDBModalBody style={{    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',flexDirection:'column',paddingTop:'4vh',paddingBottom:'4vh'}}>
              <img alt='Loading...' alt='Loading...' src={require('../../assets/Exit.jpg')} className='catGif'/>
              <p>Are you sure you want to exit this application?</p>
           <div style={{display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-evenly'}}>      <text onClick={()=>this.exitApp()} style={{backgroundColor:'#ff000f',color:'white'}} className='seriesText'>Exit</text>

              
              <text onClick={()=>this.setState({exit:false})} className='seriesText'>Cancel</text>

         
            </div>
             
              </MDBModalBody>

        </MDBModal>
    )
  }
  }
  
  toggleExit = () => {
   
    this.setState({
      exit: !this.state.exit,
     });
  }
  toggleVideo = () => {
   
    this.setState({
      video_modal: !this.state.video_modal,
     });
  }



 
 


  componentDidMount(){
    this.getAllImages()  
  
    var userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf(' electron/') > -1) {
       this.setState({showExit:true})
    }
   
  }
  renderExitButton(){
    if(this.state.showExit){
     return(<div
       className="sideInActive"
       
       onClick={this.toggleExit}                 
       >
       <img src={require('../../assets/Exit.jpg')} style={{width:35}}/>
       <text style={{marginTop:5}}>Exit</text>
       </div>)
    }
   
   }

  getVideo=async()=>{
    this.setState({
      loading:true
    })
  
 console.log('getVideo')

var responseJson=[{"language":"English","language_id":1,"active":0,"key":1,"label":"English",selected:0},
{"language":"German","language_id":2,"active":0,"key":2,"label":"German",selected:0},
{"language":"Chinese","language_id":3,"active":0,"key":3,"label":"Chinese",selected:0},
{"language":"Spanish","language_id":4,"active":0,"key":4,"label":"Spanish",selected:0},
{"language":"French","language_id":5,"active":0,"key":5,"label":"French",selected:0}]

  for(var v=0;v<responseJson.length;v++){
    responseJson[v].label=responseJson[v].language
    responseJson[v].value=responseJson[v].language_id
  }
  var defaultLanguage=window.localStorage.getItem('admindefault')
  if(defaultLanguage==null){
    responseJson[0].selected=1
   this.setState({
     languages:responseJson,
     selected_language:responseJson[0]
   },()=> {this.getContent()
  this.getCategories()
  this.getBenefits()
    this.getProSub()
    this.getFoot()
    this.getCase()
    this.getAllProducts()
    this.getIndustry1()

  })
  }
  else{
    if(JSON.parse(defaultLanguage).language=='German' || JSON.parse(defaultLanguage).language=='Deutsche' ||
     JSON.parse(defaultLanguage).language=='德语' || JSON.parse(defaultLanguage).language=='alemán' || JSON.parse(defaultLanguage).language=='allemand'){
      responseJson[0].language='Englisch'
      responseJson[1].language='Deutsche'
      responseJson[2].language='Chinesisch'
      responseJson[3].language='Spanisch'
      responseJson[4].language='Französisch'
     this.setState({logoutText:'Ausloggen',edit_content:'Inhalt bearbeiten',
     videoText:'Video',nextText:'Ursache für schlechte Stromqualität',read_less_text:'LESE WENIGER',read_more_text:'WEITERLESEN',languages:responseJson,closeText:'Schließen'})

    }
    else if(JSON.parse(defaultLanguage).language=='Chinese' || JSON.parse(defaultLanguage).language=='Chinesisch' ||
    JSON.parse(defaultLanguage).language=='中文' || JSON.parse(defaultLanguage).language=='chino' || JSON.parse(defaultLanguage).language=='chinois'){
    
      responseJson[0].language='英语'
      responseJson[1].language='德语'
      responseJson[2].language='中文'
      responseJson[3].language='西班牙语'
      responseJson[4].language='法国'
      this.setState({logoutText:'登出',edit_content:'编辑内容',videoText:'视频',nextText:'电能质量差的原因',read_less_text:'阅读不多',read_more_text:'阅读更多',languages:responseJson,closeText:'关'})

 
     }
     else if(JSON.parse(defaultLanguage).language=='Spanish' || JSON.parse(defaultLanguage).language=='Spanisch' ||
     JSON.parse(defaultLanguage).language=='西班牙语' || JSON.parse(defaultLanguage).language=='Español' || JSON.parse(defaultLanguage).language=='Espanol'){
      responseJson[0].language='Inglés'
      responseJson[1].language='alemán'
      responseJson[2].language='chino'
      responseJson[3].language='Español'
      responseJson[4].language='francés'
      this.setState({logoutText:'Cerrar sesión',edit_content:'Contenido editado',videoText:'Vídeo',
      nextText:'Causa de mala calidad eléctrica',read_less_text:'LEER MENOS',read_more_text:'LEE MAS',languages:responseJson,closeText:'Cerrar'})

 
     }
     else if(JSON.parse(defaultLanguage).language=='French' || JSON.parse(defaultLanguage).language=='Französisch' ||
     JSON.parse(defaultLanguage).language=='法国' || JSON.parse(defaultLanguage).language=='francés' || JSON.parse(defaultLanguage).language=='français'){
      responseJson[0].language='Anglais'
      responseJson[1].language='allemand'
      responseJson[2].language='chinois'
      responseJson[3].language='Espanol'
      responseJson[4].language='français'
       
     
      this.setState({logoutText:'Connectez - Out',edit_content:'Modifier le contenu',videoText:'Vidéo',nextText:`Cause de mauvaise qualité d'alimentation`,read_less_text:'LIRE MOINS',read_more_text:'LIRE LA SUITE',languages:responseJson,closeText:'Fermer'})

 
     }
    console.log(JSON.parse(defaultLanguage),'selected_language')
    responseJson[JSON.parse(defaultLanguage).key-1].selected=1
   this.setState({
     languages:responseJson,
    
     selected_language:JSON.parse(defaultLanguage)
   },()=> {this.getContent()
    this.getCategories()
    this.getBenefits()
    this.getProSub()
    this.getFoot()
    this.getCase()
    this.getIndustry1()
    this.getAllProducts()

    })
  }
  
 }
  
     
  
  


  
 

  selectLanguage(data,key){
    window.localStorage.removeItem('admindefault')
      window.localStorage.setItem('admindefault',JSON.stringify(data))
   
this.setState({
  selected_language:data
},()=>window.location.reload())
  }
  renderLanguages(){

    if(this.state.loading==false){
      if(this.state.languages.length>0){
        return(<div  
      
           
          
          style={{backgroundColor:'rgb(255, 255, 255, 0.1)',cursor:'pointer',display:'flex',alignItems:'center',marginRight:100,marginTop:'.5%'}}>
        <MDBIcon size="lg" style={{marginLeft:10,marginRight:20}}   className="grey-text menuBar" icon="bars" onClick={()=>this.setState({mobileMenu:!this.state.mobileMenu})}/>
        <MDBIcon size="lg" style={{marginLeft:10}} onClick={()=>this.setState({lang_mod:true})}   className="grey-text" icon="globe-africa" />
        <MDBDropdown className='languageDiv' >
       <MDBDropdownToggle caret color="#004080" size="md" >
       <text className='languageText'>{this.state.languages[this.state.selected_language.key-1].language}</text>
       </MDBDropdownToggle>
       <MDBDropdownMenu style={{margin:0,padding:0,borderRadius:0}}> 
       <MDBDropdownItem style={{backgroundColor:'#d2d2d2',color:'black',textAlign:'center',padding:12}}  >ENGLISH</MDBDropdownItem>
       {/* { (this.state.languages).map((data,key) => {
    
  if(data.selected==0){
  return ( 
    <MDBDropdownItem style={{backgroundColor:'#fff',color:'#6e6e6e',textAlign:'center',padding:12}} className='dropButton'  onClick={()=>this.selectLanguage(data)}>{data.language}</MDBDropdownItem>
        
         )
  }
  else{
  return ( 
    <MDBDropdownItem style={{backgroundColor:'#d2d2d2',color:'black',textAlign:'center',padding:12}}  >{data.language}</MDBDropdownItem>
        
         )
  }
    })
    } */}
    </MDBDropdownMenu>
    </MDBDropdown>
      
          </div>)
       }
     
    }
       
      }


 


allContent(){
  if(this.state.read_more){
    return(
      <div>
         <p className='lightext'>
                   {this.state.paragraph[1].paragraph}
                   </p>
                   
                    <p >
                     
                      
                   {this.state.list[0].header}
                    
                    </p> 
                    <ul >
                    
                      { (this.state.list[0].values).map((data,key) => {
    
  return(  <li style={{marginLeft:-22,fontFamily:'ABBvoice-Light'}}>{data}</li>)
       })
                      }
                    </ul>
                   <div  style={{display:'flex',justifyContent:'flex-end',alignItems:'center'}}> <text onClick={()=>this.setState({read_more:false})}
    style={{cursor:'pointer',fontFamily:'ABBvoice-Bold'}}>&nbsp;{this.state.read_less_text}</text>  
    </div>     
      </div>
    )
  }
}
renderReadMore(){
  if(!this.state.read_more){
    return( <p className='lightext'>
    {this.state.paragraph[0].paragraph.substr(0, 248)} <text onClick={()=>this.setState({read_more:true})}
    style={{cursor:'pointer',fontFamily:'ABBvoice-Bold'}}>&nbsp;{this.state.read_more_text}</text>
     
                        </p> ) 
  }
    else{
      return(
        <p className='lightext'>
        {this.state.paragraph[0].paragraph}
       
                            </p> 
      )
    }
  
}
 renderWindowDiv(){
   return(  <div style={{textAlign:'justify'}} className='windowDiv'>
   <h1 className='homeHead'>{this.state.heading}</h1>
   <p className='lightext'>
 {this.state.paragraph[0].paragraph}
  
                     </p> 
                     
                    <p className='lightext'>
                    {this.state.paragraph[1].paragraph}
                    </p>
                    
                     <p >
                      
                       
                    {this.state.list[0].header}
                     
                     </p> 
                     <ul >
                     
                       { (this.state.list[0].values).map((data,key) => {
     
   return(  <li style={{marginLeft:-22,fontFamily:'ABBvoice-Light'}}>{data}</li>)
        })
                       }
                     </ul>
                     
                     <text onClick={this.toggleYoutube}  className='why'>
                      
                       
                      {this.state.videoText}
                       
                       </text> 
   </div>)
 }
 reload = ()=>{
  const current = this.props.location.pathname;
  this.props.history.replace(`/reload`);
     setTimeout(() => {
       this.props.history.replace(current);
     });
 }
 showUpdateBar() {
  if(this.state.showAdded){
    
    
      return(
        <ScrollAnimation animateOnce={false}   animateIn="fadeIn"  className='fadeIn toast2' animationOut="fadeOut" 
         isVisible={true} >
       <div className="snack">Go online and <text onClick={()=>this.reload()} style={{cursor:'pointer',textDecoration:'underline'}}>click here</text> to refresh</div>
    </ScrollAnimation>
    
       
      )
    
 
  }
  }
  trigger() {

    setTimeout(() => { 
        this.setState({
            showAdded: false
        })
    }, 5000);
  }
 renderMobileDiv(){
  return(  <div style={{textAlign:'justify'}} className='mobileDiv'>
  <h1 className='homeHead'>{this.state.heading}</h1>
 {this.renderReadMore()}
                    
                  {this.allContent()}
                  <div style={{display:'flex',justifyContent:'center',marginTop:25,marginBottom:15}}>  
                  <text onClick={this.toggleYoutube}  className='why'>
                     
                      
                     {this.state.videoText}
                      
                      </text> 
                      </div>
  </div>)
}
renderData(){
  if(!this.state.loading && this.state.paragraph.length!=0){
    return(   <div className='mainDiv'>
  {this.renderMobileDiv()}
  {this.renderWindowDiv()}
     <img alt='Loading...' onError={()=>this.setState({showAdded:true})} src={require('../../assets/dash.gif')} alt='Loading...'  className='imgDash'/>  
     <h1 className='mobileHead' style={{marginBottom:0}}>{this.state.heading}</h1>
    </div>)
  }

}

renderDetails(){
  var style={opacity:1,backgroundColor:'#fff',height:'100vh',borderStyle:'solid',borderColor:'#d2d2d2',borderWidth:1,
    overflow:'auto',padding:'2.5%',display:'flex',flexDirection:'column',alignItems:'flex-start'}


  if(this.state.edit){



  return(
   
    <div id="dashsidebar"    ref={ref => { this['dashsidebar'] = ref }}
    className="dashsidebar">
     <ScrollAnimation animateOnce={false} animateIn="slideInLeft"  className='slideInLeft' animationOut="slideOutLeft" 
     isVisible={true} style={style}>
    <div style={{backgroundColor:'#fff',display:'flex',justifyContent:'space-between',paddingRight:10,width:'100%',alignItems:'center'}}>
    <h3 >
                 
                  
                 {this.state.edit_content}
                </h3>
  <text onClick={()=>this.setState({edit:false})} className='arrow'></text></div>
 
              <div style={{paddingLeft:5,width:'100%'}}>
              <MDBInput
         
            group
      
            type="text"
            value={this.state.edit_heading} 
        onChange={(text)=>this.setState({edit_heading:text.target.value})}
            error="wrong"
            success="right"
        />
<MDBInput
           
            group
        
            type="textarea"
            value={this.state.para1} 
        onChange={(text)=>this.setState({para1:text.target.value})}
            error="wrong"
            success="right"
        />
 <MDBInput
            
            group
       
            type="textarea"
            value={this.state.para2} 
        onChange={(text)=>this.setState({para2:text.target.value})}
            error="wrong"
            success="right"
        />
</div>

            <div style={{paddingLeft:5,width:'100%'}}>
              <MDBInput
            
            group
      
            type="text"
            value={this.state.list_header} 
        onChange={(text)=>this.setState({list_header:text.target.value})}
            error="wrong"
            success="right"
        />
         <MDBInput
          
            group
      
            type="text"
            value={this.state.list1} 
        onChange={(text)=>this.setState({list1:text.target.value})}
            error="wrong"
            success="right"
        />
<MDBInput
            
            group
        
            type="text"
            value={this.state.list2} 
        onChange={(text)=>this.setState({list2:text.target.value})}
            error="wrong"
            success="right"
        />
 <MDBInput
            
            group
       
            type="text"
            value={this.state.list3} 
        onChange={(text)=>this.setState({list3:text.target.value})}
            error="wrong"
            success="right"
        />
         <MDBInput
            
            group
       
            type="text"
            value={this.state.list4} 
        onChange={(text)=>this.setState({list4:text.target.value})}
            error="wrong"
            success="right"
        />
</div>
 <div style={{width:'100%',display:'flex',justifyContent:'flex-end'}}><text style={{fontWeight:'bold'}}  onClick={()=>this.updateContent()} className='arrowRed'></text>
 </div>            
              
</ScrollAnimation>

</div>

   
  )

 
}
}
renderoverLay(){
  if(this.state.mobileMenu){
  
    return (<div id='overlayCat' onClick={()=>this.setState({mobileMenu:false})}></div>)
  }
}
mobileMenu(){
  var style={opacity:1,backgroundColor:'#fff',height:'100vh',borderStyle:'solid',borderColor:'#d2d2d2',borderWidth:1,
  overflow:'auto',padding:'2.5%',display:'flex',flexDirection:'column',alignItems:'flex-start',paddingLeft:10,paddingRight:10}

 
  if(this.state.mobileMenu){
    return(
      <div className='mobileMenu'>
        <ScrollAnimation animateOnce={false}  animateIn="slideInLeft"  className='slideInLeft' animationOut="slideOutLeft" 
     isVisible={true}  style={style}>
         {dashboardRoutes.map((prop, key) => {
                  if(prop.path=='/home'){
                    return (
                     
                     <div
                     key={key}
      className="sideActiveMobile"
      
       onClick={()=>this.props.history.push(`${prop.path}`)}                
      >
      <img alt='Loading...' src={require('../../assets/navbar/Home.png')} alt='Loading...' style={{width:20,marginRight:10}}/>
      
      <text style={{marginTop:5,fontSize:12}}>{prop.name}</text>
      </div>
                     
                   );
                   return null;
                  }
                  else{
                    return (
                     
                     <div
      className="sideInActiveMobile"
      
       onClick={()=>this.props.history.push(`${prop.path}`)}                
      >
      <img alt='Loading...' src={require('../../assets/navbar/'+prop.icon)} style={{width:20,marginRight:10}}/>
      <text style={{marginTop:5,fontSize:12}}>{prop.name}</text>
      </div>
                     
                   );
                   return null; 
                  }
                 

                })}
           
                </ScrollAnimation>
      </div>
    )
  }
}
renderImages(){
  console.log('all images')
  if(this.state.all_images.length!=0){
    return(
      this.state.all_images.map((data,index)=>{
       
        return(
          <img src={data} style={{width:5,height:5,opacity:1}} />
        )
      })
    )
    
  }
  else{
    return(
      <div>Loading</div>
    )
  }
}
  render() {
   
    const sidebarBackground = {
      backgroundColor: "#1C2231"
    };
    
    if(this.state.loading){
      return(<div className="loadingSpin"><HalfCircleSpinner color="#ff000f"/></div>)
    }
    else{
      // if(this.state.paragraph.length!=0 && this.state.list.length!=0){
        
          return (
            <div className="bodyDivCl" >
      
         <div

            id="sidebar"
            className="sideCat"
            data-color="#1C2231"
           
          >
            <div className="sidebar-background" style={sidebarBackground} />
            
            <div className="sidebar-wrapper" style={{display:'flex',flexDirection:'column',justifyContent:'flex-start',alignItems:'center',marginTop:10}}>
            
                  
            {dashboardRoutes.map((prop, key) => {
                  if(prop.path=='/home'){
                    return (
                     
                     <div
                     key={key}
      className="sideActive"
      
       onClick={()=>this.props.history.push(`${prop.path}`)}                
      >
      <img alt='Loading...' src={require('../../assets/navbar/Home_Hover.png')} alt='Loading...' style={{width:25}}/>
      
      <text style={{marginTop:5}}>{prop.name}</text>
      </div>
                     
                   );
                   return null;
                  }
                  else{
                    return (
                     
                     <div
      className="sideInActive"
      
       onClick={()=>this.props.history.push(`${prop.path}`)}                
      >
      <img alt='Loading...' src={require('../../assets/navbar/'+prop.icon)} style={{width:25}}/>
      <text style={{marginTop:5}}>{prop.name}</text>
      </div>
                     
                   );
                   return null; 
                  }
                 
                })}
                {this.renderExitButton()}
            </div>
            <div
        className="sideInActive"
      
         onClick={()=>this.logout()}                
        >
        <MDBIcon style={{marginRight:5,width:'100%',display:'flex',justifyContent:'center',alignItems:'center'}} className="grey-text" size="3x"    icon="sign-out-alt" />
        <text>{this.state.logoutText}</text>
        </div>
          </div>
         
          
          {this.mobileMenu()}
      
            <div className="conTable">
       
       <div style={{backgroundColor:'white'}}>
          
       <div className="topMenu">
      
    
       <div style={{display:'flex',justifyContent:'center'}}>{this.renderLanguages()}</div>
        
       <div style={{display:'flex',justifyContent:'center'}}><img alt='Loading...'  src={require('../../assets/abb_logo.png')} className='abb_logo'/></div>
          
        
               
                
                 </div>
         </div>
         
      {this.renderData()}
       {/* {this.renderImages()}  */}
      {/* <div style={{opacity:0,position:'absolute'}}><Subcatagories />
      <Catagories />
      </div> */}
      <div className='next' style={{marginTop:10}}>
       <h5>{this.state.nextText}</h5> <text onClick={()=>this.props.history.push('causes')} className='arrowRed'></text></div>
      
     
      </div>  
          
          
      
      
      {this.renderoverLay()}
       {this.renderlangModal()}
       {this.renderYouTubeModal()}
       {this.renderExitModal()}
       {this.renderDetails()}
       {this.showUpdateBar()}
                     </div>
                    
          
        )
      }
  
    
    
  
    
  

    
  }
}
export default Dashboard