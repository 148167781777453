import React, { Component } from "react";
import ReactTooltip from 'react-tooltip'
import { MDBNavbar, MDBDropdownItem,MDBDropdownMenu,  MDBIcon, MDBTable, MDBTableBody, MDBTableHead, MDBBtn,MDBRow,
  MDBCol,
  MDBDropdown,
  MDBDropdownToggle,
  MDBCardHeader,MDBCard,MDBCardImage, MDBModal, MDBModalBody, MDBInput, MDBModalFooter,MDBCardBody,MDBPopover, MDBPopoverBody, MDBCardTitle,  } from 'mdbreact';
  import dashboardRoutes from "../../routes/dashboard";
  import swal from 'sweetalert'
  import { Grid, Row, Col,Image,ListGroup,ListGroupItem,Button,Form,FormControl,Checkbox,ControlLabel, } from "react-bootstrap";
import './products.css'
import Select from 'react-select'

class Products extends Component {
  constructor(props) {
    super(props);
  this.state={
    loading:false,info_heads:[],add_head:false,clients:[],
    new_name:"",new_des:"",new_hsn:"",new_type:"Select Cost Type",selected_id:"",
    selected_language:[],products:[],products2:[],
    add_product:false, edit_product:false,product_name:'',description:'',specs:[{application:''}],applications:[{application:''}],features:[{feature:''}],product_image:'',
    edit_product_name:'',edit_description:'',languages:[],
    active_div:0,active_app:0,active_spec:0,edit_image:'',specs_type:0,selected_spec:{label:'Select Type',value:2},
    specs_image:null,superadmin:false,admin:false,superadmin:false,admin:false,selected_product:null,
    edit_specs_image:null,specs_type_edit:0,selected_spec_edit:{label:'Select Type',value:2},
    table_headers:['',''],table_rows:[{value:['','']}],open_table_modal:false,brochure:'',website:'',thingmark:'',edit_thingmark:''
  }
}
  componentWillMount(){

  }
  specsType(){
    if(this.state.selected_spec.value==0){
      return( <div>
        {this.renderSpecs()}
      <div 
style={{display:'flex',justifyContent:'flex-end',marginTop:5,marginBottom:5}}
><text className="addButton" onClick={()=>this.addSpecs()}>+ Add Specs</text>

</div>
      </div>)
    }
    else if(this.state.selected_spec.value==1){
      return(
    <div  style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
    <div 
style={{display:'flex',justifyContent:'flex-end',marginTop:5,marginBottom:5,width:'100%'}}
><text className='addButton' onClick={()=>this.addRow()}>+ Row</text>
<text className='addButton' onClick={()=>this.addColumn()}>+ Column</text>

</div>
 
<table>

<tr > {this.state.table_headers.map((head, i)=>(<th  key={i}>
  <div><MDBIcon style={{margin:8}} icon='trash' className='red-text' onClick={()=>this.deleteCol(i)}/></div>
<input placeholder='Enter Heading' onChange={(text)=>this.handleHeading(text,i)} value={head}/></th>))} </tr>
{this.state.table_rows.map((data, i1)=>
<tr>{data.value.map((data2, i2)=>(<td key={i2}><input placeholder='Enter Value' onChange={(text)=>this.handleValues(text,i1,i2)} value={data2}/></td>))}  <MDBIcon className='red-text' style={{margin:8}} icon='trash' onClick={()=>this.deleteRow(i1)}/></tr>)}
   </table>
 
  </div>
  )
    }
  }
    getDetails(){
      var userlogin=window.localStorage.getItem('userlogin')
      var adminlogin=window.localStorage.getItem('superadmin')
      if(userlogin==null && adminlogin==null){
        this.props.history.push('login')
      }
      else if(userlogin==null && adminlogin!=null){
        this.setState({superadmin:true})
      }
      else if(userlogin!=null && adminlogin==null){
        this.setState({admin:true})
      }
        }
  addApps(){
    let {applications}=this.state
    applications.push({application:''})
    this.setState({applications})
  
  }
  removeApp(data,key){
    let {applications}=this.state
    applications.splice(key,1)
    this.setState({applications})
  
  }
  addEditApps(){
    let {applications}=this.state
    
    applications.push({application:''})
    this.setState({applications})
  
  }
  removeEditApp(data,key){
    let {applications}=this.state
    applications.splice(key,1)
    this.setState({applications})
  
  }
  addSpecs(){
    let {specs}=this.state
    specs.push({application:''})
    this.setState({specs})
  
  }
  removeSpecs(data,key){
    let {specs}=this.state
    specs.splice(key,1)
    this.setState({specs})
  
  }
  addEditSpecs(){
    let {specs}=this.state
    specs.push({application:''})
    this.setState({specs})
  
  }
  removeEditSpecs(data,key){
    let {specs}=this.state

    specs.splice(key,1)
    this.setState({specs})
  
  }
  addFeature(){
    let {features}=this.state
    features.push({feature:''})
    this.setState({features})
  
  }
  removeFeature(data,key){
    let {features}=this.state
    features.splice(key,1)
    this.setState({features})
  
  }
  addEditFeature(){
    let {features}=this.state
    features.push({feature:''})
    this.setState({features})
  
  }
  removeEditFeature(data,key){
    let {features}=this.state

    features.splice(key,1)
    this.setState({features})
  
  }
  toggleAdd = () => {
   
    this.setState({
      add_head: !this.state.add_head,
      new_name:"",new_des:"",new_hsn:"",new_type:"Select Cost Type",selected_id:"",
    });
  }

  componentDidMount(){
this.getuserdata()

  }

  getuserdata=async()=>{
    var userdata=window.localStorage.getItem('userlogin')
    var admindata=window.localStorage.getItem('superadmin')
    if(userdata!=null && admindata==null){
    this.setState({
      loading:true
    })
      let details = {
      admin_id:JSON.parse(userdata).admin_id
    };
    let formBody = [];
    for (let property in details) {
     let encodedKey = encodeURIComponent(property);
     let encodedValue = encodeURIComponent(details[property]);
     formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    
    this.setState({
     response: fetch('http://18.191.5.172:3000/get_admin', {
       method: 'POST',
       headers: {
        
         'Content-Type': 'application/x-www-form-urlencoded',
         'Cache-Control': 'max-age=31536000'
     },
    body:formBody
      
       
     }).then((response) => response.json())
     .then((responseJson)=>{
      var defaultLanguage=window.localStorage.getItem('userdefault')
      for(var v=0;v<dashboardRoutes.length;v++){
        if(dashboardRoutes[v].name=="Users"){
          dashboardRoutes.splice(v,1)
        }
      }
    if(defaultLanguage==null){

      this.setState({
        userdata:responseJson,
        loading:false,
        selected_language:JSON.parse(responseJson.language_access)[0]
      })
    
     }
     else{
      this.setState({
        userdata:responseJson,
        loading:false,
        selected_language:JSON.parse(defaultLanguage)
      })
     }
     

     this.getProducts()
    }
  
   )
     .catch((error) => {
    this.setState({
      loading:false
    })
    swal("Warning!", "Check your network!", "warning");
  console.log(error)
      })
     })
    }
    else if(userdata==null && admindata!=null){
      this.setState({
        loading:true
      })
      this.setState({
        response: fetch('http://18.191.5.172:3000/get_languages', {
          method: 'GET',
          headers: {
           
            'Content-Type': 'application/x-www-form-urlencoded',
            'Cache-Control': 'max-age=31536000'
        },
      
          
        }).then((response) => response.json())
        .then((responseJson)=>{
      
     var defaultLanguage=window.localStorage.getItem('admindefault')
     if(defaultLanguage==null){

      this.setState({
        languages:responseJson,
        loading:false,
        selected_language:responseJson[0]
      })
     }
     else{
      this.setState({
        languages:responseJson,
        loading:false,
        selected_language:JSON.parse(defaultLanguage)
      })
     }
   
     this.getProducts()
       }
     
      )
        .catch((error) => {
       this.setState({
         loading:false
       })
       swal("Warning!", "Check your network!", "warning");
     console.log(error)
         })
        })
    }
  }
  getProducts=async()=>{
    this.setState({
      loading:true
    })
  
    let details = {
    language_id:this.state.selected_language.language_id
  };
  let formBody = [];
  for (let property in details) {
   let encodedKey = encodeURIComponent(property);
   let encodedValue = encodeURIComponent(details[property]);
   formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  
  this.setState({
   response: fetch('http://18.191.5.172:3000/get_all_products', {
     method: 'POST',
     headers: {
      
       'Content-Type': 'application/x-www-form-urlencoded',
       'Cache-Control': 'max-age=31536000'
   },
  body:formBody
    
     
   }).then((response) => response.json())
   .then((responseJson)=>{
    for(var v=0;v<responseJson.length;v++){
      responseJson[v].selected=0

    }
     this.setState({
      products:responseJson,
      products2:responseJson,
       loading:false
     })
   
   }
  
   )
     .catch((error) => {
    this.setState({
      loading:false
    })
    swal("Warning!", "Check your network!", "warning");
  console.log(error)
      })
     })
  
  }
  selectPorduct(data,key){
    this.setState({selected_product:data})
    let {products}=this.state
    let targetproduct=products[key]
    for(var v=0;v<products.length;v++){
      products[v].selected=0
    }
    targetproduct.selected=1
    this.setState({products})
    }


 
  addP=()=>{
  if(this.state.product_name.length==0 ||  this.state.product_image.length==0 || 
    (this.state.applications[0].application).length==0 || 
    (this.state.features[0].feature).length==0 )
  
   {
swal('Warning','Fill all fields','warning')
   }
   else
    {
      if(this.state.selected_spec.value==2){
 this.setState({
         loading:true
       })
       let formData = new FormData()
       formData.append('language_id',this.state.selected_language.language_id)
       formData.append('product_name',this.state.product_name)
    
       formData.append('image',this.state.product_image)
       formData.append('application',JSON.stringify(this.state.applications))
       formData.append('specs_type',2)
       formData.append('features',JSON.stringify(this.state.features))
       formData.append('brochure',this.state.brochure)
       formData.append('website',this.state.website)
       for (let key of formData.entries()) {
         console.log(key[0] + ', ' + key[1]);
     }
   
     
     this.setState({
      response: fetch('http://18.191.5.172:3000/add_product', {
        method: 'POST',
      body: formData
       
        
      }).then((response) => response.json())
      .then((responseJson)=>{
        console.log(responseJson); 
window.location.reload()
  this.setState({
    loading:false})

    
  }
      )
        .catch((error) => {
     
          this.setState({
            show:false,item_name:'',item_price:'',item_image:''
          })
  console.log(error)
         })
        })
      }
      else if(this.state.selected_spec.value==0){
        if((this.state.specs[0].application).length==0){
          swal('Warning','Enter specifications','warning')
        }
        this.setState({
          loading:true
        })
        let formData = new FormData()
        formData.append('language_id',this.state.selected_language.language_id)
        formData.append('product_name',this.state.product_name)
     
        formData.append('image',this.state.product_image)
        formData.append('application',JSON.stringify(this.state.applications))
        formData.append('specs_type',0)
        formData.append('features',JSON.stringify(this.state.features))
        formData.append('specs',JSON.stringify(this.state.specs))
        formData.append('brochure',this.state.brochure)
        formData.append('website',this.state.website)
        for (let key of formData.entries()) {
          console.log(key[0] + ', ' + key[1]);
      }
    
      
      this.setState({
       response: fetch('http://18.191.5.172:3000/add_product', {
         method: 'POST',
       body: formData
        
         
       }).then((response) => response.json())
       .then((responseJson)=>{
        console.log(responseJson);
   window.location.reload()
   this.setState({
     loading:false})
 
     
   }
       )
         .catch((error) => {
      
           this.setState({
             show:false,item_name:'',item_price:'',item_image:''
           })
   console.log(error)
          })
         })
      }
      else if(this.state.selected_spec.value==1){
        if(this.state.table_headers[0]==''){
          swal('Warning','Enter data','warning')
        }
        else{
          this.setState({
            loading:true
          })
          let formData = new FormData()
          formData.append('language_id',this.state.selected_language.language_id)
          formData.append('product_name',this.state.product_name)
      
          formData.append('image',this.state.product_image)
          formData.append('application',JSON.stringify(this.state.applications))
          formData.append('specs_type',1)
          formData.append('features',JSON.stringify(this.state.features))
          formData.append('specs',JSON.stringify({headers:this.state.table_headers,data:this.state.table_rows}))
          formData.append('brochure',this.state.brochure)
          formData.append('website',this.state.website)
          for (let key of formData.entries()) {
            console.log(key[0] + ', ' + key[1]);
        }
      
        
        this.setState({
         response: fetch('http://18.191.5.172:3000/add_product', {
           method: 'POST',
         body: formData
          
           
         }).then((response) => response.json())
         .then((responseJson)=>{
          console.log(responseJson);
         window.location.reload()
     this.setState({
       loading:false})
   
       
     }
         )
           .catch((error) => {
        
             this.setState({
               show:false,item_name:'',item_price:'',item_image:''
             })
     console.log(error)
            })
           })
        }
   
      }
    }
 }
 edit_product=()=>{


   if(this.state.edit_image.length!=0){
  
    if(this.state.edit_product_name.length==0 || 
      (this.state.applications[0].application).length==0 || 
      (this.state.features[0].feature).length==0 ) {
  swal('Warning','Fill all fields','warning')
}
     else
      {
  
       if(this.state.selected_product.specs_type==0){
         if((this.state.applications[0].application).length==0){
          swal('Warning','Enter Specification','warning') 
         }
         else{
          this.setState({
           loading:true
         })
         let formData = new FormData()
         formData.append('language_id',this.state.selected_language.language_id)
         formData.append('product_name',this.state.edit_product_name)
  
         formData.append('image',this.state.edit_image)
         formData.append('application',JSON.stringify(this.state.applications))
         formData.append('product_id',this.state.selected_product.product_id)
         formData.append('image_filename',this.state.selected_product.image_filename)
         formData.append('features',JSON.stringify(this.state.features))
         formData.append('specs',JSON.stringify(this.state.specs))
         formData.append('brochure',this.state.brochure)
         formData.append('website',this.state.website)
         for (let key of formData.entries()) {
           console.log(key[0] + ', ' + key[1]);
       }
     
       
       this.setState({
        response: fetch('http://18.191.5.172:3000/edit_product', {
          method: 'POST',
        body: formData
         
          
        }).then((response) => response.json())
        .then((responseJson)=>{
          console.log(responseJson)
 window.location.reload()
    this.setState({
      loading:false})
  
      
    }
        )
          .catch((error) => {
       
            this.setState({
              loading:false
            })
    console.log(error)
           })
          })
         }
       
       }
       else  if(this.state.selected_product.specs_type==1){
         if(this.state.table_headers[0]==''){
          swal('Warning','Enter data in table','warning')   
         }
else{
  this.setState({
           loading:true
         })
         let formData = new FormData()
         formData.append('language_id',this.state.selected_language.language_id)
         formData.append('product_name',this.state.edit_product_name)
     
         formData.append('image',this.state.edit_image)
         formData.append('application',JSON.stringify(this.state.applications))
         formData.append('product_id',this.state.selected_product.product_id)
         formData.append('image_filename',this.state.selected_product.image_filename)
         formData.append('features',JSON.stringify(this.state.features))
         formData.append('specs_type',1)
         formData.append('specs',JSON.stringify({headers:this.state.table_headers,data:this.state.table_rows}))
         formData.append('brochure',this.state.brochure)
         formData.append('website',this.state.website)
         for (let key of formData.entries()) {
           console.log(key[0] + ', ' + key[1]);
       }
     
       
       this.setState({
        response: fetch('http://18.191.5.172:3000/edit_product', {
          method: 'POST',
        body: formData
         
          
        }).then((response) => response.json())
        .then((responseJson)=>{
          console.log(responseJson)
 window.location.reload()
    this.setState({
      loading:false})
  
      
    }
        )
          .catch((error) => {
       
            this.setState({
              loading:false
            })
    console.log(error)
           })
          })
}
     
       }
       else  if(this.state.selected_product.specs_type==2){
        this.setState({
           loading:true
         })
         let formData = new FormData()
         formData.append('language_id',this.state.selected_language.language_id)
         formData.append('product_name',this.state.edit_product_name)
   
         formData.append('image',this.state.edit_image)
         formData.append('application',JSON.stringify(this.state.applications))
         formData.append('product_id',this.state.selected_product.product_id)
         formData.append('image_filename',this.state.selected_product.image_filename)
         formData.append('features',JSON.stringify(this.state.features))
         formData.append('specs_type',2)
         formData.append('brochure',this.state.brochure)
         formData.append('website',this.state.website)
         for (let key of formData.entries()) {
           console.log(key[0] + ', ' + key[1]);
       }
     
       
       this.setState({
        response: fetch('http://18.191.5.172:3000/edit_product', {
          method: 'POST',
        body: formData
         
          
        }).then((response) => response.json())
        .then((responseJson)=>{
          console.log(responseJson)
 window.location.reload()
    this.setState({
      loading:false})
  
      
    }
        )
          .catch((error) => {
       
            this.setState({
              loading:false
            })
    console.log(error)
           })
          })
       }
      
         }
  
   }
   else{
    if(this.state.edit_product_name.length==0 || 
      (this.state.applications[0].application).length==0 ||  (this.state.features[0].feature).length==0 ) 
    
     {
  swal('Warning','Fill all fields','warning')
     }
     else{
      if(this.state.selected_product.specs_type==0){
         if((this.state.applications[0].application).length==0){
          swal('Warning','Enter Specification','warning') 
         }
         else{
      let details = {
        language_id:this.state.selected_language.language_id,
        product_name:this.state.edit_product_name,
      
        application:JSON.stringify(this.state.applications),
        product_id:this.state.selected_product.product_id,
        image_filename:this.state.selected_product.image_filename,
        specs:JSON.stringify(this.state.specs),
        specs_type:0,
        brochure:this.state.brochure,
        website:this.state.website
        };
        console.log(details)
    
        let formBody = [];
        for (let property in details) {
         let encodedKey = encodeURIComponent(property);
         let encodedValue = encodeURIComponent(details[property]);
         formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        
        this.setState({
         response: fetch('http://18.191.5.172:3000/edit_product', {
           method: 'POST',
           headers: {
            
             'Content-Type': 'application/x-www-form-urlencoded',
           
         },
         body:formBody
        
          
           
         }).then((response) => response.json())
         .then((responseJson)=>{
      console.log(responseJson)
          window.location.reload() 
       }
        
         )
           .catch((error) => {
          this.setState({
            loading:false
          })
          alert("Warning!", "Check your network!", "warning");
        console.log(error)
            })
           })
          }
        }
        else  if(this.state.selected_product.specs_type==1){
          if(this.state.table_headers[0]==''){
          swal('Warning','Enter data in table','warning')   
         }
else{

   let details = {
        language_id:this.state.selected_language.language_id,
        product_name:this.state.edit_product_name,
     
        application:JSON.stringify(this.state.applications),
        product_id:this.state.selected_product.product_id,
        image_filename:this.state.selected_product.image_filename,
        specs:JSON.stringify({headers:this.state.table_headers,data:this.state.table_rows}),
       specs_type:1,
       brochure:this.state.brochure,
       website:this.state.website
        };
        console.log(details)
    
        let formBody = [];
        for (let property in details) {
         let encodedKey = encodeURIComponent(property);
         let encodedValue = encodeURIComponent(details[property]);
         formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        
        this.setState({
         response: fetch('http://18.191.5.172:3000/edit_product', {
           method: 'POST',
           headers: {
            
             'Content-Type': 'application/x-www-form-urlencoded',
           
         },
         body:formBody
        
          
           
         }).then((response) => response.json())
         .then((responseJson)=>{
       console.log(responseJson)
          window.location.reload() 
       }
        
         )
           .catch((error) => {
          this.setState({
            loading:false
          })
          alert("Warning!", "Check your network!", "warning");
        console.log(error)
            })
           })
}
        }
        else  if(this.state.selected_product.specs_type==2){
   
   let details = {
        language_id:this.state.selected_language.language_id,
        product_name:this.state.edit_product_name,
    
        application:JSON.stringify(this.state.applications),
        product_id:this.state.selected_product.product_id,
        image_filename:this.state.selected_product.image_filename,
       specs_type:2,
       brochure:this.state.brochure,
       website:this.state.website
        };
        console.log(details)
    
        let formBody = [];
        for (let property in details) {
         let encodedKey = encodeURIComponent(property);
         let encodedValue = encodeURIComponent(details[property]);
         formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        
        this.setState({
         response: fetch('http://18.191.5.172:3000/edit_product', {
           method: 'POST',
           headers: {
            
             'Content-Type': 'application/x-www-form-urlencoded',
           
         },
         body:formBody
        
          
           
         }).then((response) => response.json())
         .then((responseJson)=>{
       console.log(responseJson)
          window.location.reload() 
       }
        
         )
           .catch((error) => {
          this.setState({
            loading:false
          })
          alert("Warning!", "Check your network!", "warning");
        console.log(error)
            })
           })

        }
     }
    }
 }


  
 
  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  
editHead(data){

  var t="";
  

  if(data.type==1){t="One Time Cost"}
  else if(data.type==2){t="Recurring Cost"}
  else{t="Select Cost Type"}

this.setState({
new_name:data.name,
new_des:data.description,
new_hsn:data.hsn,
new_type:t,
selected_id:data.head_id,
add_head: !this.state.add_head,
})



}

  openDetails(data){
if(this.state.active_div==data.product_id){
  this.setState({active_div:0})
}
else{
  this.setState({active_div:data.product_id})
}
  }
  openApp(data){
 
    if(this.state.active_app==data.product_id){
      this.setState({active_app:0,active_spec:0})
    }
    else{
      this.setState({active_app:data.product_id,active_spec:data.product_id})
    }
  }
  openSpecs(data){

  
    if(this.state.active_spec==data.product_id){
      this.setState({active_app:0,active_spec:0})
    }
    else{
      this.setState({active_spec:data.product_id,active_app:0})
    }
  }
  renderApps(data){

    if(data.product_id==this.state.active_app){
         
      return( <div style={{display:'flex',flexDirection:'column',margin:10}}>{JSON.parse(data.application).map((data,key) => {
  
        return (   
       
        <text style={{color:'#000',fontSize:15}}><MDBIcon icon="hand-point-right" className='black-text' size='sm'/> {data.application}</text> 
        

        
        
               )
        
        
        })}</div>)
    }
  }
  renderSpecsData(data){

    if(data.product_id==this.state.active_spec){
         
return( <div style={{display:'flex',flexDirection:'column',margin:10}}>{JSON.parse(data.specs).map((data,key) => {
  
  return (   

  <text style={{color:'#000',fontSize:15}}><MDBIcon icon="hand-point-right" className='black-text'  size='sm'/>  {data.application}</text> 
 

  
  
         )
  
  
  })}</div>)
    }
  }
renderDetails(data){
  
    return(<div
    style={{display:'flex',justifyContent:'flex-end',alignItems:'center',flexDirection:'row',width:'-webkit-fill-available'}}>
<div  style={{display:'flex',justifyContent:'center',alignItems:'flex-start',flexDirection:'row'}}>
<div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',width:300}}>
<text 
ref={element => { 
  if (element) element.style.setProperty('background-color', 'white', 'important'); 
}}
className='btn' 
onClick={()=>this.openApp(data)}
style={{backgroundColor:'white !important',color:'#000',borderRadius:0,margin:5}}
>Applications &nbsp;<MDBIcon icon='angle-down' className='black-text'/></text>
{this.renderApps(data)}
</div>
<div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',width:300}}>



<text 
onClick={()=>this.openApp(data)}
ref={element => { 
  if (element) element.style.setProperty('background-color', 'white', 'important'); 
}}
style={{backgroundColor:'white !important',color:'#000',borderRadius:0,margin:5}}
className='btn'>Specifications&nbsp;<MDBIcon icon='angle-down' className='black-text'/></text>
{this.renderSpecsData(data)}
</div>
</div>

<div style={{display:'flex',justifyContent:'center'}}><img src={data.image} 
style={{height:120,margin:5}}/></div>
 
 </div>)
 
}
  renderList(){

    if(this.state.loading){
      return(<div className="loadingSpin"><div className="loader border-top-primary border-right-warning border-bottom-success border-left-danger"></div></div>)
    }
    else{
      return (
        this.state.products.map((data,key) => {
  
  return (   
  <div style={{display:'flex',flexDirection:'row',
  justifyContent:'center',alignItems:'center',borderBottomColor:'#d2d2d2',borderBottomStyle:'solid',borderBottomWidth:1}}>
  <text onClick={()=>this.openDetails(data)} style={{color:'#FC0107',fontSize:20,marginLeft:50}}>{data.product_name}</text> 
  {this.renderDetails(data)}
  </div>
  
         )
  
  
  })
  
        
        
      )
    }
   


  }



  addHead=async()=>{


if(this.state.selected_id===""){




    if(this.state.new_name.length==0||this.state.new_des.length==0||this.state.new_hsn.length==0||this.state.new_type=="Select Cost Type"){
      swal("Warning!", "Enter all fields!", "warning");

    }
    else{
     this.setState({new_name:"",new_des:"",new_hsn:"",new_type:"Select Cost Type",loading:true,add_head:false})
       var t="";
     if(this.state.new_type=="One Time Cost"){t=1;}
     if(this.state.new_type=="Recurring Cost"){t=2;}
     else{t=0;}



    let details = {
    name:this.state.new_name,
    description:this.state.new_des,
    hsn:this.state.new_hsn,
    type:t
    };
    
    let formBody = [];
    for (let property in details) {
    let encodedKey = encodeURIComponent(property);
    let encodedValue = encodeURIComponent(details[property]);
    formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    
    this.setState({
    response: fetch('http://52.15.32.193:3000/add_head', {
     method: 'POST',
     headers: {
      
       'Content-Type': 'application/x-www-form-urlencoded',
     
    },
    body:formBody
    
     
    }).then((response) => response.json())
    .then((responseJson)=>{
      window.location.reload()
    this.setState({loading:false})
    
    }
    
    )
     .catch((error) => {
    this.setState({
      loading:false
    })
    alert("Warning!", "Check your network!", "warning");
    console.log(error)
      })
     })





    }
    
    }


    else{

      

      if(this.state.new_name.length==0||this.state.new_des.length==0||this.state.new_hsn.length==0||this.state.new_type=="Select Cost Type"){
        swal("Warning!", "Enter all fields!", "warning");
  
      }
      else{


        this.setState({new_name:"",new_des:"",new_hsn:"",new_type:"Select Cost Type",loading:true,add_head:false})
        var t="";
      if(this.state.new_type=="One Time Cost"){t=1;}
      if(this.state.new_type=="Recurring Cost"){t=2;}
      else{t=0;}
 
 
     let details = {
     name:this.state.new_name,
     description:this.state.new_des,
     hsn:this.state.new_hsn,
     type:t,
     head_id:this.state.selected_id
     };
     
     let formBody = [];
     for (let property in details) {
     let encodedKey = encodeURIComponent(property);
     let encodedValue = encodeURIComponent(details[property]);
     formBody.push(encodedKey + "=" + encodedValue);
     }
     formBody = formBody.join("&");
     
     this.setState({
     response: fetch('http://52.15.32.193:3000/edit_head', {
      method: 'POST',
      headers: {
       
        'Content-Type': 'application/x-www-form-urlencoded',
      
     },
     body:formBody
     
      
     }).then((response) => response.json())
     .then((responseJson)=>{
       window.location.reload()
     this.setState({loading:false})
     
     }
     
     )
      .catch((error) => {
     this.setState({
       loading:false
     })
     alert("Warning!", "Check your network!", "warning");
     console.log(error)
       })
      })





      }




    }

  }


  returnTitle(){
if(this.state.selected_id===""){return(<text>Add Head</text>)}
else{return(<text>Edit Head</text>)}

  }


 


handleChangename=(e)=>{
  this.setState({[e.target.id]:e.target.value});
}

toggle = () => {
  this.setState({
    add_product: !this.state.add_product,
  });
}
editSelectedProduct(){

if(this.state.selected_product.specs_type==0){
  this.setState({
    edit_product_name:this.state.selected_product.product_name,
    applications:JSON.parse(this.state.selected_product.application),
    specs:JSON.parse(this.state.selected_product.specs),
    features:JSON.parse(this.state.selected_product.features),
 
    specs_type:this.state.selected_product.specs_type,
    brochure:this.state.selected_product.brochure,
    website:this.state.selected_product.website
  },this.toggleEdit)
}
else if(this.state.selected_product.specs_type==1){
  this.setState({
    edit_product_name:this.state.selected_product.product_name,
    applications:JSON.parse(this.state.selected_product.application),
   table_headers:JSON.parse(this.state.selected_product.specs).headers,
   table_rows:JSON.parse(this.state.selected_product.specs).data,
    features:JSON.parse(this.state.selected_product.features),
 
    specs_type:this.state.selected_product.specs_type,
    brochure:this.state.selected_product.brochure,
    website:this.state.selected_product.website
  },this.toggleEdit)
}
 else if(this.state.selected_product.specs_type==2){
  this.setState({
    edit_product_name:this.state.selected_product.product_name,
    applications:JSON.parse(this.state.selected_product.application),
    specs:[{application:''}],
    features:JSON.parse(this.state.selected_product.features),

    specs_type:this.state.selected_product.specs_type,
    brochure:this.state.selected_product.brochure,
    website:this.state.selected_product.website
  },this.toggleEdit)
 }
}
toggleEdit = () => {

  this.setState({
    edit_product: !this.state.edit_product,
  });
}
handleChangepname=(e)=> {
  this.setState({product_name: e.target.value});
}
handleChangepdescription=(e)=> {
  this.setState({description: e.target.value});
}
handleChangeEditpname=(e)=> {
  this.setState({edit_product_name: e.target.value});
}
handleChangebrochure=(e)=> {
  this.setState({brochure: e.target.value});
}
handleChangewebsite=(e)=> {
  this.setState({website: e.target.value});
}
handleChangeEditpdescription=(e)=> {
  this.setState({edit_description: e.target.value});
}
handleChangepapplication(e,i) {

let {applications}=this.state
let targetApp = applications[i]  
targetApp.application = e.target.value
this.setState({applications})
}

handleChangepspecs(e,i) {

  let {specs}=this.state
  let targetspecs = specs[i]  
  targetspecs.application = e.target.value
  this.setState({specs})
  }
  handleChangefeature(e,i) {
    let {features}=this.state
let targetfeatures = features[i]  
targetfeatures.feature = e.target.value
this.setState({features})
    }
  handleChangepEditapplication(e,i) {

    let {applications}=this.state
    let targetApp = applications[i]  
    targetApp.application = e.target.value
    this.setState({applications})
    }
    handleChangeEditSpecs(e,i) {
      let {specs}=this.state
      let targetspecs = specs[i]  
      targetspecs.application = e.target.value
      this.setState({specs})
      }
      handleChangeEditFeature(e,i) {
        let {features}=this.state
  let targetfeatures = features[i]  
  targetfeatures.feature = e.target.value
  this.setState({features})
        }
productImageHandler = (event) => {
 
  this.setState({product_image:event.target.files[0]})
}
thingmarkHandler = (event) => {
 
  this.setState({thingmark:event.target.files[0]})
}
productEditImageHandler = (event) => {
  this.setState({
    edit_image:event.target.files[0]
  })
}
productEditSpecsImageHandler = (event) => {
  this.setState({
    edit_specs_image:event.target.files[0]
  })
}
productSpecsHandler = (event) => {
 
  this.setState({specs_image:event.target.files[0]})
}
// specsImageHandler = (event) => {
 
//   this.setState({product_image:event.target.files[0]})
// }
renderthingmark(){
  if(this.state.thingmark.length==0){
    return(<div className="upload-btn-wrapper"  
    style={{cursor:'pointer',display:'flex',justifyContent:'center',width:'fit-content',alignItems:'center',margin:10}}>
    <text  style={{marginTop:10,marginRight:10,marginBottom:10}} >Thingmark</text>
    <input type="file"  onChange={this.thingmarkHandler}/>
  </div>)
  }
  else{
    return(
      <div  style={{cursor:'pointer',display:'flex',justifyContent:'space-around',margin:10,width:'-webkit-fill-available',alignItems:'center'}}>
    <text style={{color:'black'}}>{this.state.thingmark.name}</text>
    <MDBIcon onClick={()=>this.setState({thingmark:''})} icon="times-circle" />
    </div>
    )
  }
}
renderProductImage(){

  if(this.state.product_image.length==0){
    return(<div className="upload-btn-wrapper"  
    style={{cursor:'pointer',display:'flex',justifyContent:'center',width:'fit-content',alignItems:'center',margin:10}}>
    <text  style={{marginTop:10,marginRight:10,marginBottom:10}} >Image</text>
    <input type="file"  onChange={this.productImageHandler}/>
  </div>)
  }
  else{
    return(
      <div  style={{cursor:'pointer',display:'flex',justifyContent:'space-around',margin:10,width:'-webkit-fill-available',alignItems:'center'}}>
    <text style={{color:'black'}}>{this.state.product_image.name}</text>
    <MDBIcon onClick={()=>this.setState({product_image:''})} icon="times-circle" />
    </div>
    )
  }
}
removeEditImage(){
this.setState({
  edit_image:''
})
}
renderProductEditImage(){

  if((this.state.edit_image).length==0){
    return(<div className="upload-btn-wrapper"  
    style={{cursor:'pointer',display:'flex',justifyContent:'center',width:'fit-content',alignItems:'center',margin:10}}>
    <text  style={{marginTop:10,marginRight:5,marginBottom:10}} >Edit Image</text>
    <input type="file"  onChange={this.productEditImageHandler}/>
  </div>)
  }
  else{
    return(
      <div  style={{cursor:'pointer',display:'flex',justifyContent:'space-between',width:'-webkit-fill-available',alignItems:'center',margin:10}}>
    <text style={{color:'black'}}>{this.state.edit_image.name}</text>
    <MDBIcon onClick={()=>this.removeEditImage()} icon="times-circle" />
    </div>
    )
  }
}

renderEditthingmark(){
  if(this.state.edit_thingmark.length==0){
    return(<div className="upload-btn-wrapper"  
    style={{cursor:'pointer',display:'flex',justifyContent:'center',width:'fit-content',alignItems:'center',margin:10}}>
    <text  style={{marginTop:10,marginRight:10,marginBottom:10}} >Image</text>
    <input type="file"  onChange={(data)=>this.setState({edit_thingmark:data.value.files[0]})}/>
  </div>)
  }
  else{
    return(
      <div  style={{cursor:'pointer',display:'flex',justifyContent:'space-around',margin:10,width:'-webkit-fill-available',alignItems:'center'}}>
    <text style={{color:'black'}}>{this.state.edit_thingmark.name}</text>
    <MDBIcon onClick={()=>this.setState({edit_thingmark:''})} icon="times-circle" />
    </div>
    )
  }
}
addColumn(){
  let {table_headers,table_rows} = this.state
 table_headers.push('')
  for(var v=0;v<table_rows.length;v++){
   
    (table_rows[v].value).push('')
  }
 
 this.setState({table_headers,table_rows})
}
addRow(){
  let {table_headers,table_rows} = this.state
  let value=[]
  for(var v=0;v<table_headers.length;v++){
   value.push('')
    
  }

  table_rows.push({value:value})
  this.setState({table_rows})
}
handleHeading(text,i){
  let {table_headers} = this.state
 
  table_headers[i]= text.target.value
  this.setState({table_headers})
}
handleValues(text,i,i2){
  let {table_rows} = this.state
  let target_row = table_rows[i].value
  target_row[i2]=text.target.value
 
  this.setState({table_rows})
}
deleteRow(index){
  
  let {table_rows} = this.state
  if((table_rows).length>1){
table_rows.splice(index,1)
 
  this.setState({table_rows})
}
}
deleteCol(index){
  
  let {table_headers,table_rows} = this.state
  if(table_headers.length>1){
    table_headers.splice(index,1)
  for(var v=0;v<table_rows.length;v++){
   
   (table_rows[v].value).splice(index,1)
   this.setState({table_headers,table_rows})
 }
  }

}
renderProductSpecs(){
  if(this.state.open_table_modal){
    return(
    <div  style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
    <div style={{display:'flex',justifyContent:'flex-end',cursor:'pointer'}}><text onClick={()=>this.addRow()}>+ Row</text><text onClick={()=>this.addColumn()}>+ Column</text></div>
<table>

<tr tyle={{display:'flex'}}> {this.state.table_headers.map((head, i)=>(<th  key={i}>
  <div><MDBIcon style={{margin:8}} icon='trash' className='red-text' onClick={()=>this.deleteCol(i)}/></div>
<input placeholder='Enter Heading' onChange={(text)=>this.handleHeading(text,i)} value={head}/></th>))} </tr>
{this.state.table_rows.map((data, i1)=>
<tr>{data.value.map((data2, i2)=>(<td key={i2}><input placeholder='Enter Value' onChange={(text)=>this.handleValues(text,i1,i2)} value={data2}/></td>))}  <MDBIcon className='red-text' style={{margin:8}} icon='trash' onClick={()=>this.deleteRow(i1)}/></tr>)}
   </table>
 
  </div>
  )
  }

}
renderSpecs(){
 
    return( this.state.specs.map((data,key) => {
  
      return ( 
       <div style={{marginTop:10,}}>
      <div style={{display:'flex',justifyContent:'flex-end',cursor:'pointer',width:'100%'}}><MDBIcon 
      onClick={()=>this.removeSpecs(data,key)} className="red-text pr-3" icon="times" /> </div>  
       <MDBInput
        hint="Product Specification"
        group
    
        type="text"
        value={data.application} 
    onChange={(text)=>this.handleChangepspecs(text,key)}
        error="wrong"
        success="right"
    />
       </div>
            )
      
      
      }))
  
}
renderFeatures(){
 
  return( this.state.specs.map((data,key) => {

    return ( 
     <div style={{marginTop:10,}}>
    <div style={{display:'flex',justifyContent:'flex-end',cursor:'pointer',width:'100%'}}><MDBIcon 
    onClick={()=>this.removeFeature(data,key)} className="red-text pr-3" icon="times" /> </div>  
     <MDBInput
      hint="Product Feature"
      group
  
      type="text"
      value={data.application} 
  onChange={(text)=>this.handleChangefeature(text,key)}
      error="wrong"
      success="right"
  />
     </div>
          )
    
    
    }))

}
changeSpecType(){
  let {selected_product}=this.state
  selected_product.specs_type=2
  this.setState({selected_product}) 
}
renderEditSpecs(){
  if(this.state.selected_product.specs_type==0){
    return(
      <div style={{marginTop:10,}}>

      <text style={{color:'black',fontSize:15,fontWeight:'bold',marginTop:10}}>Performance data/ technical specification  </text>

           { this.state.specs.map((data,key) => {
  
  return ( 
   <div >
  <div style={{display:'flex',justifyContent:'flex-end',cursor:'pointer',width:'100%'}}><MDBIcon onClick={()=>this.removeEditSpecs(data,key)} className="red-text pr-3" icon="times" /> </div>  
     <MDBInput
        hint="Product Specification"
        group
    
        type="text"
        value={data.application} 
    onChange={(text)=>this.handleChangeEditSpecs(text,key)}
        error="wrong"
        success="right"
    />
    
   </div>
        )
  
  
  })}
  <div 
style={{display:'flex',justifyContent:'flex-end',marginTop:5,marginBottom:5}}
><text className="addButton" onClick={()=>this.addEditSpecs()}>+ Add Specs</text>

</div>
       </div>
           
    )
  }
  else if(this.state.selected_product.specs_type==2){
    return
//     (
       
// <div style={{margin:5}}>
// <text style={{color:'black',fontSize:15,fontWeight:'bold',marginTop:10}}>Performance data/ technical specification  </text>
// <Select
//       closeMenuOnSelect={true}
//          onChange={e=>{
//           let {selected_product}=this.state
//   selected_product.specs_type=e.value
//   this.setState({selected_product}) 
//          }}
        
//          className='select'
//          options={[{label:'None',value:2},{label:'List',value:0},{label:'Image',value:1}]}
//         value={this.state.selected_spec_edit}
//          />
// </div>
//     )

  }
  else if(this.state.selected_product.specs_type==1){
    return(
      <div  style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
    <div 
style={{display:'flex',justifyContent:'flex-end',marginTop:5,marginBottom:5,width:'100%'}}
><text className='addButton' onClick={()=>this.addRow()}>+ Row</text>
<text className='addButton' onClick={()=>this.addColumn()}>+ Column</text>

</div>
 
<table>

<tr > {this.state.table_headers.map((head, i)=>(<th  key={i}>
  <div><MDBIcon style={{margin:8}} icon='trash' className='red-text' onClick={()=>this.deleteCol(i)}/></div>
<input placeholder='Enter Heading' onChange={(text)=>this.handleHeading(text,i)} value={head}/></th>))} </tr>
{this.state.table_rows.map((data, i1)=>
<tr>{data.value.map((data2, i2)=>(<td key={i2}><input placeholder='Enter Value' onChange={(text)=>this.handleValues(text,i1,i2)} value={data2}/></td>))}  <MDBIcon className='red-text' style={{margin:8}} icon='trash' onClick={()=>this.deleteRow(i1)}/></tr>)}
   </table>
 
  </div>
      
    )
  }
 
  
}
renderEdifFeatures(){
  
    return( (this.state.features).map((data,key) => {
  
      return ( 
       <div style={{marginTop:10,}}>
       
      <div style={{display:'flex',justifyContent:'flex-end',cursor:'pointer',width:'100%'}}><MDBIcon 
      onClick={()=>this.removeEditFeature(data,key)} className="red-text pr-3" icon="times" /> </div>  
       <MDBInput
        hint="Product Features"
        group
    
        type="text"
        value={data.feature} 
    onChange={(text)=>this.handleChangeEditFeature(text,key)}
        error="wrong"
        success="right"
    />
       </div>
            )
      
      
      }))
  
}
logout(){
  window.localStorage.clear()
window.location.reload()

}
selectLanguage(data,key){
  window.localStorage.removeItem('admindefault')
    window.localStorage.setItem('admindefault',JSON.stringify(data))
 
this.setState({
selected_language:data
},()=>window.location.reload())
}
renderLanguages(){
 
  var adminlogin=window.localStorage.getItem('superadmin')
if(this.state.loading==false){
if(this.state.userdata!=null){

  return(<div  

     
    
    style={{backgroundColor:'rgb(255, 255, 255, 0.1)',cursor:'pointer',display:'flex',alignItems:'center'}}>
  <MDBIcon size="2x"    icon="globe-africa" />
  <MDBDropdown style={{marginLeft:5}} >
 <MDBDropdownToggle caret color="#004080" size="md" >
 <text style={{color:'#fff',fontSize:10}}>{this.state.selected_language.language}</text>
 </MDBDropdownToggle>
 <MDBDropdownMenu > 
 { JSON.parse((this.state.userdata).language_access).map((data,key) => {
 
return ( 
<MDBDropdownItem  onClick={()=>this.selectLanguage(data)}>{data.language}</MDBDropdownItem>
  
 )
})
}
</MDBDropdownMenu> 
</MDBDropdown>

    </div>)
}
else if(adminlogin!=null ){

 if(this.state.languages.length>0){
  return(<div  

     
    
    style={{backgroundColor:'rgb(255, 255, 255, 0.1)',cursor:'pointer',display:'flex',alignItems:'center'}}>
  <MDBIcon size="2x"   className="grey-text" icon="globe-africa" />
  <MDBDropdown style={{marginLeft:5}} >
 <MDBDropdownToggle caret color="#004080" size="md" >
 <text style={{color:'#fff',fontSize:10}}>{this.state.selected_language.language}</text>
 </MDBDropdownToggle>
 <MDBDropdownMenu > 
 { (this.state.languages).map((data,key) => {

return ( 
<MDBDropdownItem  onClick={()=>this.selectLanguage(data)}>{data.language}</MDBDropdownItem>
  
   )
})
}
</MDBDropdownMenu>
</MDBDropdown>

    </div>)
 }
}
}
 
}
renderAddProduct(){

  if(this.state.add_product){
    return(<MDBModal isOpen={this.state.add_product} toggle={this.toggle}  fullHeight  position="top">
    <div style={{backgroundColor:'#FF000F',display:'flex',justifyContent:'center',padding:0}}>
                <text  style={{color:'white',padding:10,fontSize:20,fontWeight:'bold'}}>
                  <MDBIcon fab icon="creative-commons-sampling-plus" className='white-text'/> Add Product
                </text>
              </div>
        <MDBModalBody>
        <MDBRow>
        <MDBCol >
        
        <MDBCardBody style={{padding:0}}>
              <form>
              
                <div className="grey-text">
                <MDBRow  style={{display:'flex',justifyContent:'space-between',}}> 
                <input
                    placeholder="Product Name"
                    style={{width:'45%',margin:5,padding:5}}
                    group
                    type="text"
                    value={this.state.product_name} 
         onChange={this.handleChangepname}
                    error="wrong"
                    success="right"
                  />
                   <input
                    placeholder="Description"
                    style={{width:'45%',margin:5,padding:5}}
                    group
                    type="text"
                    value={this.state.description} 
         onChange={this.handleChangepdescription}
                    error="wrong"
                    success="right"
                  /></MDBRow>
                  <MDBRow  style={{display:'flex',justifyContent:'space-between',}}> 
                <input
                    placeholder="Brochure Link"
                    style={{width:'45%',margin:5,padding:5}}
                    group
                    type="text"
                    value={this.state.brochure} 
         onChange={this.handleChangebrochure}
                    error="wrong"
                    success="right"
                  />
                   <input
                    placeholder="Web reference"
                    style={{width:'45%',margin:5,padding:5}}
                    group
                    type="text"
                    value={this.state.website} 
         onChange={this.handleChangewebsite}
                    error="wrong"
                    success="right"
                  /></MDBRow>
{this.renderProductImage()}
{this.renderthingmark()}
  <text style={{color:'black',fontSize:15,fontWeight:'bold',marginTop:10}}>Product Description & Features</text>
  { this.state.features.map((data,key) => {
  
  return ( 
   <div >
  <div style={{display:'flex',justifyContent:'flex-end',cursor:'pointer',width:'100%'}}><MDBIcon onClick={()=>this.removeFeature(data,key)} className="red-text pr-3" icon="times" /> </div>  
   <MDBInput
    hint="Product Feature"
    group

    type="text"
    value={data.feature} 
onChange={(text)=>this.handleChangefeature(text,key)}
    error="wrong"
    success="right"
/>
   </div>
        )
  
  
  })}
  <div 
   style={{display:'flex',justifyContent:'flex-end',marginTop:5,marginBottom:5}}
   ><text className="addButton" onClick={()=>this.addFeature()}>+ Add Feature</text>
   
   </div>
  <text style={{color:'black',fontSize:15,fontWeight:'bold'}}>Product Applications</text>
       { this.state.applications.map((data,key) => {
  
  return ( 
   <div >
  <div style={{display:'flex',justifyContent:'flex-end',cursor:'pointer',width:'100%'}}><MDBIcon onClick={()=>this.removeApp(data,key)} className="red-text pr-3" icon="times" /> </div>  
   <MDBInput
    hint="Product application"
    group

    type="text"
    value={data.application} 
onChange={(text)=>this.handleChangepapplication(text,key)}
    error="wrong"
    success="right"
/>
   </div>
        )
  
  
  })}
   <div 
   style={{display:'flex',justifyContent:'flex-end',marginTop:5,marginBottom:5}}
   ><text className="addButton" onClick={()=>this.addApps()}>+ Add Application</text>
   
   </div>
 </div>
 <text style={{color:'black',fontSize:15,fontWeight:'bold'}}>Performance data/ technical specification</text>
<div style={{margin:5}}>
<Select
         closeMenuOnSelect={true}
         onChange={e=>{

           if(e.value==1){
             this.setState({
              specs_type:e.value,
        selected_spec:e,
             },this.toggleSpecs)
           }
     else{
      this.setState({
              specs_type:e.value,
        selected_spec:e,
             }) 
     }
         }}
        
         className='select'
         options={[{label:'None',value:2},{label:'List',value:0},{label:'Table',value:1}]}
        value={this.state.selected_spec}
         />
</div>

      
          {this.specsType()}  
     
  
 
              </form>
            </MDBCardBody>
         
        </MDBCol>
      </MDBRow>
        </MDBModalBody>
        <MDBModalFooter>
          <Button style={{backgroundColor:'#FF000F'}} className="confirm"  onClick={()=>this.addP()}>Confirm</Button>
          <MDBBtn style={{backgroundColor:'#FF000F'}} color="elegant" onClick={this.toggle}>Close</MDBBtn>
        </MDBModalFooter>
      </MDBModal>)
  }
}
toggleSpecs = () => {
  this.setState({
    open_table_modal: !this.state.open_table_modal,
  });
}
specsTable(){
  if(this.state.open_table_modal){
    return(
<div>      <MDBModal isOpen={this.state.open_table_modal} toggle={this.toggleSpecs} size="lg"  position="top-right">

<div style={{backgroundColor:'#FF000F',display:'flex',justifyContent:'center',padding:0,}}>
            <text  style={{color:'white',padding:10,fontSize:20,fontWeight:'bold'}}>
              <MDBIcon fab icon="creative-commons-sampling-plus" className='white-text'/> Add Specs
            </text>
          </div>
    <MDBModalBody>
  
    </MDBModalBody>
    <MDBModalFooter>

      <Button className="confirm"   style={{backgroundColor:'#FF000F'}} onClick={()=>console.log(this.state.table_rows,this.state.table_headers)}>Confirm</Button>
      <MDBBtn color="elegant" style={{backgroundColor:'#FF000F'}} onClick={this.toggleSpecs}>Close</MDBBtn>
    </MDBModalFooter>
  </MDBModal></div>
    )
  }
  
}
renderEditProduct(){

  if(this.state.edit_product){
    return(<MDBModal isOpen={this.state.edit_product} toggle={this.toggleEdit} fullHeight  position="bottom">
    <div style={{backgroundColor:'#FF000F',display:'flex',justifyContent:'center',padding:0}}>
                <text  style={{color:'white',padding:10,fontSize:20,fontWeight:'bold'}}>
                  <MDBIcon fab icon="creative-commons-sampling-plus" className='white-text'/> Edit Product
                </text>
              </div>
        <MDBModalBody>
        <MDBRow>
        <MDBCol >
        
        <MDBCardBody style={{padding:0}}>
              <form>
              
                <div className="grey-text">
                <MDBRow  style={{display:'flex',justifyContent:'space-between',}}> 
                  <input
                    placeholder="Product Name"
                    style={{width:'45%',margin:5,padding:5}}
                    group
                    type="text"
                    value={this.state.edit_product_name} 
         onChange={this.handleChangeEditpname}
                    error="wrong"
                    success="right"
                  />
                   <input
                    placeholder="Description"
                    style={{width:'45%',margin:5,padding:5}}
                    group
                    type="text"
                    value={this.state.edit_description} 
         onChange={this.handleChangeEditpdescription}
                    error="wrong"
                    success="right"
                  />
                  </MDBRow>
{this.renderProductEditImage()}
{this.renderEditthingmark()}
<text style={{color:'black',fontSize:15,fontWeight:'bold',marginTop:10}}>Product Description & Features</text>
  { this.state.features.map((data,key) => {
  
  return ( 
   <div >
  <div style={{display:'flex',justifyContent:'flex-end',cursor:'pointer',width:'100%'}}><MDBIcon onClick={()=>this.removeFeature(data,key)} className="red-text pr-3" icon="times" /> </div>  
   <MDBInput
    hint="Product Feature"
    group

    type="text"
    value={data.feature} 
onChange={(text)=>this.handleChangefeature(text,key)}
    error="wrong"
    success="right"
/>
   </div>
        )
  
  
  })}
  <div 
   style={{display:'flex',justifyContent:'flex-end',marginTop:5,marginBottom:5}}
   ><text className="addButton" onClick={()=>this.addFeature()}>+ Add Feature</text>
   
   </div>
   <text style={{color:'black',fontSize:15,fontWeight:'bold',marginTop:10}}>Product Applications</text>
       { this.state.applications.map((data,key) => {
  
  return ( 
   <div >
  <div style={{display:'flex',justifyContent:'flex-end',cursor:'pointer',width:'100%'}}><MDBIcon onClick={()=>this.removeEditApp(data,key)} className="red-text pr-3" icon="times" /> </div>  
   <MDBInput
    hint="Product application"
    group

    type="text"
    value={data.application} 
onChange={(text)=>this.handleChangepEditapplication(text,key)}
    error="wrong"
    success="right"
/>
   </div>
        )
  
  
  })}
   <div 
   style={{display:'flex',justifyContent:'flex-end',marginTop:5,marginBottom:5}}
   ><text className="addButton" onClick={()=>this.addEditApps()}>+ Add Application</text>
   
   </div>
 
   
                </div>
     

               {this.renderEditSpecs()}
              
  

              </form>
            </MDBCardBody>
         
        </MDBCol>
      </MDBRow>
        </MDBModalBody>
        <MDBModalFooter>
          <Button className="confirm"   style={{backgroundColor:'#FF000F'}} onClick={()=>this.edit_product()}>Confirm</Button>
          <MDBBtn color="elegant" style={{backgroundColor:'#FF000F'}} onClick={this.toggleEdit}>Close</MDBBtn>
        </MDBModalFooter>
      </MDBModal>)
  }
}
renderSpecsDetails(){
  if(this.state.selected_product.specs_type==0){
    return(
         <div>
           <text style={{color:'black',fontSize:20,fontWeight:'bold'}}>Product data/technical specification</text>
        {
          JSON.parse(this.state.selected_product.specs).map((data,key) => {
  
  return ( 
   
  <div style={{display:'flex',flexDirection:'column'}}>
  <text style={{color:'black',lineHeight:2}}>{data.application}</text>
   </div>  
  
   
        )
  
  
  })   
        }
         </div>
           
    )
  }
  else if(this.state.selected_product.specs_type==2){
    return
  }
  else if(this.state.selected_product.specs_type==1){
    return(
      <div style={{display:'flex',flexDirection:'column'}}>
            <text style={{color:'black',fontSize:20,fontWeight:'bold'}}>Product data/technical specification</text>
            <MDBTable className="table table-striped table-bordered table-sm dataTable" >
            <MDBTableHead style={{backgroundColor:'#000'}}>
            <tr style={{color:'white'}}> {JSON.parse(this.state.selected_product.specs).headers.map((head, i)=>(<th  key={i}><text>{head}</text></th>))} </tr>
     
            </MDBTableHead>
  
            <MDBTableBody>
  

{JSON.parse(this.state.selected_product.specs).data.map((data1, i1)=>
<tr>{data1.value.map((data2, i2)=>(<td key={i2}><text>{data2}</text></td>))}</tr>)}
  
  
            </MDBTableBody>
  
  
          </MDBTable>
      
      </div>

      
    )
  }
}
renderBrochure(){
  if(this.state.selected_product.brochure && this.state.selected_product.website){
    return(<div style={{margin:20,cursor:'pointer'}}><text style={{backgroundColor:'#d2d2d2'}} className="bro"  onClick={()=>window.open(this.state.selected_product.brochure, "_blank")}>Product Brochure</text>
    <text style={{backgroundColor:'#d2d2d2'}} className="bro"  onClick={()=>window.open(this.state.selected_product.website, "_blank")}>Website Reference</text></div>)
  }
  else if(!this.state.selected_product.brochure && this.state.selected_product.website)
  {
    return(<div style={{margin:20,cursor:'pointer'}}>
    <text style={{backgroundColor:'#d2d2d2'}} className="bro" onClick={()=>window.open(this.state.selected_product.website, "_blank")}>Website Reference</text>
    </div>)
  }
  else if(this.state.selected_product.brochure && !this.state.selected_product.website )
  {
    return(<div style={{margin:20,cursor:'pointer'}}>
    <text style={{backgroundColor:'#d2d2d2'}} className="bro" onClick={()=>window.open(this.state.selected_product.brochure, "_blank")}>Product Brochure</text>
    </div>)
  }
  else return
}
renderProcutDetails(){
  if(this.state.selected_product!=null){
    return(
      <div style={{display:'flex',flexDirection:'column',padding:'1%'}}>
        <div style={{display:'flex',flexDirection:'row'}}>
        <div style={{display:'flex',flexDirection:'column'}}>
        <img src={this.state.selected_product.image} style={{margin:5,width:300,height:270}}/>
        <text style={{color:'black',fontSize:25,fontWeight:'bold'}}>{this.state.selected_product.product_name}</text>
        </div>
            </div>
     
        {this.renderBrochure()}
        <text style={{color:'black',fontSize:20,fontWeight:'bold'}}>Product Description & Features</text>
        {
           JSON.parse(this.state.selected_product.features).map((data,key) => {
  
  return ( 
   
  <li style={{display:'flex',flexDirection:'column'}}>
  <text style={{color:'black',lineHeight:2}}>{data.feature}</text>
   </li>  
  
   
        )
  
  
  })} 
        <br/>
        <text style={{color:'black',fontSize:20,fontWeight:'bold'}}>Product Applications</text>
        {
           JSON.parse(this.state.selected_product.application).map((data,key) => {
  
  return ( 
   
  <div style={{display:'flex',flexDirection:'column'}}>
  <text style={{color:'black',lineHeight:2}}>{data.application}</text>
   </div>  
  
   
        )
  
  
  })}  
     <br/>  
  
     {this.renderSpecsDetails()}
      </div>
    )
  }
}
renderProducts(){
 
  if(this.state.selected_product==null){
    let columns=[];
    this.state.products.forEach((prop,key) => {
  
        // push column
        columns.push(
          <MDBCard style={{display:'flex',justifyContent:'center',alignItems:'center',margin:10,flex:1,}} onClick={()=>this.selectPorduct(prop,key)}>
          <MDBCardImage
            src={prop.image}
            alt="Loading..."
            top
            hover
            overlay="white-slight"
          
          />
          <MDBCardTitle style={{margin:5,textAlign:'center',}} >{prop.product_name}</MDBCardTitle>
           
           
       </MDBCard>
        )
  
        // force wrap to next row every 4 columns
        if ((key+1)%5===0) {columns.push(<div className="w-100"></div>)}
    })
  
    return(
      <div className="row">
      {columns}
      </div>
    )
  }
  else{
    return(
      <MDBRow style={{margin:0,display:'flex',justifyContent:'space-between'}}>
      {this.renderProcutDetails()}
  <div
     
     className="sideProList"
     data-color="#fff"
    
   >
 {/* A short brief about what power factor is and how it is going to affect the efficiency and productivity of users'r equipment. */}
    
     <div className="sidebar-wrapper" style={{display:'flex',flexDirection:'column',backgroundColor:'#fff',justifyContent:'flex-start',alignItems:'center',marginTop:10}}>
   
     {this.state.products.map((prop, key) => {
           if(prop.selected==0){
             return (
              
              <div
              key={key}
className="sideProductInActive"
     onClick={()=>this.selectPorduct(prop,key)}       
>

<text style={{cursor:'pointer',color:'grey',margin:5}}>{prop.product_name}</text>
</div>
              
            );
            return null;
           }
           else {
             return (
              
              <div
className="sideProductActive"

               
>
<text style={{color:'#fff'}}>{prop.product_name}</text>
</div>
              
            );
            return null; 
           }
          
         })}
    
     </div>
    
   </div>

  
  </MDBRow>
    )
  }
}
confirmDelete=async()=>{
  swal({
    title: "Are you sure?",
    text:'You are deleting this product!!',
    icon: "warning",
    buttons: true,
    dangerMode: true,
  })
  .then((willDelete) => {
    if (willDelete) {
      this.deleteProduct()} else return
  });

 
 }
deleteProduct=async()=>{
  this.setState({
    loading:true
  })

  let details = {
  product_id:this.state.selected_product.product_id
};
let formBody = [];
for (let property in details) {
 let encodedKey = encodeURIComponent(property);
 let encodedValue = encodeURIComponent(details[property]);
 formBody.push(encodedKey + "=" + encodedValue);
}
formBody = formBody.join("&");

this.setState({
 response: fetch('http://18.191.5.172:3000/delete_product', {
   method: 'POST',
   headers: {
    
     'Content-Type': 'application/x-www-form-urlencoded',
     'Cache-Control': 'max-age=31536000'
 },
body:formBody
  
   
 }).then((response) => response.json())
 .then((responseJson)=>{
 window.location.reload()
 
 }

 )
   .catch((error) => {
  this.setState({
    loading:false
  })
  swal("Warning!", "Check your network!", "warning");
console.log(error)
    })
   })

}
renderEditButton(){
  if(this.state.selected_product!=null){
    return(
     <div  onClick={()=>this.editSelectedProduct()} className="waves-effect waves-light nav-link Ripple-parent waves-effect waves-light"  style={{backgroundColor:'rgb(255, 255, 255, 0.1)'}} >
 <MDBIcon className="black-text" icon="edit" />
 <text className="black-text" style={{paddingLeft:10,fontSize:20}}>Edit Product</text>
 </div>

    )
  }
}
renderDeleteButton(){
  if(this.state.selected_product!=null){
    return(
     
 <div onClick={()=>this.confirmDelete()} className="waves-effect waves-light nav-link Ripple-parent waves-effect waves-light"  style={{backgroundColor:'rgb(255, 255, 255, 0.1)'}} >
 <MDBIcon className="black-text" icon="trash" />
 <text className="black-text" style={{paddingLeft:10,fontSize:20}}>Delete Product</text>
 </div>
    )
  }
}
// searchProduct(text){
//   let {products}=this.state
//   products.filter(
//     (item) => Object.keys(item).some(key => item[key].search(products) !== -1)    
//   );
//   this.setState({products})
// }
searchProduct(event){

  let {products,products2} = this.state
 if(event.target.value==''){
  this.setState({products:products2});
 }
 else{
  let filter=products
  filter = filter.filter((item) => {
    let pname = item.product_name.toLowerCase()
    return pname.indexOf(
      (event.target.value).toLowerCase()) !== -1
  })
  this.setState({products:filter});
 }
  
}

  render() {

    
    const sidebarBackground = {
      backgroundColor: "#fff"
    };
    
    return (
      <div  className={this.state.open_table_modal? "bodyDivPro" : "bodyDivProBlur"}>

   <div
     
      className="sidePro"
      data-color="#fff"
     
    >
   
     
      <div className="sidebar-wrapper" style={{display:'flex',flexDirection:'column',backgroundColor:'#fff',justifyContent:'flex-start',alignItems:'center',marginTop:10}}>
       
      {dashboardRoutes.map((prop, key) => {
            if(prop.path=='/products'){
              return (
               
               <div
               key={key}
className="sideActive"

 onClick={()=>this.props.history.push(`${prop.path}`)}                
>
<img src={require('../../assets/navbar/Solution_hover.png')} style={{width:35}}/>

<text style={{marginTop:5}}>{prop.name}</text>
</div>
               
             );
             return null;
            }
            else{
              return (
               
               <div
className="sideInActive"

 onClick={()=>this.props.history.push(`${prop.path}`)}                
>
<img src={require('../../assets/navbar/'+prop.icon)} style={{width:35}}/>
<text style={{marginTop:5}}>{prop.name}</text>
</div>
               
             );
             return null; 
            }
           
          })}
        
      </div>
    </div>

      <div className="conTable">
 
 <div style={{backgroundColor:'white'}}>
    
 <div style={{display:'flex',alignItems:'center',justifyContent:'flex-end',width:'100%'}}>
 {this.renderLanguages()}
 {this.renderEditButton()}
                 {this.renderDeleteButton()}
 <div onClick={()=>this.setState({add_product:true})} className="waves-effect waves-light nav-link Ripple-parent waves-effect waves-light"  style={{backgroundColor:'rgb(255, 255, 255, 0.1)'}} >
                  <MDBIcon className="black-text" icon="plus-square" />
                  <text className="black-text" style={{paddingLeft:10,fontSize:20}}>Add Product</text>
                  </div>
                  <div style={{width:'30%'}} className="input-group">
      <div className="input-group-prepend">
        <span className="input-group-text" id="basic-addon">
          <i className="fa fa-search prefix"></i>
        </span>
      </div>
      <input type="text" onChange={(text)=>this.searchProduct(text)} className="form-control" placeholder="Search Product" aria-label="Search Product" aria-describedby="basic-addon" />
    </div>
                     
 <div style={{display:'flex',justifyContent:'center'}}><img src={require('../../assets/abb_logo.png')} style={{height:40,width:130,margin:5}}/></div>
    
  
         
          
           </div>
   </div>
   <div  >
       
   <div style={{display:'flex',flexDirection:'column',backgroundColor:'#51596b',
  justifyContent:'center',alignItems:'flex-start',borderBottomColor:'#d2d2d2',borderBottomStyle:'solid',borderBottomWidth:1}}>

 
  </div>
  
 
{this.renderProducts()}
{this.renderAddProduct()}

{this.renderEditProduct()}



               </div>
          
</div>
 

      
   
     





    


    
 
 
               </div>
              
    );

    
  }
}
export default Products