import React, { Component } from "react";
import ReactTooltip from 'react-tooltip'
import { MDBDropdown, MDBRow,MDBBtn,MDBIcon,MDBDropdownToggle,MDBDropdownMenu,MDBDropdownItem } from "mdbreact";
import './login.css'
  import swal from 'sweetalert'
  import { Tabs,Tab } from "react-bootstrap";
  import { Grid, Row, Col,Table,Button,Modal,Form,FormControl,Image,FormGroup,ControlLabel,NavItem, Nav, DropdownButton, MenuItem} from "react-bootstrap";



  class LoginPage extends Component {
    constructor(props) {
      super(props);
    
      this.state = {
      
        loading:false,
       email:'',
       password:'',
       data:''
      }
  
    }
    handleChangeEmail=(e)=> {
      this.setState({email: e.target.value});
    }
    handleChangepass=(e)=> {
      this.setState({password: e.target.value});
    }
    validateForm() {
      return this.state.email.length > 0 && this.state.password.length > 0;
    }
    userLogin=async()=>{
       this.setState({
         loading:true
       })
        let details = {
        email:this.state.email,
        password:this.state.password
      };
      console.log(details,'details')
      let formBody = [];
      for (let property in details) {
       let encodedKey = encodeURIComponent(property);
       let encodedValue = encodeURIComponent(details[property]);
       formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      
      this.setState({
       response: fetch('http://18.191.5.172:3000/user_login', {
         method: 'POST',
         headers: {
          
           'Content-Type': 'application/x-www-form-urlencoded',
           'Cache-Control': 'max-age=31536000'
       },
      body:formBody
        
         
       }).then((response) => response.json())
       .then((responseJson)=>{
             if(responseJson.status==200){
                 window.localStorage.removeItem('superadmin')
                 window.localStorage.removeItem('userlogin')
                 window.localStorage.setItem('userlogin',JSON.stringify(responseJson))
                 this.props.history.push('catagories')
             }
             else if(responseJson.status==400){
               this.setState({
                 loading:false
               })
swal('Warning',responseJson.message,'warning')
             }
         
       }
      
       )
         .catch((error) => {
        this.setState({
          loading:false
        })
        swal("Warning!", "Check your network!", "warning");
      console.log(error)
          })
         })
      
      }
      superadmin(){
        this.setState({
          loading:true
        })
          if(this.state.email=='super@admin.com' && this.state.password=='super@aaa'){
              window.localStorage.removeItem('userlogin')
              window.localStorage.removeItem('superadmin')
              window.localStorage.setItem('superadmin',JSON.stringify({key:'superadmin'}))
              this.props.history.push('products')
          }
          else{
            swal("Warning!", "Invalid credentials!", "warning");
          }
      }
    onLogin(){
      if(this.state.password=='' || this.state.email==''){
        swal("Warning!", "Please fill all fields!", "warning");
      }
      else{
        if(this.state.password=='123admin' && this.state.email=='admin'){
  return
        }
      }
    }
    render() {
      return (
          <div className="loginMain">
        <div className='loginDiv' style={{justifyContent:'center',display:'flex',alignItems:'center'}}>
       
    <div className="card" style={{padding:20,borderWidth:8,borderColor:'#000',backgroundColor:'rgba(255,255,255,0.1)'}}>
   
        
           <div style={{display:'flex',justifyContent:'center'}}><img src={require('../../assets/abb_logo.png')} style={{height:50,width:150}}/></div>
            <input
              autoFocus
              type="email"
              style={{width:400,marginTop:10,marginBottom:10,padding:5}}
              value={this.state.email}
              onChange={this.handleChangeEmail}
              placeholder="Email"
            />
         
        
            <input
              value={this.state.password}
              onChange={this.handleChangepass}
              type="password"
              placeholder="Password"
              style={{width:400,marginTop:10,marginBottom:10,padding:5}}
            />
         
          <div  style={{display:'flex',justifyContent:'space-evenly'}}>
           <MDBBtn
        style={{backgroundColor:'#ff0011'}}
          
            type="submit"
         
            onClick={()=>this.userLogin()}
          >
           User Login
          </MDBBtn>
          <MDBBtn
        style={{backgroundColor:'#ff0011'}}
            onClick={()=>this.superadmin()}
          >
           Admin Login
          </MDBBtn>
          </div>
          
       
    </div>
     
      </div>
      </div>
      );
    }
  }
  
  export default LoginPage;