import React from 'react';
import packageJson from '../package.json';
import swal from 'sweetalert'
import { unregister } from './registerServiceWorker';
global.appVersion = packageJson.version;

// version from response - first param, local version second param
const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

class CacheBuster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isLatestVersion: false,
      refreshCacheAndReload: () => {
        console.log('Clearing cache and hard reloading...')
       
       
        
          caches.delete('airhorner').then(function(response) {
            swal({
              title: "Warning!!",
              text:'You are using old version, clear data',
              icon: "warning",
              buttons: true,
              dangerMode: true,
            })
            .then((willDelete) => {
              if (willDelete) {
                unregister()
                window.location.reload(true)
              }
            })

          });
        
        
          // Service worker cache should be cleared with caches.delete()
        //   caches.keys().then((names) =>{
            
        //     for (let name of names){
        //       console.log(name)
        //       caches.delete(name);
             
        //     }


        //   });
        

        // delete browser cache and hard reload
       
       
      }
    };
  }

  componentDidMount() {
    fetch('/meta.json')
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;
        const currentVersion = global.appVersion;
console.log(latestVersion,currentVersion)
        const shouldForceRefresh = semverGreaterThan(currentVersion,latestVersion);
        if (shouldForceRefresh) {
          console.log(`We have a new version - ${currentVersion}. Should force refresh`);
          this.setState({ loading: false, isLatestVersion: false });
        } else {
          console.log(`You already have the latest version - ${currentVersion}. No cache refresh needed.`);
          this.setState({ loading: false, isLatestVersion: true });
        }
      });
  }
  render() {
    const { loading, isLatestVersion, refreshCacheAndReload } = this.state;
    return this.props.children({ loading, isLatestVersion, refreshCacheAndReload });
  }
}

export default CacheBuster;