import React, { Component } from "react";
import ReactTooltip from 'react-tooltip'
import { MDBNavbar, MDBDropdownItem,MDBDropdownMenu,  MDBIcon, MDBTable, MDBTableBody, MDBTableHead, MDBBtn,MDBRow,
  MDBCol,
  MDBDropdown,
  MDBDropdownToggle,
  MDBCardHeader, MDBModal, MDBModalBody, MDBInput, MDBModalFooter,MDBCollapse,MDBPopover, MDBPopoverBody, MDBTooltip,  } from 'mdbreact';
  import dashboardRoutes from "../../routes/dashboard";
  import swal from 'sweetalert'
  import { Grid, Row, Col,Image,ListGroup,ListGroupItem,Button,Form,FormControl,Checkbox,ControlLabel, } from "react-bootstrap";
import './footprints.css'
import Select from 'react-select'
import CryptoJS from 'crypto'
import ScrollAnimation from 'react-animate-on-scroll';
import { HalfCircleSpinner } from 'react-epic-spinners'
class Footprints extends Component {
  constructor(props) {
    super(props);
  this.state={
    loading:false,info_heads:[],add_head:false,clients:[],
    new_name:"",new_des:"",new_hsn:"",new_type:"Select Cost Type",selected_id:"",
    selected_language:[],users:[],new_pass:'',add_user:false,
    new_name:'',new_email:'',new_password:'',edit_name:'',edit_email:'',edit_password:'',
    languages:[],new_user_languages:[],edit_user:false,user_to_edit:null,
    edit_user_languages:[],superadmin:false,admin:false,super_pass:'',languages:[],
    paragraph:[],heading:[],continents:["Europe","Asia","Africa","Americas","Ocenia"],
    nextText:"Case Studies",logoutText:'Logout',edit_content:'Edit Content',para1:'',para2:'',edit:false,editmain:false,selected_data:null,data:[],selected_solutions:[],
    jum_sol:[],brom_sol:[],lud_sol:[],sev_sol:[],lily_sol:[],ben_sol:[],ban_sol:[],xia_sol:[],dam_sol:[],mod_sol:[],guar_sol:[],san_sol:[],cub_sol:[],cair_sol:[],contactUs:'CONTACT US',mobileMenu:false,read_more:false,read_more_text:'READ MORE...',scrollmap:0,
    scroll_text:'Scroll',contactlink:'',maplink:'',page_head:'',sub_head:'',showExit:false,read_less_text:'READ LESS',showAdded:false
  
  }
  this._div = React.createRef()
  }
  componentWillMount(){

  }
    


  componentDidMount(){
    this.getuserdata()
    var userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf(' electron/') > -1) {
       this.setState({showExit:true})
    }
  }
  renderExitButton(){
    if(this.state.showExit){
     return(<div
       className="sideInActive"
       
        onClick={this.toggleExit}                
       >
       <img onError={()=>this.setState({showAdded:true})} src={require('../../assets/Exit.jpg')} style={{width:35}}/>
       <text style={{marginTop:5}}>Exit</text>
       </div>)
    }
   
   }

  


  
 
  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

 



  exitApp(){
    var userAgent = navigator.userAgent.toLowerCase();
  if (userAgent.indexOf(' electron/') > -1) {
    const remote = window.require('electron').remote
    let w = remote.getCurrentWindow()
    w.close()
  }
  
  
  }
     renderExitModal(){
  
  
     
      if(this.state.exit==true){
    
    
      return(
        <MDBModal size='md' isOpen={this.state.exit} centered toggle={this.toggleExit} >
     
           
     <MDBModalBody style={{    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',flexDirection:'column',paddingTop:'4vh',paddingBottom:'4vh'}}>
              <img onError={()=>this.setState({showAdded:true})} alt='Loading...' src={require('../../assets/Exit.jpg')} className='catGif'/>
              <p>Are you sure you want to exit this application?</p>
           <div style={{display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-evenly'}}>      <text onClick={()=>this.exitApp()} style={{backgroundColor:'#ff000f',color:'white'}} className='seriesText'>Exit</text>

              
              <text onClick={()=>this.setState({exit:false})} className='seriesText'>Cancel</text>

         
            </div>
             
              </MDBModalBody>
  
          </MDBModal>
      )
    }
    }
    
    toggleExit = () => {
     
      this.setState({
        exit: !this.state.exit,
       });
    }




  componentWillMount(){

  }
  selectLanguage(data,key){
    window.localStorage.removeItem('admindefault')
      window.localStorage.setItem('admindefault',JSON.stringify(data))
   
this.setState({
  selected_language:data
},()=>window.location.reload())
  }
  getuserdata=async()=>{
    this.setState({
      loading:true
    })
  
 console.log('getVideo')

var responseJson=[{"language":"English","language_id":1,"active":0,"key":1,"label":"English",selected:0},
{"language":"German","language_id":2,"active":0,"key":2,"label":"German",selected:0},
{"language":"Chinese","language_id":3,"active":0,"key":3,"label":"Chinese",selected:0},
{"language":"Spanish","language_id":4,"active":0,"key":4,"label":"Spanish",selected:0},
{"language":"French","language_id":5,"active":0,"key":5,"label":"French",selected:0}]

  for(var v=0;v<responseJson.length;v++){
    responseJson[v].label=responseJson[v].language
    responseJson[v].value=responseJson[v].language_id
  }
  var defaultLanguage=window.localStorage.getItem('admindefault')
  if(defaultLanguage==null){
    responseJson[0].selected=1
   this.setState({
     languages:responseJson,
     selected_language:responseJson[0]
   },()=> this.getContent())
  }
  else{
    if(JSON.parse(defaultLanguage).language=='German' || JSON.parse(defaultLanguage).language=='Deutsche' ||
    JSON.parse(defaultLanguage).language=='德语' || JSON.parse(defaultLanguage).language=='alemán' || JSON.parse(defaultLanguage).language=='allemand'){
     responseJson[0].language='Englisch'
     responseJson[1].language='Deutsche'
     responseJson[2].language='Chinesisch'
     responseJson[3].language='Spanisch'
     responseJson[4].language='Französisch'
     this.setState({logoutText:'Ausloggen',nextText:'Fallstudien',continents:["Europa","Asien","Afrika","Amerika","Ozeanien"],edit_content:'Inhalt bearbeiten',contactUs:'kontaktiere uns',read_less_text:'LESE WENIGER',read_more_text:'WEITERLESEN',closeText:'Schließen',scroll_text:'scroll_text'})

    }
    else if(JSON.parse(defaultLanguage).language=='Chinese' || JSON.parse(defaultLanguage).language=='Chinesisch' ||
    JSON.parse(defaultLanguage).language=='中文' || JSON.parse(defaultLanguage).language=='chino' || JSON.parse(defaultLanguage).language=='chinois'){
    
      responseJson[0].language='英语'
      responseJson[1].language='德语'
      responseJson[2].language='中文'
      responseJson[3].language='西班牙语'
      responseJson[4].language='法国'
     
      this.setState({logoutText:'登出',nextText:'实例探究',continents:["欧洲","亚洲","非洲","美洲","大洋洲"],edit_content:'编辑内容',contactUs:'联系我们',read_less_text:'阅读不多',read_more_text:'阅读更多',closeText:'关',scroll_text:'滚动'})

 
     }
     else if(JSON.parse(defaultLanguage).language=='Spanish' || JSON.parse(defaultLanguage).language=='Spanisch' ||
     JSON.parse(defaultLanguage).language=='西班牙语' || JSON.parse(defaultLanguage).language=='Español' || JSON.parse(defaultLanguage).language=='Espanol'){
      responseJson[0].language='Inglés'
      responseJson[1].language='alemán'
      responseJson[2].language='chino'
      responseJson[3].language='Español'
      responseJson[4].language='francés'
      
      this.setState({logoutText:'Cerrar sesión',nextText:'Estudios de caso',continents:["Europa","Asia","África","América","Oceanía"],edit_content:'Contenido editado',contactUs:'Contáctenos',read_less_text:'LEER MENOS',read_more_text:'LEE MAS',closeText:'Cerrar',scroll_text:'voluta'})

 
     }
     else if(JSON.parse(defaultLanguage).language=='French' || JSON.parse(defaultLanguage).language=='Französisch' ||
     JSON.parse(defaultLanguage).language=='法国' || JSON.parse(defaultLanguage).language=='francés' || JSON.parse(defaultLanguage).language=='français'){
      responseJson[0].language='Anglais'
      responseJson[1].language='allemand'
      responseJson[2].language='chinois'
      responseJson[3].language='Espanol'
      responseJson[4].language='français'
       
      this.setState({logoutText:'Connectez - Out',nextText:'Études de cas',continents:["L'Europe ","Asie","Afrique","Amériques","Océanie"],edit_content:'Modifier le contenu',contactUs:'Contactez nous',read_less_text:'LIRE MOINS',read_more_text:'LIRE LA SUITE',scroll_text:'faire défiler'})
 
     }
    console.log(JSON.parse(defaultLanguage),'selected_language')
    responseJson[JSON.parse(defaultLanguage).key-1].selected=1
   this.setState({
     languages:responseJson,
    
     selected_language:JSON.parse(defaultLanguage)
   },()=> this.getContent())
  }
  
 }
 getSidebar=async()=>{
         
  if(!navigator.onLine){
var offline_content=window.localStorage.getItem('sidebar')
if(offline_content==null){

this.setState({
 loading:false
})
}
else{
console.log(JSON.parse(JSON.parse(offline_content).content),'cccc')
dashboardRoutes[0].name=JSON.parse(JSON.parse(offline_content).content)[0].name
dashboardRoutes[1].name=JSON.parse(JSON.parse(offline_content).content)[1].name
dashboardRoutes[2].name=JSON.parse(JSON.parse(offline_content).content)[2].name
dashboardRoutes[3].name=JSON.parse(JSON.parse(offline_content).content)[4].name
dashboardRoutes[4].name=JSON.parse(JSON.parse(offline_content).content)[5].name
dashboardRoutes[5].name=JSON.parse(JSON.parse(offline_content).content)[6].name
this.setState({
 sidebar:JSON.parse(offline_content).content,
loading:false
})
}
  }
  else{
   this.setState({loading:true})
   let details = {
     language_id:this.state.selected_language.language_id
   };
   let formBody = [];
   for (let property in details) {
    let encodedKey = encodeURIComponent(property);
    let encodedValue = encodeURIComponent(details[property]);
    formBody.push(encodedKey + "=" + encodedValue);
   }
   formBody = formBody.join("&");
   this.setState({
     response: fetch('https://www.mobilitysolution.info/get_sidebar', {
       method: 'POST',
       headers: {
        
         'Content-Type': 'application/x-www-form-urlencoded',
         'Cache-Control': 'max-age=31536000'
     },
   body:formBody
       
     }).then((response) => response.json())
     .then((responseJson)=>{
       console.log(JSON.parse(responseJson.content),'sidebar')
      window.localStorage.removeItem('sidebar')
      window.localStorage.setItem('sidebar',JSON.stringify(responseJson))
dashboardRoutes[0].name=JSON.parse(responseJson.content)[0].name
dashboardRoutes[1].name=JSON.parse(responseJson.content)[1].name
dashboardRoutes[2].name=JSON.parse(responseJson.content)[2].name
dashboardRoutes[3].name=JSON.parse(responseJson.content)[4].name
dashboardRoutes[4].name=JSON.parse(responseJson.content)[5].name
dashboardRoutes[5].name=JSON.parse(responseJson.content)[6].name
       this.setState({
         sidebar:(responseJson.content).sidebar,
loading:false
       })
         })
         .catch((error) => {
           this.setState({
             loading:false
           })
          
           var offline_content=window.localStorage.getItem('sidebar')
           if(offline_content==null){
           
           this.setState({
            loading:false
           })
           }
           else{
           console.log(JSON.parse(JSON.parse(offline_content).content),'cccc')
           dashboardRoutes[0].name=JSON.parse(JSON.parse(offline_content).content)[0].name
           dashboardRoutes[1].name=JSON.parse(JSON.parse(offline_content).content)[1].name
           dashboardRoutes[2].name=JSON.parse(JSON.parse(offline_content).content)[2].name
           dashboardRoutes[3].name=JSON.parse(JSON.parse(offline_content).content)[4].name
           dashboardRoutes[4].name=JSON.parse(JSON.parse(offline_content).content)[5].name
           dashboardRoutes[5].name=JSON.parse(JSON.parse(offline_content).content)[6].name
           this.setState({
            sidebar:JSON.parse(offline_content).content,
           loading:false
           })
           }
             })
            })
  }
           }
 getContent=async()=>{
  if(!navigator.onLine){
    var offline_content=window.localStorage.getItem('footprint')
    if(offline_content==null){
      swal('Warning','Check your network and refresh','warning')
      this.setState({
        loading:false
      })
    }
    else{
     console.log(JSON.parse(offline_content)[0].map)
      this.setState({
       
        heading:JSON.parse(JSON.parse(offline_content)[0].heading),
        paragraph:JSON.parse(JSON.parse(offline_content)[0].paragraph),
        data:JSON.parse(offline_content),
        maplink:JSON.parse(offline_content)[0].map,
         loading:false
        },()=>console.log(this.state.heading,this.state.paragraph))
    }
    this.getSidebar()
         }
         else{
           
  this.setState({loading:true})
   let details = {
     language_id:this.state.selected_language.language_id
   };
   let formBody = [];
   for (let property in details) {
    let encodedKey = encodeURIComponent(property);
    let encodedValue = encodeURIComponent(details[property]);
    formBody.push(encodedKey + "=" + encodedValue);
   }
   formBody = formBody.join("&");
   console.log(details)
   this.setState({
     response: fetch('https://www.mobilitysolution.info/get_content_footprint', {
       method: 'POST',
       headers: {
        
         'Content-Type': 'application/x-www-form-urlencoded',
         'Cache-Control': 'max-age=31536000'
     },
   body:formBody
       
     }).then((response) => response.json())
     .then((responseJson)=>{
       console.log(JSON.parse(responseJson[0].heading))
      window.localStorage.removeItem('footprint')
      window.localStorage.setItem('footprint',JSON.stringify(responseJson))
    
       this.setState({
       
       heading:JSON.parse(responseJson[0].heading),
       paragraph:JSON.parse(responseJson[0].paragraph),
       data:responseJson,
       contactlink:responseJson[0].contact,
       maplink:responseJson[0].map,
        loading:false
       },()=>console.log(this.state.heading,this.state.paragraph))
       this.getSidebar()
         })
         .catch((error) => {
          if(offline_content==null){
            swal('Warning','Check your network and refresh','warning')
            this.setState({
              loading:false
            })
          }
          else{
           console.log(JSON.parse(offline_content)[0].map)
            this.setState({
             
              heading:JSON.parse(JSON.parse(offline_content)[0].heading),
              paragraph:JSON.parse(JSON.parse(offline_content)[0].paragraph),
              data:JSON.parse(offline_content),
              maplink:JSON.parse(offline_content)[0].map,
               loading:false
              },()=>console.log(this.state.heading,this.state.paragraph))
          }
          this.getSidebar()
         console.log(error)
             })
            })

         }
       }
       renderLanguages(){

        if(this.state.loading==false){
          if(this.state.languages.length>0){
            return(<div  
          
               
              
              style={{backgroundColor:'rgb(255, 255, 255, 0.1)',cursor:'pointer',display:'flex',alignItems:'center',marginRight:100,marginTop:'.5%'}}>
            <MDBIcon size="lg" style={{marginLeft:10,marginRight:20}}   className="grey-text menuBar" icon="bars" onClick={()=>this.setState({mobileMenu:!this.state.mobileMenu})}/>
            <MDBIcon size="lg" style={{marginLeft:10}} onClick={()=>this.setState({lang_mod:true})}   className="grey-text" icon="globe-africa" />
            <MDBDropdown className='languageDiv' >
           <MDBDropdownToggle caret color="#004080" size="md" >
           <text className='languageText'>{this.state.languages[this.state.selected_language.key-1].language}</text>
           </MDBDropdownToggle>
           <MDBDropdownMenu style={{margin:0,padding:0,borderRadius:0}}> 
           <MDBDropdownItem style={{backgroundColor:'#d2d2d2',color:'black',textAlign:'center',padding:12}}  >ENGLISH</MDBDropdownItem>
           {/* { (this.state.languages).map((data,key) => {
        
      if(data.selected==0){
      return ( 
        <MDBDropdownItem style={{backgroundColor:'#fff',color:'#6e6e6e',textAlign:'center',padding:12}} className='dropButton'  onClick={()=>this.selectLanguage(data)}>{data.language}</MDBDropdownItem>
            
             )
      }
      else{
      return ( 
        <MDBDropdownItem style={{backgroundColor:'#d2d2d2',color:'black',textAlign:'center',padding:12}}  >{data.language}</MDBDropdownItem>
            
             )
      }
        })
        } */}
        </MDBDropdownMenu>
        </MDBDropdown>
          
              </div>)
           }
         
        }
           
          }

renderReadMore(){
  if(!this.state.read_more){
    return(
   <text>{this.state.paragraph[0].paragraph.substr(0, 117)} <text onClick={()=>this.setState({read_more:true})}
   style={{cursor:'pointer',fontFamily:'ABBvoice-Bold'}}>&nbsp;{this.state.read_more_text}</text></text>
      ) 
  }
    else{
      return(
        this.state.paragraph[0].paragraph
      )
    }
  
}
allContent(){
  if(this.state.read_more){
    return(
      this.state.paragraph[1].paragraph
    )
  }
}
openBro(filePath){
  var userAgent = navigator.userAgent.toLowerCase();
  if (userAgent.indexOf(' electron/') > -1) {
    
    const {shell} = window.require('electron');
    shell.openExternal(filePath);

  }
  else{
    window.open(filePath, "_blank")
  }
 
}
renderDataWindow(){
  return(
    <div className='footDiv'>
               
              
               <div style={{display:'flex',flexDirection:'column',width:'25%'}}>
                <div style={{textAlign:'justify',width:'30vw'}}> 
                
              <text className='light' style={{fontSize:16,marginBottom:10}}>
                                   
                                    
              {this.state.paragraph[0].paragraph}
              <br style={{lineHeight:2}}/>
              {this.state.paragraph[1].paragraph}
               
                                  </text> 
                                 
                                
                                
                                      
                </div>
                <text onClick={()=>this.openBro(this.state.contactlink)}  className='contactUs'>
                       
                        
                       {this.state.contactUs}
                        
                        </text>  
              </div>
              
              <img onError={()=>this.setState({showAdded:true})} alt='Loading...'
                  src={this.state.maplink}
                
                  style={{width:'65%'}}
                />
              {/* <img onError={()=>this.setState({showAdded:true})} alt='Loading...' src={this.state.maplink} style={{width:'65%'}}/>  
            */}
              </div> 
  )
}
renderMapMobile(){
  return(<div className='mapCont' ref={ el => this.container = el}><img onError={()=>this.setState({showAdded:true})} id='worldmap'  alt='Loading...' src={this.state.maplink} style={{width:'190%',overflow:'scroll'}}/></div> )
}
renderArrowForward(){
    return(
      <text onClick={()=>this.setState({scrollmap:1},()=>this.container.scrollLeft += 400)} style={{fontSize:13,margin:0,width:22}}  className='arrowRed'></text>
    )
  
   
  
}
renderArrowBackwar(){
  
    return(<text onClick={()=>this.setState({scrollmap:0},()=>this.container.scrollLeft -= 400)} style={{fontSize:13,margin:0,width:22}}  className='arrowRed'></text>)
  
    
}

renderReadLess(){
  if(this.state.read_more==true){
    return(<text onClick={()=>this.setState({read_more:false})}
    style={{cursor:'pointer',fontFamily:'ABBvoice-Bold'}}>&nbsp;{this.state.read_less_text}</text>)
  }
}
renderDataMobile(){
  return(
    <div className='footDivMobile'>
     
    
  <div style={{display:'flex',flexDirection:'column',width:'100%'}}>
   <div style={{textAlign:'justify',width:'100%'}}> 
   
 <text className='light' style={{fontSize:16,marginBottom:10}}>
                      
                       
 {this.renderReadMore()}
 <br style={{lineHeight:2}}/>
 {this.allContent()}
 {this.renderReadLess()}
                     </text> 
                    
                   
                   
                         
   </div>
    
 </div>
 
 {this.renderMapMobile()} 
 <div  className='scrollText' >
              
 {this.renderArrowBackwar()}  <h5 className='nextTextMargin' style={{fontSize:15,marginLeft:5,marginRight:5}}>{this.state.scroll_text}</h5>
               {this.renderArrowForward()}</div>
 <text onClick={()=>window.open('https://new.abb.com/contact-centers', "_blank")}  className='contactUs'>
          
           
          {this.state.contactUs}
           
           </text> 
          
 </div> 
  )
}

          renderData(){
      
            if(this.state.loading==false && this.state.heading.length!=0){
             
           
              return(  <div className='valueMain' style={{overflowX:'hidden'}}> 
       
              <h1 className='valueheading' >
                                   
              {this.state.heading[0].heading}                  
                                  </h1>
                                  <h1 className='valuedata'>{this.state.heading[0].subheading}</h1>
                 
                             
              {this.renderDataWindow()} 
                      {this.renderDataMobile()}
              
                      <div className='next' style={{marginTop:2}}>
       <h5>{dashboardRoutes[5].name}</h5> <text onClick={()=>this.props.history.push('case_study')} className='arrowRed'></text></div>
                  
              </div>)
            }
          } 
    selectContinent(data){
      // window.localStorage.removeItem('selected_cont')
      // window.localStorage.setItem('selected_cont',JSON.stringify({'countinent':data}))
      // this.props.history.push('country')
    }
    renderoverLay(){
      if(this.state.mobileMenu){
      
        return (<div id='overlayCat' onClick={()=>this.setState({mobileMenu:false})}></div>)
      }
    }
    mobileMenu(){
      var style={opacity:1,backgroundColor:'#fff',height:'100vh',borderStyle:'solid',borderColor:'#d2d2d2',borderWidth:1,
      overflow:'auto',padding:'2.5%',display:'flex',flexDirection:'column',alignItems:'flex-start',paddingLeft:10,paddingRight:10}
    
     
      if(this.state.mobileMenu){
        return(
          <div className='mobileMenu'>
            <ScrollAnimation animateOnce={false}  animateIn="slideInLeft"  className='slideInLeft' animationOut="slideOutLeft" 
         isVisible={true}  style={style}>
             {dashboardRoutes.map((prop, key) => {
                      if(prop.path=='/footprints'){
                        return (
                         
                         <div
                         key={key}
          className="sideActiveMobile"
          
           onClick={()=>this.props.history.push(`${prop.path}`)}                
          >
          <img onError={()=>this.setState({showAdded:true})} id='map' alt='Loading...' src={require('../../assets/navbar/Footprint.png')}  style={{width:20,marginRight:10}}/>
          
          <text style={{marginTop:5,fontSize:12}}>{prop.name}</text>
          </div>
                         
                       );
                       return null;
                      }
                      else{
                        return (
                         
                         <div
          className="sideInActiveMobile"
          
           onClick={()=>this.props.history.push(`${prop.path}`)}                
          >
          <img onError={()=>this.setState({showAdded:true})} alt='Loading...' src={require('../../assets/navbar/'+prop.icon)} style={{width:20,marginRight:10}}/>
          <text style={{marginTop:5,fontSize:12}}>{prop.name}</text>
          </div>
                         
                       );
                       return null; 
                      }
                     
    
                    })}
                   
                    </ScrollAnimation>
          </div>
        )
      }
    }
    toggleLang = () => {
   
      this.setState({
        lang_mod: !this.state.lang_mod,
       });
    }
    renderlangModal(){


   
      if(this.state.lang_mod==true){
    
    
      return(
        <MDBModal className='mobileLang' size='md' isOpen={this.state.lang_mod} toggle={this.toggleLang} centered>
     
           
                <MDBModalBody>
                {/* { (this.state.languages).map((data,key) => {
        
        if(data.selected==0){
         return ( 
           <MDBDropdownItem style={{backgroundColor:'#fff',color:'#6e6e6e',textAlign:'center',padding:12}} className='dropButton'  onClick={()=>this.selectLanguage(data)}>{data.language}</MDBDropdownItem>
               
                )
        }
        else{
         return ( 
           <MDBDropdownItem style={{backgroundColor:'#d2d2d2',color:'black',textAlign:'center',padding:12}}  >{data.language}</MDBDropdownItem>
               
                )
        }
           })
           } */}
           <MDBDropdownItem style={{backgroundColor:'#d2d2d2',color:'black',textAlign:'center',padding:12}}  >ENGLISH</MDBDropdownItem>
                           </MDBModalBody>
              
          </MDBModal>
      )
    }
    }
    reload = ()=>{
      const current = this.props.location.pathname;
      this.props.history.replace(`/reload`);
         setTimeout(() => {
           this.props.history.replace(current);
         });
     }
    showUpdateBar() {
      if(this.state.showAdded){
        
        
          return(
            <ScrollAnimation animateOnce={false}   animateIn="fadeIn"  className='fadeIn toast2' animationOut="fadeOut" 
             isVisible={true} >
           <div className="snack">Go online and <text onClick={()=>this.reload()} style={{cursor:'pointer',textDecoration:'underline'}}>click here</text> to refresh</div>
        </ScrollAnimation>
        
           
          )
        
     
      }
      }
      trigger() {
  
        setTimeout(() => { 
            this.setState({
                showAdded: false
            })
        }, 5000);
      }
  render() {

    
    const sidebarBackground = {
      backgroundColor: "#1C2231"
    };
    if(this.state.loading){
    
      return(<div className="loadingSpin"><HalfCircleSpinner color="#ff000f"/></div>)
        }
        else{
    return (
      <div className="bodyDivCl" >

   <div
      id="sidebar"
      className="sideCat"
      data-color="#1C2231"
     
    >
      <div className="sidebar-background" style={sidebarBackground} />
      
      <div className="sidebar-wrapper" style={{display:'flex',flexDirection:'column',justifyContent:'flex-start',alignItems:'center',marginTop:10}}>
       
      {dashboardRoutes.map((prop, key) => {
                  if(prop.path=='/footprints'){
                    return (
                     
                     <div
                     key={key}
      className="sideActive"
      
       onClick={()=>this.props.history.push(`${prop.path}`)}                
      >
      <img onError={()=>this.setState({showAdded:true})} alt='Loading...' src={require('../../assets/navbar/Footprint_Hover.png')} style={{width:25}}/>
      
      <text style={{marginTop:5}}>{prop.name}</text>
      </div>
                     
                   );
                   return null;
                  }
                  else{
                    return (
                     
                     <div
      className="sideInActive"
      
       onClick={()=>this.props.history.push(`${prop.path}`)}                
      >
      <img onError={()=>this.setState({showAdded:true})} alt='Loading...' src={require('../../assets/navbar/'+prop.icon)} style={{width:25}}/>
      <text style={{marginTop:5}}>{prop.name}</text>
      </div>
                     
                   );
                   return null; 
                  }
                 
                })}
     
       {this.renderExitButton()}
      </div>
    
    </div>
{this.mobileMenu()}
      <div className="conTable">
 
      <div style={{backgroundColor:'white'}} >
      <div className="topMenu">

 <div style={{display:'flex',justifyContent:'center'}}>{this.renderLanguages()}</div>
  
 <div style={{display:'flex',justifyContent:'center'}}><img onError={()=>this.setState({showAdded:true})} alt='Loading...' src={require('../../assets/abb_logo.png')} className='abb_logo'/></div>
    
  
         
          
           </div>
           
   </div>
   <div >
  {this.renderData()}
 
               </div>
              

</div>
 

      
   
     





    
{this.renderoverLay()}
{this.renderExitModal()}
    {this.showUpdateBar()}
 {this.renderlangModal()}
 
               </div>
              
    );
              }
    
  }
}
export default Footprints