import React, { Component } from "react";
import ReactTooltip from 'react-tooltip'
import { MDBNavbar, MDBDropdownItem,MDBDropdownMenu,  MDBIcon, MDBTable, MDBTableBody, MDBTableHead, MDBBtn,MDBRow,
  MDBCol,
  MDBDropdown,
  MDBDropdownToggle,
  MDBCardHeader, MDBModal, MDBModalBody, MDBInput, MDBModalFooter,MDBCardBody,MDBPopover, MDBPopoverBody, MDBTooltip,  } from 'mdbreact';
  import dashboardRoutes from "../../routes/dashboard";
  import swal from 'sweetalert'
  import { Grid, Row, Col,Image,ListGroup,ListGroupItem,Button,Form,FormControl,Checkbox,ControlLabel, } from "react-bootstrap";
import './catagories.css'
import Select from 'react-select'

class AddCatagories extends Component {
    constructor(props) {
      super(props);
    this.state={
      loading:false,catagory:[],selected_sub_catagories:[{label:'Select Sub Catagory'}],
      specs_image:null,catagory_name:'',subcatagories:[],selected_sub:null,products:[],medium:[],high:[],low:[],selected_product:null,superadmin:false,admin:false
    }
    }
  
    
  
    componentDidMount(){
  
  this.getProducts()
  this.getSub()
    }
    componentWillMount(){
      this.getDetails()
    }
  getDetails(){
var userlogin=window.localStorage.getItem('userlogin')
var adminlogin=window.localStorage.getItem('superadmin')
if(userlogin==null && adminlogin==null){
  this.props.history.push('login')
}
else if(userlogin==null && adminlogin!=null){
  this.setState({superadmin:true})
}
else if(userlogin!=null && adminlogin==null){
  this.setState({admin:true})
}
  }
  logout(){
    window.localStorage.removeItem('userlogin')
window.localStorage.removeItem('superadmin')
this.props.history.push('login')
  }
    getProducts=async()=>{
      this.setState({
        loading:true
      })
    
      let details = {
      language_id:1
    };
    let formBody = [];
    for (let property in details) {
     let encodedKey = encodeURIComponent(property);
     let encodedValue = encodeURIComponent(details[property]);
     formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    
    this.setState({
     response: fetch('https://nativeapps-968826836.us-east-2.elb.amazonaws.com/get_all_products', {
       method: 'POST',
       headers: {
        
         'Content-Type': 'application/x-www-form-urlencoded',
         'Cache-Control': 'max-age=31536000'
     },
    body:formBody
      
       
     }).then((response) => response.json())
     .then((responseJson)=>{
        for(var v=0;v<responseJson.length;v++){
            responseJson[v].label=responseJson[v].product_name
            responseJson[v].value=responseJson[v].product_id
        }
        
       this.setState({
        products:responseJson,
         loading:false
       })
     
     }
    
     )
       .catch((error) => {
      this.setState({
        loading:false
      })
      swal("Warning!", "Check your network!", "warning");
    console.log(error)
        })
       })
    
    }
    
    getSub=async()=>{
        this.setState({
          loading:true
        })
      
        let details = {
        language_id:1
      };
      let formBody = [];
      for (let property in details) {
       let encodedKey = encodeURIComponent(property);
       let encodedValue = encodeURIComponent(details[property]);
       formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      
      this.setState({
       response: fetch('https://nativeapps-968826836.us-east-2.elb.amazonaws.com/get_sub_catagories', {
         method: 'POST',
         headers: {
          
           'Content-Type': 'application/x-www-form-urlencoded',
           'Cache-Control': 'max-age=31536000'
       },
      body:formBody
        
         
       }).then((response) => response.json())
       .then((responseJson)=>{
      for(var v=0;v<responseJson.length;v++){
          responseJson[v].label=responseJson[v].sub_catagory_name
          responseJson[v].value=responseJson[v].sub_catagory_id
          responseJson[v].selected=0

      }
         this.setState({
          subcatagories:responseJson,
           loading:false
         })
       
       }
      
       )
         .catch((error) => {
        this.setState({
          loading:false
        })
        swal("Warning!", "Check your network!", "warning");
      console.log(error)
          })
         })
      
      }
      
  
    
    addP=()=>{
    if(this.state.product_name.length==0 || this.state.description.length==0 || this.state.product_image.length==0 || 
      (this.state.applications[0].application).length==0 || (this.state.specs[0].application).length==0  )
    
     {
  swal('Warning','Fill all fields','warning')
     }
     else
      {
  
        this.setState({
           loading:true
         })
         var formData = new FormData()
         formData.append('language_id',1)
         formData.append('product_name',this.state.product_name)
         formData.append('product_description',this.state.description)
         formData.append('image',this.state.product_image)
         formData.append('application',JSON.stringify(this.state.applications))
         formData.append('specs_type',0)
         formData.append('specs',JSON.stringify(this.state.specs))
         for (var key of formData.entries()) {
           console.log(key[0] + ', ' + key[1]);
       }
     
       
       this.setState({
        response: fetch('https://nativeapps-968826836.us-east-2.elb.amazonaws.com/add_product', {
          method: 'POST',
        body: formData
         
          
        }).then((response) => response.json())
        .then((responseJson)=>{
  window.location.reload()
    this.setState({
      loading:false})
  
      
    }
        )
          .catch((error) => {
       
            this.setState({
              show:false,item_name:'',item_price:'',item_image:''
            })
    console.log(error)
           })
          })
        }
   }
  
 handleChangename=(e)=>{
    this.setState({[e.target.id]:e.target.value});
  }
  
  toggle = () => {
    this.setState({
      add_product: !this.state.add_product,
    });
  }
  handleChangepname=(e)=> {
    this.setState({product_name: e.target.value});
  }
  handleChangepdescription=(e)=> {
    this.setState({description: e.target.value});
  }
  handleChangepapplication(e,i) {
  
  let {applications}=this.state
  let targetApp = applications[i]  
  targetApp.application = e.target.value
  this.setState({applications})
  }
  handleChangepspecs(e,i) {
  
    let {specs}=this.state
    let targetspecs = specs[i]  
    targetspecs.application = e.target.value
    this.setState({specs})
    }
  productImageHandler = (event) => {
   
    this.setState({product_image:event.target.files[0]})
  }
  productSpecsHandler = (event) => {
   
    this.setState({specs_image:event.target.files[0]})
  }
  specsImageHandler = (event) => {
   
    this.setState({product_image:event.target.files[0]})
  }
  
  handleSubCat(e){
      e.products=[]
    this.state.final_sub_catagory.push(e)  
    this.setState({selected_sub:e})
  }
  handleSelectProduct(e){
this.state.catagory.push(this.state.selected_sub)
this.setState({
    selected_product:e
})
 }
  renderSelectProduct(data,key){
  
   for(var v=0;v<this.state.selected_sub_catagories.length;v++){
    
     if(this.state.selected_sub_catagories[v].sub_catagory_id==data.sub_catagory_id){
      return( <div style={{display:'flex',flex:1}}>


<div >

<Select
closeMenuOnSelect={false}
onChange={e=>
{
  let {selected_sub_catagories}=this.state
  let targetSubCatagory=selected_sub_catagories[key]
  targetSubCatagory.products=e
 this.setState({selected_sub_catagories})
}

   }
value={data.products}

options={this.state.products}
isMulti
/>
</div>
</div>)
     }
   }
     
  }
  addSubCatatagory(){
   
    let {selected_sub_catagories}=this.state
    selected_sub_catagories.push({label:'Select Sub Catagory'})
    this.setState({selected_sub_catagories})
  }
    render() {
  
      
      const sidebarBackground = {
        backgroundColor: "#1C2231"
      };
      
      return (
        <div className="bodyDivCl" >
  
     <div
        id="sidebar"
        className="sideCat"
        data-color="#1C2231"
       
      >
        <div className="sidebar-background" style={sidebarBackground} />
       
        <div className="sidebar-wrapper" style={{display:'flex',flexDirection:'column',justifyContent:'flex-start',alignItems:'center',marginTop:10}}>
         
        {dashboardRoutes.map((prop, key) => {
            
                return (
                 
                 <div
  className="sideInActive"
 
   onClick={()=>this.props.history.push(`${prop.path}`)}                
  >
  <MDBIcon style={{marginRight:5,width:'100%',display:'flex',justifyContent:'center',alignItems:'center',color:'#d2d2d2'}}  className="grey-text" size="3x"    icon={prop.icon} />
  <text>{prop.name}</text>
  </div>
                 
               );
               return null; 
              }
              
            )}
            <div
  className="sideInActive"

   onClick={()=>this.logout()}                
  >
  <MDBIcon style={{marginBottom:5,width:'100%',display:'flex',justifyContent:'center',alignItems:'center',color:'#d2d2d2'}} className="gey-text" size="3x"    icon="sign-out-alt" />
  <text style={{color:'#d2d2d2'}}>Logout</text>
  </div>
        </div>
      </div>
  
        <div className="conTable">
   
   <MDBNavbar color="unique-color-dark"  dark>
      
   <div style={{display:'flex',alignItems:'center',justifyContent:'flex-end',width:'100%'}}>
   <div style={{display:'flex',justifyContent:'center'}}><img src={require('../../assets/abb_logo.png')} style={{height:40,width:130,margin:5}}/></div>
      
    
           
            
             </div>
     </MDBNavbar>
     <div style={{display:'flex',justifyContent:'center',alignItems:'center',paddingTop:20}}>
         
  
  
<div style={{width:'50%'}}>
<div >
                   
            <input
                  onChange={(data)=>this.setState({catagory_name:data.target.value})}
                  value={this.state.catagory_name}
            placeholder="Catagory Name"
            icon="money-check-alt"
              type="text"
              id="reference"
              className="form-control"
              required
              
            /></div><br/>
            {this.state.selected_sub_catagories.map((data,key) => {
    
    return (   
   
        <div style={{display:'flex'}}>
     

        <Select
closeMenuOnSelect={true}
onChange={e=>{
  let {selected_sub_catagories}=this.state
  e.products=[]
  var already_exist=0
for(var v=0;v<selected_sub_catagories.length;v++){
  if(selected_sub_catagories[v].label==e.label){
    already_exist=already_exist+1
  }
  if(selected_sub_catagories[v].label=='Select Sub Catagory'){
    selected_sub_catagories.splice(v,1)
  }
  
}
if(already_exist==0){

}
    selected_sub_catagories.push(e,{label:'Select Sub Catagory'})
    
    this.setState({selected_sub_catagories})
}}
value={data}
className='select'
options={this.state.subcatagories}

/>
{this.renderSelectProduct(data,key)}

</div>  

    
    
           )
    
    
    })}
          
</div>
 
  
 
  
  
                 </div>
                
  </div>
   
  
        
     
       
  
  
  
  
  
      
  
  
      
   
   
                 </div>
                
      );
  
      
    }
  }
export default AddCatagories