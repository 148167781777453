

import Dashboard from "../views/Dashboard/Dashboard.jsx"
import Catagories from "../views/Catagories/Catagories.jsx";
import Products from "../views/Products/Products.jsx";
import Subcatagories from "../views/Subcatagories/Subcatagories.jsx";
import Videos from "../views/Videos/Videos.jsx";
import Users from "../views/Users/Users.jsx";
import Footprints from "../views/Footprints/Footprints.jsx";
import Case from "../views/Case/Case.jsx";
import Value from "../views/Value/Value.jsx";
import React, { Component } from "react";
var dashboardRoutes = [
   
  {
    path: "/home",
    name: "Home",
    icon: "Home.png",
    component: Dashboard,
    check:"Home"
  },
 
  {
    path: "/causes",
    name: "Causes",
    icon: "Cause.png",
    component: Subcatagories,
    check:"Causes"
  },
  {
    path: "/solutions",
    name: "Solutions",
    icon: "Solution.png",
    component: Catagories,
    check:"Solutions"
  },
  {
    path: "/value",
    name: "Value Proposition",
    icon: "Value_Proposition.png",
    component: Value,
    check:"Value Proposition"
  },
  

{
    path: "/footprints",
    name: "Footprint",
    icon: "Footprint.png",
    component: Footprints,
    check:"Footprint"
  },

{
path: "/case_study",
name: "Case Studies",
icon: "Casestudy.png",
component: Case,
check:"Case Studies"
}]

class dashboard  extends Component {
  constructor(props) {
    super(props);
  this.state={
    loading:false,exit:false,languages:[],sidebar:[],selected_language:null
   
  }
  }
  componentDidMount(){
    this.getVideo()
      }
      getVideo(){
        var responseJson=[{"language":"English","language_id":1,"active":0,"key":1,"label":"English",selected:0},
        {"language":"German","language_id":2,"active":0,"key":2,"label":"German",selected:0},
        {"language":"Chinese","language_id":3,"active":0,"key":3,"label":"Chinese",selected:0},
        {"language":"Spanish","language_id":4,"active":0,"key":4,"label":"Spanish",selected:0},
        {"language":"French","language_id":5,"active":0,"key":5,"label":"French",selected:0}]
                for(var v=0;v<responseJson.length;v++){
                  responseJson[v].selected=0
                }
           var defaultLanguage=window.localStorage.getItem('admindefault')
           if(defaultLanguage==null){
            responseJson[0].selected=1
            this.setState({
              languages:responseJson,
              loading:false,
              selected_language:responseJson[0]
            },()=>this.getContent())
           }
           else{
              if(JSON.parse(defaultLanguage).language=='German'){
                dashboardRoutes[0].name='Zuhause'
                dashboardRoutes[1].name='Ursachen'
                dashboardRoutes[2].name='Lösungen'
                dashboardRoutes[3].name='Wertversprechen'
                dashboardRoutes[4].name='Fußabdruck'
                dashboardRoutes[5].name='Fallstudien'
             
             this.setState({logoutText:'Ausloggen',videoText:'MEHR WISSEN',nextText:'Lösungen',heading:'Überblick',})
        
            }
            else if(JSON.parse(defaultLanguage).language=='Chinese'){
              dashboardRoutes[0].name='家'
              dashboardRoutes[1].name='原因'
              dashboardRoutes[2].name='解决方案'
              dashboardRoutes[3].name='价值主张'
              dashboardRoutes[4].name='脚印'
              dashboardRoutes[5].name='实例探究'
             
              this.setState({logoutText:'登出',videoText:'了解更多',nextText:'解决方案',heading:'概观'})
        
         
             }
             else if(JSON.parse(defaultLanguage).language=='Spanish'){
              dashboardRoutes[0].name='Casa'
              dashboardRoutes[1].name='Causas'
              dashboardRoutes[2].name='Soluciones'
              dashboardRoutes[3].name='Propuesta de valor'
              dashboardRoutes[4].name='Huella'
              dashboardRoutes[5].name='Estudios de caso'
              this.setState({logoutText:'Cerrar sesión',videoText:'SABER MÁS',nextText:'Soluciones',heading:'Visión general'})
        
         
             }
             else if(JSON.parse(defaultLanguage).language=='French'){
              dashboardRoutes[0].name='Accueil'
              dashboardRoutes[1].name='Les causes'
              dashboardRoutes[2].name='Solutions'
              dashboardRoutes[3].name='Proposition de valeur'
              dashboardRoutes[4].name='Empreinte'
              dashboardRoutes[5].name='Études de cas'
               
             
              this.setState({logoutText:'Connectez - Out',videoText:'SAVOIR PLUS',nextText:'Solutions',heading:`Vue d'ensemble`})
        
         
             }
            responseJson[JSON.parse(defaultLanguage).key-1].selected=1
            this.setState({
              languages:responseJson,
              loading:false,
              selected_language:JSON.parse(defaultLanguage)
            },()=>this.getContent())
           }
              
      }
  getContent=async()=>{
         
    if(!navigator.onLine){
var offline_content=window.localStorage.getItem('sidebar')
if(offline_content==null){

 this.setState({
   loading:false
 })
}
else{
 console.log(offline_content)
 this.setState({
   sidebar:JSON.parse(JSON.parse((offline_content.content).sidebar)),
 loading:false
 })
}
    }
    else{
     this.setState({loading:true})
     let details = {
       language_id:this.state.selected_language.language_id
     };
     let formBody = [];
     for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
     }
     formBody = formBody.join("&");
     this.setState({
       response: fetch('https://www.nativeapps.in/get_sidebar', {
         method: 'POST',
         headers: {
          
           'Content-Type': 'application/x-www-form-urlencoded',
           'Cache-Control': 'max-age=31536000'
       },
     body:formBody
         
       }).then((response) => response.json())
       .then((responseJson)=>{
         console.log(responseJson,'sidebar')
        window.localStorage.removeItem('sidebar')
        window.localStorage.setItem('sidebar',JSON.stringify(responseJson))
// for(var v=0;v<dashboardRoutes.length;v++){
//   for(var v1=0;v1<JSON.parse(responseJson.content).sidebar;v1++){
// if(dashboardRoutes[v])
//   }

// }
         this.setState({
           sidebar:JSON.parse((responseJson.content).sidebar),
 loading:false
         })
           })
           .catch((error) => {
             this.setState({
               loading:false
             })
            
           console.log(error)
               })
              })
    }
             }
}
 
    
   

 

export default dashboardRoutes;
