import React, { Component } from "react";
import ReactTooltip from 'react-tooltip'
import { MDBNavbar, MDBDropdownItem,MDBDropdownMenu,  MDBIcon, MDBTable, MDBTableBody, MDBTableHead, MDBBtn,MDBRow,
  MDBCol,
  MDBDropdown,
  MDBDropdownToggle,
  MDBCardHeader, MDBModal, MDBModalBody, MDBInput, MDBModalFooter,MDBCollapse,MDBPopover, MDBPopoverBody, MDBTooltip,  } from 'mdbreact';
  import dashboardRoutes from "../../routes/dashboard";
  import swal from 'sweetalert'
  import { Grid, Row, Col,Image,ListGroup,ListGroupItem,Button,Form,FormControl,Checkbox,ControlLabel, } from "react-bootstrap";
import './case.css'
import ScrollAnimation from 'react-animate-on-scroll';
import Select from 'react-select'
import "animate.css/animate.min.css";
import { HalfCircleSpinner } from 'react-epic-spinners'
   
import CryptoJS from 'crypto'
class Case extends Component {
  constructor(props) {
    super(props);
  this.state={
    loading:false,info_heads:[],add_head:false,clients:[],
    new_name:"",new_des:"",new_hsn:"",new_type:"Select Cost Type",selected_id:"",
    selected_language:[],users:[],new_pass:'',add_user:false,
    new_name:'',new_email:'',new_password:'',edit_name:'',edit_email:'',edit_password:'',
    languages:[],new_user_languages:[],edit_user:false,user_to_edit:null,exit:false,
    edit_user_languages:[],superadmin:false,admin:false,super_pass:'',
    catagories:[{name:'Renewables',sub:[],image:'Wind.gif',selected:0,id:1},
    {name:'Industry',sub:[{sub_name:'ALUMINIUM',sub_id:1},{sub_name:'AUTOMOTIVE',sub_id:1},{sub_name:'FOOD & BEVERAGES',sub_id:1}],showAdded:false,
    image:'Industry.gif',selected:0,id:2},
    {name:'Transport',sub:[{sub_name:'MARINE',sub_id:3},{sub_name:'RAILWAYS',sub_id:3}],image:'Railways.gif',selected:0,id:3}
    ,{name:'Infrastructure',sub:[],image:'Infrastructure.gif',selected:0,id:4},
    {name:'Utility',sub:[],image:'Transmission.gif',selected:0,id:5}],selected_catagory:null,sub_cat:false,tabs:[],heading:[],sub_tabs:[],logoutText:'Logout',mobileMenu:false,read_more:false,read_more_text:'READ MORE...',read_less_text:'READ LESS'
  }
  }
  exitApp(){
    var userAgent = navigator.userAgent.toLowerCase();
  if (userAgent.indexOf(' electron/') > -1) {
    const remote = window.require('electron').remote
    let w = remote.getCurrentWindow()
    w.close()
  }
  
  
  }
  renderError(){
    return(<div id="snackbar">To view this page, please connect to internet</div>)
  }

  renderExitModal(){
  
  
     
    if(this.state.exit==true){
  
  
    return(
      <MDBModal size='md' isOpen={this.state.exit} centered toggle={this.toggleExit} >
   
         
   <MDBModalBody style={{    display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',flexDirection:'column',paddingTop:'4vh',paddingBottom:'4vh'}}>
            <img onError={()=>this.setState({showAdded:true})} src={require('../../assets/Exit.jpg')} className='catGif'/>
            <p>Are you sure you want to exit this application?</p>
         <div style={{display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-evenly'}}>      <text onClick={()=>this.exitApp()} style={{backgroundColor:'#ff000f',color:'white'}} className='seriesText'>Exit</text>

            
            <text onClick={()=>this.setState({exit:false})} className='seriesText'>Cancel</text>

       
          </div>
           
            </MDBModalBody>

        </MDBModal>
    )
  }
  }
  
    
    toggleExit = () => {
     
      this.setState({
        exit: !this.state.exit,
       });
    }
    toggleSub = () => {
     
      this.setState({
        sub_cat: !this.state.sub_cat,
       });
    }
    reload = ()=>{
      const current = this.props.location.pathname;
      this.props.history.replace(`/reload`);
         setTimeout(() => {
           this.props.history.replace(current);
         });
     }
    showUpdateBar() {
      if(this.state.showAdded){
        
        
          return(
            <ScrollAnimation animateOnce={false}   animateIn="fadeIn"  className='fadeIn toast2' animationOut="fadeOut" 
             isVisible={true} >
           <div className="snack">Go online and <text onClick={()=>this.reload()} style={{cursor:'pointer',textDecoration:'underline'}}>click here</text> to refresh</div>
        </ScrollAnimation>
        
           
          )
        
     
      }
      }
      trigger() {
  
        setTimeout(() => { 
            this.setState({
                showAdded: false
            })
        }, 5000);
      }
  componentDidMount(){
    this.getuserdata()
  
    var userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf(' electron/') > -1) {
       this.setState({showExit:true})
    }
  }
  renderExitButton(){
    if(this.state.showExit){
     return(<div
       className="sideInActive"
       
        onClick={this.toggleExit}                
       >
       <img onError={()=>this.setState({showAdded:true})} src={require('../../assets/Exit.jpg')} style={{width:35}}/>
       <text style={{marginTop:5}}>Exit</text>
       </div>)
    }
   
   } 
  componentWillMount(){

  }
  selectLanguage(data,key){
    window.localStorage.removeItem('admindefault')
      window.localStorage.setItem('admindefault',JSON.stringify(data))
   
this.setState({
  selected_language:data
},()=>window.location.reload())
  }
  getuserdata=async()=>{
    this.setState({
      loading:true
    })
  
 

var responseJson=[{"language":"English","language_id":1,"active":0,"key":1,"label":"English",selected:0},
{"language":"German","language_id":2,"active":0,"key":2,"label":"German",selected:0},
{"language":"Chinese","language_id":3,"active":0,"key":3,"label":"Chinese",selected:0},
{"language":"Spanish","language_id":4,"active":0,"key":4,"label":"Spanish",selected:0},
{"language":"French","language_id":5,"active":0,"key":5,"label":"French",selected:0}]

  for(var v=0;v<responseJson.length;v++){
    responseJson[v].label=responseJson[v].language
    responseJson[v].value=responseJson[v].language_id
  }
  var defaultLanguage=window.localStorage.getItem('admindefault')
  if(defaultLanguage==null){
    responseJson[0].selected=1
   this.setState({
     languages:responseJson,
     loading:false,
     selected_language:responseJson[0]
   },()=>this.getContent())
  }
  else{
    if(JSON.parse(defaultLanguage).language=='German' || JSON.parse(defaultLanguage).language=='Deutsche' ||
    JSON.parse(defaultLanguage).language=='德语' || JSON.parse(defaultLanguage).language=='alemán' || JSON.parse(defaultLanguage).language=='allemand'){
     responseJson[0].language='Englisch'
     responseJson[1].language='Deutsche'
     responseJson[2].language='Chinesisch'
     responseJson[3].language='Spanisch'
     responseJson[4].language='Französisch'
     this.setState({logoutText:'Ausloggen',read_less_text:'LESE WENIGER',read_more_text:'WEITERLESEN'})

    }
    else if(JSON.parse(defaultLanguage).language=='Chinese' || JSON.parse(defaultLanguage).language=='Chinesisch' ||
    JSON.parse(defaultLanguage).language=='中文' || JSON.parse(defaultLanguage).language=='chino' || JSON.parse(defaultLanguage).language=='chinois'){
    
      responseJson[0].language='英语'
      responseJson[1].language='德语'
      responseJson[2].language='中文'
      responseJson[3].language='西班牙语'
      responseJson[4].language='法国'
     
      this.setState({logoutText:'登出',read_less_text:'阅读不多',read_more_text:'阅读更多'})

 
     }
     else if(JSON.parse(defaultLanguage).language=='Spanish' || JSON.parse(defaultLanguage).language=='Spanisch' ||
     JSON.parse(defaultLanguage).language=='西班牙语' || JSON.parse(defaultLanguage).language=='Español' || JSON.parse(defaultLanguage).language=='Espanol'){
      responseJson[0].language='Inglés'
      responseJson[1].language='alemán'
      responseJson[2].language='chino'
      responseJson[3].language='Español'
      responseJson[4].language='francés'
      this.setState({logoutText:'Cerrar sesión',read_less_text:'LEER MENOS',read_more_text:'LEE MAS'})

 
     }
     else if(JSON.parse(defaultLanguage).language=='French' || JSON.parse(defaultLanguage).language=='Französisch' ||
     JSON.parse(defaultLanguage).language=='法国' || JSON.parse(defaultLanguage).language=='francés' || JSON.parse(defaultLanguage).language=='français'){
      responseJson[0].language='Anglais'
      responseJson[1].language='allemand'
      responseJson[2].language='chinois'
      responseJson[3].language='Espanol'
      responseJson[4].language='français'
       
      this.setState({logoutText:'Connectez - Out',read_less_text:'LIRE MOINS',read_more_text:'LIRE LA SUITE'})

 
     }
    console.log(JSON.parse(defaultLanguage),'selected_language')
    responseJson[JSON.parse(defaultLanguage).key-1].selected=1
   this.setState({
     languages:responseJson,
     loading:false,
     selected_language:JSON.parse(defaultLanguage)
   },()=>this.getContent())
  }
   
 }
  
 getSidebar=async()=>{
         
  if(!navigator.onLine){
var offline_content=window.localStorage.getItem('sidebar')
if(offline_content==null){

this.setState({
 loading:false
})
}
else{
console.log(JSON.parse(JSON.parse(offline_content).content),'cccc')
dashboardRoutes[0].name=JSON.parse(JSON.parse(offline_content).content)[0].name
dashboardRoutes[1].name=JSON.parse(JSON.parse(offline_content).content)[1].name
dashboardRoutes[2].name=JSON.parse(JSON.parse(offline_content).content)[2].name
dashboardRoutes[3].name=JSON.parse(JSON.parse(offline_content).content)[4].name
dashboardRoutes[4].name=JSON.parse(JSON.parse(offline_content).content)[5].name
dashboardRoutes[5].name=JSON.parse(JSON.parse(offline_content).content)[6].name
this.setState({
 sidebar:JSON.parse(offline_content).content,
loading:false
})
}
  }
  else{
   this.setState({loading:true})
   let details = {
     language_id:this.state.selected_language.language_id
   };
   let formBody = [];
   for (let property in details) {
    let encodedKey = encodeURIComponent(property);
    let encodedValue = encodeURIComponent(details[property]);
    formBody.push(encodedKey + "=" + encodedValue);
   }
   formBody = formBody.join("&");
   this.setState({
     response: fetch('https://www.mobilitysolution.info/get_sidebar', {
       method: 'POST',
       headers: {
        
         'Content-Type': 'application/x-www-form-urlencoded',
         'Cache-Control': 'max-age=31536000'
     },
   body:formBody
       
     }).then((response) => response.json())
     .then((responseJson)=>{
       console.log(JSON.parse(responseJson.content),'sidebar')
      window.localStorage.removeItem('sidebar')
      window.localStorage.setItem('sidebar',JSON.stringify(responseJson))
dashboardRoutes[0].name=JSON.parse(responseJson.content)[0].name
dashboardRoutes[1].name=JSON.parse(responseJson.content)[1].name
dashboardRoutes[2].name=JSON.parse(responseJson.content)[2].name
dashboardRoutes[3].name=JSON.parse(responseJson.content)[4].name
dashboardRoutes[4].name=JSON.parse(responseJson.content)[5].name
dashboardRoutes[5].name=JSON.parse(responseJson.content)[6].name
       this.setState({
         sidebar:(responseJson.content).sidebar,
loading:false
       })
         })
         .catch((error) => {
          var offline_content=window.localStorage.getItem('sidebar')
          if(offline_content==null){
          
          this.setState({
           loading:false
          })
          }
          else{
          console.log(JSON.parse(JSON.parse(offline_content).content),'cccc')
          dashboardRoutes[0].name=JSON.parse(JSON.parse(offline_content).content)[0].name
          dashboardRoutes[1].name=JSON.parse(JSON.parse(offline_content).content)[1].name
          dashboardRoutes[2].name=JSON.parse(JSON.parse(offline_content).content)[2].name
          dashboardRoutes[3].name=JSON.parse(JSON.parse(offline_content).content)[4].name
          dashboardRoutes[4].name=JSON.parse(JSON.parse(offline_content).content)[5].name
          dashboardRoutes[5].name=JSON.parse(JSON.parse(offline_content).content)[6].name
          this.setState({
           sidebar:JSON.parse(offline_content).content,
          loading:false
          })
          }
             })
            })
  }
           }
 getContent=async()=>{
  if(!navigator.onLine){
    var offline_content=window.localStorage.getItem('case')
    if(offline_content==null){
      swal('Warning','Check your network and refresh','warning')
      this.setState({
        loading:false
      })
    }
    else{
      this.setState({
      
        heading:JSON.parse(JSON.parse(offline_content).page_heading),
        tabs:(JSON.parse(JSON.parse(offline_content).tabs))[0].tabs,
         sub_tabs:(JSON.parse(JSON.parse(offline_content).tabs))[0].sub_tabs,

         loading:false
        },()=>console.log(this.state.heading))
        
    }
    this.getSidebar()
         }

 else{
  this.setState({loading:true})
  let details = {
    language_id:this.state.selected_language.language_id
  };
  let formBody = [];
  for (let property in details) {
   let encodedKey = encodeURIComponent(property);
   let encodedValue = encodeURIComponent(details[property]);
   formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  console.log(details)
  this.setState({
    response: fetch('https://www.mobilitysolution.info/get_content_case', {
      method: 'POST',
      headers: {
       
        'Content-Type': 'application/x-www-form-urlencoded',
        'Cache-Control': 'max-age=31536000'
    },
  body:formBody
      
    }).then((response) => response.json())
    .then((responseJson)=>{
      window.localStorage.removeItem('case')
      window.localStorage.setItem('case',JSON.stringify(responseJson))
    
      this.setState({
        tabs:JSON.parse(responseJson.tabs)[0].tabs,
        sub_tabs:JSON.parse(responseJson.tabs)[0].sub_tabs,
      heading:JSON.parse(responseJson.page_heading),
       loading:false
      },()=>console.log(this.state.heading))
      this.getSidebar()
        })
        .catch((error) => {
          var offline_content=window.localStorage.getItem('case')
          if(offline_content==null){
            swal('Warning','Check your network and refresh','warning')
            this.setState({
              loading:false
            })
          }
          else{
            this.setState({
            
              heading:JSON.parse(JSON.parse(offline_content).page_heading),
              tabs:(JSON.parse(JSON.parse(offline_content).tabs))[0].tabs,
               sub_tabs:(JSON.parse(JSON.parse(offline_content).tabs))[0].sub_tabs,
      
               loading:false
              },()=>console.log(this.state.heading))
              
          }
          this.getSidebar()
            })
           })
 }
       }
  
       renderLanguages(){

        if(this.state.loading==false){
          if(this.state.languages.length>0){
            return(<div  
          
               
              
              style={{backgroundColor:'rgb(255, 255, 255, 0.1)',cursor:'pointer',display:'flex',alignItems:'center',marginRight:100,marginTop:'.5%'}}>
            <MDBIcon size="lg" style={{marginLeft:10,marginRight:20}}   className="grey-text menuBar" icon="bars" onClick={()=>this.setState({mobileMenu:!this.state.mobileMenu})}/>
            <MDBIcon size="lg" style={{marginLeft:10}} onClick={()=>this.setState({lang_mod:true})}   className="grey-text" icon="globe-africa" />
            <MDBDropdown className='languageDiv' >
           <MDBDropdownToggle caret color="#004080" size="md" >
           <text className='languageText'>{this.state.languages[this.state.selected_language.key-1].language}</text>
           </MDBDropdownToggle>
           <MDBDropdownMenu style={{margin:0,padding:0,borderRadius:0}}> 
           <MDBDropdownItem style={{backgroundColor:'#d2d2d2',color:'black',textAlign:'center',padding:12}}  >ENGLISH</MDBDropdownItem>
           {/* { (this.state.languages).map((data,key) => {
        
      if(data.selected==0){
      return ( 
        <MDBDropdownItem style={{backgroundColor:'#fff',color:'#6e6e6e',textAlign:'center',padding:12}} className='dropButton'  onClick={()=>this.selectLanguage(data)}>{data.language}</MDBDropdownItem>
            
             )
      }
      else{
      return ( 
        <MDBDropdownItem style={{backgroundColor:'#d2d2d2',color:'black',textAlign:'center',padding:12}}  >{data.language}</MDBDropdownItem>
            
             )
      }
        })
        } */}
        </MDBDropdownMenu>
        </MDBDropdown>
          
              </div>)
           }
         
        }
           
          }
  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }




 
    selectCatagory(data,key){

      let {catagories,selected_catagory}=this.state
      let targetCatagory=catagories[key]
      for(var v=0;v<catagories.length;v++){
        catagories[v].selected=0
    }
    targetCatagory.selected=1
    for(var v1=0;v1<selected_catagory.length;v1++){
      
      selected_catagory[v1].selected_sub=0
        }
    
    
    
    this.setState({catagories,selected_catagory})
    if(selected_catagory.sub.length==0){
 
        if(selected_catagory.name=="Renewables"){
          this.props.history.push('renewables')
        }
        else if(selected_catagory.name=="Infrastructure"){
         this.props.history.push('infra')
        }
        else if(selected_catagory.name=="Utility"){
         this.props.history.push('utility')
        }
    }
    else{
      this.setState({
        sub_cat:true
      })
    }
    this.setState({
    selected_catagory:data,
    })
    }
    selectCatagoryMobile(data,key){
console.log(data)
      let {catagories,selected_catagory}=this.state
      let targetCatagory=catagories[key]
      for(var v=0;v<catagories.length;v++){
        catagories[v].selected=0
    }
    targetCatagory.selected=1
    for(var v1=0;v1<selected_catagory.length;v1++){
      
      selected_catagory[v1].selected_sub=0
        }
    
    
    
    this.setState({catagories,selected_catagory})
    if(selected_catagory.name=="Renewables"){
      this.props.history.push('renewables')
    }
    else if(selected_catagory.name=="Infrastructure"){
     this.props.history.push('infra')
    }
    else if(selected_catagory.name=="Utility"){
     this.props.history.push('utility')
    }
    else if(selected_catagory.name=="Industry"){
      console.log('ind')
      this.props.history.push('industry')
     }
     else if(selected_catagory.name=="Transport"){
       console.log('tran')
      this.props.history.push('transport')
     }
  
    
    }
    renderList(){

      if(this.state.loading){
    
    return(<div className="loadingSpin"><HalfCircleSpinner color="#ff000f"/></div>)
      }
      else{
        let columns=[];
      this.state.catagories.forEach((data,key) => {
    
          // push column
          columns.push(
            this.renderData(data,key)
    
          )
    
          // force wrap to next row every 4 columns
          if ((key+1)%5===0) {columns.push(<div className="w-100"></div>)}
      })
    
        return (
      
      
      <div   className='rowDiv'>
          
        
         {columns}
        
          </div>
     
         
      )
          
          
     
        
      }
     
    
    
    }
    renderListMobile(){

      if(this.state.loading){
        
    return(<div className="loadingSpin"><HalfCircleSpinner color="#ff000f"/></div>)
      }
      else{
        let columns=[];
      this.state.catagories.forEach((data,key) => {
    
          // push column
          columns.push(
            this.renderDataMobile(data,key)
    
          )
    
          // force wrap to next row every 4 columns
          if ((key+1)%5===0) {columns.push(<div className="w-100"></div>)}
      })
    
        return (
      
      
      <div   className='rowDiv'>
          
        
         {columns}
        
          </div>
     
         
      )
          
          
     
        
      }
     
    
    
    }
    selectSub(data){
     if(data.name!=undefined){
       if(data.name=="Renewables"){
         this.props.history.push('renewables')
       }
       else if(data.name=="Infrastructure"){
        this.props.history.push('infra')
       }
       else if(data.name=="Utility"){
        this.props.history.push('utility')
       }
     }
    else{
      if(data.sub_name=='RAILWAYS'){
        this.props.history.push('railways')
      }
      else if(data.sub_name=='MARINE'){
        this.props.history.push('marine')
      }
      else if(data.sub_name=='FOOD & BEVERAGES'){
        this.props.history.push('f&b')
      }
      else if(data.sub_name=='ALUMINIUM'){
        this.props.history.push('aluminium')
      }
      else if(data.sub_name=='AUTOMOTIVE'){
        this.props.history.push('automotive')
      }
    }
    }
    renderSub(){
      if(this.state.selected_catagory!=null){
        if(this.state.selected_catagory.sub.length>0){
       return(
      <ScrollAnimation animateOnce={true} animateIn="slideInUp"  className='slideInUp' animationOut="slideOutLeft"  isVisible={true} style={{opacity:1,position:'absolute',width:'100%',marginLeft:'-8%',bottom:0}}>
  <MDBRow className='subRow'>
      {
        this.state.selected_catagory.sub.map((data,key) => {
    
    // push column
    if(data.sub_name=='AUTOMOBILE'){
      return(
    <text onClick={()=>this.selectSub(data)} className='subText'>{this.state.sub_tabs[1]}</text>
    )
    }
    else if(data.sub_name=='ALUMINIUM'){
      return(
    <text onClick={()=>this.selectSub(data)} className='subText'>{this.state.sub_tabs[0]}</text>
    )
    }
    else   if(data.sub_name=='AUTOMOTIVE'){
      return(
    <text onClick={()=>this.selectSub(data)} className='subText'>{this.state.sub_tabs[1]}</text>
    )
    }
    else if(data.sub_name=='FOOD & BEVERAGES'){
      return(
    <text onClick={()=>this.selectSub(data)} className='subText'>{this.state.sub_tabs[2]}</text>
    )
    }
    else if(data.sub_name=='MARINE'){
      return(
    <text onClick={()=>this.selectSub(data)} className='subText'>{this.state.sub_tabs[3]}</text>
    )
    }
    else if(data.sub_name=='RAILWAYS'){
      return(
    <text onClick={()=>this.selectSub(data)} className='subText'>{this.state.sub_tabs[4]}</text>
    )
    }


})
      }
      </MDBRow>
</ScrollAnimation>

       )
        }
        else{
          return(
            <MDBRow className='subRow'>
           <text  onClick={()=>this.selectSub(this.state.selected_catagory)} className='arrowRed'></text>
            </MDBRow>
          )
        }
      }
    }
    renderData(data,key){

      if(data.selected==0){
        return(
        
    <div  onClick={()=>this.setState({selected_catagory:data},()=>this.selectCatagory(data,key))} className="catagories2"    >
     <img onError={()=>this.setState({showAdded:true})} src={require('../../assets/Gifs/'+data.image)} className='caseGif'/>
    <p style={{color:'black',fontFamily:'ABBvoice-Bold'}} >{this.state.tabs[key]}</p>
    </div>
        )
      }
      else if(data.selected==1){
        return(
              
    <div   onClick={()=>this.setState({selected_catagory:data},()=>this.selectCatagory(data,key))} className="catagoriesHover2"  >
    <img onError={()=>this.setState({showAdded:true})} src={require('../../assets/Gifs/'+data.image)} className='caseGif'/>
    <p style={{color:'black',fontFamily:'ABBvoice-Bold'}}>{this.state.tabs[key]}</p>
    </div>
        )
      }
    }
    renderDataMobile(data,key){

      if(data.selected==0){
        return(
        
    <div  onClick={()=>this.setState({selected_catagory:data},()=>this.selectCatagoryMobile(data,key))} className="catagories2"    >
     <img onError={()=>this.setState({showAdded:true})} src={require('../../assets/Gifs/'+data.image)} className='caseGif'/>
    <p style={{color:'black',fontFamily:'ABBvoice-Bold',marginBottom:0}} >{this.state.tabs[key]}</p>
    </div>
        )
      }
      else if(data.selected==1){
        return(
              
    <div   onClick={()=>this.setState({selected_catagory:data},()=>this.selectCatagoryMobile(data,key))} className="catagoriesHover2"  >
    <img onError={()=>this.setState({showAdded:true})} src={require('../../assets/Gifs/'+data.image)} className='caseGif'/>
    <p style={{color:'black',fontFamily:'ABBvoice-Bold',marginBottom:0}}>{this.state.tabs[key]}</p>
    </div>
        )
      }
    }
    renderReadMore(){
      if(!this.state.read_more){
        return(
            <p className='caseext' style={{textAlign:'justify'}}>{this.state.heading[0].paragraph.substr(0, 165)}<text onClick={()=>this.setState({read_more:true})}
            style={{cursor:'pointer',fontFamily:'ABBvoice-Bold'}}>&nbsp;{this.state.read_more_text}</text></p>  
      
          ) 
      }
        else{
          return(
            <p className='caseext' style={{textAlign:'justify'}}>{this.state.heading[0].paragraph}<text onClick={()=>this.setState({read_more:false})}
            style={{cursor:'pointer',fontFamily:'ABBvoice-Bold'}}>&nbsp;{this.state.read_less_text}</text></p>
          )
        }
      
    }
 
    renderContentmobile(){
      if(this.state.loading==false && this.state.heading.length!=0){
        return(   <div className='mainDivCaseMobile'> 
    
        <h1  className='caseheading'>
                             
                              
                             {this.state.heading[0].heading}                    
                            </h1>
                            <h1 style={{marginBottom:30}} className='casedata'>{this.state.heading[0].subheading}</h1>
          {this.renderReadMore()}
            <div >
        
        <MDBRow>
        
        
        
        {this.renderListMobile()}
        
        </MDBRow>
       
        </div>   
                
        </div>)
      }
    }
 renderContentwindow(){
   if(this.state.loading==false && this.state.heading.length!=0){
     return(   <div className='mainDivCase'> 
 
     <h1  className='valueheading'>
                          
                           
                          {this.state.heading[0].heading}                    
                         </h1>
                         <h1 style={{marginBottom:30}} className='valuedata'>{this.state.heading[0].subheading}</h1>
         <p className='caseext'>{this.state.heading[0].paragraph}</p>  
         <div >
     
     <MDBRow>
     
     
     
     {this.renderList()}
     
     </MDBRow>
     {this.renderSub()}  
     </div>   
             
     </div>)
   }
 }
 renderoverLay(){
  if(this.state.mobileMenu){
  
    return (<div id='overlayCat' onClick={()=>this.setState({mobileMenu:false})}></div>)
  }
}
mobileMenu(){
  var style={opacity:1,backgroundColor:'#fff',height:'100vh',borderStyle:'solid',borderColor:'#d2d2d2',borderWidth:1,
  overflow:'auto',padding:'2.5%',display:'flex',flexDirection:'column',alignItems:'flex-start',paddingLeft:10,paddingRight:10}

 
  if(this.state.mobileMenu){
    return(
      <div className='mobileMenu'>
        <ScrollAnimation animateOnce={false}  animateIn="slideInLeft"  className='slideInLeft' animationOut="slideOutLeft" 
     isVisible={true}  style={style}>
         {dashboardRoutes.map((prop, key) => {
                  if(prop.path=='/case_study'){
                    return (
                     
                     <div
                     key={key}
      className="sideActiveMobile"
      
       onClick={()=>this.props.history.push(`${prop.path}`)}                
      >
      <img onError={()=>this.setState({showAdded:true})} alt='Loading...' src={require('../../assets/navbar/Casestudy.png')} alt='Loading...' style={{width:20,marginRight:10}}/>
      
      <text style={{marginTop:5,fontSize:12}}>{prop.name}</text>
      </div>
                     
                   );
                   return null;
                  }
                  else{
                    return (
                     
                     <div
      className="sideInActiveMobile"
      
       onClick={()=>this.props.history.push(`${prop.path}`)}                
      >
      <img onError={()=>this.setState({showAdded:true})} alt='Loading...' src={require('../../assets/navbar/'+prop.icon)} style={{width:20,marginRight:10}}/>
      <text style={{marginTop:5,fontSize:12}}>{prop.name}</text>
      </div>
                     
                   );
                   return null; 
                  }
                 

                })}
               
                </ScrollAnimation>
      </div>
    )
  }
}
toggleLang = () => {
   
  this.setState({
    lang_mod: !this.state.lang_mod,
   });
}
renderlangModal(){


   
  if(this.state.lang_mod==true){


  return(
    <MDBModal className='mobileLang' size='md' isOpen={this.state.lang_mod} toggle={this.toggleLang} centered>
 
       
            <MDBModalBody>
            {/* { (this.state.languages).map((data,key) => {
    
    if(data.selected==0){
     return ( 
       <MDBDropdownItem style={{backgroundColor:'#fff',color:'#6e6e6e',textAlign:'center',padding:12}} className='dropButton'  onClick={()=>this.selectLanguage(data)}>{data.language}</MDBDropdownItem>
           
            )
    }
    else{
     return ( 
       <MDBDropdownItem style={{backgroundColor:'#d2d2d2',color:'black',textAlign:'center',padding:12}}  >{data.language}</MDBDropdownItem>
           
            )
    }
       })
       } */}
       <MDBDropdownItem style={{backgroundColor:'#d2d2d2',color:'black',textAlign:'center',padding:12}}  >ENGLISH</MDBDropdownItem>
                       </MDBModalBody>
          
      </MDBModal>
  )
}
}
  render() {

    
    const sidebarBackground = {
      backgroundColor: "#1C2231"
    };
    if(this.state.loading){
    
      return(<div className="loadingSpin"><HalfCircleSpinner color="#ff000f"/></div>)
        }
        else{
    return (
      <div className="bodyDivCl" >

   <div
      id="sidebar"
      className="sideCat"
      data-color="#1C2231"
     
    >
      <div className="sidebar-background" style={sidebarBackground} />
      
      <div className="sidebar-wrapper" style={{display:'flex',flexDirection:'column',justifyContent:'flex-start',alignItems:'center',marginTop:10}}>
      
      
      {dashboardRoutes.map((prop, key) => {
            if(prop.path=='/case_study'){
              return (
               
               <div
               key={key}
className="sideActive"

 onClick={()=>this.props.history.push(`${prop.path}`)}                
>
<img onError={()=>this.setState({showAdded:true})} src={require('../../assets/navbar/Casestudy_Hover.png')} style={{width:25}}/>

<text style={{marginTop:5}}>{prop.name}</text>
</div>
               
             );
             return null;
            }
            else{
              return (
               
               <div
className="sideInActive"

 onClick={()=>this.props.history.push(`${prop.path}`)}                
>
<img onError={()=>this.setState({showAdded:true})} src={require('../../assets/navbar/'+prop.icon)} style={{width:25}}/>
<text style={{marginTop:5}}>{prop.name}</text>
</div>
               
             );
             return null; 
            }
           
          })}
       {this.renderExitButton()}
      </div>
  
       

    </div>
    {this.mobileMenu()}
      <div className="conTable">
      <div style={{backgroundColor:'white'}} >
    
    <div className="topMenu">
    <div style={{display:'flex',justifyContent:'center'}}>{this.renderLanguages()}</div>
     
    <div style={{display:'flex',justifyContent:'center'}}><img onError={()=>this.setState({showAdded:true})} src={require('../../assets/abb_logo.png')} className='abb_logo'/></div>
       
     
            
             
              </div>
   
      </div>
{this.renderContentwindow()}
{this.renderContentmobile()}



   <div >
       {this.renderlangModal()}
{this.renderoverLay()}
{this.renderExitModal()}
 {this.showUpdateBar()}


               </div>  
</div>
 

      
   
     





    


    
 
 
               </div>
              
    );

        }
  }
}
export default Case