import React, { Component } from "react";
import ReactTooltip from 'react-tooltip'
import { MDBNavbar, MDBDropdownItem,MDBDropdownMenu,  MDBIcon, MDBTable, MDBTableBody, MDBTableHead, MDBBtn,MDBRow,
  MDBCol,
  MDBDropdown,
  MDBDropdownToggle,
  MDBCardHeader, MDBModal, MDBModalBody, MDBInput, MDBModalFooter,MDBCollapse,MDBPopover, MDBPopoverBody, MDBTooltip,  } from 'mdbreact';
  import dashboardRoutes from "../../routes/dashboard";
  import swal from 'sweetalert'
  import '../../routes/main.css'
  import { Grid, Row, Col,Image,ListGroup,ListGroupItem,Button,Form,FormControl,Checkbox,ControlLabel, } from "react-bootstrap";
import './overview.css'
import "../../../node_modules/video-react/dist/video-react.css";
import { Player } from 'video-react';
import { HalfCircleSpinner } from 'react-epic-spinners'
import ScrollAnimation from 'react-animate-on-scroll';
class Overview extends Component {
  constructor(props) {
    super(props);
  this.state={
    loading:false,exit:false,languages:[],overview_data:[],collapseID:0,mobileMenu:false,lang_mod:false,nextText:'Solutions',showExit:false,exit:false,heading:'Overview',selected_language:null,showAdded:false
   
  }
  }
  componentWillMount(){

  }


exitApp(){
  var userAgent = navigator.userAgent.toLowerCase();
if (userAgent.indexOf(' electron/') > -1) {
  const remote = window.require('electron').remote
  let w = remote.getCurrentWindow()
  w.close()
}


}
   renderExitModal(){


   
    if(this.state.exit==true){
  
  
    return(
      <MDBModal  isOpen={this.state.exit} centered toggle={this.toggleExit} >
   
         
              <MDBModalBody style={{    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',flexDirection:'column',paddingTop:'4vh',paddingBottom:'4vh'}}>
              <img onError={()=>this.setState({showAdd:true})} src={require('../../assets/Exit.jpg')} className='catGif'/>
              <p>Are you sure you want to exit this application?</p>
           <div style={{display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-evenly'}}>      <text onClick={()=>this.exitApp()} style={{backgroundColor:'#ff000f',color:'white'}} className='seriesText'>Exit</text>

              
              <text onClick={()=>this.setState({exit:false})} className='seriesText'>Cancel</text>

         
            </div>
             
              </MDBModalBody>

        </MDBModal>
    )
  }
  }
  
  toggleExit = () => {
   
    this.setState({
      exit: !this.state.exit,
     });
  }

 


 
 getData(){
   var overview_data=[
     
  {name:'Consulting',id:1,list:['Asset consultancy','System studies and consultancy','Solution optimisation and filter design','Training services']},
   
   {name:'Capacitors & Filters',id:2,list:['LV, MV and HV capacitor units','LV, MV and HV capacitor banks','LV, MV and HV filter solutions','Training services','Wet and dry type DC capacitors','Power quality controllers','Capacitor switches']},
  
   {name:'Active power quality solutions',id:3,list:['Inverter-based power factor correction','Inverter-based harmonic mitigation','Inverter-based unbalance compensation']},
  
   { name:'Surge arresters & surge capacitors',id:4,list:['HV surge arresters','MV surge arresters','MV surge capacitors','Voltage-limiting devices']},

   { name:'Volt-var management',id:5,list:['Volt-var management across the distribution network','Capacitor banks control system','Line voltage regulators','Synchronous condensers']},

   { name:'FACTS',id:6,list:['Thyristor controlled series compensation (TCSC)','Static var compensation (SVC)','STATCOM']},

   { name:'Energy storage',id:7,list:['Energy storage inverters','Energy storage systems','Scalable, modular and turnkey solutions']},

   { name:'Shunt reactors',id:8,list:['Shunt reactors','Variable shunt reactors','Series reactors','Earthing transformers','Dry and water-cooled reactors']},

   { name:'Voltage conditioning & regulation',id:9,list:['Active voltage conditioner','Uninterruptible power supplies','Line voltage regulators']},

   { name:'Monitoring, protection & control',id:10,list:['Protection relays with power quality monitoring','Automation and communication solutions']},
  ]
  this.setState({overview_data:overview_data})
 }


  componentDidMount(){
this.getVideo()
this.trigger()
  }

  getContent=async()=>{
         
    if(!navigator.onLine){
var offline_content=window.localStorage.getItem('overview')
if(offline_content==null){
 swal('Warning','Check your network and refresh','warning')
 this.setState({
   loading:false
 })
}
else{
 console.log(offline_content)
 this.setState({
   paragraph:JSON.parse(JSON.parse(offline_content).paragraph),
           list:JSON.parse(JSON.parse(offline_content).list),
           heading:JSON.parse(offline_content).heading,
 loading:false
 })
}
    }
    else{
     this.setState({loading:true})
     let details = {
       language_id:this.state.selected_language.language_id
     };
     let formBody = [];
     for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
     }
     formBody = formBody.join("&");
     console.log(details)
     this.setState({
       response: fetch('https://www.mobilitysolution.info/get_content_overview', {
         method: 'POST',
         headers: {
          
           'Content-Type': 'application/x-www-form-urlencoded',
           'Cache-Control': 'max-age=31536000'
       },
     body:formBody
         
       }).then((response) => response.json())
       .then((responseJson)=>{
         console.log(responseJson)
        window.localStorage.removeItem('overview')
        window.localStorage.setItem('overview',JSON.stringify(responseJson))
         this.setState({
         
           
          
           overview_data:responseJson,
           videolink:responseJson.videlink,
 loading:false
         })
           })
           .catch((error) => {
             this.setState({
               loading:false
             })
             swal("Warning!", "Check your network!", "warning");
           console.log(error)
               })
              })
    }
             }
             showUpdateBar() {
              if(this.state.showAdded){
                
                
                  return(
                    <ScrollAnimation animateOnce={false}   animateIn="fadeIn"  className='fadeIn toast2' animationOut="fadeOut" 
                     isVisible={true} >
                   <div className="snack">To view this page, please connect to internet</div>
                </ScrollAnimation>
                
                   
                  )
                
             
              }
              }
              trigger() {
          
                setTimeout(() => { 
                    this.setState({
                        showAdded: false
                    })
                }, 5000);
              }
             getVideo(){
              var responseJson=[{"language":"English","language_id":1,"active":0,"key":1,"label":"English",selected:0},
              {"language":"German","language_id":2,"active":0,"key":2,"label":"German",selected:0},
              {"language":"Chinese","language_id":3,"active":0,"key":3,"label":"Chinese",selected:0},
              {"language":"Spanish","language_id":4,"active":0,"key":4,"label":"Spanish",selected:0},
              {"language":"French","language_id":5,"active":0,"key":5,"label":"French",selected:0}]
                      for(var v=0;v<responseJson.length;v++){
                        responseJson[v].selected=0
                      }
                 var defaultLanguage=window.localStorage.getItem('admindefault')
                 if(defaultLanguage==null){
                  responseJson[0].selected=1
                  this.setState({
                    languages:responseJson,
                    loading:false,
                    selected_language:responseJson[0]
                  },()=>this.getContent())
                 }
                 else{
                  if(JSON.parse(defaultLanguage).language=='German' || JSON.parse(defaultLanguage).language=='Deutsche' ||
                  JSON.parse(defaultLanguage).language=='德语' || JSON.parse(defaultLanguage).language=='alemán' || JSON.parse(defaultLanguage).language=='allemand'){
                   responseJson[0].language='Englisch'
                   responseJson[1].language='Deutsche'
                   responseJson[2].language='Chinesisch'
                   responseJson[3].language='Spanisch'
                   responseJson[4].language='Französisch'
                   this.setState({logoutText:'Ausloggen',videoText:'MEHR WISSEN',nextText:'Lösungen',heading:'Überblick',})
              
                  }
                  else if(JSON.parse(defaultLanguage).language=='Chinese' || JSON.parse(defaultLanguage).language=='Chinesisch' ||
                  JSON.parse(defaultLanguage).language=='中文' || JSON.parse(defaultLanguage).language=='chino' || JSON.parse(defaultLanguage).language=='chinois'){
                  
                    responseJson[0].language='英语'
                    responseJson[1].language='德语'
                    responseJson[2].language='中文'
                    responseJson[3].language='西班牙语'
                    responseJson[4].language='法国'
                    this.setState({logoutText:'登出',videoText:'了解更多',nextText:'解决方案',heading:'概观'})
              
               
                   }
                   else if(JSON.parse(defaultLanguage).language=='Spanish' || JSON.parse(defaultLanguage).language=='Spanisch' ||
     JSON.parse(defaultLanguage).language=='西班牙语' || JSON.parse(defaultLanguage).language=='Español' || JSON.parse(defaultLanguage).language=='Espanol'){
      responseJson[0].language='Inglés'
      responseJson[1].language='alemán'
      responseJson[2].language='chino'
      responseJson[3].language='Español'
      responseJson[4].language='francés'
                    this.setState({logoutText:'Cerrar sesión',videoText:'SABER MÁS',nextText:'Soluciones',heading:'Visión general'})
              
               
                   }
                   else if(JSON.parse(defaultLanguage).language=='French' || JSON.parse(defaultLanguage).language=='Französisch' ||
                   JSON.parse(defaultLanguage).language=='法国' || JSON.parse(defaultLanguage).language=='francés' || JSON.parse(defaultLanguage).language=='français'){
                    responseJson[0].language='Anglais'
                    responseJson[1].language='allemand'
                    responseJson[2].language='chinois'
                    responseJson[3].language='Espanol'
                    responseJson[4].language='français'
                     
                   
                    this.setState({logoutText:'Connectez - Out',videoText:'SAVOIR PLUS',nextText:'Solutions',heading:`Vue d'ensemble`})
              
               
                   }
                  responseJson[JSON.parse(defaultLanguage).key-1].selected=1
                  this.setState({
                    languages:responseJson,
                    loading:false,
                    selected_language:JSON.parse(defaultLanguage)
                  },()=>this.getContent())
                 }
                    
            }
  
     
  
  


  
 

  selectLanguage(data,key){
    window.localStorage.removeItem('admindefault')
      window.localStorage.setItem('admindefault',JSON.stringify(data))
   
this.setState({
  selected_language:data
},()=>window.location.reload())
  }
  renderLanguages(){

    if(this.state.loading==false){
      if(this.state.languages.length>0){
        return(<div  
      
           
          
          style={{backgroundColor:'rgb(255, 255, 255, 0.1)',cursor:'pointer',display:'flex',alignItems:'center',marginRight:100,marginTop:'.5%'}}>
        <MDBIcon size="lg" style={{marginLeft:10,marginRight:20}}   className="grey-text menuBar" icon="bars" onClick={()=>this.setState({mobileMenu:!this.state.mobileMenu})}/>
        <MDBIcon size="lg" style={{marginLeft:10}} onClick={()=>this.setState({lang_mod:true})}   className="grey-text" icon="globe-africa" />
        <MDBDropdown className='languageDiv' >
       <MDBDropdownToggle caret color="#004080" size="md" >
       <text className='languageText'>{this.state.languages[this.state.selected_language.key-1].language}</text>
       </MDBDropdownToggle>
       <MDBDropdownMenu style={{margin:0,padding:0,borderRadius:0}}> 
       <MDBDropdownItem style={{backgroundColor:'#d2d2d2',color:'black',textAlign:'center',padding:12}}  >ENGLISH</MDBDropdownItem>
       {/* { (this.state.languages).map((data,key) => {
    
  if(data.selected==0){
  return ( 
    <MDBDropdownItem style={{backgroundColor:'#fff',color:'#6e6e6e',textAlign:'center',padding:12}} className='dropButton'  onClick={()=>this.selectLanguage(data)}>{data.language}</MDBDropdownItem>
        
         )
  }
  else{
  return ( 
    <MDBDropdownItem style={{backgroundColor:'#d2d2d2',color:'black',textAlign:'center',padding:12}}  >{data.language}</MDBDropdownItem>
        
         )
  }
    })
    } */}
    </MDBDropdownMenu>
    </MDBDropdown>
      
          </div>)
       }
     
    }
       
      }


 



 

      toggleCollapse = collapseID => () => {
console.log(collapseID)
        if(this.state.collapseID==collapseID){
          this.setState({
            collapseID:0,
           
          })
        }
        else{
          this.setState({
            collapseID:collapseID,
           
          })
        }
      }
      mobileMenu(){
        var style={opacity:1,backgroundColor:'#fff',height:'100vh',borderStyle:'solid',borderColor:'#d2d2d2',borderWidth:1,
        overflow:'auto',padding:'2.5%',display:'flex',flexDirection:'column',alignItems:'flex-start',paddingLeft:10,paddingRight:10}
      
       
        if(this.state.mobileMenu){
          return(
            <div className='mobileMenu'>
              <ScrollAnimation animateOnce={false}  animateIn="slideInLeft"  className='slideInLeft' animationOut="slideOutLeft" 
           isVisible={true}  style={style}>
               {dashboardRoutes.map((prop, key) => {
                        if(prop.path=='/solutions'){
                          return (
                           
                           <div
                           key={key}
            className="sideActiveMobile"
            
             onClick={()=>this.props.history.push(`${prop.path}`)}                
            >
            <img onError={()=>this.setState({showAdd:true})} alt='Loading...' src={require('../../assets/navbar/Solution.png')} alt='Loading...' style={{width:20,marginRight:10}}/>
            
            <text style={{marginTop:5,fontSize:12}}>{prop.name}</text>
            </div>
                           
                         );
                         return null;
                        }
                        else{
                          return (
                           
                           <div
            className="sideInActiveMobile"
            
             onClick={()=>this.props.history.push(`${prop.path}`)}                
            >
            <img onError={()=>this.setState({showAdd:true})} alt='Loading...' src={require('../../assets/navbar/'+prop.icon)} style={{width:20,marginRight:10}}/>
            <text style={{marginTop:5,fontSize:12}}>{prop.name}</text>
            </div>
                           
                         );
                         return null; 
                        }
                       
      
                      })}
                      <div
            className="sideInActiveMobile"
            
             onClick={()=> console.log('exit')}                
            >
            <img onError={()=>this.setState({showAdd:true})} alt='Loading...' src={require('../../assets/navbar/Exit.png')} style={{width:20,marginRight:10}}/>
            <text style={{marginTop:5,fontSize:12}}>Exit</text>
            </div>
                      </ScrollAnimation>
            </div>
          )
        }
      }
  renderData(){
  
     if(this.state.loading){
      return(<div className="loadingSpin"><HalfCircleSpinner color="#ff000f"/></div>)
     }
     else{
 let columns=[];
this.state.overview_data.forEach((data,key) => {

    // push column
    columns.push(
      <div   className="overViewCont"    >
   <img onError={()=>this.setState({showAdd:true})} src={data.image} className='overviewImage'/>
   <div
            key={key}
style={{backgroundColor:"white",width:'100%',flexDirection:'column',
display:'flex',justifyContent:'center',alignItems:'flex-start',padding:10,
borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#d2d2d2',
borderTopStyle:'solid',borderTopWidth:1,borderTopColor:'#d2d2d2',height:35}}
       onClick={this.toggleCollapse(key+1)}
>
<div style={{backgroundColor:"white",flexDirection:'row',justifyContent:'space-between',display:'flex',width:'100%'}}>

<text style={{cursor:'pointer',color:"#000",fontSize:14,fontWeight:400
}}>{data.name}</text>
<MDBIcon icon={this.state.collapseID == key+1 ? 'caret-up' : "caret-down"} className='black-text'/>
</div>

</div>
<MDBCollapse  id={key+1} className='listCollapse' isOpen={this.state.collapseID} style={{backgroundColor:'white',zIndex:1}}> 
<ul style={{marginLeft:-10}}>
{ JSON.parse(data.list).map((value,index) => {
 return(<li style={{paddingTop:3,paddingBottom:3}}>{value}</li>)
     })
}
</ul>
</MDBCollapse> 



   </div>

    )

    // force wrap to next row every 4 columns
    if ((key+1)%4===0) {columns.push(<div className="w-100"></div>)}
})

  return (
  <div>    
<div  className="row rowD" style={{marginBottom:'1%'}}>
    
  
   {columns}
  
    </div>
    <div className='nextAl' style={{marginTop:'2vh',display:'flex',alignItems:'center',position:'absolute',bottom:10}}>
  <text onClick={()=>this.props.history.push('solutions')} className='arrowRed'></text><h5 style={{marginBottom:0,marginLeft:10}}>{this.state.nextText}</h5></div>

</div>
   
)
     }
   
  }
  renderDataMobile(){
    
       if(this.state.loading){
      return(<div className="loadingSpin"><HalfCircleSpinner color="#ff000f"/></div>)
     }
     else{
      let columns=[];
      this.state.overview_data.forEach((data,key) => {
    
          // push column
          columns.push(
            <div style={{width:'100%'}}>
    <div   className="overViewCont"    style={{flexDirection:'row',justifyContent:'center',display:'flex',alignItems:'center',
            borderTopStyle:'solid',borderTopWidth:1,borderTopColor:'#d2d2d2',}}>
                <img onError={()=>this.setState({showAdd:true})} src={data.image} className='overviewImage'/>
         <div
                  key={key}
    style={{backgroundColor:"white",width:'100%',flexDirection:'column',
    display:'flex',justifyContent:'center',alignItems:'flex-start',padding:10,
    height:50}}
             onClick={this.toggleCollapse(key+1)}
    >
    <div style={{backgroundColor:"white",flexDirection:'row',justifyContent:'space-between',display:'flex',width:'100%'}}>
    
    <text style={{cursor:'pointer',color:"#000",fontSize:15,fontWeight:400
    }}>{data.name}</text>
    <MDBIcon icon={this.state.collapseID == key+1 ? 'caret-up' : "caret-down"} className='black-text'/>
    </div>
    
    </div>
     
     
    
    
    
         </div>
         <MDBCollapse  id={key+1} className='listCollapse' isOpen={this.state.collapseID} style={{backgroundColor:'white',zIndex:1}}> 
    <ul style={{margin:5}}>
     { JSON.parse(data.list).map((value,index) => {
       return(<li style={{paddingTop:3,paddingBottom:3,fontWeight:'bold'}}>{value}</li>)
           })
     }
     </ul>
    </MDBCollapse>
     </div>
            
          )
    
          // force wrap to next row every 4 columns
          if ((key+1)%4===0) {columns.push(<div className="w-100"></div>)}
      })
    
        return (
        <div>    
      <div  className="row rowD" style={{marginBottom:'1%'}}>
          
        
         {columns}
        
          </div>
          <div className='overnext nextAl'>
        <text onClick={()=>this.props.history.push('solutions')} className='arrowRed'></text><h5 style={{marginBottom:0,marginLeft:10}}>{this.state.nextText}</h5></div>
    
      </div>
         
      )
     }
   
  }
  renderoverLay(){
    if(this.state.mobileMenu){
    
      return (<div id='overlayCat' onClick={()=>this.setState({mobileMenu:false})}></div>)
    }
  }
  toggleLang = () => {
   
    this.setState({
      lang_mod: !this.state.lang_mod,
     });
  }
  renderlangModal(){
  
  
     
    if(this.state.lang_mod==true){
  
  
    return(
      <MDBModal className='mobileLang' size='md' isOpen={this.state.lang_mod} toggle={this.toggleLang} centered>
   
         
              <MDBModalBody>
              { (this.state.languages).map((data,key) => {
      
      if(data.selected==0){
       return ( 
         <MDBDropdownItem style={{backgroundColor:'#fff',color:'#6e6e6e',textAlign:'center',padding:12}} className='dropButton'  onClick={()=>this.selectLanguage(data)}>{data.language}</MDBDropdownItem>
             
              )
      }
      else{
       return ( 
         <MDBDropdownItem style={{backgroundColor:'#d2d2d2',color:'black',textAlign:'center',padding:12}}  >{data.language}</MDBDropdownItem>
             
              )
      }
         })
         }
                         </MDBModalBody>
              <MDBModalFooter style={{padding:5}}>
              <text className="videoButton" style={{color:'#fff'}}  onClick={()=>this.setState({lang_mod:false})}>CLOSE</text>   
            
               
              </MDBModalFooter>
        </MDBModal>
    )
  }
  }
  render() {

    
    const sidebarBackground = {
      backgroundColor: "#1C2231"
    };
    
    return (
      <div className="bodyDivCl" >

   <div
      id="sidebar"
      className="sideCat"
      data-color="#1C2231"
     
    >
      <div className="sidebar-background" style={sidebarBackground} />
      
      <div className="sidebar-wrapper" style={{display:'flex',flexDirection:'column',justifyContent:'flex-start',alignItems:'center',marginTop:10}}>
   
      {dashboardRoutes.map((prop, key) => {
                  if(prop.path=='/solutions'){
                    return (
                     
                     <div
                     key={key}
      className="sideActive"
      
       onClick={()=>this.props.history.push(`${prop.path}`)}                
      >
      <img onError={()=>this.setState({showAdd:true})} src={require('../../assets/navbar/Solution_hover.png')} style={{width:25}}/>
      
      <text style={{marginTop:5}}>{prop.name}</text>
      </div>
                     
                   );
                   return null;
                  }
                  else{
                    return (
                     
                     <div
      className="sideInActive"
      
       onClick={()=>this.props.history.push(`${prop.path}`)}                
      >
      <img onError={()=>this.setState({showAdd:true})} src={require('../../assets/navbar/'+prop.icon)} style={{width:25}}/>
      <text style={{marginTop:5}}>{prop.name}</text>
      </div>
                     
                   );
                   return null; 
                  }
                 
                })}
          
      </div>
    </div>
{this.mobileMenu()}
      <div className="conTable">
 
 <div style={{backgroundColor:'white'}}>
    
 <div className='topMenu'>
 <div style={{display:'flex',justifyContent:'center'}}>{this.renderLanguages()}</div>
  
 <div style={{display:'flex',justifyContent:'center'}}><img onError={()=>this.setState({showAdd:true})}  src={require('../../assets/abb_logo.png')} className='abb_logo'/></div>
    
  
         
          
           </div>
   </div>
    
   <div className='headText'> <h1 >
                       
                        
                      {this.state.heading}
                      
                      </h1>
  </div> 
<div className='mainDiv' style={{marginTop:'-1vw'}}>
 
<div className='window' style={{height: '75vh'}}>
{this.renderData()}  
</div>
 <div className='mobile' style={{marginTop:'8%',borderBottomStyle:'solid',borderBottomWidth:1,
 borderBottomColor:'#d2d2d2',width:'100%',height: '81vh'}}>
   {this.renderDataMobile()}
 </div> 

</div>  
    
   

</div>
 

      
   
     




{this.renderoverLay()}
    {this.renderlangModal()}
 {this.renderExitModal()}
 {this.showUpdateBar()}
               </div>
              
    );

    
  }
}
export default Overview