import React, { Component } from "react";
import ReactTooltip from 'react-tooltip'
import { MDBNavbar, MDBDropdownItem,MDBDropdownMenu,  MDBIcon, MDBTable, MDBTableBody, MDBTableHead, MDBBtn,MDBRow,
  MDBCol,
  MDBDropdown,
  MDBDropdownToggle,
  MDBCardHeader, MDBModal, MDBModalBody, MDBInput, MDBModalFooter,MDBCollapse,MDBPopover, MDBPopoverBody, MDBTooltip,  } from 'mdbreact';
  import dashboardRoutes from "../../routes/dashboard";
  import swal from 'sweetalert'
  import { Grid, Row, Col,Image,ListGroup,ListGroupItem,Button,Form,FormControl,Checkbox,ControlLabel, } from "react-bootstrap";
import './value.css'
import Select from 'react-select'
import CryptoJS from 'crypto'
import ScrollAnimation from 'react-animate-on-scroll';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { HalfCircleSpinner } from 'react-epic-spinners'
   
class Value extends Component {
  constructor(props) {
    super(props);
  this.state={
    loading:false,info_heads:[],add_head:false,clients:[],
    new_name:"",new_des:"",new_hsn:"",new_type:"Select Cost Type",selected_id:"",
    selected_language:[],users:[],new_pass:'',add_user:false,
    new_name:'',new_email:'',new_password:'',edit_name:'',edit_email:'',edit_password:'',
    languages:[],new_user_languages:[],edit_user:false,user_to_edit:null,
    edit_user_languages:[],superadmin:false,admin:false,super_pass:'',
    stronger:true,greener:false,smarter:false,languages:[],exit:false,
    strongerText:'Stronger',smarterText:'Smarter',greenerText:'Greener',data:[{"heading":"Power quality offering from ABB","subheading":"Ensuring stronger, smarter, greener grids"}],strongData:[],smartData:[],greenData:[],logoutText:'Logout',nextText:'Footprint',edit_content:'Edit Content',edit_strong:'',showAdded:false,
    strongerhead1:'',stronghead2:'',strongervalue1:'',strongervalue2:'',
    smarterhead1:'',smarterhead2:'',smartervalue1:'',smartervalue2:'',
    greenerhead1:'',greenerhead2:'',greenervalue1:'',greenervalue2:'',mobileMenu:false,showExit:false,all_data:[],sidebar:[]
  }
  }

  exitApp(){
    var userAgent = navigator.userAgent.toLowerCase();
  if (userAgent.indexOf(' electron/') > -1) {
    const remote = window.require('electron').remote
    let w = remote.getCurrentWindow()
    w.close()
  }
  
  
  }
  reload = ()=>{
    const current = this.props.location.pathname;
    this.props.history.replace(`/reload`);
       setTimeout(() => {
         this.props.history.replace(current);
       });
   }
  showUpdateBar() {
    if(this.state.showAdded){
      
      
        return(
          <ScrollAnimation animateOnce={false}   animateIn="fadeIn"  className='fadeIn toast2' animationOut="fadeOut" 
           isVisible={true} >
         <div className="snack">Go online and <text onClick={()=>this.reload()} style={{cursor:'pointer',textDecoration:'underline'}}>click here</text> to refresh</div>
      </ScrollAnimation>
      
         
        )
      
   
    }
    }
    trigger() {

      setTimeout(() => { 
          this.setState({
              showAdded: false
          })
      }, 5000);
    }
     renderExitModal(){
  
  
     
      if(this.state.exit==true){
    
    
      return(
        <MDBModal size='md' isOpen={this.state.exit} centered toggle={this.toggleExit} >
     
           
     <MDBModalBody style={{    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',flexDirection:'column',paddingTop:'4vh',paddingBottom:'4vh'}}>
              <img onError={()=>this.setState({showAdded:true})} alt='Loading...' src={require('../../assets/Exit.jpg')} className='catGif'/>
              <p>Are you sure you want to exit this application?</p>
           <div style={{display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-evenly'}}>      <text onClick={()=>this.exitApp()} style={{backgroundColor:'#ff000f',color:'white'}} className='seriesText'>Exit</text>

              
              <text onClick={()=>this.setState({exit:false})} className='seriesText'>Cancel</text>

         
            </div>
             
              </MDBModalBody>
  
          </MDBModal>
      )
    }
    }
    
    toggleExit = () => {
     
      this.setState({
        exit: !this.state.exit,
       });
    }
  componentWillMount(){
    this.getuserdata()

  }
  componentDidMount(){
    var userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf(' electron/') > -1) {
       this.setState({showExit:true})
    }
  }
  renderExitButton(){
    if(this.state.showExit){
     return(<div
       className="sideInActive"
       
        onClick={()=>console.log('exit')}                
       >
       <img onError={()=>this.setState({showAdded:true})} src={require('../../assets/Exit.jpg')} style={{width:35}}/>
       <text style={{marginTop:5}}>Exit</text>
       </div>)
    }
   
   }
  getuserdata=async()=>{
    this.setState({
      loading:true
    })
  
 

var responseJson=[{"language":"English","language_id":1,"active":0,"key":1,"label":"English",selected:0},
{"language":"German","language_id":2,"active":0,"key":2,"label":"German",selected:0},
{"language":"Chinese","language_id":3,"active":0,"key":3,"label":"Chinese",selected:0},
{"language":"Spanish","language_id":4,"active":0,"key":4,"label":"Spanish",selected:0},
{"language":"French","language_id":5,"active":0,"key":5,"label":"French",selected:0}]

  for(var v=0;v<responseJson.length;v++){
    responseJson[v].label=responseJson[v].language
    responseJson[v].value=responseJson[v].language_id
  }
  var defaultLanguage=window.localStorage.getItem('admindefault')
  if(defaultLanguage==null){
    responseJson[0].selected=1
   this.setState({
     languages:responseJson,
     selected_language:responseJson[0]
   },()=>this.getContent())
  }
  else{
    if(JSON.parse(defaultLanguage).language=='German' || JSON.parse(defaultLanguage).language=='Deutsche' ||
    JSON.parse(defaultLanguage).language=='德语' || JSON.parse(defaultLanguage).language=='alemán' || JSON.parse(defaultLanguage).language=='allemand'){
     responseJson[0].language='Englisch'
     responseJson[1].language='Deutsche'
     responseJson[2].language='Chinesisch'
     responseJson[3].language='Spanisch'
     responseJson[4].language='Französisch'
     this.setState({logoutText:'Ausloggen',nextText:'Fußabdruck',edit_content:'Inhalt bearbeiten'})

    }
    else if(JSON.parse(defaultLanguage).language=='Chinese' || JSON.parse(defaultLanguage).language=='Chinesisch' ||
    JSON.parse(defaultLanguage).language=='中文' || JSON.parse(defaultLanguage).language=='chino' || JSON.parse(defaultLanguage).language=='chinois'){
    
      responseJson[0].language='英语'
      responseJson[1].language='德语'
      responseJson[2].language='中文'
      responseJson[3].language='西班牙语'
      responseJson[4].language='法国'
      this.setState({logoutText:'登出',nextText:'脚印',edit_content:'编辑内容'})

 
     }
     else if(JSON.parse(defaultLanguage).language=='Spanish' || JSON.parse(defaultLanguage).language=='Spanisch' ||
     JSON.parse(defaultLanguage).language=='西班牙语' || JSON.parse(defaultLanguage).language=='Español' || JSON.parse(defaultLanguage).language=='Espanol'){
      responseJson[0].language='Inglés'
      responseJson[1].language='alemán'
      responseJson[2].language='chino'
      responseJson[3].language='Español'
      responseJson[4].language='francés'
     
      this.setState({logoutText:'Cerrar sesión',nextText:'Huella',edit_content:'Contenido editado'})

 
     }
     else if(JSON.parse(defaultLanguage).language=='French' || JSON.parse(defaultLanguage).language=='Französisch' ||
     JSON.parse(defaultLanguage).language=='法国' || JSON.parse(defaultLanguage).language=='francés' || JSON.parse(defaultLanguage).language=='français'){
      responseJson[0].language='Anglais'
      responseJson[1].language='allemand'
      responseJson[2].language='chinois'
      responseJson[3].language='Espanol'
      responseJson[4].language='français'
       
    
      this.setState({logoutText:'Connectez - Out',nextText:'Empreinte',edit_content:'Modifier le contenu'})

 
     }
    console.log(JSON.parse(defaultLanguage),'selected_language')
    responseJson[JSON.parse(defaultLanguage).key-1].selected=1
   this.setState({
     languages:responseJson,
     selected_language:JSON.parse(defaultLanguage)
   },()=>this.getContent())
  }
  
 }

   
   
   
  openSmart(){
    this.setState({
      smarter:true,
      stronger:false,
      greener:false
    })
  }
  openGreen(){
    this.setState({
      smarter:false,
      stronger:false,
      greener:true
    })
  }
  openStrong(){
    this.setState({
      smarter:false,
      stronger:true,
      greener:false
    })
  }
    getDetails(){
      var userlogin=window.localStorage.getItem('userlogin')
      var adminlogin=window.localStorage.getItem('superadmin')
      if(userlogin==null && adminlogin==null){
        this.props.history.push('products')
      }
      else if(userlogin==null && adminlogin!=null){
        this.setState({superadmin:true})
      }
      else if(userlogin!=null && adminlogin==null){
     this.logout()
      }
        }
  toggleAdd = () => {
   
    this.setState({
      add_user: !this.state.add_user,
     });
  }
  toggleEdit = () => {
   
    this.setState({
      edit_user: !this.state.edit_user,
     });
  }
  openEditModal(data){
    
    this.setState({user_to_edit:data,
    edit_name:data.admin_username,
    edit_email:data.email,
    edit_password:data.password,
    edit_user_languages:JSON.parse(data.language_access)


    },this.toggleEdit)
  }
 



  

  selectLanguage(data,key){
    window.localStorage.removeItem('admindefault')
      window.localStorage.setItem('admindefault',JSON.stringify(data))
   
this.setState({
  selected_language:data
},()=>window.location.reload())
  }
  


  
 
  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }
  getSidebar=async()=>{
         
    if(!navigator.onLine){
var offline_content=window.localStorage.getItem('sidebar')
if(offline_content==null){

 this.setState({
   loading:false
 })
}
else{
 console.log(JSON.parse(JSON.parse(offline_content).content),'cccc')
 dashboardRoutes[0].name=JSON.parse(JSON.parse(offline_content).content)[0].name
 dashboardRoutes[1].name=JSON.parse(JSON.parse(offline_content).content)[1].name
 dashboardRoutes[2].name=JSON.parse(JSON.parse(offline_content).content)[2].name
 dashboardRoutes[3].name=JSON.parse(JSON.parse(offline_content).content)[4].name
 dashboardRoutes[4].name=JSON.parse(JSON.parse(offline_content).content)[5].name
 dashboardRoutes[5].name=JSON.parse(JSON.parse(offline_content).content)[6].name
 this.setState({
   sidebar:JSON.parse(offline_content).content,
 loading:false
 })
}
    }
    else{
     this.setState({loading:true})
     let details = {
       language_id:this.state.selected_language.language_id
     };
     let formBody = [];
     for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
     }
     formBody = formBody.join("&");
     this.setState({
       response: fetch('https://www.mobilitysolution.info/get_sidebar', {
         method: 'POST',
         headers: {
          
           'Content-Type': 'application/x-www-form-urlencoded',
           'Cache-Control': 'max-age=31536000'
       },
     body:formBody
         
       }).then((response) => response.json())
       .then((responseJson)=>{
         console.log(JSON.parse(responseJson.content),'sidebar')
        window.localStorage.removeItem('sidebar')
        window.localStorage.setItem('sidebar',JSON.stringify(responseJson))
dashboardRoutes[0].name=JSON.parse(responseJson.content)[0].name
dashboardRoutes[1].name=JSON.parse(responseJson.content)[1].name
dashboardRoutes[2].name=JSON.parse(responseJson.content)[2].name
dashboardRoutes[3].name=JSON.parse(responseJson.content)[4].name
dashboardRoutes[4].name=JSON.parse(responseJson.content)[5].name
dashboardRoutes[5].name=JSON.parse(responseJson.content)[6].name
         this.setState({
           sidebar:(responseJson.content).sidebar,
 loading:false
         })
           })
           .catch((error) => {
            var offline_content=window.localStorage.getItem('sidebar')
            if(offline_content==null){
            
             this.setState({
               loading:false
             })
            }
            else{
             console.log(JSON.parse(JSON.parse(offline_content).content),'cccc')
             dashboardRoutes[0].name=JSON.parse(JSON.parse(offline_content).content)[0].name
             dashboardRoutes[1].name=JSON.parse(JSON.parse(offline_content).content)[1].name
             dashboardRoutes[2].name=JSON.parse(JSON.parse(offline_content).content)[2].name
             dashboardRoutes[3].name=JSON.parse(JSON.parse(offline_content).content)[4].name
             dashboardRoutes[4].name=JSON.parse(JSON.parse(offline_content).content)[5].name
             dashboardRoutes[5].name=JSON.parse(JSON.parse(offline_content).content)[6].name
             this.setState({
               sidebar:JSON.parse(offline_content).content,
             loading:false
             })
            }
               })
              })
    }
             }
  getContent=async()=>{
    if(!navigator.onLine){
      var offline_content=window.localStorage.getItem('value')
      if(offline_content==null){
        swal('Warning','Check your network and refresh','warning')
        this.setState({
          loading:false
        })
      }
      else{
        console.log(offline_content)
        this.setState({
                
          strongData:JSON.parse(JSON.parse(offline_content).stronger),
          smartData:JSON.parse(JSON.parse(offline_content).smarter),
          greenData:JSON.parse(JSON.parse(offline_content).greener),
          data:JSON.parse(JSON.parse(offline_content).heading),
          strongerText:JSON.parse(JSON.parse(offline_content).headers).stronger,
           smarterText:JSON.parse(JSON.parse(offline_content).headers).smarter,
           all_data:JSON.parse(offline_content),
                   loading:false
         })
      }
      this.getSidebar()
           }
           else{
            this.setState({loading:true})
            let details = {
              language_id:this.state.selected_language.language_id
            };
            let formBody = [];
            for (let property in details) {
             let encodedKey = encodeURIComponent(property);
             let encodedValue = encodeURIComponent(details[property]);
             formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");
            console.log(details)
            this.setState({
              response: fetch('https://www.mobilitysolution.info/get_content_value', {
                method: 'POST',
                headers: {
                 
                  'Content-Type': 'application/x-www-form-urlencoded',
                  'Cache-Control': 'max-age=31536000'
              },
            body:formBody
                
              }).then((response) => response.json())
              .then((responseJson)=>{
                window.localStorage.removeItem('value')
                window.localStorage.setItem('value',JSON.stringify(responseJson))
              console.log(JSON.parse(responseJson.greener))
                this.setState({
                
                 strongData:JSON.parse(responseJson.stronger),
                 smartData:JSON.parse(responseJson.smarter),
                 greenData:JSON.parse(responseJson.greener),
                 strongerText:JSON.parse(responseJson.headers).stronger,
                 smarterText:JSON.parse(responseJson.headers).smarter,
                 greenerText:JSON.parse(responseJson.headers).Greener,
                 data:JSON.parse(responseJson.heading),
                 all_data:responseJson,
                 loading:false
                })
                this.getSidebar()
                  })
                  .catch((error) => {
                    var offline_content=window.localStorage.getItem('value')
      if(offline_content==null){
        swal('Warning','Check your network and refresh','warning')
        this.setState({
          loading:false
        })
      }
      else{
        console.log(offline_content)
        this.setState({
                
          strongData:JSON.parse(JSON.parse(offline_content).stronger),
          smartData:JSON.parse(JSON.parse(offline_content).smarter),
          greenData:JSON.parse(JSON.parse(offline_content).greener),
          data:JSON.parse(JSON.parse(offline_content).heading),
          strongerText:JSON.parse(JSON.parse(offline_content).headers).stronger,
           smarterText:JSON.parse(JSON.parse(offline_content).headers).smarter,
           all_data:JSON.parse(offline_content),
                   loading:false
         })
      }
      this.getSidebar()
                  console.log(error)
                      })
                     })
           }
  
        }
        renderLanguages(){

          if(this.state.loading==false){
            if(this.state.languages.length>0){
              return(<div  
            
                 
                
                style={{backgroundColor:'rgb(255, 255, 255, 0.1)',cursor:'pointer',display:'flex',alignItems:'center',marginRight:100,marginTop:'.5%'}}>
              <MDBIcon size="lg" style={{marginLeft:10,marginRight:20}}   className="grey-text menuBar" icon="bars" onClick={()=>this.setState({mobileMenu:!this.state.mobileMenu})}/>
              <MDBIcon size="lg" style={{marginLeft:10}} onClick={()=>this.setState({lang_mod:true})}   className="grey-text" icon="globe-africa" />
              <MDBDropdown className='languageDiv' >
             <MDBDropdownToggle caret color="#004080" size="md" >
             <text className='languageText'>{this.state.languages[this.state.selected_language.key-1].language}</text>
             </MDBDropdownToggle>
             <MDBDropdownMenu style={{margin:0,padding:0,borderRadius:0}}> 
             <MDBDropdownItem style={{backgroundColor:'#d2d2d2',color:'black',textAlign:'center',padding:12}}  >ENGLISH</MDBDropdownItem>
             {/* { (this.state.languages).map((data,key) => {
          
        if(data.selected==0){
        return ( 
          <MDBDropdownItem style={{backgroundColor:'#fff',color:'#6e6e6e',textAlign:'center',padding:12}} className='dropButton'  onClick={()=>this.selectLanguage(data)}>{data.language}</MDBDropdownItem>
              
               )
        }
        else{
        return ( 
          <MDBDropdownItem style={{backgroundColor:'#d2d2d2',color:'black',textAlign:'center',padding:12}}  >{data.language}</MDBDropdownItem>
              
               )
        }
          })
          } */}
          </MDBDropdownMenu>
          </MDBDropdown>
            
                </div>)
             }
           
          }
             
            }
     
    
       
      


openSwal(data,key){
  swal({
    text: 'Enter admin password',
    content: "input",
    button: {
      text: "Enter!",
      closeModal: true,
    },
  })
  .then(name => {
  if(name=='super@aaa'){
    let {users} = this.state
    let targetuser = users[key]
    targetuser.show_pass=true
    this.setState({users})
  }
  else{
    swal('Warning','Incorrect Password','warning')
  }
  })
  
 .catch(err => {
    console.log(err)
  });
}
renderPassword(data,key){
 if(data.show_pass==true){
   return( <td style={{textAlign:'center',fontSize:20}}>{data.password}    <MDBIcon  icon="edit" onClick={()=>this.openEditModal(data)} /></td>)
 }
 else if(data.show_pass==false){
  return( <td style={{textAlign:'center',fontSize:20}}>*********    <MDBIcon icon="eye" onClick={()=>this.openSwal(data,key)} />   <MDBIcon  icon="edit" onClick={()=>this.openEditModal(data)} /> </td>)
 }
}

  renderList(){

    if(this.state.loading){
      
      return(<div className="loadingSpin"><HalfCircleSpinner color="#ff000f"/></div>)
    }
    else{
      return (
        <div className="wrapper">
  
        
        
        <MDBTable striped responsive>
            <MDBTableHead style={{backgroundColor:'#FF000F'}}>
  
            <tr >
        <th style={{color:'white',textAlign:'center',fontWeight:'bold'}}>#</th>
        <th style={{color:'white',textAlign:'center',fontWeight:'bold'}}>Name</th>
        <th style={{color:'white',textAlign:'center',fontWeight:'bold'}}>Email</th>
        <th style={{color:'white',textAlign:'center',fontWeight:'bold'}}>Language Access</th>
        <th style={{color:'white',textAlign:'center',fontWeight:'bold'}}>Password</th>
      </tr>
            </MDBTableHead>
  
            <MDBTableBody>
  
            { this.state.users.map((data,key) => {
  
  return (   
  <tr key={key}>
  <td style={{textAlign:'center'}}>{key+1}</td>
  <td style={{textAlign:'center'}}>{data.admin_username}</td>
  <td style={{textAlign:'center'}}>{data.email}</td>

 {this.renderPassword(data,key)}

  
       
  </tr>     )
  
  
  })}
  
  
            </MDBTableBody>
  
  
          </MDBTable>
        
        
        </div>
      )
    }
   


  }



 


  returnTitle(){
if(this.state.selected_id===""){return(<text>Add Head</text>)}
else{return(<text>Edit Head</text>)}

  }


  logout(){
    window.localStorage.clear()
window.location.reload()

  }

handleChangenewname=(e)=>{
  this.setState({new_name:e.target.value});
}
handleChangenewemail=(e)=>{
  this.setState({new_email:e.target.value});
}
handleChangenew_password=(e)=>{
  this.setState({new_password:e.target.value});
}
handleChangeeditname=(e)=>{
  this.setState({edit_name:e.target.value});
}
handleChangeeditemail=(e)=>{
  this.setState({edit_email:e.target.value});
}
handleChangeedit_password=(e)=>{
  this.setState({edit_password:e.target.value});
}
handleChangesuper_password=(e)=>{
  this.setState({super_pass:e.target.value});
}
renderStronger(){
  console.log()
if(this.state.loading==false && this.state.strongData.length!=0){
  if(this.state.stronger){
    return(
      <div className='dataWindow'>
      <img onError={()=>this.setState({showAdded:true})} alt='Loading...' className='imgB'   src={this.state.all_data.str_image} /> 
      <div className='overlay' id='overlay'>
      <div className='overlay-content'>
     
      
        </div>
        <div className='overlayTextStrong'>

      
      <h2 className='value' style={{marginLeft:'10%',color:'white'}}>{this.state.strongerText}</h2>
      <br/>
      
      <p style={{fontSize:20,marginLeft:'10%',fontFamily:'ABBvoice-Bold',marginBottom:1}}>{this.state.strongData[0].heading}</p>
      
      <text className="data">{this.state.strongData[0].value}</text>
     
      
      <p style={{fontSize:20,marginLeft:'10%',fontFamily:'ABBvoice-Bold',marginBottom:1}}>{this.state.strongData[1].heading}</p>
      
      <text style={{marginBottom:60}} className="data">{this.state.strongData[1].value}</text>
      
      </div>
        <div className='other' onClick={()=>this.openSmart()}>
      
      
      <h2 className='blackText' style={{fontSize:30,marginLeft:20,color:'black',fontFamily:'ABBvoice-Bold',cursor:'pointer'}}>{this.state.smarterText}</h2>
      
      
      </div>
      <div className='other2' onClick={()=>this.openGreen()}>
     
      
      <h2 className='blackText' style={{fontSize:30,marginLeft:20,color:'black',fontFamily:'ABBvoice-Bold',cursor:'pointer'}}>{this.state.greenerText}</h2>
      
      </div>
      
      </div>
 
      </div>
    )
  }
  else if(this.state.smarter){
    return(
      <div className='dataWindow'>
      <img onError={()=>this.setState({showAdded:true})} alt='Loading...' src={this.state.all_data.smart_image} className='imgB'  /> 
      <div className='overlay'>
     
        <div className='other' onClick={()=>this.openStrong()}>
    
      
      <h2 className='value' style={{fontSize:30,marginLeft:'10%',color:'white',fontFamily:'ABBvoice-Bold',cursor:'pointer'}}>{this.state.strongerText}</h2>
      
      
      </div>
      
      <div className='overlay-content'>
      
        </div>
        <div style={{display:'flex',flexDirection:'column',justifyContent:'flex-end',alignItems:'flex-start',height:'100%',color:'white',position:'absolute',marginLeft:'33%',width:'25vw'}}>
      
      <h2 className='value' style={{marginLeft:'10%',color:'white'}}>{this.state.smarterText}</h2>
  
      <br/>
      
     <p style={{fontSize:20,marginLeft:'10%',fontFamily:'ABBvoice-Bold',marginBottom:1}}>{this.state.smartData[0].heading}</p>
      
      <text className="data">{this.state.smartData[0].value}</text>
 
      
      <p style={{fontSize:20,marginLeft:'10%',fontFamily:'ABBvoice-Bold',marginBottom:1}}>{this.state.smartData[1].heading}</p>
      
      <text style={{marginBottom:60}} className="data">{this.state.smartData[1].value}</text>
      
      </div>
     
      <div className='other2' onClick={()=>this.openGreen()}>
    
      
      <h2 className='value' style={{fontSize:30,marginLeft:'10%',color:'white',fontFamily:'ABBvoice-Bold',cursor:'pointer'}}>{this.state.greenerText}</h2>
      
      </div>
      
      </div>
   
      </div>
    )

  }
  else if(this.state.greener)
 {
  return(
    <div  className='dataWindow'>
    <img onError={()=>this.setState({showAdded:true})} alt='Loading...' src={this.state.all_data.green_image} className='imgB'   /> 
    <div className='overlay' >
    
      <div className='other' onClick={()=>this.openStrong()}>
   
    
    <h2 className='value' style={{fontSize:30,marginLeft:'10%',color:'white',cursor:'pointer'}}>{this.state.strongerText}</h2>
    
    
    </div>
    <div className='other2' onClick={()=>this.openSmart()}>
    <text style={{fontSize:30,marginLeft:'10%',color:'white',cursor:'pointer'}}>
    —
    </text>
    
    <text style={{fontSize:30,marginLeft:'10%',color:'white',fontFamily:'ABBvoice-Bold',cursor:'pointer'}}>{this.state.smarterText}</text>
    
    </div>
    <div className='overlay-content'>
    
      </div>
      <div style={{display:'flex',flexDirection:'column',justifyContent:'flex-end',alignItems:'flex-start',height:'100%',color:'white',position:'absolute',marginLeft:'61vw'}}>
       
      <h2 className='value' style={{marginLeft:'10%',color:'white',fontFamily:'ABBvoice-Bold'}}>{this.state.greenerText}</h2>
  
    <br/>
    
     <p style={{fontSize:20,marginLeft:'10%',fontFamily:'ABBvoice-Bold',marginBottom:1}}>{this.state.greenData[0].heading}</p>
    
    <text className="data">{this.state.greenData[0].value}</text>
   
    
    <p style={{fontSize:20,marginLeft:'10%',fontFamily:'ABBvoice-Bold',marginBottom:1}}>{this.state.greenData[1].heading}</p>
    
    <text style={{marginBottom:60}} className="data">{this.state.greenData[1].value}</text>
    
    </div>
   
    </div>
   
    </div>
  )
 }
}

}
renderStrongerMobile(){
  console.log(this.state.strongData)
if(this.state.loading==false && this.state.strongData.length!=0){
 
return(
  <Carousel showThumbs={false} showStatus={false} showArrows={false}>
  <div className='dataMobile'>
<img onError={()=>this.setState({showAdded:true})} alt='Loading...' className='imgB'   src={this.state.all_data.str_mobile} /> 


  <div className='valueDataMobile'>


<h2 className='value' style={{color:'white'}}>{this.state.strongerText}</h2>


<p style={{fontSize:15,fontFamily:'ABBvoice-Bold',marginBottom:1}}>{this.state.strongData[0].heading}</p>

<text className="dataMobile" >{this.state.strongData[0].value}</text>


<p style={{fontSize:15,fontFamily:'ABBvoice-Bold',marginBottom:1}}>{this.state.strongData[1].heading}</p>

<text  className="dataMobile">{this.state.strongData[1].value}</text>

</div>



</div>


<div className='dataMobile'>
<img onError={()=>this.setState({showAdded:true})} alt='Loading...' src={this.state.all_data.smart_mobile} className='imgB'  /> 




  <div className='valueDataMobile'>

<h2 className='value' style={{color:'white'}}>{this.state.smarterText}</h2>

<br/>

<p style={{fontSize:15,fontFamily:'ABBvoice-Bold',marginBottom:1}}>{this.state.smartData[0].heading}</p>

<text className="dataMobile">{this.state.strongData[0].value}</text>


<p style={{fontSize:15,fontFamily:'ABBvoice-Bold',marginBottom:1}}>{this.state.strongData[1].heading}</p>

<text  className="dataMobile">{this.state.strongData[1].value}</text>

</div>



</div>


<div  className='dataMobile'>
<img onError={()=>this.setState({showAdded:true})} alt='Loading...' src={this.state.all_data.green_mobile} className='imgB'   /> 



<div className='valueDataMobile'>
 
<h2 className='value' style={{color:'white',fontFamily:'ABBvoice-Bold'}}>{this.state.greenerText}</h2>

<br/>

<p style={{fontSize:15,fontFamily:'ABBvoice-Bold',marginBottom:1}}>{this.state.greenData[0].heading}</p>

<text className="dataMobile">{this.state.greenData[0].value}</text>


<p style={{fontSize:15,fontFamily:'ABBvoice-Bold',marginBottom:1}}>{this.state.greenData[1].heading}</p>

<text  className="dataMobile">{this.state.greenData[1].value}</text>

</div>

</div>


</Carousel>
)
}

}
renderDetails(){
  var style={opacity:1,backgroundColor:'#fff',height:'100vh',borderStyle:'solid',borderColor:'#d2d2d2',borderWidth:1,
    overflow:'auto',padding:'2.5%',display:'flex',flexDirection:'column',alignItems:'flex-start'}


  if(this.state.edit){



  return(
   
    <div id="dashsidebar"    ref={ref => { this['dashsidebar'] = ref }}
    className="dashsidebar">
     <ScrollAnimation animateOnce={false} animateIn="slideInLeft"  className='slideInLeft' animationOut="slideOutLeft" 
     isVisible={true} style={style}>
    <div style={{backgroundColor:'#fff',display:'flex',justifyContent:'space-between',paddingRight:10,width:'100%',alignItems:'center'}}>
    <h3 >
                 
                  
                 {this.state.edit_content}
                </h3>
  <text onClick={()=>this.setState({edit:false})} className='arrow'></text></div>
 
             
            <div style={{paddingLeft:5,width:'100%'}}>
              
           <div className='editHead'><text>{this.state.strongerText}</text></div>
<MDBInput
            
            group
            style={{fontWeight:'bold'}}
            type="text"
            value={this.state.strongerhead1} 
        onChange={(text)=>this.setState({strongerhead1:text.target.value})}
            error="wrong"
            success="right"
        />
        <MDBInput
            
            group
        
            type="text"
            value={this.state.strongervalue1} 
        onChange={(text)=>this.setState({strongervalue1:text.target.value})}
            error="wrong"
            success="right"
        />
<MDBInput
            
            group
            style={{fontWeight:'bold'}}
            type="text"
            value={this.state.strongerhead2} 
        onChange={(text)=>this.setState({strongerhead2:text.target.value})}
            error="wrong"
            success="right"
        />
        <MDBInput
            
            group
        
            type="text"
            value={this.state.strongervalue2} 
        onChange={(text)=>this.setState({strongervalue2:text.target.value})}
            error="wrong"
            success="right"
        />
         
</div>
        <div style={{paddingLeft:5,width:'100%'}}>
              
            
           <div className='editHead'><text>{this.state.smarterText}</text></div>
                <MDBInput
                 
                 group
                 style={{fontWeight:'bold'}}
                 type="text"
                 value={this.state.smarterhead1} 
             onChange={(text)=>this.setState({smarterhead1:text.target.value})}
                 error="wrong"
                 success="right"
             />
             <MDBInput
                 
                 group
             
                 type="text"
                 value={this.state.smartervalue1} 
             onChange={(text)=>this.setState({smartervalue1:text.target.value})}
                 error="wrong"
                 success="right"
             />
     <MDBInput
                 
                 group
                 style={{fontWeight:'bold'}}
                 type="text"
                 value={this.state.smarterhead2} 
             onChange={(text)=>this.setState({smarterhead2:text.target.value})}
                 error="wrong"
                 success="right"
             />
             <MDBInput
                 
                 group
             
                 type="text"
                 value={this.state.smartervalue2} 
             onChange={(text)=>this.setState({smartervalue2:text.target.value})}
                 error="wrong"
                 success="right"
             />
              
     </div>
     <div style={{paddingLeft:5,width:'100%'}}>
              
           <div className='editHead'><text>{this.state.greenerText}</text></div>
     <MDBInput
                 
                 group
             style={{fontWeight:'bold'}}
                 type="text"
                 value={this.state.greenerhead1} 
             onChange={(text)=>this.setState({greenerhead1:text.target.value})}
                 error="wrong"
                 success="right"
             />
             <MDBInput
                 
                 group
             
                 type="text"
                 value={this.state.greenervalue1} 
             onChange={(text)=>this.setState({greenervalue1:text.target.value})}
                 error="wrong"
                 success="right"
             />
     <MDBInput
                 
                 group
                 style={{fontWeight:'bold'}}
                 type="text"
                 value={this.state.greenerhead2} 
             onChange={(text)=>this.setState({greenerhead2:text.target.value})}
                 error="wrong"
                 success="right"
             />
             <MDBInput
                 
                 group
             
                 type="text"
                 value={this.state.greenervalue2} 
             onChange={(text)=>this.setState({greenervalue2:text.target.value})}
                 error="wrong"
                 success="right"
             />
              
     </div>
 <div style={{width:'100%',display:'flex',justifyContent:'flex-end'}}><text style={{fontWeight:'bold'}}  onClick={()=>this.updateContent()} className='arrowRed'></text>
 </div>            
              
</ScrollAnimation>

</div>

   
  )

 
}
}

updateContent=async()=>{
   
  this.setState({loading:true})
   let details = {
     language_id:this.state.selected_language.language_id,
    stronger:JSON.stringify([{"heading":this.state.strongerhead1,"value":this.state.strongervalue1},{"heading":this.state.strongerhead2,"value":this.state.strongervalue2}]),
    smarter:JSON.stringify([{"heading":this.state.smarterhead1,"value":this.state.smartervalue1},{"heading":this.state.smarterhead2,"value":this.state.smartervalue2}]),
    greener:JSON.stringify([{"heading":this.state.greenerhead1,"value":this.state.greenervalue1},{"heading":this.state.greenerhead2,"value":this.state.greenervalue2}]),
   
   };
   let formBody = [];
   for (let property in details) {
    let encodedKey = encodeURIComponent(property);
    let encodedValue = encodeURIComponent(details[property]);
    formBody.push(encodedKey + "=" + encodedValue);
   }
   formBody = formBody.join("&");
   console.log(details)
   this.setState({
     response: fetch('https://www.mobilitysolution.info/update_content_value', {
       method: 'POST',
       headers: {
        
         'Content-Type': 'application/x-www-form-urlencoded',
         'Cache-Control': 'max-age=31536000'
     },
   body:formBody
       
     }).then((response) => response.json())
     .then((responseJson)=>{
       console.log(responseJson)
      window.location.reload()
         })
         .catch((error) => {
           this.setState({
             loading:false
           })
           swal("Warning!", "Check your network!", "warning");
         console.log(error)
             })
            })
       }
       renderoverLay(){
        if(this.state.mobileMenu){
        
          return (<div id='overlayCat' onClick={()=>this.setState({mobileMenu:false})}></div>)
        }
      }
      mobileMenu(){
        var style={opacity:1,backgroundColor:'#fff',height:'100vh',borderStyle:'solid',borderColor:'#d2d2d2',borderWidth:1,
        overflow:'auto',padding:'2.5%',display:'flex',flexDirection:'column',alignItems:'flex-start',paddingLeft:10,paddingRight:10}
      
       
        if(this.state.mobileMenu){
          return(
            <div className='mobileMenu'>
              <ScrollAnimation animateOnce={false}  animateIn="slideInLeft"  className='slideInLeft' animationOut="slideOutLeft" 
           isVisible={true}  style={style}>
               {dashboardRoutes.map((prop, key) => {
                        if(prop.path=='/value'){
                          return (
                           
                           <div
                           key={key}
            className="sideActiveMobile"
            
             onClick={()=>this.props.history.push(`${prop.path}`)}                
            >
            <img onError={()=>this.setState({showAdded:true})} alt='Loading...' src={require('../../assets/navbar/Value_Proposition.png')} alt='Loading...' style={{width:20,marginRight:10}}/>
            
            <text style={{marginTop:5,fontSize:12}}>{prop.name}</text>
            </div>
                           
                         );
                         return null;
                        }
                        else{
                          return (
                           
                           <div
            className="sideInActiveMobile"
            
             onClick={()=>this.props.history.push(`${prop.path}`)}                
            >
            <img onError={()=>this.setState({showAdded:true})} alt='Loading...' src={require('../../assets/navbar/'+prop.icon)} style={{width:20,marginRight:10}}/>
            <text style={{marginTop:5,fontSize:12}}>{prop.name}</text>
            </div>
                           
                         );
                         return null; 
                        }
                       
      
                      })}
                    
                      </ScrollAnimation>
            </div>
          )
        }
      }
      toggleLang = () => {
   
        this.setState({
          lang_mod: !this.state.lang_mod,
         });
      }
      renderlangModal(){


   
        if(this.state.lang_mod==true){
      
      
        return(
          <MDBModal className='mobileLang' size='md' isOpen={this.state.lang_mod} toggle={this.toggleLang} centered>
       
             
                  <MDBModalBody>
                  {/* { (this.state.languages).map((data,key) => {
          
          if(data.selected==0){
           return ( 
             <MDBDropdownItem style={{backgroundColor:'#fff',color:'#6e6e6e',textAlign:'center',padding:12}} className='dropButton'  onClick={()=>this.selectLanguage(data)}>{data.language}</MDBDropdownItem>
                 
                  )
          }
          else{
           return ( 
             <MDBDropdownItem style={{backgroundColor:'#d2d2d2',color:'black',textAlign:'center',padding:12}}  >{data.language}</MDBDropdownItem>
                 
                  )
          }
             })
             } */}
             <MDBDropdownItem style={{backgroundColor:'#d2d2d2',color:'black',textAlign:'center',padding:12}}  >ENGLISH</MDBDropdownItem>
                             </MDBModalBody>
                
            </MDBModal>
        )
      }
      }
  render() {
console.log(this.state.loading)
    
    const sidebarBackground = {
      backgroundColor: "#1C2231"
    };
    
   if(!this.state.loading){
    return (
      <div className="bodyDivCl" >

   <div
      id="sidebar"
      className="sideCat"
      data-color="#1C2231"
     
    >
      <div className="sidebar-background" style={sidebarBackground} />
      
      <div className="sidebar-wrapper" style={{display:'flex',flexDirection:'column',justifyContent:'flex-start',alignItems:'center',marginTop:10}}>
      
     
      
      {dashboardRoutes.map((prop, key) => {
            if(prop.path=='/value'){
              return (
               
               <div
               key={key}
className="sideActive"

 onClick={()=>this.props.history.push(`${prop.path}`)}                
>
<img onError={()=>this.setState({showAdded:true})} alt='Loading...' src={require('../../assets/navbar/Value_Proposition_Hover.png')} style={{width:25}}/>

<text style={{marginTop:5}}>{prop.name}</text>
</div>
               
             );
             return null;
            }
            else{
              return (
               
               <div
className="sideInActive"

 onClick={()=>this.props.history.push(`${prop.path}`)}                
>
<img onError={()=>this.setState({showAdded:true})} alt='Loading...' src={require('../../assets/navbar/'+prop.icon)} style={{width:25}}/>
<text style={{marginTop:5}}>{prop.name}</text>
</div>
               
             );
             return null; 
            }
           
          })}
         {this.renderExitButton()}
      </div>
    </div>
{this.mobileMenu()}
      <div className="conTable">
      <div style={{backgroundColor:'white'}} >
    
    <div className='topMenu'>
  
    <div style={{display:'flex',justifyContent:'center'}}>{this.renderLanguages()}</div>
     
    <div style={{display:'flex',justifyContent:'center'}}><img onError={()=>this.setState({showAdded:true})} alt='Loading...' src={require('../../assets/abb_logo.png')} className='abb_logo'/></div>
       
     
            
             
              </div>
   
      </div>
   <div className='valueMain'> 
  
<h1 className='valueheading' >
                     
                      
                     {(this.state.data[0].heading)}                  
                    </h1>
                    <h1 style={{marginBottom:15}} className='valuedata' >{(this.state.data)[0].subheading}</h1>
                    {this.renderStronger()}                
                    {this.renderStrongerMobile()}                
</div>


  <div className='next'>
 <h5>{dashboardRoutes[4].name}</h5> <text onClick={()=>this.props.history.push('footprints')}  className='arrowRed'></text></div>

</div>
 

 
     




    
{this.renderlangModal()}
 {this.renderoverLay()}
    {this.renderExitModal()}
 {this.renderDetails()}
 {this.showUpdateBar()}
               </div>
              
    );
   }
else{
 
  return(<div className="loadingSpin"><HalfCircleSpinner color="#ff000f"/></div>)
 
}
    
  }
}
// const stronger=require('./Stronger.png')
// const styles = {
//   header: {
//     backgroundImage: "url("+stronger+")",
//     height: '80%',
//     width:'80%' },

//   content: {
//     height: '100%',
//     width: '30%',
//     backgroundColor: 'rgba(0, 0, 0, 0.5)',
//     color: 'white'
//   }
// }
  
// <div style={styles.header}>
// <div style={styles.content}>
//   Portfolio
//   </div>
// </div>
export default Value