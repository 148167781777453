import React, { Component } from "react";
import ReactTooltip from 'react-tooltip'
import { MDBNavbar, MDBDropdownItem,MDBInput,MDBDropdownMenu,  MDBIcon, MDBTable, MDBTableBody, MDBTableHead, MDBBtn,MDBRow,
  MDBCol,
  MDBDropdown,
  MDBDropdownToggle,
  MDBModalHeader, MDBModal, MDBModalBody,  MDBModalFooter,MDBCollapse,MDBContainer,MDBPopover, MDBPopoverBody, MDBTooltip,  } from 'mdbreact';
  import Drawer from 'react-motion-drawer';
  import swal from 'sweetalert'
  import { Grid, Row, Col,Image,MenuItem,ListGroupItem,Button,Form,FormControl,Checkbox,ControlLabel, } from "react-bootstrap";
  import url from 'url'
  import { Scrollbars } from 'react-custom-scrollbars';
import './catagories.css'
import '../../assets/css/_accordeon.css'
import Select from 'react-select'
import ColoredScrollbar from './ColoredScrollbar';
import ScrollAnimation from 'react-animate-on-scroll';
import dashboardRoutes from "../../routes/dashboard";
import { HalfCircleSpinner } from 'react-epic-spinners'

class Catagories extends Component {
  constructor(props) {
    super(props);
  this.state={
    loading:false,info_heads:[],add_head:false,clients:[],all_images:[],
    new_name:"",new_des:"",new_hsn:"",new_type:"Select Cost Type",selected_id:"",
    selected_language:{"language":"English","language_id":1,"active":0},catagories:[],hover:false,
    hover2:false,hover3:false,exit:false,
    add_catagory:false,superadmin:false,admin:false,
    selected_catagory:null,catagory_details:false,selected_catagory_data:[],
    selected_subcatagory:null,collapseID: "",collapseID2: "",collapseID3: "",collapseID4: "",show_product_list:false,
    selected_products:[{high:[],medium:[],low:[]}],show_product_details:false,
    selected_product_data:null,selected_sub_catagories:[{label:'Select Sub Catagory'}],errored:false,
  subcatagories:[],catagory_name:'',products:[],selected_picto_type:'',
  consequences:[{consequence:''}],userdata:null,languages:[],showLeaf:false,
  selected_data:[],collapseIDEdit: "",collapseIDEdit2: "",picto_type:'',selected_picto:[],selected_product_id:0,
new_image:'',hover_image:'',catagory_edit_details:false,show_picto:false,edit_name:'',groups:[],modal_width:0,second:false,
  add_catagory_data:[{name:'Enter name'}],selected_langugae:null,edit_hover_image:'',edit_image:'',
  add_sub_catagories:[{sub_catagory_name:'Select Cause',products:[{high:[{product_name:'Select Product',}],medium:[{product_name:'Select Product',}],low:[{product_name:'Select Product',}]}]}],overview:false,heading:'ABB solutions for power quality challenges',
  OEM:[],nextText:'Value Proposition',showAdded:false,
  Accessories:[],showExit:false,sub_name:'',high:false,med:false,low:false,showBanner:true,product_subs:[],sidebar:[],
  highText:'High Voltage Solutions',mediumText:'Medium Voltage Solutions',lowText:'Low Voltage Solutions',dcText:'DC Applications',abbsolText:'ABB solutions for power quality challenges',highMob:'High Voltage',mediumMob:'Medium Voltage',
  lowMob:'Low Voltage',broText:'PRODUCT BROCHURE',webText:'PRODUCT WEB PAGE',leafText:'PRODUCT LEAFLET'
  


  }
  this.myInput = React.createRef()
  this.leafdiv = React.createRef()
  this._div = React.createRef()
  }
  selectLanguage(data,key){
    window.localStorage.removeItem('admindefault')
      window.localStorage.setItem('admindefault',JSON.stringify(data))
   
this.setState({
  selected_language:data
},()=>window.location.reload())
  }

   
      toggleCollapse = collapseID => () => {

    if(this.state.collapseID==collapseID){
      this.setState({
        collapseID:0,
       
      })
    }
    else{
      this.setState({
        collapseID:collapseID,
       collapseID2:''
      })
    }
  }
  
  toggleCollapse2 = collapseID2 => () => {
    
    if(this.state.collapseID2==collapseID2){
      this.setState({
        collapseID2:''
      })
    }
    else{
      this.setState({
        collapseID2:collapseID2,
      
      })
    }
   
  }
  toggleCollapse3 = (collapseID3) => () => {

    this.setState({
        collapseID3:collapseID3,
      
      })
   
  }
  toggleCollapse4 = (collapseID4) => () => {

    this.setState({
        collapseID4:collapseID4,
      
      })
   
  }
  
  toggleCollapseEdit = collapseIDEdit=> () => {
 
    this.setState({
      collapseIDEdit:collapseIDEdit,
      collapseIDEdit2:''
    },this.toggleEdit)
  }
  toggleCollapseEdit2 = collapseIDEdit2 => () => {
    this.setState({
      collapseIDEdit2:collapseIDEdit2,
    
    })
  }
  toggleAdd = () => {
   
    this.setState({
      add_catagory:!this.state.add_catagory});
  }
  togglePicto = () => {
   
    this.setState({
      show_picto:true});
  }
  
  toggleCat= () => {
  
    if(this.state.show_product_details){
      this.setState({
        show_product_details:!this.state.show_product_details})
     
    }
    else if(this.state.show_picto){
      this.setState({
        show_picto:!this.state.show_picto})
     
    }
    else{
 
      this.setState({
        catagory_details:!this.state.catagory_details},this.toggleCollapse(this.state.collapseID))
     
    }
  
   
  }
  toggleOver= () => {
   
    this.setState({
      overview:!this.state.overview})
   
  }
  renderLanguages(){

    if(this.state.loading==false){
      if(this.state.languages.length>0){
        return(<div  
      
           
          
          style={{backgroundColor:'rgb(255, 255, 255, 0.1)',cursor:'pointer',display:'flex',alignItems:'center',marginRight:100,marginTop:'.5%'}}>
        <MDBIcon size="lg" style={{marginLeft:10,marginRight:20}}   className="grey-text menuBar" icon="bars" onClick={()=>this.setState({mobileMenu:!this.state.mobileMenu})}/>
        <MDBIcon size="lg" style={{marginLeft:10}} onClick={()=>this.setState({lang_mod:true})}   className="grey-text" icon="globe-africa" />
        <MDBDropdown className='languageDiv' >
       <MDBDropdownToggle caret color="#004080" size="md" >
       <text className='languageText'>{this.state.languages[this.state.selected_language.key-1].language}</text>
       </MDBDropdownToggle>
       <MDBDropdownMenu style={{margin:0,padding:0,borderRadius:0}}> 
       <MDBDropdownItem style={{backgroundColor:'#d2d2d2',color:'black',textAlign:'center',padding:12}}  >ENGLISH</MDBDropdownItem>
       {/* { (this.state.languages).map((data,key) => {
    
  if(data.selected==0){
  return ( 
    <MDBDropdownItem style={{backgroundColor:'#fff',color:'#6e6e6e',textAlign:'center',padding:12}} className='dropButton'  onClick={()=>this.selectLanguage(data)}>{data.language}</MDBDropdownItem>
        
         )
  }
  else{
  return ( 
    <MDBDropdownItem style={{backgroundColor:'#d2d2d2',color:'black',textAlign:'center',padding:12}}  >{data.language}</MDBDropdownItem>
        
         )
  }
    })
    } */}
    </MDBDropdownMenu>
    </MDBDropdown>
      
          </div>)
       }
     
    }
       
      }
      renderLanguagesback(){

        if(this.state.loading==false){
          if(this.state.languages.length>0){
            return(<div  
          
               
              
              style={{backgroundColor:'rgb(255, 255, 255, 0.1)',cursor:'pointer',display:'flex',alignItems:'center',marginRight:100,marginTop:'.5%'}}>
            <text onClick={this.toggleCat} className='arrow'></text>
            <MDBIcon size="lg" style={{marginLeft:10}}   className="grey-text" icon="globe-africa" onClick={this.toggleLang} />
            <MDBDropdown className='languageDiv' >
           <MDBDropdownToggle caret color="#004080" size="md" >
           <text className='languageText'>{this.state.languages[this.state.selected_language.key-1].language}</text>
           </MDBDropdownToggle>
           <MDBDropdownMenu style={{margin:0,padding:0,borderRadius:0}}> 
           { (this.state.languages).map((data,key) => {
        
     if(data.selected==0){
      return ( 
        <MDBDropdownItem style={{backgroundColor:'#fff',color:'#6e6e6e',textAlign:'center',padding:12}} className='dropButton'  onClick={()=>this.selectLanguage(data)}>{data.language}</MDBDropdownItem>
            
             )
     }
     else{
      return ( 
        <MDBDropdownItem style={{backgroundColor:'#d2d2d2',color:'black',textAlign:'center',padding:12}}  >{data.language}</MDBDropdownItem>
            
             )
     }
        })
        }
        </MDBDropdownMenu>
        </MDBDropdown>
          
              </div>)
           }
         
        }
           
          }

  getAllProducts=async()=>{
    this.setState({
      loading:true
    })      
    if(!navigator.onLine){
      
      var offline_products=window.localStorage.getItem('products')
      if(offline_products==null){
        swal('Warning','Check your network and refresh','warning')
        this.setState({
          loading:false
        })
      }
      else{
        for(var v=0;v<JSON.parse(offline_products).length;v++){
         
          JSON.parse(offline_products)[v].selected_product=0

      }
         this.setState({
          products:JSON.parse(offline_products),
           loading:false
         },()=>this.getSubcatagories())
      }
           }
           else{
            this.setState({
              loading:true
            })
          
            let details = {
            language_id:this.state.selected_language.language_id
          };
          let formBody = [];
          for (let property in details) {
           let encodedKey = encodeURIComponent(property);
           let encodedValue = encodeURIComponent(details[property]);
           formBody.push(encodedKey + "=" + encodedValue);
          }
          formBody = formBody.join("&");
          
          this.setState({
           response: fetch('https://www.mobilitysolution.info/get_all_products', {
             method: 'POST',
             headers: {
              
               'Content-Type': 'application/x-www-form-urlencoded',
               'Cache-Control': 'max-age=31536000'
           },
          body:formBody
            
             
           }).then((response) => response.json())
           .then((responseJson)=>{
            window.localStorage.removeItem('products')
            window.localStorage.setItem('products',JSON.stringify(responseJson))
            
          for(var v=0;v<responseJson.length;v++){
             
              responseJson[v].selected_product=0
    
          }
             this.setState({
              products:responseJson,
               loading:false
             },()=>this.getSubcatagories())
           
           }
          
           )
             .catch((error) => {
            this.setState({
              loading:false
            })
                
      var offline_products=window.localStorage.getItem('products')
      if(offline_products==null){
        swal('Warning','Check your network and refresh','warning')
        this.setState({
          loading:false
        })
      }
      else{
        for(var v=0;v<JSON.parse(offline_products).length;v++){
         
          JSON.parse(offline_products)[v].selected_product=0

      }
         this.setState({
          products:JSON.parse(offline_products),
           loading:false
         },()=>this.getSubcatagories())
      }
          console.log(error)
              })
             })
           }
    
      
      }
  addData(){

    let {add_sub_catagories,products}=this.state
    
    for(let v=0;v<add_sub_catagories.length;v++){
      let  target_sub= add_sub_catagories[v]
      target_sub.label=target_sub.sub_catagory_name
    target_sub.sub_catagory_id=target_sub.sub_catagory_id
  
    }
    for(let v1=0;v1<add_sub_catagories.length;v1++){
      let  target_pro= (add_sub_catagories[v1].products)[0]

     
     
  
    }
   
    this.setState({add_sub_catagories},this.toggleCollapseEdit(0))
  }
  openEdit(){
   
   
    

    this.setState({
      edit_name:this.state.selected_catagory.catagory_name,
      consequences:JSON.parse(this.state.selected_catagory.consequences),
      image_filename:this.state.selected_catagory.hover_filename,
      hover_filename:this.state.selected_catagory.image_filename,
      add_sub_catagories:JSON.parse(this.state.selected_catagory.data)
    },()=>this.addData())
    

    
   
  }
  toggleEdit= () => {
   
    this.setState({
      catagory_edit_details:!this.state.catagory_edit_details,
      catagory_details:false
    });
  }
  toggleProduct= () => {
   
    this.setState({
      show_product_list:true});
  }
  toggleProdDetails= () => {
   
    this.setState({
      show_product_details:true,
      selected_product_id:this.state.selected_product_data.product_id
     },()=>
     {
     document.getElementById('productTop').scrollIntoView()
if(document.getElementById('productTopMob'))
{
       document.getElementById('productTopMob').scrollIntoView()
}     
     }
     )
  }

  exitApp(){
    var userAgent = navigator.userAgent.toLowerCase();
  if (userAgent.indexOf(' electron/') > -1) {
    const remote = window.require('electron').remote
    let w = remote.getCurrentWindow()
    w.close()
  }
  
  
  }
     renderExitModal(){
  
  
     
      if(this.state.exit==true){
    
    
      return(
        <MDBModal size='md' isOpen={this.state.exit} centered toggle={this.toggleExit} >
     
           
     <MDBModalBody style={{    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',flexDirection:'column',paddingTop:'4vh',paddingBottom:'4vh'}}>
              <img onError={()=>this.setState({showAdded:true})} alt='Loading...' src={require('../../assets/Exit.jpg')} className='catGif'/>
              <p>Are you sure you want to exit this application?</p>
           <div style={{display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-evenly'}}>      <text onClick={()=>this.exitApp()} style={{backgroundColor:'#ff000f',color:'white'}} className='seriesText'>Exit</text>

              
              <text onClick={()=>this.setState({exit:false})} className='seriesText'>Cancel</text>

         
            </div>
             
              </MDBModalBody>
  
          </MDBModal>
      )
    }
    }
    
    toggleExit = () => {
     
      this.setState({
        exit: !this.state.exit,
       });
    }
componentWillMount(){

  this.getuserdata()
}
 
 
componentDidMount(){
  var userAgent = navigator.userAgent.toLowerCase();
  if (userAgent.indexOf(' electron/') > -1) {
     this.setState({showExit:true})
  }
}
renderImages1(){
  
  if(this.state.all_images.length!=0){
    return(
      this.state.all_images.map((data,index)=>{
        console.log(index)
        return(
          <img onError={()=>this.setState({showAdded:true})} src={data} style={{width:5,height:5,opacity:1}} />
        )
      })
    )
    
  }
  else{
    return(
      <div>Loading</div>
    )
  }
}
renderExitButton(){
  if(this.state.showExit){
   return(<div
     className="sideInActive"
     
      onClick={this.toggleExit}                
     >
     <img onError={()=>this.setState({showAdded:true})} src={require('../../assets/Exit.jpg')} style={{width:35}}/>
     <text style={{marginTop:5}}>Exit</text>
     </div>)
  }
 
 } 
 getAllImages(){
 
  

  this.setState({
   response: fetch('https://www.mobilitysolution.info/getfiles', {
     method: 'POST',
     headers: {
      
       'Content-Type': 'application/x-www-form-urlencoded',
       'Cache-Control': 'max-age=31536000'
   },
    
     
   }).then((response) => response.json())
   .then((responseJson)=>{
  this.setState({
    all_images:responseJson
  }) 
  console.log(this.state.all_images,'aaaa')
   }
  
   )
     .catch((error) => {
      
    //swal("Warning!", "Check your network!", "warning");
  console.log(error)
      })
     })
    
   }
   getSidebar=async()=>{
         
    if(!navigator.onLine){
var offline_content=window.localStorage.getItem('sidebar')
if(offline_content==null){

 this.setState({
   loading:false
 })
}
else{
 console.log(JSON.parse(JSON.parse(offline_content).content),'cccc')
 dashboardRoutes[0].name=JSON.parse(JSON.parse(offline_content).content)[0].name
 dashboardRoutes[1].name=JSON.parse(JSON.parse(offline_content).content)[1].name
 dashboardRoutes[2].name=JSON.parse(JSON.parse(offline_content).content)[2].name
 dashboardRoutes[3].name=JSON.parse(JSON.parse(offline_content).content)[4].name
 dashboardRoutes[4].name=JSON.parse(JSON.parse(offline_content).content)[5].name
 dashboardRoutes[5].name=JSON.parse(JSON.parse(offline_content).content)[6].name
 this.setState({
   sidebar:JSON.parse(offline_content).content,
 loading:false
 })
}
    }
    else{
     this.setState({loading:true})
     let details = {
       language_id:this.state.selected_language.language_id
     };
     let formBody = [];
     for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
     }
     formBody = formBody.join("&");
     this.setState({
       response: fetch('https://www.mobilitysolution.info/get_sidebar', {
         method: 'POST',
         headers: {
          
           'Content-Type': 'application/x-www-form-urlencoded',
           'Cache-Control': 'max-age=31536000'
       },
     body:formBody
         
       }).then((response) => response.json())
       .then((responseJson)=>{
         console.log(JSON.parse(responseJson.content),'sidebar')
        window.localStorage.removeItem('sidebar')
        window.localStorage.setItem('sidebar',JSON.stringify(responseJson))
dashboardRoutes[0].name=JSON.parse(responseJson.content)[0].name
dashboardRoutes[1].name=JSON.parse(responseJson.content)[1].name
dashboardRoutes[2].name=JSON.parse(responseJson.content)[2].name
dashboardRoutes[3].name=JSON.parse(responseJson.content)[4].name
dashboardRoutes[4].name=JSON.parse(responseJson.content)[5].name
dashboardRoutes[5].name=JSON.parse(responseJson.content)[6].name
         this.setState({
           sidebar:(responseJson.content).sidebar,
 loading:false
         })
           })
           .catch((error) => {
            if(offline_content==null){

              this.setState({
                loading:false
              })
             }
             else{
              console.log(JSON.parse(JSON.parse(offline_content).content),'cccc')
              dashboardRoutes[0].name=JSON.parse(JSON.parse(offline_content).content)[0].name
              dashboardRoutes[1].name=JSON.parse(JSON.parse(offline_content).content)[1].name
              dashboardRoutes[2].name=JSON.parse(JSON.parse(offline_content).content)[2].name
              dashboardRoutes[3].name=JSON.parse(JSON.parse(offline_content).content)[4].name
              dashboardRoutes[4].name=JSON.parse(JSON.parse(offline_content).content)[5].name
              dashboardRoutes[5].name=JSON.parse(JSON.parse(offline_content).content)[6].name
              this.setState({
                sidebar:JSON.parse(offline_content).content,
              loading:false
              })
             }
               })
              })
    }
             }
 getProductsSub=async()=>{
  if(!navigator.onLine){
    var offline_content=window.localStorage.getItem('product_sub')
    if(offline_content==null){
     swal('Warning','Check your network and refresh','warning')
     this.setState({
       loading:false
     })
    }
    else{
   
     this.setState({
      product_sub:JSON.parse(JSON.parse(offline_content).sub_header),
             
     loading:false
     })
    }
        }
        else{
          this.setState({
            loading:true
          })
        
          let details = {
          language_id:this.state.selected_language.language_id
        };
        let formBody = [];
        for (let property in details) {
         let encodedKey = encodeURIComponent(property);
         let encodedValue = encodeURIComponent(details[property]);
         formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        
        this.setState({
         response: fetch('https://www.mobilitysolution.info/get_product_sub', {
           method: 'POST',
           headers: {
            
             'Content-Type': 'application/x-www-form-urlencoded',
             'Cache-Control': 'max-age=31536000'
         },
        body:formBody
          
           
         }).then((response) => response.json())
         .then((responseJson)=>{  window.localStorage.removeItem('product_sub')
         window.localStorage.setItem('product_sub',JSON.stringify(responseJson))
       
           this.setState({
          product_subs:JSON.parse(responseJson.sub_header),
             loading:false
           })
         
         }
        
         )
           .catch((error) => {
          this.setState({
            loading:false
          })
          if(offline_content==null){
            swal('Warning','Check your network and refresh','warning')
            this.setState({
              loading:false
            })
           }
           else{
          
            this.setState({
             product_sub:JSON.parse(JSON.parse(offline_content).sub_header),
                    
            loading:false
            })
           }
        console.log(error)
            })
           })
        }
 

}
getSubcatagories=async()=>{
             
  if(!navigator.onLine){
    var offline_content=window.localStorage.getItem('causes')
    if(offline_content==null){
      swal('Warning','Check your network and refresh','warning')
      this.setState({
        loading:false
      })
    }
    else{
      this.setState({
        subcatagories:JSON.parse(offline_content),
         loading:false
       })
    }
    this.getProductsSub()
    this.getSidebar()
  }
  else{
    this.setState({
      loading:true
    })
  
    let details = {
    language_id:this.state.selected_language.language_id
  };
  let formBody = [];
  for (let property in details) {
   let encodedKey = encodeURIComponent(property);
   let encodedValue = encodeURIComponent(details[property]);
   formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  
  this.setState({
   response: fetch('https://www.mobilitysolution.info/get_sub_catagories', {
     method: 'POST',
     headers: {
      
       'Content-Type': 'application/x-www-form-urlencoded',
       'Cache-Control': 'max-age=31536000'
   },
  body:formBody
    
     
   }).then((response) => response.json())
   .then((responseJson)=>{
    window.localStorage.removeItem('causes')
    window.localStorage.setItem('causes',JSON.stringify(responseJson))
     this.setState({
      subcatagories:responseJson,
       loading:false
     })
   this.getProductsSub()
   this.getSidebar()
   }
  
   )
     .catch((error) => {
    this.setState({
      loading:false
    })
    var offline_content=window.localStorage.getItem('causes')
    if(offline_content==null){
      swal('Warning','Check your network and refresh','warning')
      this.setState({
        loading:false
      })
    }
    else{
      this.setState({
        subcatagories:JSON.parse(offline_content),
         loading:false
       })
    }
    this.getProductsSub()
    this.getSidebar()
  
  console.log(error)
      })
     })
  }


}
doSelected(){
  let {catagories} = this.state
  for(var v=0;v<catagories.length;v++){
    catagories[v].selected = 0
    catagories[v].hover = 0
  }
  this.setState({
    catagories
  })
}
getCatagories=async()=>{
  this.setState({
    loading:true
  })
  if(!navigator.onLine){
    var offline_solution=window.localStorage.getItem('solutions')
   
    if(offline_solution==null){
      swal('Warning','Check your network and refresh','warning')
      this.setState({
        loading:false
      })
    }
    else{
      for(var v=0;v<JSON.parse(offline_solution).length;v++){
  
        JSON.parse(offline_solution)[v].selected=0
        JSON.parse(offline_solution)[v].hover=0
        if(JSON.parse(offline_solution)[v].catagory_id==8 || JSON.parse(offline_solution)[v].catagory_id==21 || JSON.parse(offline_solution)[v].catagory_id==34 || JSON.parse(offline_solution)[v].catagory_id==47 || JSON.parse(offline_solution)[v].catagory_id==60){
         
          var OEM = [{high:(JSON.parse(JSON.parse(offline_solution)[v].data)[0].products)[0].high,
                      medium:(JSON.parse(JSON.parse(offline_solution)[v].data)[0].products)[0].medium,
                     low:(JSON.parse(JSON.parse(offline_solution)[v].data)[0].products)[0].low,
                    dc:(JSON.parse(JSON.parse(offline_solution)[v].data)[1].products)[0].high}]
        
          this.setState({OEM:OEM})
        }
        if(JSON.parse(offline_solution)[v].catagory_id==3 || JSON.parse(offline_solution)[v].catagory_id==16 || JSON.parse(offline_solution)[v].catagory_id==29 || JSON.parse(offline_solution)[v].catagory_id==42 || JSON.parse(offline_solution)[v].catagory_id==55){
         
          var Accessories = [{high:(JSON.parse(JSON.parse(offline_solution)[v].data)[0].products)[0].high,
                      medium:(JSON.parse(JSON.parse(offline_solution)[v].data)[0].products)[0].medium,
                     low:(JSON.parse(JSON.parse(offline_solution)[v].data)[0].products)[0].low}]
        
          this.setState({Accessories:Accessories})
        }
      }
      
         this.setState({
          catagories:JSON.parse(offline_solution),
           loading:false
         },()=> {this.getAllProducts()
        this.doSelected()
        })
    }
         }
         else{
          this.setState({
            loading:true
          })
        
          let details = {
          language_id:this.state.selected_language.language_id
        };
        
        let formBody = [];
        for (let property in details) {
         let encodedKey = encodeURIComponent(property);
         let encodedValue = encodeURIComponent(details[property]);
         formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        
        this.setState({
         response: fetch('https://www.mobilitysolution.info/get_all_catagories', {
           method: 'POST',
           headers: {
            
             'Content-Type': 'application/x-www-form-urlencoded',
             'Cache-Control': 'max-age=31536000'
         },
        body:formBody
          
           
         }).then((response) => response.json())
         .then((responseJson)=>{
          window.localStorage.removeItem('solutions')
          window.localStorage.setItem('solutions',JSON.stringify(responseJson))
        
        for(var v=0;v<responseJson.length;v++){
          
          responseJson[v].selected=0
          responseJson[v].hover=0
          if(responseJson[v].catagory_id==8 || responseJson[v].catagory_id==21 || responseJson[v].catagory_id==34 || responseJson[v].catagory_id==47 || responseJson[v].catagory_id==60){
           
            var OEM = [{high:(JSON.parse(responseJson[v].data)[0].products)[0].high,
                        medium:(JSON.parse(responseJson[v].data)[0].products)[0].medium,
                       low:(JSON.parse(responseJson[v].data)[0].products)[0].low,
                      dc:(JSON.parse(responseJson[v].data)[1].products)[0].high}]
          
            this.setState({OEM:OEM})
          }
          if(responseJson[v].catagory_id==3 || responseJson[v].catagory_id==16 || responseJson[v].catagory_id==29 || responseJson[v].catagory_id==42 || responseJson[v].catagory_id==55){
           
            var Accessories = [{high:(JSON.parse(responseJson[v].data)[0].products)[0].high,
                        medium:(JSON.parse(responseJson[v].data)[0].products)[0].medium,
                       low:(JSON.parse(responseJson[v].data)[0].products)[0].low}]
          
            this.setState({Accessories:Accessories})
          }
        }
        
           this.setState({
            catagories:responseJson,
             loading:false
           },()=> this.getAllProducts())
         
         }
        
         )
           .catch((error) => {
            var offline_solution=window.localStorage.getItem('solutions')
   
            if(offline_solution==null){
              swal('Warning','Check your network and refresh','warning')
              this.setState({
                loading:false
              })
            }
            else{
              for(var v=0;v<JSON.parse(offline_solution).length;v++){
          
                JSON.parse(offline_solution)[v].selected=0
                JSON.parse(offline_solution)[v].hover=0
                if(JSON.parse(offline_solution)[v].catagory_id==8 || JSON.parse(offline_solution)[v].catagory_id==21 || JSON.parse(offline_solution)[v].catagory_id==34 || JSON.parse(offline_solution)[v].catagory_id==47 || JSON.parse(offline_solution)[v].catagory_id==60){
                 
                  var OEM = [{high:(JSON.parse(JSON.parse(offline_solution)[v].data)[0].products)[0].high,
                              medium:(JSON.parse(JSON.parse(offline_solution)[v].data)[0].products)[0].medium,
                             low:(JSON.parse(JSON.parse(offline_solution)[v].data)[0].products)[0].low,
                            dc:(JSON.parse(JSON.parse(offline_solution)[v].data)[1].products)[0].high}]
                
                  this.setState({OEM:OEM})
                }
                if(JSON.parse(offline_solution)[v].catagory_id==3 || JSON.parse(offline_solution)[v].catagory_id==16 || JSON.parse(offline_solution)[v].catagory_id==29 || JSON.parse(offline_solution)[v].catagory_id==42 || JSON.parse(offline_solution)[v].catagory_id==55){
                 
                  var Accessories = [{high:(JSON.parse(JSON.parse(offline_solution)[v].data)[0].products)[0].high,
                              medium:(JSON.parse(JSON.parse(offline_solution)[v].data)[0].products)[0].medium,
                             low:(JSON.parse(JSON.parse(offline_solution)[v].data)[0].products)[0].low}]
                
                  this.setState({Accessories:Accessories})
                }
              }
              
                 this.setState({
                  catagories:JSON.parse(offline_solution),
                   loading:false
                 },()=> {this.getAllProducts()
                this.doSelected()
                })
            }
        console.log(error)
            })
           })
         }
 

}
  
  

   
      // if(this.state.selected_language.language_id==1){
       
      //   dashboardRoutes = [
   
      //     {
      //       path: "/home",
      //       name: "Home",
      //       icon: "Home.png",
           
      //     },
      //     {
      //       path: "/solutions",
      //       name: "Solutions",
      //       icon: "Solution.png",
           
      //     },
      //     {
      //       path: "/causes",
      //       name: "Causes",
      //       icon: "Cause.png",
           
      //     },
      //     {
      //       path: "/value",
      //       name: "Value Proposition",
      //       icon: "Value_Proposition.png",
           
      //     },
          
      
      // {
      //       path: "/footprints",
      //       name: "Footprints",
      //       icon: "Footprint.png",
           
      //     },
      
      // {
      //   path: "/case_study",
      //   name: "Case Study",
      //   icon: "Casestudy.png",
       
      // }
          
         
      //   ];
      // }
      // else 
   
  
  
  
      getuserdata=async()=>{
        this.setState({
          loading:true
        })
      
   
    
    var responseJson=[{"language":"English","language_id":1,"active":0,"key":1,"label":"English",selected:0},
    {"language":"German","language_id":2,"active":0,"key":2,"label":"German",selected:0},
    {"language":"Chinese","language_id":3,"active":0,"key":3,"label":"Chinese",selected:0},
    {"language":"Spanish","language_id":4,"active":0,"key":4,"label":"Spanish",selected:0},
    {"language":"French","language_id":5,"active":0,"key":5,"label":"French",selected:0}]
    
      for(var v=0;v<responseJson.length;v++){
        responseJson[v].label=responseJson[v].language
        responseJson[v].value=responseJson[v].language_id
      }
      var defaultLanguage=window.localStorage.getItem('admindefault')
      if(defaultLanguage==null){
        responseJson[0].selected=1
       this.setState({
         languages:responseJson,
         selected_language:responseJson[0]
       },()=> {
        this.getCatagories()
       
       })
      }
      else{
        if(JSON.parse(defaultLanguage).language=='German' || JSON.parse(defaultLanguage).language=='Deutsche' ||
     JSON.parse(defaultLanguage).language=='德语' || JSON.parse(defaultLanguage).language=='alemán' || JSON.parse(defaultLanguage).language=='allemand'){
      responseJson[0].language='Englisch'
      responseJson[1].language='Deutsche'
      responseJson[2].language='Chinesisch'
      responseJson[3].language='Spanisch'
      responseJson[4].language='Französisch'
         this.setState({logoutText:'Ausloggen',edit_content:'Inhalt bearbeiten',videoText:'Video',
         heading:'ABB-Lösungen mit schlechter Stromqualität',nextText:'Wertversprechen',highText:'Hochspannungslösungen',mediumText:'Mittelspannungslösungen',lowText:'Niederspannungslösungen',dcText:'DC-Anwendungen',
        abbsolText:'ABB-Lösungen für Herausforderungen in Bezug auf die Stromqualität',highMob:'Hochspannung',mediumMob:'Mittelspannung',lowMob:'Niederspannung',broText:'PRODUKTBROSCHÜRE',webText:'PRODUKT-WEBSEITE',leafText:'GEBRAUCHSINFORMATION'})
    
        }
        else if(JSON.parse(defaultLanguage).language=='Chinese' || JSON.parse(defaultLanguage).language=='Chinesisch' ||
        JSON.parse(defaultLanguage).language=='中文' || JSON.parse(defaultLanguage).language=='chino' || JSON.parse(defaultLanguage).language=='chinois'){
        
          responseJson[0].language='英语'
          responseJson[1].language='德语'
          responseJson[2].language='中文'
          responseJson[3].language='西班牙语'
          responseJson[4].language='法国'
          this.setState({logoutText:'登出',edit_content:'编辑内容',videoText:'视频',heading:'ABB电力质量差的解决方案',nextText:'价值主张',highText:'高压解决方案',mediumText:'中压解决方案',lowText:'低压解决方案',dcText:'直流应用',abbsolText:'ABB解决电力质量挑战的解决方案',highMob:'高压',mediumMob:'中压',lowMob:'低电压',broText:'产品手册',webText:'产品网页',leafText:'产品LEAFLET'})
    
     
         }
         else if(JSON.parse(defaultLanguage).language=='Spanish' || JSON.parse(defaultLanguage).language=='Spanisch' ||
         JSON.parse(defaultLanguage).language=='西班牙语' || JSON.parse(defaultLanguage).language=='Español' || JSON.parse(defaultLanguage).language=='Espanol'){
          responseJson[0].language='Inglés'
          responseJson[1].language='alemán'
          responseJson[2].language='chino'
          responseJson[3].language='Español'
          responseJson[4].language='francés'
          this.setState({logoutText:'Cerrar sesión',edit_content:'Contenido editado',videoText:'Vídeo',
          heading:'Soluciones ABB de baja calidad eléctrica',nextText:'Propuesta de valor',highText:'Soluciones de alta tensión',mediumText:'Soluciones de media tensión',lowText:'Soluciones de baja tensión',dcText:'Aplicaciones DC',abbsolText:'Soluciones ABB para desafíos de calidad de energía.',highMob:'Alto voltaje',mediumMob:'Voltaje medio',lowMob:'Baja tensión',broText:'FOLLETO DEL PRODUCTO',webText:'PÁGINA WEB DEL PRODUCTO',leafText:'PROSPECTO DEL PRODUCTO'})
    
     
         }
         else if(JSON.parse(defaultLanguage).language=='French' || JSON.parse(defaultLanguage).language=='Französisch' ||
     JSON.parse(defaultLanguage).language=='法国' || JSON.parse(defaultLanguage).language=='francés' || JSON.parse(defaultLanguage).language=='français'){
      responseJson[0].language='Anglais'
      responseJson[1].language='allemand'
      responseJson[2].language='chinois'
      responseJson[3].language='Espanol'
      responseJson[4].language='français'
       
         
          this.setState({logoutText:'Connectez - Out',edit_content:'Modifier le contenu',
          videoText:'Vidéo',heading:`Solutions ABB de faible qualité d'énergie`,nextText:'Proposition de valeur',highText:'Solutions haute tension',mediumText:'Solutions moyenne tension',lowText:'Solutions basse tension',dcText:'Applications DC',abbsolText:`Solutions ABB pour les défis de qualité de l'énergie`,highMob:'Haute tension',mediumMob:'Moyenne tension',lowMob:'Basse tension',broText:'BROCHURE DE PRODUIT',webText:'PAGE WEB DU PRODUIT',leafText:'NOTICE DE PRODUIT'})
    
     
         }
       
        responseJson[JSON.parse(defaultLanguage).key-1].selected=1
       this.setState({
         languages:responseJson,
        
         selected_language:JSON.parse(defaultLanguage)
       },()=> {this.getCatagories()
     
      })
      }
      
     }



  
 
  

  
  

selectCatagory(data,key){
  
if(data.catagory_id==13 || data.catagory_id==26 || data.catagory_id==39 || data.catagory_id==52 || data.catagory_id==65){
  
 this.props.history.push('overview')
// this.setState({

//   overview:true
// })
}
else if(data.catagory_id==8 || data.catagory_id==21 || data.catagory_id==34 || data.catagory_id==47 || data.catagory_id==60){

this.setState({
selected_products:this.state.OEM,
selected_sub:{sub_catagory_id:0,sub_catagory_name:'OEM'},
selected_catagory:data,
},this.toggleCat)

}
else if(data.catagory_id==3 || data.catagory_id==16 || data.catagory_id==29 || data.catagory_id==42 || data.catagory_id==55){

  this.setState({
  selected_products:this.state.Accessories,
  selected_sub:{sub_catagory_id:0,sub_catagory_name:'Accessories'},
  selected_catagory:data,
  },this.toggleCat)
  
  }
else{

  let {catagories,selected_catagory_data}=this.state
  let targetCatagory=catagories[key]
  for(var v=0;v<catagories.length;v++){
    catagories[v].selected=0
}
targetCatagory.selected=1
for(var v1=0;v1<selected_catagory_data.length;v1++){
  
  selected_catagory_data[v1].selected_sub=0
    }



this.setState({catagories,selected_catagory_data})


this.setState({
selected_catagory:data,
},this.toggleCat)
}
}
hoverCatagory(data,key){

  let {catagories,selected_catagory_data}=this.state
  let targetCatagory=catagories[key]
  for(var v=0;v<catagories.length;v++){
    catagories[v].selected=0
}
targetCatagory.selected=1
for(var v1=0;v1<selected_catagory_data.length;v1++){
  
  selected_catagory_data[v1].selected_sub=0
    }



this.setState({catagories,selected_catagory_data})


}
leaveHover(data,key){

  let {catagories,selected_catagory_data}=this.state

  for(var v=0;v<catagories.length;v++){
    catagories[v].selected=0
}

for(var v1=0;v1<selected_catagory_data.length;v1++){
  
  selected_catagory_data[v1].selected_sub=0
    }



this.setState({catagories,selected_catagory_data})



}
hoverPicto(data,key){

let {selected_picto}=this.state

let targetPicto=selected_picto[key]
for(var v=0;v<selected_picto.length;v++){
  selected_picto[v].selected_product=0
}

targetPicto.selected_product=1



}
leaveHoverPicto(data,key){

let {selected_picto}=this.state

for(var v=0;v<selected_picto.length;v++){
  selected_picto[v].selected_product=0
}




this.setState({selected_picto})



}
renderError(){
  if(this.state.errored){
    return(swal('error'))
  }
}
renderData(data,key){

  if(data.selected==0){

    return(
    
<div id={key+1}  onMouseEnter={() => this.hoverCatagory(data,key)} 
   onMouseLeave={() => this.leaveHover(data,key)}  onClick={()=>this.setState({selected_catagory_data:JSON.parse(data.data)},()=>this.selectCatagory(data,key))} className="catagories"    >
<img onError={()=>this.setState({showAdded:true})} alt='Loading...' src={data.image} className='catGif'/>
<p style={{color:'black',fontFamily:'ABBvoice-Bold',width:'80%'}}>{data.catagory_name}</p>
</div>
    )
  }
  else if(data.selected==1){
    return(
          
<div id={key+1} onMouseLeave={() => this.leaveHover(data,key)}  onMouseEnter={() => this.hoverCatagory(data,key)}  onClick={()=>this.setState({selected_catagory_data:JSON.parse(data.data)},()=>this.selectCatagory(data,key))} className="catagoriesHover"  >
<img onError={()=>this.setState({showAdded:true})} alt='Loading...' src={data.hover_image} className='catGif'/>
<p style={{color:'white',fontFamily:'ABBvoice-Bold',width:'80%'}}>{data.catagory_name}</p>
</div>
    )
  }
}
renderList(){

  if(this.state.loading){
   
    return(<div className="loadingSpin"><HalfCircleSpinner color="#ff000f"/></div>)
  }
  else {
  
    let columns=[];
  this.state.catagories.forEach((data,key) => {

      // push column
      columns.push(
        this.renderData(data,key)

      )

      // force wrap to next row every 4 columns
      if ((key+1)%5===0) {columns.push(<div className="w-100"></div>)}
  })

    return (
    <div>   
  <div  className="row" style={{marginBottom:'1%'}}>
      
    
     {columns}
    
      </div>
  </div>
     
  )
      
      
 
    
  }
 


}

renderListmobile(){

  if(this.state.loading){
    return(<div className="loadingSpin" style={{paddingBottom:250}}><HalfCircleSpinner color="#ff000f"/></div>)
  }
  else {
   
    let columns=[];
  this.state.catagories.forEach((data,key) => {

      // push column
      columns.push(
        this.renderData(data,key)

      )

      // force wrap to next row every 4 columns
      if ((key+1)%2===0) {columns.push(<div className="w-100"></div>)}
  })

    return (
    <div>   
  <div  className="row" style={{marginBottom:'1%'}}>
      
    
     {columns}
    
      </div>
  </div>
     
  )
      
      
 
    
  }
 


}

 


  

 
select_cause(prop,key){

  let {selected_catagory_data}=this.state
  let targetCatagory=selected_catagory_data[key]
  for(var v=0;v<selected_catagory_data.length;v++){
    selected_catagory_data[v].selected_sub=0
  }
  for(let v1=0;v1<prop.products[0].high.length;v1++){
    (prop.products[0].high)[v1].selected_product=0
  }
  for(let v2=0;v2<prop.products[0].medium.length;v2++){
    (prop.products[0].medium)[v2].selected_product=0
  }
  for(let v3=0;v3<prop.products[0].low;v3++){
    (prop.products[0].low)[v3].selected_product=0
  }
  targetCatagory.selected_sub=1
  this.setState({selected_catagory_data})
 
  this.setState({
    selected_products:prop.products,
    selected_subcatagory:prop
    

  },this.toggleCollapse(prop.sub_catagory_id))
}


renderCon(){

 
       
return( <div style={{display:'flex',flexDirection:'column',margin:10}}>

<ul style={{marginLeft:-30}}>
{JSON.parse(this.state.selected_catagory.consequences).map((data,key) => {
 
if(key!=0){
  return (   



  
<li tyle={{color:'#000',fontSize:15}}>{data.consequence}</li>




     )
}



})}
</ul>  
</div>)

}
medDiv(){

if((this.state.selected_products[0].medium).length!=0 ){
  if((this.state.selected_products[0].medium)[0].product_name!='Select Product'){
    return(this.renderMedium())
  }
  
}

}
lowDiv(){
  if((this.state.selected_products[0].low).length!=0){
    if((this.state.selected_products[0].low)[0].product_name!='Select Product'){
      return(this.renderLow())
    }
    
  
  }
  
  }
  highDiv(){
    
    if((this.state.selected_products[0].high).length!=0){
      if((this.state.selected_products[0].high)[0].product_name!='Select Product'){
        return(this.renderHigh())
      }
    }
    
    }
    renderConHead(){
   
          return(
              <text style={{color:'black',fontSize:15,marginTop:2,fontFamily:'ABBvoice'}}>{JSON.parse(this.state.selected_catagory.consequences)[0].description}</text>)
        
    
      
      
      
    
   
    }
    getSubName(data){
  
      if(data.sub_catagory_name=='Low Power Factor'){
        return(<text style={{cursor:'pointer',color:"#FF000F",fontSize:16,fontWeight:400
      }}>Poor Power Factor</text>)
      }
      else if(data.sub_catagory_name=='Harmonic pollution'){
        return(<text style={{cursor:'pointer',color:"#FF000F",fontSize:16,fontWeight:400
      }}>{this.state.subcatagories[1].sub_catagory_name}</text>)
      }
      else  if(data.sub_catagory_name=='Load Imbalance'){
        return(<text style={{cursor:'pointer',color:"#FF000F",fontSize:16,fontWeight:400
      }}>{this.state.subcatagories[3].sub_catagory_name}</text>)
      }
      else if(data.sub_catagory_name=='Voltage surges, swells, dips'){
        return(<text style={{cursor:'pointer',color:"#FF000F",fontSize:16,fontWeight:400
      }}>{this.state.subcatagories[2].sub_catagory_name}</text>)
      }
          }
          getSubNamemobile(data){
           
          if(this.state.selected_catagory!=null){
            if(this.state.selected_catagory.catagory_id==3 || this.state.selected_catagory.catagory_id==16 || this.state.selected_catagory.catagory_id==29 || this.state.selected_catagory.catagory_id==42 || this.state.selected_catagory.catagory_id==55 || this.state.selected_catagory.catagory_id==8 || this.state.selected_catagory.catagory_id==21 || this.state.selected_catagory.catagory_id==34 || this.state.selected_catagory.catagory_id==47 || this.state.selected_catagory.catagory_id==60){
              return this.state.selected_catagory.catagory_name
            }
         else{
          return data.sub_catagory_name
         }
          }
        
                }
renderDetails(){
 
  var style={opacity:1,backgroundColor:'#fff',height:'100vh',borderStyle:'solid',borderColor:'#d2d2d2',borderWidth:1,
  overflow:'auto',padding:'2.5%',display:'flex',flexDirection:'column',alignItems:'flex-start'}

 

  if(this.state.catagory_details){
if(this.state.selected_catagory.catagory_id==8 || this.state.selected_catagory.catagory_id==21 || this.state.selected_catagory.catagory_id==34 || this.state.selected_catagory.catagory_id==47 || this.state.selected_catagory.catagory_id==60){
  return(
    <div id="mySidebar"  onMouseEnter={() => this.setState({hover:true})} 
    onMouseLeave={() => this.setState({hover:false})}   ref={ref => { this['mySidebar'] = ref }}
    className="sidebar">
     <ScrollAnimation animateOnce={false}  animateIn="slideInLeft"  className='slideInLeft' animationOut="slideOutLeft" 
     isVisible={true}  style={style}>
    <div style={{backgroundColor:'#fff',display:'flex',justifyContent:'flex-end',paddingRight:10,width:'100%'}}>
  <text onClick={this.toggleCat} className='arrow'></text></div>
  <div style={{backgroundColor:'#fff',display:'flex',justifyContent:'flex-start',paddingLeft:15}}>
 
    
<h2 style={{marginBottom:30}}>
                     
                      {this.state.selected_catagory.catagory_name}
                    
                    
                    </h2>

       
                    
                  </div>
          
<div style={{paddingLeft:10,width:'97%'}}>



<div style={{display:'flex',alignItems:'center',height:40,flexDirection:'row',paddingLeft:5,backgroundColor:this.state.picto_type == 'high' ? '#E9E7E7' : "white",}}><MDBIcon icon='circle' size='sm' className='black-text' style={{fontSize:5,paddingRight:10}}/>
        <text style={{cursor:'pointer',color:"black",fontSize:16,paddingRight:5,paddingTop:5,paddingBottom:5,fontWeight:400,
        display:'flex'}}  
        onClick={()=>this.openPicto(this.state.OEM[0].high,'high')}> {this.state.highText}</text>
        </div> 
        <div style={{display:'flex',alignItems:'center',height:40,flexDirection:'row',paddingLeft:5,backgroundColor:this.state.picto_type == 'medium' ? '#E9E7E7' : "white",}}><MDBIcon icon='circle' size='sm' className='black-text' style={{fontSize:5,paddingRight:10}}/>
        <text style={{cursor:'pointer',color:"black",fontSize:16,flexDirection:'column',display:'flex',paddingRight:5,paddingTop:5,paddingBottom:5,fontWeight:400,}}  
        onClick={()=>this.openPicto(this.state.OEM[0].medium,'medium')}> {this.state.mediumText}</text>
        </div>
        <div style={{display:'flex',alignItems:'center',height:40,flexDirection:'row',paddingLeft:5,backgroundColor:this.state.picto_type == 'dc' ? '#E9E7E7' : "white",}}><MDBIcon icon='circle' size='sm' className='black-text' style={{fontSize:5,paddingRight:10}}/>
        <text style={{cursor:'pointer',color:"black",fontSize:16,flexDirection:'column',display:'flex',paddingRight:5,paddingTop:5,paddingBottom:5,fontWeight:400,}}  
        onClick={()=>this.openPicto(this.state.OEM[0].medium,'dc')}> {this.state.dcText}</text>
        </div>
        <div style={{display:'flex',alignItems:'center',height:40,flexDirection:'row',paddingLeft:5,backgroundColor:this.state.picto_type == 'low' ? '#E9E7E7' : "white",}}><MDBIcon icon='circle' size='sm' className='black-text' style={{fontSize:5,paddingRight:10}}/>
       <text style={{cursor:'pointer',color:"black",fontSize:16,flexDirection:'column',fontWeight:400,
       display:'flex',paddingRight:5,paddingTop:5,paddingBottom:5,}}  
       onClick={()=>this.openPicto(this.state.OEM[0].low,'low')}> {this.state.lowText}</text>
        </div>
</div>



           
        
        
      </ScrollAnimation>
      </div>
        )
}
else if(this.state.selected_catagory.catagory_id==3 || this.state.selected_catagory.catagory_id==16 || this.state.selected_catagory.catagory_id==29 || this.state.selected_catagory.catagory_id==42 || this.state.selected_catagory.catagory_id==55){
  return(
    <div id="mySidebar" onMouseEnter={() => this.setState({hover:true})} 
    onMouseLeave={() =>this.setState({hover:false})}   ref={ref => { this['mySidebar'] = ref }}
    className="sidebar">
     <ScrollAnimation animateOnce={false} animateIn="slideInLeft"  className='slideInLeft' animationOut="slideOutLeft" 
     isVisible={true} style={style}>
    <div style={{backgroundColor:'#fff',display:'flex',justifyContent:'flex-end',paddingRight:10,width:'100%'}}>
  <text onClick={this.toggleCat} className='arrow'></text></div>
  
                  <div style={{backgroundColor:'#fff',display:'flex',justifyContent:'flex-start',paddingLeft:15}}>
 

 <h2 style={{marginBottom:30}}>
                  
                   
 {this.state.selected_catagory.catagory_name}
                 </h2>
 
    
                 
               </div>

<div style={{paddingLeft:10,width:'97%'}}>



<div style={{display:'flex',alignItems:'center',height:40,flexDirection:'row',paddingLeft:5,backgroundColor:this.state.picto_type == 'high' ? '#E9E7E7' : "white",}}><MDBIcon icon='circle' size='sm' className='black-text' style={{fontSize:5,paddingRight:10}}/>
        <text style={{cursor:'pointer',color:"black",fontSize:16,paddingRight:5,paddingTop:5,paddingBottom:5,fontWeight:400,
        display:'flex'}}  
        onClick={()=>this.openPicto(this.state.Accessories[0].high,'high')}> {this.state.highText}</text>
        </div> 
        <div style={{display:'flex',alignItems:'center',height:40,flexDirection:'row',paddingLeft:5,backgroundColor:this.state.picto_type == 'medium' ? '#E9E7E7' : "white",}}><MDBIcon icon='circle' size='sm' className='black-text' style={{fontSize:5,paddingRight:10}}/>
        <text style={{cursor:'pointer',color:"black",fontSize:16,flexDirection:'column',display:'flex',paddingRight:5,paddingTop:5,paddingBottom:5,fontWeight:400,}}  
        onClick={()=>this.openPicto(this.state.Accessories[0].medium,'medium')}> {this.state.mediumText}</text>
        </div>
        
        <div style={{display:'flex',alignItems:'center',height:40,flexDirection:'row',paddingLeft:5,backgroundColor:this.state.picto_type == 'low' ? '#E9E7E7' : "white",}}><MDBIcon icon='circle' size='sm' className='black-text' style={{fontSize:5,paddingRight:10}}/>
       <text style={{cursor:'pointer',color:"black",fontSize:16,flexDirection:'column',fontWeight:400,
       display:'flex',paddingRight:5,paddingTop:5,paddingBottom:5,}}  
       onClick={()=>this.openPicto(this.state.Accessories[0].low,'low')}> {this.state.lowText}</text>
        </div>
</div>



            
        
        
      </ScrollAnimation>
      </div>
  )
}
else{


  return(
   
    <div id="mySidebar"   onMouseEnter={() => this.setState({hover:true})} 
    onMouseLeave={() => this.setState({hover:false})}  ref={ref => { this['mySidebar'] = ref }}
    className="sidebar">
     <ScrollAnimation animateOnce={false} animateIn="slideInLeft"  className='slideInLeft' animationOut="slideOutLeft" 
     isVisible={true} style={style}>
    <div style={{backgroundColor:'#fff',display:'flex',justifyContent:'flex-end',paddingRight:10,width:'100%'}}>
  <text onClick={this.toggleCat} className='arrow'></text></div>
  <div style={{backgroundColor:'#fff',display:'flex',justifyContent:'flex-start',paddingLeft:10}}>
 

<h2 style={{marginBottom:30}}>
                 
                  
                 {this.state.selected_catagory.catagory_name}
                
                </h2>

   
                
              </div>
              <div style={{paddingLeft:5}}>
{this.renderConHead()}

{this.renderCon()}
<p style={{color:'black',fontSize:15,fontWeight:'bold',marginTop:2,fontFamily:'ABBvoice-Bold',lineHeight:1.5}}>{this.state.abbsolText}</p>
       {(this.state.selected_catagory_data).map((prop, key) => {
    return(
         <div style={{flexDirection:'column',
display:'flex'}}>         
      <div
              key={key}
style={{backgroundColor:"white",width:'100%',flexDirection:'column',
display:'flex',justifyContent:'center',alignItems:'flex-start',padding:10,
borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#d2d2d2',
borderTopStyle:'solid',borderTopWidth:1,borderTopColor:'#d2d2d2',height:40}}
     onClick={()=>this.select_cause(prop,key)}       
>
<div style={{backgroundColor:"white",flexDirection:'row',justifyContent:'space-between',display:'flex',width:'100%'}}>

<text style={{cursor:'pointer',color:"#FF000F",fontSize:16,fontWeight:400
      }}>{prop.sub_catagory_name}</text>
<MDBIcon icon={this.state.collapseID == prop.sub_catagory_id ? 'caret-up' : "caret-down"}/>
</div>

</div>

<MDBCollapse id={prop.sub_catagory_id} style={{backgroundColor:'white'}} isOpen={this.state.collapseID}> 

{this.highDiv()}
{this.medDiv()}
{this.lowDiv()}

</MDBCollapse>
</div>
    ) 
         })}
 
</div>

</ScrollAnimation>

</div>

   
  )
}
 
}
}



renderImage(){

  if(this.state.new_image.length==0){
    return(<div className="upload-btn-wrapper"  
    style={{cursor:'pointer',display:'flex',justifyContent:'center',width:'fit-content',alignItems:'center',margin:10}}>
    <text  style={{marginTop:10,marginRight:10,marginBottom:10}} >Image</text>
    <input type="file"  onChange={this.imageHandler}/>
  </div>)
  }
  else{
    return(
      <div  style={{cursor:'pointer',display:'flex',justifyContent:'space-around',margin:10,width:'-webkit-fill-available',alignItems:'center'}}>
    <text style={{color:'black'}}>{this.state.new_image.name}</text>
    <MDBIcon onClick={()=>this.setState({new_image:''})} icon="times-circle" />
    </div>
    )
  }
}


de_select_products_high(data,key){
  
  let {selected_products}=this.state
  
  for(var v=0;v<(selected_products[0].high).length;v++){
    (selected_products[0].high)[v].selected_product = 0
  }
 
  
  this.setState({selected_products})
this.setState({
   showLeaf:false,
  selected_product_data:data
},this.toggleProdDetails)
}
de_select_products_medium(data,key){
  let {selected_products}=this.state
  

  for(var v=0;v<(selected_products[0].medium).length;v++){
    (selected_products[0].medium)[v].selected_product = 0
  }

  this.setState({selected_products})
this.setState({
   showLeaf:false,
  selected_product_data:data
},this.toggleProdDetails)
}
de_select_products_low(data,key){
  let {selected_products}=this.state
  

  for(var v=0;v<(selected_products[0].low).length;v++){
    (selected_products[0].low)[v].selected_product = 0
  }
  
  this.setState({selected_products})
this.setState({
   showLeaf:false,
  selected_product_data:data
},this.toggleProdDetails)
}
de_select_products_dc(data,key){

  let {OEM}=this.state
  for(var v=0;v<((OEM)[0].dc).length;v++){
   ((OEM)[0].dc)[v].selected_product=0
  }

  this.setState({OEM})
this.setState({
   showLeaf:false,
},this.toggleProdDetails)
}
select_product_high(data,key){
  let {selected_products}=this.state
  
  for(var v=0;v<(selected_products[0].high).length;v++){
    (selected_products[0].high)[v].selected_product = 0
  }
  let targetProduct=(selected_products[0].high)[key]
  targetProduct.selected_product=1
  this.setState({selected_products})
this.setState({
   showLeaf:false,
  selected_product_data:data
},this.toggleProdDetails)
}
select_product_medium(data,key){
  let {selected_products}=this.state
  for(var v=0;v<(selected_products[0].medium).length;v++){
    (selected_products[0].medium)[v].selected_product = 0
  }
  let targetProduct=(selected_products[0].medium)[key]
  targetProduct.selected_product=1
  this.setState({selected_products})
this.setState({
   showLeaf:false,
  selected_product_data:data,
  
},this.toggleProdDetails)
}
select_product_low(data,key){
  let {selected_products}=this.state
  for(var v=0;v<(selected_products[0].low).length;v++){
    (selected_products[0].low)[v].selected_product = 0
  }
  let targetProduct=(selected_products[0].low)[key]
  targetProduct.selected_product=1
  this.setState({selected_products})
this.setState({
   showLeaf:false,
  selected_product_data:data
},this.toggleProdDetails)
}
select_product_dc(data,key){

  let {OEM}=this.state
  for(var v=0;v<((OEM)[0].dc).length;v++){
   ((OEM)[0].dc)[v].selected_product=0
  }
let targetProduct=((OEM)[0].dc)[key]
  targetProduct.selected_product=1
  this.setState({OEM})
this.setState({
   showLeaf:false,
  selected_product_data:data
},this.toggleProdDetails)
}
renderHigh(){

  if((this.state.selected_products[0].high).length!=0){
    if((this.state.selected_products[0].high)[0].product_name!='Select Product'){
    return(
      <div >
      <div style={{display:'flex',alignItems:'center',height:40,flexDirection:'row',paddingLeft:5,backgroundColor:this.state.picto_type == 'high' ? '#E9E7E7' : "white",}}><MDBIcon icon='circle' size='sm' className='black-text' style={{fontSize:5,paddingRight:10}}/>
        <text style={{cursor:'pointer',color:"black",fontSize:16,paddingRight:5,paddingTop:5,paddingBottom:5,fontWeight:400,
        display:'flex'}}  
        onClick={()=>this.openPicto(this.state.selected_products[0].high,'high')}> {this.state.highText}</text>
        </div> 

        
      </div>
    )
  }
  }

}

renderMedium(){
  if((this.state.selected_products[0].medium).length!=0){
    if((this.state.selected_products[0].medium)[0].product_name!='Select Product'){
    return(
      <div >
       <div style={{display:'flex',alignItems:'center',height:40,flexDirection:'row',paddingLeft:5,backgroundColor:this.state.picto_type == 'medium' ? '#E9E7E7' : "white",}}><MDBIcon icon='circle' size='sm' className='black-text' style={{fontSize:5,paddingRight:10}}/>
        <text style={{cursor:'pointer',color:"black",fontSize:16,flexDirection:'column',display:'flex',paddingRight:5,paddingTop:5,paddingBottom:5,fontWeight:400,}}  
        onClick={()=>this.openPicto(this.state.selected_products[0].medium,'medium')}> {this.state.mediumText}</text>
        </div>

      </div>
    )
  }
  }
}
renderLow(){
  if((this.state.selected_products[0].low).length!=0){
    if((this.state.selected_products[0].low)[0].product_name!='Select Product'){
    return(
      <div >
       <div style={{display:'flex',alignItems:'center',height:40,flexDirection:'row',paddingLeft:5,backgroundColor:this.state.picto_type == 'low' ? '#E9E7E7' : "white",}}><MDBIcon icon='circle' size='sm' className='black-text' style={{fontSize:5,paddingRight:10}}/>
       <text style={{cursor:'pointer',color:"black",fontSize:16,flexDirection:'column',fontWeight:400,
       display:'flex',paddingRight:5,paddingTop:5,paddingBottom:5,}}  
       onClick={()=>this.openPicto(this.state.selected_products[0].low,'low')}> {this.state.lowText}</text>
        </div>

      </div>
    )
  }
  }
} 
showLeaf(){

  
  this.setState({showLeaf:true},()=>document.getElementById('leaflet').scrollIntoView())
}
renderAbb(){
  if(this.state.showLeaf){
      let columns=[];
     
  {
     JSON.parse(this.state.selected_product_data.product_leaflet).forEach((data,key) => {

      // push column
      columns.push(
        <text onClick={()=>this.openBro(data.leaflet)} className='seriesText'>{data.name}</text>

      )

      // force wrap to next row every 4 columns
      if ((key+1)%4===0) {columns.push(<div className="w-100"></div>)}
  })
  }

    return(
      <ScrollAnimation  isVisible={true} style={{opacity:1,height:'18%',width:'100%',marginLeft:'1%',marginBottom:-34}}>   
    <div id='leaflet' style={{display:'flex',marginTop:25,width:'55%',justifyContent:'center',width:'100%'}} className="row">
      
    
     {columns}
    
      </div>
      </ScrollAnimation>
    )
  }
}
renderAbbmob(){
  if(this.state.showLeaf){
      let columns=[];
     
  {
     JSON.parse(this.state.selected_product_data.product_leaflet).forEach((data,key) => {

      // push column
      columns.push(
        <text onClick={()=>window.open(data.leaflet,'_blank')} className='seriesText'>{data.name}</text>

      )

      // force wrap to next row every 4 columns
      if ((key+1)%4===0) {columns.push(<div className="w-100"></div>)}
  })
  }

    return(
      <ScrollAnimation  isVisible={true} style={{opacity:1,height:'18%',width:'100%',marginLeft:'1%'}}>   
    <div id='leaflet' style={{display:'flex',marginTop:25,width:'55%',justifyContent:'center',width:'100%'}} className="row">
      
    
     {columns}
    
      </div>
      </ScrollAnimation>
    )
  }
}
renderLeaf(){

  if(this.state.selected_product_data.product_leaflet!='null'){
 return(
    <button className='brochure'  onClick={()=>this.showLeaf()}>{this.state.leafText}</button>
  )
  }
 

}
openBro(filePath){
  var userAgent = navigator.userAgent.toLowerCase();
  if (userAgent.indexOf(' electron/') > -1) {
    const {shell} = window.require('electron');
    shell.openExternal(filePath);
 
  }
  else{
    window.open(filePath, "_blank")
  }
  
}
  renderBrochure(){
  
    if(this.state.selected_product_data.brochure && this.state.selected_product_data.website){
      return(<div style={{marginTop:25,cursor:'pointer',marginBottom:30,
      display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'center'}}>
      <button className='brochure'   onClick={()=>this.openBro(this.state.selected_product_data.brochure)}>{this.state.broText}</button>
      <button className="brochure"  onClick={()=>this.openBro(this.state.selected_product_data.website)}>{this.state.webText}</button>
      {this.renderLeaf()}
      </div>)
    }
    else if(!this.state.selected_product_data.brochure && this.state.selected_product_data.website)
    {
      return(<div style={{marginTop:25,cursor:'pointer',marginBottom:30}}>
      <button className="brochure" onClick={()=>this.openBro(this.state.selected_product_data.website)}>{this.state.webText}</button>
      {this.renderLeaf()}
      </div>)
    }
    else if(this.state.selected_product_data.brochure && !this.state.selected_product_data.website )
    {
      return(<div style={{marginTop:25,cursor:'pointer',marginBottom:30}}>
      <button className="brochure" onClick={()=>this.openBro(this.state.selected_product_data.brochure)}>{this.state.broText}</button>
      {this.renderLeaf()}
      </div>)
    }
    else return
  }
  renderSpecsDetails(){
    if(this.state.selected_product_data.specs_type==0){
      return(
           <div>
             <p className='bold_P'>{this.state.product_subs[2]}</p>
          <ul style={{marginLeft:-20,marginTop:10}}> {
            JSON.parse(this.state.selected_product_data.specs).map((data,key) => {
    
    return ( 
     
    
    <li style={{color:'black',fontWeight:400,fontFamily:'ABBvoice-Light'}}>{data.application}</li>
   
    
     
          )
    
    
    })   
          }
          </ul>
           </div>
             
      )
    }
    else if(this.state.selected_product_data.specs_type==2){
      return
    }
    else if(this.state.selected_product_data.specs_type==1){
      return(
        <div style={{display:'flex',flexDirection:'column'}}>
              <text style={{color:'black',fontSize:20,fontWeight:'bold',marginBottom:10}}>{this.state.product_subs[2]}</text>
              <table className="table table-sm dataTable" >
              
              <tr > {JSON.parse(this.state.selected_product_data.specs).headers.map((head, i)=>
              (<th  style={{fontFamily:'ABBvoice-Bold'}} key={i}>{head}</th>))} </tr>
       
             
    
              <tbody>
    
  
  {JSON.parse(this.state.selected_product_data.specs).data.map((data1, i1)=>
  <tr >{data1.value.map((data2, i2)=>(<td style={{fontFamily:'ABBvoice-Light'}} key={i2}>{data2}</td>))}</tr>)}
    
    
              </tbody>
    
    
            </table>
        
        </div>
  
        
      )
    }
  }
  
  renderThingMArk=()=>{

     if(this.state.selected_product_data.product_id==14 || this.state.selected_product_data.product_id==15 ||      
        this.state.selected_product_data.product_id==16 ||this.state.selected_product_data.product_id==18 ||
        this.state.selected_product_data.product_id==19 ||this.state.selected_product_data.product_id==23 ||
        this.state.selected_product_data.product_id==25 ||this.state.selected_product_data.product_id==26 ||
        this.state.selected_product_data.product_id==28 ||this.state.selected_product_data.product_id==30 ||
        this.state.selected_product_data.product_id==44 ||this.state.selected_product_data.product_id==46 ||
        this.state.selected_product_data.product_id==47 ||this.state.selected_product_data.product_id==49){
        return(
          <div className='thingContainer'>
             <img onError={()=>this.setState({showAdded:true})} alt='Loading...'  src={this.state.selected_product_data.thingmark} className='thingmark' 
       
       
             />
             <p className='thingText'>Scan the thingmark on top with CapDes Augmented Reality app for 360 degree view</p>
          </div>
          )
      }
   
 
  }
renderMultiImage(){

  return(
    <div className='imgContainer'>
    <img onError={()=>this.setState({showAdded:true})} alt='Loading...'  ref={(ref) => this._div = ref} src={this.state.selected_product_data.image}  className='productIm'/>
   {this.renderThingMArk()}
        </div>
   )
}
renderMultiImagemobile(){

  return(
    <div className='imgContainer' style={{paddingTop:10,paddingBottom:10}}>
    <img onError={()=>this.setState({showAdded:true})} alt='Loading...'  ref={(ref) => this._div = ref} src={this.state.selected_product_data.image}  className='productIm'/>
        </div>
   )
}
renderProductDetails(){
  var style={opacity:1,backgroundColor:'#fff',height:'100vh',borderStyle:'solid',borderColor:'#d2d2d2',borderWidth:1,
  padding:'2.5%',overflowY:'auto'
  }
  

  if(this.state.show_product_details){

    return(
      <div id="mySidebar3"  className="sidebar3" onMouseEnter={()=>this.setState({hover3:true})} >
     <ScrollAnimation animateOnce={false}  animateIn="slideInLeft"  className='slideInLeft' animationOut="slideOutRight" 
     isVisible={true} style={style}>
              <div id='productTop'></div>
              <div id='productIm' style={{display:'flex',flexDirection:'column',padding:'1%'}}>
      
      {this.renderMultiImage()}
       
     <text style={{color:'black',fontSize:30,fontWeight:'bold'}}>{this.state.selected_product_data.product_name}</text>
        <text style={{color:'black'}}>{this.state.selected_product_data.product_description}</text>
        <br/>
       
        <br/>
        <p className='bold_P'>{this.state.product_subs[0]}</p>
         <ul style={{marginLeft:-20,marginTop:10}}>
         {
           JSON.parse(this.state.selected_product_data.features).map((data,key) => {
  
  return ( 
   
 
  <li style={{color:'black',fontWeight:400,fontFamily:'ABBvoice-Light'}}>{data.feature}</li>
  
  
   
        )
  
  
  })} 
  </ul>
        <br/> 
        <p className='bold_P'>{this.state.product_subs[1]}</p>
        
        <ul style={{marginLeft:-20,marginTop:10}}>{
           JSON.parse(this.state.selected_product_data.application).map((data,key) => {
  
  return ( 
   
 
  <li style={{color:'black',fontWeight:400,fontFamily:'ABBvoice-Light'}}>{data.application}</li>
  
  
   
        )
  
  
  })}  
  </ul>
     <br/>  

       
  {this.renderSpecsDetails()}
  {this.renderBrochure()}
  {this.renderAbb()}

      </div>
            </ScrollAnimation>
            </div>
    )
  }
 
}
renderProductDetailsmobile(){
  var style={opacity:1,backgroundColor:'#fff',height:'100vh',borderStyle:'solid',borderColor:'#d2d2d2',borderWidth:1,
  padding:'2.5%',overflowY:'auto'
  }
  

  if(this.state.show_product_details){

    return(
      <div   onMouseEnter={()=>this.setState({hover3:true})} >
              
              <div id='productTopMob' style={{display:'flex',flexDirection:'column',padding:'3%'}}>
      
      {this.renderMultiImagemobile()}
       
     <text style={{color:'black',fontSize:20,fontWeight:'bold'}}>{this.state.selected_product_data.product_name}</text>
        <text style={{color:'black'}}>{this.state.selected_product_data.product_description}</text>
        <br/>
        <p className='bold_P' style={{color:'black'}}>{this.state.product_subs[0]}</p>
         <ul style={{marginLeft:-20,marginTop:10}}>
         {
           JSON.parse(this.state.selected_product_data.features).map((data,key) => {
  
  return ( 
   
 
  <li style={{color:'black',fontWeight:400,fontFamily:'ABBvoice-Light'}}>{data.feature}</li>
  
  
   
        )
  
  
  })} 
  </ul>
        <p className='bold_P' style={{color:'black'}}>{this.state.product_subs[1]}</p>
        
        <ul style={{marginLeft:-20,marginTop:10}}>{
           JSON.parse(this.state.selected_product_data.application).map((data,key) => {
  
  return ( 
   
 
  <li style={{color:'black',fontWeight:400,fontFamily:'ABBvoice-Light'}}>{data.application}</li>
  
  
   
        )
  
  
  })}  
  </ul>
     <br/>  

       
  {this.renderSpecsDetails()}
  {this.renderBrochure()}
  {this.renderAbbmob()}
      </div>
            </div>
    )
  }
 
}

openPicto(data,type){

if(this.state.selected_catagory.catagory_id==8 || this.state.selected_catagory.catagory_id==21 || this.state.selected_catagory.catagory_id==34 || this.state.selected_catagory.catagory_id==47 || this.state.selected_catagory.catagory_id==60){
  let {OEM} = this.state
  let selected_type = data

 for(let v=0;v<selected_type.length;v++){
  selected_type[v].selected=0
  }
  let high=OEM[0].high
  let medium=OEM[0].medium
  let low=OEM[0].low
  let dc=OEM[0].dc

  for(let v=0;v<high.length;v++){
    high[v].selected_product=0
  }
  for(let v=0;v<medium.length;v++){
    medium[v].selected_product=0
  }
  for(let v=0;v<low.length;v++){
    low[v].selected_product=0
  }
  for(let v=0;v<dc.length;v++){
    dc[v].selected_product=0
  }
this.setState({OEM})
  this.setState({
    selected_picto:selected_type,
    picto_type:type,
    selected_picto_type:type
},this.togglePicto)
}
else if(this.state.selected_catagory.catagory_id==3 || this.state.selected_catagory.catagory_id==16 || this.state.selected_catagory.catagory_id==29 || this.state.selected_catagory.catagory_id==42 || this.state.selected_catagory.catagory_id==55){
  let {Accessories} = this.state
  let selected_type = data
 
 for(let v=0;v<selected_type.length;v++){
  selected_type[v].selected=0
  }
  let high=Accessories[0].high
  let medium=Accessories[0].medium
  let low=Accessories[0].low


  for(let v=0;v<high.length;v++){
    high[v].selected_product=0
  }
  for(let v=0;v<medium.length;v++){
    medium[v].selected_product=0
  }
  for(let v=0;v<low.length;v++){
    low[v].selected_product=0
  }
 
this.setState({Accessories})
  this.setState({
    selected_picto:selected_type,
    picto_type:type,
    selected_picto_type:type
},this.togglePicto)
}
else{
  let {selected_products} = this.state
  let selected_type = data
 
 for(let v=0;v<selected_type.length;v++){
  selected_type[v].selected=0
  }
  let high=selected_products[0].high
  let medium=selected_products[0].medium
  let low=selected_products[0].low
  for(let v=0;v<high.length;v++){
    high[v].selected_product=0
  }
  for(let v=0;v<medium.length;v++){
    medium[v].selected_product=0
  }
  for(let v=0;v<low.length;v++){
    low[v].selected_product=0
  }
this.setState({selected_products})
  this.setState({
    selected_picto:selected_type,
    picto_type:type,
    selected_picto_type:type
},this.togglePicto)
}
}
//mobile
renderProHighHovermob(data,key){
  return(
    this.state.products.map((data2, key2) => {

      if(data2.product_id==data.product_id){
        return( <div style={{width:'100%'}}><div  
          className="pictoActivemob" onClick={()=>this.de_select_products_high(data2,key)}    style={{display:'flex',flexDirection:'row',width:'97%',height:'100%',backgroundColor:'#fff',justifyContent:'space-between'}}>
   <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',padding:10}}> <img onError={()=>this.setState({showAdded:true})} alt='Loading...'  src={data2.pictogram} style={{width:30,marginLeft:10}}/>
      <text style={{color:'black',fontWeight:500,paddingLeft:5,paddingRight:5,fontSize:14}}>{data2.product_name}</text>
      </div>  
      <MDBIcon icon="caret-up" className='black-text'/>

      </div>
{this.renderProductDetailsmobile()}</div>)
      }
      
        })
  )


}
renderProHighmob(data,key){
  return(
    this.state.products.map((data2, key2) => {
   

      if(data2.product_id==data.product_id){
       
        return( 
        <div  
          className="pictoInActivemob"  onClick={()=>this.select_product_high(data2,key)}   style={{display:'flex',flexDirection:'row',width:'97%',height:'100%',justifyContent:'space-between'}}>
    <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',padding:10}}><img onError={()=>this.setState({showAdded:true})} alt='Loading...' src={data2.pictogram} style={{width:30}}/>
      <text style={{color:'black',fontWeight:500,paddingLeft:5,paddingRight:5,fontSize:14}}>{data2.product_name}</text>
      </div>  
       <MDBIcon icon="caret-down" className='black-text' style={{margin:10}}/>
      </div>)
      }
        })
  )
 

}
renderProMediumHovermob(data,key){
  return(
    this.state.products.map((data2, key2) => {

      if(data2.product_id==data.product_id){
        return( <div style={{width:'100%'}}>
        <div  
          className="pictoActivemob" onClick={()=>this.de_select_products_medium(data2,key)}    style={{display:'flex',flexDirection:'row',width:'97%',height:'100%',backgroundColor:'#fff',justifyContent:'space-between'}}>
     <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',padding:10}}> <img onError={()=>this.setState({showAdded:true})} alt='Loading...'  src={data2.pictogram} style={{width:30,marginLeft:10}}/>
      <text style={{color:'black',fontWeight:500,paddingLeft:5,paddingRight:5,fontSize:14}}>{data2.product_name}</text></div>
      <MDBIcon icon="caret-up" className='black-text'/>
      </div>
      {this.renderProductDetailsmobile()}</div>)
      }
      
        })
  )


}
renderProMediummob(data,key){
  return(
    this.state.products.map((data2, key2) => {
   

      if(data2.product_id==data.product_id){
       
        return( 
        <div  
          className="pictoInActivemob"  onClick={()=>this.select_product_medium(data2,key)}   style={{display:'flex',flexDirection:'row',width:'97%',height:'100%',justifyContent:'space-between'}}>
    <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',padding:10}}>  <img onError={()=>this.setState({showAdded:true})} alt='Loading...' src={data2.pictogram} style={{width:30}}/>
      <text style={{color:'black',fontWeight:500,paddingLeft:5,paddingRight:5,fontSize:14}}>{data2.product_name}</text>
      </div>
      <MDBIcon icon="caret-down" className='black-text' style={{margin:10}}/>
      </div>)
      }
        })
  )
 

}
renderProLowHovermob(data,key){
  return(
    this.state.products.map((data2, key2) => {

      if(data2.product_id==data.product_id){
        return(<div style={{width:'100%'}}>
        <div  
          className="pictoActivemob" onClick={()=>this.de_select_products_low(data2,key)}    style={{display:'flex',flexDirection:'row',width:'97%',height:'100%',backgroundColor:'#fff',justifyContent:'space-between'}}>
     <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',padding:10}}><img onError={()=>this.setState({showAdded:true})} alt='Loading...'  src={data2.pictogram} style={{width:30,marginLeft:10}}/>
      <text style={{color:'black',fontWeight:500,paddingLeft:5,paddingRight:5,fontSize:14}}>{data2.product_name}</text>
    </div>
      <MDBIcon icon="caret-up" className='black-text'/>
      </div>
      {this.renderProductDetailsmobile()}</div>)
      }
      
        })
  )


}
renderProLowmob(data,key){
  return(
    this.state.products.map((data2, key2) => {
   

      if(data2.product_id==data.product_id){
       
        return( 
        <div  
          className="pictoInActivemob"  onClick={()=>this.select_product_low(data2,key)}   style={{display:'flex',flexDirection:'row',width:'97%',height:'100%',justifyContent:'space-between'}}>
    <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',padding:10}}><img onError={()=>this.setState({showAdded:true})} alt='Loading...' src={data2.pictogram} style={{width:30}}/>
      <text style={{color:'black',fontWeight:500,paddingLeft:5,paddingRight:5,fontSize:14}}>{data2.product_name}</text>
      
      </div>  
      <MDBIcon icon="caret-down" className='black-text' style={{margin:10}}/>
      </div>)
      }
        })
  )
 

}
renderProDcHovermob(data,key){
  return(
    this.state.products.map((data2, key2) => {

      if(data2.product_id==data.product_id){
        return( <div style={{width:'100%'}}>
        <div  
          className="pictoActivemob" onClick={()=>this.de_select_products_dc(data2,key)}    style={{display:'flex',flexDirection:'row',width:'97%',height:'100%',backgroundColor:'#fff',justifyContent:'space-between'}}>
     <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',padding:10}}><img onError={()=>this.setState({showAdded:true})} alt='Loading...'  src={data2.pictogram} style={{width:30,marginLeft:10}}/>
     <text style={{color:'black',fontWeight:500,paddingLeft:5,paddingRight:5,fontSize:14}}>{data2.product_name}</text>
     </div> 
     
      <MDBIcon icon="caret-up" className='black-text'/>
      </div>

      {this.renderProductDetailsmobile()}
      </div>)
      }
      
        })
  )


}
renderProDcmob(data,key){
  return(
    this.state.products.map((data2, key2) => {
   

      if(data2.product_id==data.product_id){
       
        return( 
          <div style={{width:'100%'}}>
        <div  
          className="pictoInActivemob"  onClick={()=>this.select_product_dc(data2,key)}   style={{display:'flex',flexDirection:'row',width:'97%',height:'100%',justifyContent:'space-between'}}>
   <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',padding:10}}>  <img onError={()=>this.setState({showAdded:true})} alt='Loading...' src={data2.pictogram} style={{width:30}}/>
      <text style={{color:'black',fontWeight:500,paddingLeft:5,paddingRight:5,fontSize:14}}>{data2.product_name}</text>
      </div> 
      <MDBIcon icon="caret-down" className='black-text' style={{margin:10}}/>
      
      </div>
      
      </div>)
      }
        })
  )
 

}
//window
renderProHighHover(data,key){
  return(
    this.state.products.map((data2, key2) => {

      if(data2.product_id==data.product_id){
        return( <div  
          className="pictoActive" onClick={()=>this.select_product_high(data2,key)}    style={{display:'flex',flexDirection:'column',width:'100%',height:'35%',backgroundColor:'#d2d2d2'}}>
      <img onError={()=>this.setState({showAdded:true})} alt='Loading...'  src={data2.picto_hover} className='picoImage'/>
      <text className='pictoText' style={{color:'white',fontWeight:500,paddingLeft:5,paddingRight:5}}>{data2.product_name}</text>
      </div>)
      }
      
        })
  )


}
renderProHigh(data,key){
  return(
    this.state.products.map((data2, key2) => {
   

      if(data2.product_id==data.product_id){
       
        return( 
        <div  
          className="pictoInActive"  onClick={()=>this.select_product_high(data2,key)}   style={{display:'flex',flexDirection:'column',width:'100%',height:'50%'}}>
      <img onError={()=>this.setState({showAdded:true})} alt='Loading...' src={data2.pictogram} className='picoImage'/>
      <text className='pictoText' style={{color:'black',fontWeight:500,paddingLeft:5,paddingRight:5}}>{data2.product_name}</text>
      </div>)
      }
        })
  )
 

}
renderProMediumHover(data,key){
  return(
    this.state.products.map((data2, key2) => {

      if(data2.product_id==data.product_id){
        return( <div  
          className="pictoActive" onClick={()=>this.select_product_medium(data2,key)}    style={{display:'flex',flexDirection:'column',width:'100%',height:'35%',backgroundColor:'#d2d2d2'}}>
      <img onError={()=>this.setState({showAdded:true})} alt='Loading...'  src={data2.picto_hover} className='picoImage'/>
      <text className='pictoText' style={{color:'white',fontWeight:500,paddingLeft:5,paddingRight:5}}>{data2.product_name}</text>
      </div>)
      }
      
        })
  )


}
renderProMedium(data,key){
  return(
    this.state.products.map((data2, key2) => {
   

      if(data2.product_id==data.product_id){
       
        return( 
        <div  
          className="pictoInActive"  onClick={()=>this.select_product_medium(data2,key)}   style={{display:'flex',flexDirection:'column',width:'100%',height:'35%'}}>
      <img onError={()=>this.setState({showAdded:true})} alt='Loading...' src={data2.pictogram} className='picoImage'/>
      <text className='pictoText' style={{color:'black',fontWeight:500,paddingLeft:5,paddingRight:5}}>{data2.product_name}</text>
      </div>)
      }
        })
  )
 

}
renderProLowHover(data,key){
  return(
    this.state.products.map((data2, key2) => {

      if(data2.product_id==data.product_id){
        return( <div  
          className="pictoActive" onClick={()=>this.select_product_low(data2,key)}    style={{display:'flex',flexDirection:'column',width:'100%',height:'35%',backgroundColor:'#d2d2d2'}}>
      <img onError={()=>this.setState({showAdded:true})} alt='Loading...'  src={data2.picto_hover} className='picoImage'/>
      <text className='pictoText' style={{color:'white',fontWeight:500,paddingLeft:5,paddingRight:5}}>{data2.product_name}</text>
      </div>)
      }
      
        })
  )


}
renderProLow(data,key){
  return(
    this.state.products.map((data2, key2) => {
   

      if(data2.product_id==data.product_id){
       
        return( 
        <div  
          className="pictoInActive"  onClick={()=>this.select_product_low(data2,key)}   style={{display:'flex',flexDirection:'column',width:'100%',height:'35%'}}>
      <img onError={()=>this.setState({showAdded:true})} alt='Loading...' src={data2.pictogram} className='picoImage'/>
      <text className='pictoText' style={{color:'black',fontWeight:500,paddingLeft:5,paddingRight:5}}>{data2.product_name}</text>
      </div>)
      }
        })
  )
 

}
renderProDcHover(data,key){
  return(
    this.state.products.map((data2, key2) => {

      if(data2.product_id==data.product_id){
        return( <div  
          className="pictoActive" onClick={()=>this.select_product_dc(data2,key)}    style={{display:'flex',flexDirection:'column',width:'100%',height:'35%',backgroundColor:'#d2d2d2'}}>
      <img onError={()=>this.setState({showAdded:true})} alt='Loading...'  src={data2.picto_hover} className='picoImage'/>
      <text className='pictoText' style={{color:'white',fontWeight:500,paddingLeft:5,paddingRight:5}}>{data2.product_name}</text>
      </div>)
      }
      
        })
  )


}
renderProDc(data,key){
  return(
    this.state.products.map((data2, key2) => {
   

      if(data2.product_id==data.product_id){
       
        return( 
        <div  
          className="pictoInActive"  onClick={()=>this.select_product_dc(data2,key)}   style={{display:'flex',flexDirection:'column',width:'100%',height:'35%'}}>
      <img onError={()=>this.setState({showAdded:true})} alt='Loading...' src={data2.pictogram} className='picoImage'/>
      <text className='pictoText' style={{color:'black',fontWeight:500,paddingLeft:5,paddingRight:5}}>{data2.product_name}</text>
      </div>)
      }
        })
  )
 

}


renderPictogram(){

  if(this.state.hover2){
    var style={opacity:1,backgroundColor:'#fff',height:'100vh',borderStyle:'solid',borderColor:'#d2d2d2',borderWidth:1,
    overflow:'auto'}
  }
  else{
    var style={opacity:1,backgroundColor:'#fff',height:'100vh',borderStyle:'solid',borderColor:'#d2d2d2',borderWidth:1,
    overflow:'hidden'}
  }

  if(this.state.show_picto){


    if(this.state.selected_picto_type=='high'){
      return(
        <div id="mySidebar2" className="sidebar2" style={{left:'20vw'}} onMouseEnter={()=>this.setState({hover2:true})} onMouseLeave={()=>this.setState({hover2:false})}>
         <ScrollAnimation animateOnce={false} animateIn="slideInLeft"  className='slideInLeft' animationOut="slideOutLeft" 
     isVisible={true}  style={style}>
                <MDBRow style={{margin:0,display:'flex',justifyContent:'center',alignItems:'center'}}>
       
    <div
       
       className="sidePictoList"
       
      
     >
   {/* A short brief about what power factor is and how it is going to affect the efficiency and productivity of users'r equipment. */}
      
       <div className="sidebar-wrapper" style={{display:'flex',flexDirection:'column',backgroundColor:'#fff',justifyContent:'flex-start',alignItems:'center',marginTop:10}}>
     
       {(this.state.selected_products)[0].high.map((data, key) => {
         
       if(data.selected_product==1){
          return (
                this.renderProHighHover(data,key)
           
                
              );
          
       
       }
       else{
      
        return (

                
          this.renderProHigh(data,key)
                
              );
             
       }
            
           })}
      
       </div>
     </div>
  
    </MDBRow>
    </ScrollAnimation>
          </div>
      )
    }
    else if(this.state.selected_picto_type=='medium'){
      return(
        <div id="mySidebar2" className="sidebar2" style={{left:'20vw',overflow:'hidden'}} onMouseEnter={()=>this.setState({hover2:true})} 
        onMouseLeave={()=>this.setState({hover2:false})}>
                 <ScrollAnimation animateOnce={false} animateIn="slideInLeft"  className='slideInLeft' animationOut="slideOutLeft" 
     isVisible={true} style={style}>
              
                <MDBRow style={{margin:0,display:'flex',justifyContent:'center',alignItems:'center'}}>
       
    <div
       
       className="sidePictoList"
    
      
     >
   {/* A short brief about what power factor is and how it is going to affect the efficiency and productivity of users'r equipment. */}
      
       <div className="sidebar-wrapper" style={{display:'flex',flexDirection:'column',backgroundColor:'#fff',justifyContent:'flex-start',alignItems:'center',marginTop:10}}>
     
       {(this.state.selected_products)[0].medium.map((data, key) => {
       if(data.selected_product==1){
        
          return (
                
            this.renderProMediumHover(data,key)    
              );
              return null;
       
       }
       else{
       
        return (
                
          this.renderProMedium(data,key)
                
              );
              return null;
       }
            
           })}
      
       </div>
      
     </div>
  
    
    </MDBRow>
   
             </ScrollAnimation>
                
          </div>
      )
    }
    else if(this.state.selected_picto_type=='low'){
      return(
        <div id="mySidebar2" className="sidebar2" style={{left:'20vw'}} onMouseEnter={()=>this.setState({hover2:true})} onMouseLeave={()=>this.setState({hover2:false})} >
             <ScrollAnimation animateOnce={false} animateIn="slideInLeft"  className='slideInLeft' animationOut="slideOutLeft" 
     isVisible={true} style={style}>
                     <MDBRow style={{margin:0,display:'flex',justifyContent:'center',alignItems:'center'}}>
       
    <div
       
       className="sidePictoList"
     
      
     >
   {/* A short brief about what power factor is and how it is going to affect the efficiency and productivity of users'r equipment. */}
      
       <div className="sidebar-wrapper" style={{display:'flex',flexDirection:'column',backgroundColor:'#fff',justifyContent:'flex-start',alignItems:'center',marginTop:10}}>
     
       {(this.state.selected_products)[0].low.map((data, key) => {
        if(data.selected_product==1){
        
        return (
              
          this.renderProLowHover(data,key)
              
            );
            return null;
     
     }
     else{
      return (
              
        this.renderProLow(data,key)   
            );
            return null;
     }
            
           })}
      
       </div>
      
     </div>
  
    
    </MDBRow>
    </ScrollAnimation>
       
          </div>
      )
    }
    else if(this.state.selected_picto_type=='dc'){
      return(
        <div id="mySidebar2" className="sidebar2" style={{left:'20vw'}} onMouseEnter={()=>this.setState({hover2:true})} onMouseLeave={()=>this.setState({hover2:false})}>
           <ScrollAnimation animateOnce={false}  animateIn="slideInLeft"  className='slideInLeft' animationOut="slideOutLeft" 
     isVisible={true} style={style}>
                <MDBRow style={{margin:0,display:'flex',justifyContent:'center',alignItems:'center'}}>
       
    <div
       
       className="sidePictoList"
     
      
     >
   {/* A short brief about what power factor is and how it is going to affect the efficiency and productivity of users'r equipment. */}
      
       <div className="sidebar-wrapper" style={{display:'flex',flexDirection:'column',backgroundColor:'#fff',justifyContent:'flex-start',alignItems:'center',marginTop:10}}>
     
       {(this.state.OEM)[0].dc.map((data, key) => {
        if(data.selected_product==1){
        
        return (
              
          this.renderProDcHover(data,key)
              
            );
            return null;
     
     }
     else{
      return (
              
        this.renderProDc(data,key)
              
            );
            return null;
     }
            
           })}
      
       </div>
      
     </div>
  
    
    </MDBRow>
       
            </ScrollAnimation>   
          </div>
      )
    }
  }
 
}
activeTab(data){
  if(data==0){
    this.setState({selected_picto_type:'high'})
  }
  else if(data==1){
    this.setState({selected_picto_type:'medium'})
  }
  else if(data==2){
    this.setState({selected_picto_type:'low'})
  }
  else if(data==3){
    this.setState({selected_picto_type:'dc'})
  }
}

renderTabs(){

  if(this.state.selected_catagory.catagory_id==8 || this.state.selected_catagory.catagory_id==21 || this.state.selected_catagory.catagory_id==34 || this.state.selected_catagory.catagory_id==47 || this.state.selected_catagory.catagory_id==60){
    if(this.state.selected_picto_type=='high'){
      return( <div  style={{backgroundColor:'#fff',display:'flex',justifyContent:'space-between',alignItems:'center'}}>
      <text onClick={()=>this.activeTab(0)} style={{backgroundColor:'#F0F0F0',paddingTop:10,paddingBottom:10,paddingLeft:10,paddingRight:10}}>{this.state.highMob}</text>
      <text onClick={()=>this.activeTab(1)} style={{paddingTop:10,paddingBottom:10,paddingLeft:10,paddingRight:10}}>{this.state.mediumMob}</text>
      <text onClick={()=>this.activeTab(3)} style={{paddingTop:10,paddingBottom:10,paddingLeft:10,paddingRight:10}}>{this.state.dcText}</text>   
      <text onClick={()=>this.activeTab(2)} style={{paddingTop:10,paddingBottom:10,paddingLeft:10,paddingRight:10}}>{this.state.lowMob}</text>   
      
      </div>)
    }
    else if(this.state.selected_picto_type=='medium'){
      return( <div  style={{backgroundColor:'#fff',display:'flex',justifyContent:'space-between',alignItems:'center'}}>
      <text onClick={()=>this.activeTab(0)} style={{paddingTop:10,paddingBottom:10,paddingLeft:10,paddingRight:10}}>{this.state.highMob}</text>
      <text onClick={()=>this.activeTab(1)} style={{backgroundColor:'#F0F0F0',paddingTop:10,paddingBottom:10,paddingLeft:10,paddingRight:10}}>{this.state.mediumMob}</text>
      <text onClick={()=>this.activeTab(3)} style={{paddingTop:10,paddingBottom:10,paddingLeft:10,paddingRight:10}}>{this.state.dcText}</text>   
      <text onClick={()=>this.activeTab(2)} style={{paddingTop:10,paddingBottom:10,paddingLeft:10,paddingRight:10}}>{this.state.lowMob}</text>   
      </div>)
    }
    else if(this.state.selected_picto_type=='dc'){
      return(<div  style={{backgroundColor:'#fff',display:'flex',justifyContent:'space-between',alignItems:'center'}}>
      <text onClick={()=>this.activeTab(0)} style={{paddingTop:10,paddingBottom:10,paddingLeft:10,paddingRight:10}}>{this.state.highMob}</text>
      <text onClick={()=>this.activeTab(1)} style={{paddingTop:10,paddingBottom:10,paddingLeft:10,paddingRight:10}}>{this.state.mediumMob}</text>
      <text onClick={()=>this.activeTab(3)} style={{backgroundColor:'#F0F0F0',paddingTop:10,paddingBottom:10,paddingLeft:10,paddingRight:10}}>{this.state.dcText}</text>
      <text onClick={()=>this.activeTab(2)} style={{paddingTop:10,paddingBottom:10,paddingLeft:10,paddingRight:10}}>{this.state.lowMob}</text>   
      </div>)
    }
    else if(this.state.selected_picto_type=='low'){
      return(<div  style={{backgroundColor:'#fff',display:'flex',justifyContent:'space-between',alignItems:'center'}}>
      <text onClick={()=>this.activeTab(0)} style={{paddingTop:10,paddingBottom:10,paddingLeft:10,paddingRight:10}}>{this.state.highMob}</text>
      <text onClick={()=>this.activeTab(1)} style={{paddingTop:10,paddingBottom:10,paddingLeft:10,paddingRight:10}}>{this.state.mediumMob}</text>
      <text onClick={()=>this.activeTab(3)} style={{paddingTop:10,paddingBottom:10,paddingLeft:10,paddingRight:10}}>{this.state.dcText}</text>   
      <text onClick={()=>this.activeTab(2)} style={{backgroundColor:'#F0F0F0',paddingTop:10,paddingBottom:10,paddingLeft:10,paddingRight:10}}>{this.state.lowMob}</text>   
      </div>)
    }
  }
  else{
    if(this.state.selected_picto_type=='high'){
      return( <div  style={{backgroundColor:'#fff',display:'flex',justifyContent:'space-between',alignItems:'center'}}>
      <text onClick={()=>this.activeTab(0)} style={{backgroundColor:'#F0F0F0',paddingTop:10,paddingBottom:10,paddingLeft:10,paddingRight:10}}>{this.state.highMob}</text>
      <text onClick={()=>this.activeTab(1)} style={{paddingTop:10,paddingBottom:10,paddingLeft:10,paddingRight:10}}>{this.state.mediumMob}</text>
      <text onClick={()=>this.activeTab(2)} style={{padding:10}}>{this.state.lowMob}</text>   
      </div>)
    }
    else if(this.state.selected_picto_type=='medium'){
      return( <div  style={{backgroundColor:'#fff',display:'flex',justifyContent:'space-between',alignItems:'center'}}>
      <text onClick={()=>this.activeTab(0)} style={{paddingTop:10,paddingBottom:10,paddingLeft:10,paddingRight:10}}>{this.state.highMob}</text>
      <text onClick={()=>this.activeTab(1)} style={{backgroundColor:'#F0F0F0',paddingTop:10,paddingBottom:10,paddingLeft:10,paddingRight:10}}>{this.state.mediumMob}</text>
      <text onClick={()=>this.activeTab(2)} style={{padding:10}}>{this.state.lowMob}</text>   
      </div>)
    }
  
    else if(this.state.selected_picto_type=='low'){
      return(<div  style={{backgroundColor:'#fff',display:'flex',justifyContent:'space-between',alignItems:'center'}}>
      <text onClick={()=>this.activeTab(0)} style={{paddingTop:10,paddingBottom:10,paddingLeft:10,paddingRight:10}}>{this.state.highMob}</text>
      <text onClick={()=>this.activeTab(1)} style={{paddingTop:10,paddingBottom:10,paddingLeft:10,paddingRight:10}}>{this.state.mediumMob}</text>
      <text onClick={()=>this.activeTab(2)} style={{backgroundColor:'#F0F0F0',paddingTop:10,paddingBottom:10,paddingLeft:10,paddingRight:10}}>{this.state.lowMob}</text>   
      </div>)
    }
  }
 
}
renderPictogrammobile(){

  if(this.state.hover2){
    var style={opacity:1,backgroundColor:'#fff',height:'100vh',borderStyle:'solid',borderColor:'#d2d2d2',borderWidth:1,
    overflow:'auto'}
  }
  else{
    var style={opacity:1,backgroundColor:'#fff',height:'100vh',borderStyle:'solid',borderColor:'#d2d2d2',borderWidth:1,
    overflow:'hidden'}
  }

  if(this.state.show_picto){


    if(this.state.selected_picto_type=='high'){
      return(
        <div id="mySidebarmobile" className="sidebarmobile"  onMouseEnter={()=>this.setState({hover2:true})} onMouseLeave={()=>this.setState({hover2:false})}>
         <ScrollAnimation animateOnce={false} animateIn="slideInLeft"  className='slideInLeft' animationOut="slideOutLeft" 
     isVisible={true}  style={style}>
     <div className='topMenu'>
 {this.renderLanguagesback()}

 
 
 <div style={{display:'flex',justifyContent:'center'}}><img onError={()=>this.setState({showAdded:true})} alt='Loading...' src={require('../../assets/abb_logo.png')} className='abb_logo'/></div>
    
  

          
           </div>
           <div className='topMenu' style={{backgroundColor:'#F0F0F0',justifyContent:"flex-start",paddingLeft:0}}>

  
<div style={{backgroundColor:'#ff000f'}}>
<img onError={()=>this.setState({showAdded:true})} alt='Loading...' src={this.state.selected_catagory.hover_image} style={{width:40,height:40,margin:5}}/>

</div>
<text style={{marginLeft:20}}>{this.getSubNamemobile(this.state.selected_subcatagory)}</text>
 
        
         
          </div>
          {this.renderTabs()}
                <MDBRow style={{margin:0,display:'flex',justifyContent:'center',alignItems:'center'}}>
       
    <div
       
       className="sidePictoList"
       
      
     >
   {/* A short brief about what power factor is and how it is going to affect the efficiency and productivity of users'r equipment. */}
      
       <div className="sidebar-wrapper" style={{display:'flex',flexDirection:'column',backgroundColor:'#fff',justifyContent:'flex-start',alignItems:'center',marginTop:10}}>
     
       {(this.state.selected_products)[0].high.map((data, key) => {
         
       if(data.selected_product==1){
          return (
                this.renderProHighHovermob(data,key)
           
                
              );
          
       
       }
       else{
      
        return (

                
          this.renderProHighmob(data,key)
                
              );
             
       }
            
           })}
      
       </div>
      
     </div>
  
    </MDBRow>
    </ScrollAnimation>
          </div>
      )
    }
    else if(this.state.selected_picto_type=='medium'){
      return(
        <div id="mySidebar2" className="sidebarmobile"  onMouseEnter={()=>this.setState({hover2:true})} 
        onMouseLeave={()=>this.setState({hover2:false})}>
                 <ScrollAnimation animateOnce={false} animateIn="slideInLeft"  className='slideInLeft' animationOut="slideOutLeft" 
     isVisible={true} style={style}>
                   <div className='topMenu'>
 {this.renderLanguagesback()}

 
 
 <div style={{display:'flex',justifyContent:'center'}}><img onError={()=>this.setState({showAdded:true})} alt='Loading...' src={require('../../assets/abb_logo.png')} className='abb_logo'/></div>
    
  

          
           </div>
           <div className='topMenu' style={{backgroundColor:'#F0F0F0',justifyContent:"flex-start",paddingLeft:0}}>

  
<div style={{backgroundColor:'#ff000f'}}>
<img onError={()=>this.setState({showAdded:true})} alt='Loading...' src={this.state.selected_catagory.hover_image} style={{width:40,height:40,margin:5}}/>

</div>
<text style={{marginLeft:20}}>{this.getSubNamemobile(this.state.selected_subcatagory)}</text>
 
        
         
          </div>
          {this.renderTabs()}
                <MDBRow style={{margin:0,display:'flex',justifyContent:'center',alignItems:'center'}}>
       
    <div
       
       className="sidePictoList"
    
      
     >
   {/* A short brief about what power factor is and how it is going to affect the efficiency and productivity of users'r equipment. */}
      
       <div className="sidebar-wrapper" style={{display:'flex',flexDirection:'column',backgroundColor:'#fff',justifyContent:'flex-start',alignItems:'center',marginTop:10}}>
     
       {(this.state.selected_products)[0].medium.map((data, key) => {
       if(data.selected_product==1){
        
          return (
                
            this.renderProMediumHovermob(data,key)    
              );
              return null;
       
       }
       else{
       
        return (
                
          this.renderProMediummob(data,key)
                
              );
              return null;
       }
            
           })}
      
       </div>
      
     </div>
  
    
    </MDBRow>
   
             </ScrollAnimation>
                
          </div>
      )
    }
    else if(this.state.selected_picto_type=='low'){
      return(
        <div id="mySidebar2" className="sidebarmobile"  onMouseEnter={()=>this.setState({hover2:true})} onMouseLeave={()=>this.setState({hover2:false})} >
             <ScrollAnimation animateOnce={false} animateIn="slideInLeft"  className='slideInLeft' animationOut="slideOutLeft" 
     isVisible={true} style={style}>
          <div className='topMenu'>
 {this.renderLanguagesback()}

 
 
 <div style={{display:'flex',justifyContent:'center'}}><img onError={()=>this.setState({showAdded:true})} alt='Loading...' src={require('../../assets/abb_logo.png')} className='abb_logo'/></div>
    
  

          
           </div>
           <div className='topMenu' style={{backgroundColor:'#F0F0F0',justifyContent:"flex-start",paddingLeft:0}}>

  
<div style={{backgroundColor:'#ff000f'}}>
<img onError={()=>this.setState({showAdded:true})} alt='Loading...' src={this.state.selected_catagory.hover_image} style={{width:40,height:40,margin:5}}/>

</div>
<text style={{marginLeft:20}}>{this.getSubNamemobile(this.state.selected_subcatagory)}</text>
 
        
         
          </div>
          {this.renderTabs()}
                     <MDBRow style={{margin:0,display:'flex',justifyContent:'center',alignItems:'center'}}>
       
    <div
       
       className="sidePictoList"
     
      
     >
   {/* A short brief about what power factor is and how it is going to affect the efficiency and productivity of users'r equipment. */}
      
       <div className="sidebar-wrapper" style={{display:'flex',flexDirection:'column',backgroundColor:'#fff',justifyContent:'flex-start',alignItems:'center',marginTop:10}}>
     
       {(this.state.selected_products)[0].low.map((data, key) => {
        if(data.selected_product==1){
        
        return (
              
          this.renderProLowHovermob(data,key)
              
            );
            return null;
     
     }
     else{
      return (
              
        this.renderProLowmob(data,key)   
            );
            return null;
     }
            
           })}
      
       </div>
      
     </div>
  
    
    </MDBRow>
    </ScrollAnimation>
       
          </div>
      )
    }
    else if(this.state.selected_picto_type=='dc'){
      return(
        <div id="mySidebar2" className="sidebarmobile"  onMouseEnter={()=>this.setState({hover2:true})} onMouseLeave={()=>this.setState({hover2:false})}>
           <ScrollAnimation animateOnce={false}  animateIn="slideInLeft"  className='slideInLeft' animationOut="slideOutLeft" 
     isVisible={true} style={style}>
          <div className='topMenu'>
 {this.renderLanguagesback()}

 
 
 <div style={{display:'flex',justifyContent:'center'}}><img onError={()=>this.setState({showAdded:true})} alt='Loading...' src={require('../../assets/abb_logo.png')} className='abb_logo'/></div>
    
  

          
           </div>
           <div className='topMenu' style={{backgroundColor:'#F0F0F0',justifyContent:"flex-start",paddingLeft:0}}>

  
<div style={{backgroundColor:'#ff000f'}}>
<img onError={()=>this.setState({showAdded:true})} alt='Loading...' src={this.state.selected_catagory.hover_image} style={{width:40,height:40,margin:5}}/>

</div>
<text style={{marginLeft:20}}>{this.getSubNamemobile(this.state.selected_subcatagory)}</text>
 
        
         
          </div>
          {this.renderTabs()}
                <MDBRow style={{margin:0,display:'flex',justifyContent:'center',alignItems:'center'}}>
       
    <div
       
       className="sidePictoList"
     
      
     >
   {/* A short brief about what power factor is and how it is going to affect the efficiency and productivity of users'r equipment. */}
      
       <div className="sidebar-wrapper" style={{display:'flex',flexDirection:'column',backgroundColor:'#fff',justifyContent:'flex-start',alignItems:'center',marginTop:10}}>
     
       {(this.state.OEM)[0].dc.map((data, key) => {
        if(data.selected_product==1){
        
        return (
              
          this.renderProDcHovermob(data,key)
              
            );
            return null;
     
     }
     else{
      return (
              
        this.renderProDcmob(data,key)
              
            );
            return null;
     }
            
           })}
      
       </div>
      
     </div>
  
    
    </MDBRow>
       
            </ScrollAnimation>   
          </div>
      )
    }
  }
 
}

renderoverLay(){
  if(this.state.catagory_details){
  
    return (<div id='overlayCat'></div>)
  }
}
renderoverLay2(){
  if(this.state.mobileMenu){
  
    return (<div id='overlayCat' onClick={()=>this.setState({mobileMenu:false})}></div>)
  }
}
mobileMenu(){
  var style={opacity:1,backgroundColor:'#fff',height:'100vh',borderStyle:'solid',borderColor:'#d2d2d2',borderWidth:1,
  overflow:'auto',padding:'2.5%',display:'flex',flexDirection:'column',alignItems:'flex-start',paddingLeft:10,paddingRight:10}

 
  if(this.state.mobileMenu){
    return(
      <div className='mobileMenu'>
        <ScrollAnimation animateOnce={false}  animateIn="slideInLeft"  className='slideInLeft' animationOut="slideOutLeft" 
     isVisible={true}  style={style}>
         {dashboardRoutes.map((prop, key) => {
                  if(prop.path=='/solutions'){
                    return (
                     
                     <div
                     key={key}
      className="sideActiveMobile"
      
       onClick={()=>this.props.history.push(`${prop.path}`)}                
      >
      <img onError={()=>this.setState({showAdded:true})} alt='Loading...' src={require('../../assets/navbar/Solution.png')} alt='Loading...' style={{width:20,marginRight:10}}/>
      
      <text style={{marginTop:5,fontSize:12}}>{prop.name}</text>
      </div>
                     
                   );
                   return null;
                  }
                  else{
                    return (
                     
                     <div
      className="sideInActiveMobile"
      
       onClick={()=>this.props.history.push(`${prop.path}`)}                
      >
      <img onError={()=>this.setState({showAdded:true})} alt='Loading...' src={require('../../assets/navbar/'+prop.icon)} style={{width:20,marginRight:10}}/>
      <text style={{marginTop:5,fontSize:12}}>{prop.name}</text>
      </div>
                     
                   );
                   return null; 
                  }
                 

                })}
               
                </ScrollAnimation>
      </div>
    )
  }
}
toggleLang = () => {
   
  this.setState({
    lang_mod: !this.state.lang_mod,
   });
}
renderlangModal(){


   
  if(this.state.lang_mod==true){


  return(
    <MDBModal className='mobileLang' size='md' isOpen={this.state.lang_mod} toggle={this.toggleLang} centered>
 
       
            <MDBModalBody>
            {/* { (this.state.languages).map((data,key) => {
    
    if(data.selected==0){
     return ( 
       <MDBDropdownItem style={{backgroundColor:'#fff',color:'#6e6e6e',textAlign:'center',padding:12}} className='dropButton'  onClick={()=>this.selectLanguage(data)}>{data.language}</MDBDropdownItem>
           
            )
    }
    else{
     return ( 
       <MDBDropdownItem style={{backgroundColor:'#d2d2d2',color:'black',textAlign:'center',padding:12}}  >{data.language}</MDBDropdownItem>
           
            )
    }
       })
       } */}
       <MDBDropdownItem style={{backgroundColor:'#d2d2d2',color:'black',textAlign:'center',padding:12}}  >ENGLISH</MDBDropdownItem>
                       </MDBModalBody>
          
      </MDBModal>
  )
}
}
isInViewport(offset = 0) {
  if (!this.yourElement) return false;
  const top = this.yourElement.getBoundingClientRect().top;
  return (top + offset) >= 0 && (top - offset) <= window.innerHeight;
}
paneDidMount = (node) => {    
  if(node) {      
    node.addEventListener("scroll", this.handleScroll.bind(this));      
  }
}

handleScroll = (event) => {    
  var node = event.target;
  const bottom = node.scrollHeight - node.scrollTop === node.clientHeight;
  if (bottom) {      
   
    this.setState({showBanner:!this.isInViewport()})
  }    
}


renderScroll(){
  if(this.state.showBanner==true && this.state.loading==false){
    return(<div className='scrollCause' onClick={()=>document.getElementById('nextSolution').scrollIntoView()}><text style={{backgroundColor:'white',fontWeight:900,boxShadow:'1px 1px 10px 1px #d2d2d2'}} className='arrow'></text></div>)
  }
}
reload = ()=>{
  const current = this.props.location.pathname;
  this.props.history.replace(`/reload`);
     setTimeout(() => {
       this.props.history.replace(current);
     });
 }
showUpdateBar() {
  if(this.state.showAdded){
    
    
      return(
        <ScrollAnimation animateOnce={false}   animateIn="fadeIn"  className='fadeIn toast2' animationOut="fadeOut" 
         isVisible={true} >
       <div className="snack">Go online and <text onClick={()=>this.reload()} style={{cursor:'pointer',textDecoration:'underline'}}>click here</text> to refresh</div>
    </ScrollAnimation>
    
       
      )
    
 
  }
  }
  trigger() {

    setTimeout(() => { 
        this.setState({
            showAdded: false
        })
    }, 5000);
  }

  render() {

  
    const sidebarBackground = {
      backgroundColor: "#1C2231"
    };
    
  var that = this
  if(this.state.loading){
   
    return(<div className="loadingSpin"><HalfCircleSpinner color="#ff000f"/></div>)
  }
  else {
    return (
    
      <div className="bodyDivCl" id="outer-container" style={{overflowY:'auto'}} onScroll={()=>this.setState({showBanner:!this.isInViewport()})} ref={that.paneDidMount}>

   <div
      id="sidebar"
      className="sideCat"
      data-color="#1C2231"
     
    >

   
      <div className="sidebar-wrapper" style={{display:'flex',flexDirection:'column',justifyContent:'flex-start',alignItems:'center',marginTop:10}}>
      {dashboardRoutes.map((prop, key) => {
                  if(prop.path=='/solutions'){
                    return (
                     
                     <div
                     key={key}
      className="sideActive"
      
       onClick={()=>this.props.history.push(`${prop.path}`)}                
      >
      <img onError={()=>this.setState({showAdded:true})} alt='Loading...' src={require('../../assets/navbar/Solution_hover.png')} style={{width:25}}/>
      
      <text style={{marginTop:5}}>{prop.name}</text>
      </div>
                     
                   );
                   return null;
                  }
                  else{
                    return (
                     
                     <div
      className="sideInActive"
      
       onClick={()=>this.props.history.push(`${prop.path}`)}                
      >
      <img onError={()=>this.setState({showAdded:true})} alt='Loading...' src={require('../../assets/navbar/'+prop.icon)} style={{width:25}}/>
      <text style={{marginTop:5}}>{prop.name}</text>
      </div>
                     
                   );
                   return null; 
                  }
                 
                })}
{this.renderExitButton()}
          
      </div>
    </div>
  
   {this.mobileMenu()}
      <div id='mainData' className="conTable">
   
 <div style={{backgroundColor:'white'}}>
    
 <div className='topMenu'>
 {this.renderLanguages()}

 
 
 <div style={{display:'flex',justifyContent:'center'}}><img onError={()=>this.setState({showAdded:true})} alt='Loading...' src={require('../../assets/abb_logo.png')} className='abb_logo'/></div>
    
  

          
           </div>
   </div>
   <div className='headText'> <h1 >
                       
                        
                       {this.state.heading}
                      
                      </h1>
  </div> 
   <div style={{display:'flex',justifyContent:'center',marginTop:-15}}>
 
      <div className='mainDivSolutions'>
    
   <div className='window'>
{this.renderList()}

</div>

<div className='mobile'  style={{marginBottom:'3%'}}>
{this.renderListmobile()}
<div className='next' ref={(el) => this.yourElement = el} id='nextSolution' style={{marginTop:10}}>
       <h5>{dashboardRoutes[3].name}</h5> <text onClick={()=>this.props.history.push('value')} className='arrowRed'></text></div>
</div>

{this.renderoverLay()}
<div className='next nextSol' style={{marginTop:10}}>
       <h5>{dashboardRoutes[3].name}</h5> <text onClick={()=>this.props.history.push('value')} className='arrowRed'></text></div>
      
 </div>

   </div>
   
   {this.renderoverLay2()}
  {this.renderScroll()}

    {this.renderDetails()} 
{this.renderExitModal()}
    {this.renderPictogram()}
    {this.renderPictogrammobile()}
 {this.renderProductDetails()}
{this.renderlangModal()}
{this.showUpdateBar()}


  

</div>



</div>
             
    );
              }
  }

    

}
export default Catagories