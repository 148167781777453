import React, { Component } from "react";
import ReactTooltip from 'react-tooltip'
import { MDBNavbar, MDBDropdownItem,MDBDropdownMenu,  MDBIcon, MDBTable, MDBTableBody, MDBTableHead, MDBBtn,MDBRow,
  MDBCol,
  MDBDropdown,
  MDBDropdownToggle,
  MDBCardHeader, MDBModal, MDBModalBody, MDBInput, MDBModalFooter,MDBCollapse,MDBPopover, MDBPopoverBody, MDBTooltip,  } from 'mdbreact';
  import dashboardRoutes from "../../routes/dashboard";
  import swal from 'sweetalert'
  import { Grid, Row, Col,Image,ListGroup,ListGroupItem,Button,Form,FormControl,Checkbox,ControlLabel, } from "react-bootstrap";
import './users.css'
import Select from 'react-select'
import CryptoJS from 'crypto'
class Users extends Component {
  constructor(props) {
    super(props);
  this.state={
    loading:false,info_heads:[],add_head:false,clients:[],
    new_name:"",new_des:"",new_hsn:"",new_type:"Select Cost Type",selected_id:"",
    selected_language:[],users:[],new_pass:'',add_user:false,
    new_name:'',new_email:'',new_password:'',edit_name:'',edit_email:'',edit_password:'',
    languages:[],new_user_languages:[],edit_user:false,user_to_edit:null,
    edit_user_languages:[],superadmin:false,admin:false,super_pass:''
  }
  }
  componentWillMount(){

  }
    getDetails(){
      var userlogin=window.localStorage.getItem('userlogin')
      var adminlogin=window.localStorage.getItem('superadmin')
      if(userlogin==null && adminlogin==null){
        this.props.history.push('products')
      }
      else if(userlogin==null && adminlogin!=null){
        this.setState({superadmin:true})
      }
      else if(userlogin!=null && adminlogin==null){
     this.logout()
      }
        }
  toggleAdd = () => {
   
    this.setState({
      add_user: !this.state.add_user,
     });
  }
  toggleEdit = () => {
   
    this.setState({
      edit_user: !this.state.edit_user,
     });
  }
  openEditModal(data){
    
    this.setState({user_to_edit:data,
    edit_name:data.admin_username,
    edit_email:data.email,
    edit_password:data.password,
    edit_user_languages:JSON.parse(data.language_access)


    },this.toggleEdit)
  }
  renderAddModal(){


   
    if(this.state.add_user==true){
  
  
    return(
      <MDBModal isOpen={this.state.add_user} toggle={this.toggleAdd} >
   
          <MDBCardHeader style={{backgroundColor:'#FF000F',display:'flex',justifyContent:'center'}}>
                      <h3 className="my-3" style={{color:'white'}}>
                        <MDBIcon icon="edit-square" /> 
                        
                        Add User
                      </h3>
                      
                    </MDBCardHeader>
              <MDBModalBody>
          
      
                       <div style={{display:"flex",alignItems:'center',justifyContent:'space-between',width:'100%'}}>
                  <text style={{margin:10,width:"30%"}}>Name</text>
          <input
                 value={this.state.new_name}
        onChange={this.handleChangenewname}
          placeholder="Name"
          icon="money-check-alt"
            type="text"
            id="new_name"
            className="form-control"
            required
            
          /></div>
  
  
  
  <div style={{display:"flex",alignItems:'center',justifyContent:'space-between',width:'100%'}}>
                  <text style={{margin:10,width:"30%"}}>Email</text>
          <input
                 value={this.state.new_email}
        onChange={this.handleChangenewemail}
          placeholder="example@abc.com"
          icon="money-check-alt"
            type="text"
            id="new_des"
            className="form-control"
            required
            
          /></div>
    <div style={{display:"flex",alignItems:'center',justifyContent:'space-between',width:'100%'}}>
                  <text style={{margin:10,width:"30%"}}>Password</text>
          <input
                 value={this.state.new_password}
        onChange={this.handleChangenew_password}
          placeholder="Password"
          icon="money-check-alt"
            type="text"
            id="new_des"
            className="form-control"
            required
            
          /></div>
         <div style={{display:"flex",alignItems:'center',justifyContent:'space-between',width:'100%'}}>
          <text style={{margin:10}}>Language Access</text>
  
          <Select
closeMenuOnSelect={false}
onChange={e=>{
   
this.setState({
  new_user_languages:e
})


}}
value={this.state.new_user_languages}

options={this.state.languages}
isMulti
/>
</div>
   <div style={{display:"flex",alignItems:'center',justifyContent:'space-between',width:'100%'}}>
                  <text style={{margin:10,width:"30%"}}>Admin Password</text>
          <input
                 value={this.state.super_pass}
        onChange={this.handleChangesuper_password}
          placeholder="Admin Password"
          icon="money-check-alt"
            type="text"
            id="new_des"
            className="form-control"
            required
            
          /></div>
  
  
  
              </MDBModalBody>
              <MDBModalFooter style={{padding:5}}>
              <Button style={{backgroundColor:'#FF000F'}} className="confirm"  onClick={()=>this.addUser()}>Confirm</Button>
              <Button style={{backgroundColor:'#FF000F'}} className="confirm"  onClick={()=>this.setState({add_user:false})}>Cancel</Button>
              
               
              </MDBModalFooter>
        </MDBModal>
    )
  }
  }
  renderEditModal(){


   
    if(this.state.edit_user==true){
  
  
    return(
      <MDBModal isOpen={this.state.edit_user} toggle={this.toggleEdit} >
   
          <MDBCardHeader style={{backgroundColor:'#FF000F',display:'flex',justifyContent:'center'}}>
                      <h3 className="my-3" style={{color:'white'}}>
                        <MDBIcon icon="edit-square" /> 
                        
                        Edit User
                      </h3>
                      
                    </MDBCardHeader>
              <MDBModalBody>
          
      
                       <div style={{display:"flex",alignItems:'center',justifyContent:'space-between',width:'100%'}}>
                  <text style={{margin:10,width:"30%"}}>Name</text>
          <input
                 value={this.state.edit_name}
        onChange={this.handleChangeeditname}
          placeholder="Name"
          icon="money-check-alt"
            type="text"
            id="new_name"
            className="form-control"
            required
            
          /></div>
  
  
  
  <div style={{display:"flex",alignItems:'center',justifyContent:'space-between',width:'100%'}}>
                  <text style={{margin:10,width:"30%"}}>Email</text>
          <input
                 value={this.state.edit_email}
        onChange={this.handleChangeeditemail}
          placeholder="example@abc.com"
          icon="money-check-alt"
            type="text"
            id="new_des"
            className="form-control"
            required
            
          /></div>
    <div style={{display:"flex",alignItems:'center',justifyContent:'space-between',width:'100%'}}>
                  <text style={{margin:10,width:"30%"}}>Password</text>
          <input
                 value={this.state.edit_password}
        onChange={this.handleChangeedit_password}
          placeholder="Password"
          icon="money-check-alt"
            type="text"
            id="new_des"
            className="form-control"
            required
            
          /></div>
         <div style={{display:"flex",alignItems:'center',justifyContent:'space-between',width:'100%'}}>
          <text style={{margin:10}}>Language Access</text>

          <Select
closeMenuOnSelect={false}
onChange={e=>{
   
this.setState({
  edit_user_languages:e
})


}}
value={this.state.edit_user_languages}

options={this.state.languages}
isMulti
/>
</div>
<div style={{display:"flex",alignItems:'center',justifyContent:'space-between',width:'100%'}}>
                  <text style={{margin:10,width:"30%"}}>Password</text>
          <input
                 value={this.state.super_pass}
        onChange={this.handleChangesuper_password}
          placeholder="Admin Password"
          icon="money-check-alt"
            type="text"
            id="new_des"
            className="form-control"
            required
            
          /></div>
 </MDBModalBody>
              <MDBModalFooter style={{padding:5}}>
              <Button className="confirm" style={{backgroundColor:'#FF000F'}}  onClick={()=>this.editUser()}>Confirm</Button>
              <Button className="confirm" style={{backgroundColor:'#FF000F'}}  onClick={()=>this.setState({edit_user:false})}>Cancel</Button>
              
               
              </MDBModalFooter>
        </MDBModal>
    )
  }
  }
  addUser(){
    if(this.state.new_name.length==0 || this.state.new_password.length==0 || this.state.new_email.length==0){
      swal('Warning','All fields ar mandatory','warning')
    }
    else{
      if(this.state.super_pass!=='super@aaa'){
        swal('Warning','All fields ar mandatory','warning')
      }
      else{
        let details = {
          admin_username:this.state.new_name,
          email:this.state.new_email,
          password:this.state.new_password,
          language_access:JSON.stringify(this.state.new_user_languages)
          };
          console.log(details)
          let formBody = [];
          for (let property in details) {
          let encodedKey = encodeURIComponent(property);
          let encodedValue = encodeURIComponent(details[property]);
          formBody.push(encodedKey + "=" + encodedValue);
          }
          formBody = formBody.join("&");
          
          this.setState({
          response: fetch('http://18.191.5.172:3000/add_admin', {
           method: 'POST',
           headers: {
            
             'Content-Type': 'application/x-www-form-urlencoded',
           
          },
          body:formBody
          
           
          }).then((response) => response.json())
          .then((responseJson)=>{
          window.location.reload()
          
          }
          
          )
           .catch((error) => {
          this.setState({
            loading:false
          })
          alert("Warning!", "Check your network!", "warning");
          console.log(error)
            })
           })
      }
    }
    
  }


  componentDidMount(){
this.getUsers()

  }


  getUsers=async()=>{
    this.setState({
      loading:true
    })
  
 
  this.setState({
   response: fetch('http://18.191.5.172:3000/get_all_admins', {
     method: 'GET',
     headers: {
      
       'Content-Type': 'application/x-www-form-urlencoded',
       'Cache-Control': 'max-age=31536000'
   },
  
    
     
   }).then((response) => response.json())
   .then((responseJson)=>{
  for(var v=0;v<responseJson.length;v++){
    responseJson[v].show_pass=false

  }
     this.setState({
      users:responseJson,
       loading:false
     })
   
   }
  
   )
     .catch((error) => {
    this.setState({
      loading:false
    })
    swal("Warning!", "Check your network!", "warning");
  console.log(error)
      })
     })
     this.setState({
      response: fetch('http://18.191.5.172:3000/get_languages', {
        method: 'GET',
        headers: {
         
          'Content-Type': 'application/x-www-form-urlencoded',
          'Cache-Control': 'max-age=31536000'
      },
     
       
        
      }).then((response) => response.json())
      .then((responseJson)=>{
     for(var v=0;v<responseJson.length;v++){
       responseJson[v].label=responseJson[v].language
       responseJson[v].value=responseJson[v].language_id
     }
     var defaultLanguage=window.localStorage.getItem('admindefault')
     if(defaultLanguage==null){

      this.setState({
        languages:responseJson,
        loading:false,
        selected_language:responseJson[0]
      })
     }
     else{
      this.setState({
        languages:responseJson,
        loading:false,
        selected_language:JSON.parse(defaultLanguage)
      })
     }
       
      
      }
     
      )
        .catch((error) => {
       this.setState({
         loading:false
       })
       swal("Warning!", "Check your network!", "warning");
     console.log(error)
         })
        })
     
  }
  


  
 
  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

 
  renderLanguages(data){
    var languageString=''
    for(var s=0;s<(JSON.parse(data.language_access)).length;s++){
    languageString=languageString+(JSON.parse(data.language_access))[s].language+','
    }
    
    return(<td style={{textAlign:'center'}}>{languageString.slice(0, -1)}</td>)
    }

editUser(){
  
  if(this.state.edit_name==''||
  this.state.edit_email==''||
    this.state.edit_password=='' ||
  this.state.edit_user_languages.length==0){
    swal('Warning','All fields ar mandatory','warning')
    }
    else{
      if(this.state.super_pass!='super@aaa'){
        swal('Warning','Incorrect Admin Password','warning')
      }
     else{
      let details = {
        admin_username:this.state.edit_name,
        email:this.state.edit_email,
        password:this.state.edit_password,
        admin_id:this.state.user_to_edit.admin_id,
        language_access:JSON.stringify(this.state.edit_user_languages)
        };
       
        let formBody = [];
        for (let property in details) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        
        this.setState({
        response: fetch('http://18.191.5.172:3000/edit_admin', {
         method: 'POST',
         headers: {
          
           'Content-Type': 'application/x-www-form-urlencoded',
         
        },
        body:formBody
        
         
        }).then((response) => response.json())
        .then((responseJson)=>{
         console.log(responseJson)
        window.location.reload()
        }
        
        )
         .catch((error) => {
        this.setState({
          loading:false
        })
        alert("Warning!", "Check your network!", "warning");
        console.log(error)
          })
         })
     }
    }
  

}
openSwal(data,key){
  swal({
    text: 'Enter admin password',
    content: "input",
    button: {
      text: "Enter!",
      closeModal: true,
    },
  })
  .then(name => {
  if(name=='super@aaa'){
    let {users} = this.state
    let targetuser = users[key]
    targetuser.show_pass=true
    this.setState({users})
  }
  else{
    swal('Warning','Incorrect Password','warning')
  }
  })
  
 .catch(err => {
    console.log(err)
  });
}
renderPassword(data,key){
 if(data.show_pass==true){
   return( <td style={{textAlign:'center',fontSize:20}}>{data.password}    <MDBIcon  icon="edit" onClick={()=>this.openEditModal(data)} /></td>)
 }
 else if(data.show_pass==false){
  return( <td style={{textAlign:'center',fontSize:20}}>*********    <MDBIcon icon="eye" onClick={()=>this.openSwal(data,key)} />   <MDBIcon  icon="edit" onClick={()=>this.openEditModal(data)} /> </td>)
 }
}

  renderList(){

    if(this.state.loading){
      return(<div className="loadingSpin"><div className="loader border-top-primary border-right-warning border-bottom-success border-left-danger"></div></div>)
    }
    else{
      return (
        <div className="wrapper">
  
        
        
        <MDBTable striped responsive>
            <MDBTableHead style={{backgroundColor:'#FF000F'}}>
  
            <tr >
        <th style={{color:'white',textAlign:'center',fontWeight:'bold'}}>#</th>
        <th style={{color:'white',textAlign:'center',fontWeight:'bold'}}>Name</th>
        <th style={{color:'white',textAlign:'center',fontWeight:'bold'}}>Email</th>
        <th style={{color:'white',textAlign:'center',fontWeight:'bold'}}>Language Access</th>
        <th style={{color:'white',textAlign:'center',fontWeight:'bold'}}>Password</th>
      </tr>
            </MDBTableHead>
  
            <MDBTableBody>
  
            { this.state.users.map((data,key) => {
  
  return (   
  <tr key={key}>
  <td style={{textAlign:'center'}}>{key+1}</td>
  <td style={{textAlign:'center'}}>{data.admin_username}</td>
  <td style={{textAlign:'center'}}>{data.email}</td>
  {this.renderLanguages(data)}
 {this.renderPassword(data,key)}

  
       
  </tr>     )
  
  
  })}
  
  
            </MDBTableBody>
  
  
          </MDBTable>
        
        
        </div>
      )
    }
   


  }



 


  returnTitle(){
if(this.state.selected_id===""){return(<text>Add Head</text>)}
else{return(<text>Edit Head</text>)}

  }


  logout(){
    window.localStorage.clear()
window.location.reload()

  }

handleChangenewname=(e)=>{
  this.setState({new_name:e.target.value});
}
handleChangenewemail=(e)=>{
  this.setState({new_email:e.target.value});
}
handleChangenew_password=(e)=>{
  this.setState({new_password:e.target.value});
}
handleChangeeditname=(e)=>{
  this.setState({edit_name:e.target.value});
}
handleChangeeditemail=(e)=>{
  this.setState({edit_email:e.target.value});
}
handleChangeedit_password=(e)=>{
  this.setState({edit_password:e.target.value});
}
handleChangesuper_password=(e)=>{
  this.setState({super_pass:e.target.value});
}
  render() {

    
    const sidebarBackground = {
      backgroundColor: "#1C2231"
    };
    
    return (
      <div className="bodyDivCl" >

   <div
      id="sidebar"
      className="sideUsers"
      data-color="#1C2231"
     
    >
      <div className="sidebar-background" style={sidebarBackground} />
      
      <div className="sidebar-wrapper" style={{display:'flex',flexDirection:'column',justifyContent:'flex-start',alignItems:'center',marginTop:10}}>
       
      {dashboardRoutes.map((prop, key) => {
            if(prop.path=='/users'){
              return (
               
               <div
               key={key}
className="sideActive"

 onClick={()=>this.props.history.push(`${prop.path}`)}                
>
<img src={require('../../assets/navbar/user.png')} style={{width:35}}/>

<text style={{marginTop:5}}>{prop.name}</text>
</div>
               
             );
             return null;
            }
            else{
              return (
               
               <div
className="sideInActive"

 onClick={()=>this.props.history.push(`${prop.path}`)}                
>
<img src={require('../../assets/navbar/'+prop.icon)} style={{width:40}}/>
<text style={{marginTop:5}}>{prop.name}</text>
</div>
               
             );
             return null; 
            }
           
          })}
          <div
  className="sideInActive"

   onClick={()=>this.logout()}                
  >
  <MDBIcon style={{marginRight:5,width:'100%',display:'flex',justifyContent:'center',alignItems:'center'}} className="grey-text" size="3x"    icon="sign-out-alt" />
  <text>Logout</text>
  </div>
      </div>
    </div>

      <div className="conTable">
 
 <div style={{backgroundColor:'white'}}>
    
 <div style={{display:'flex',alignItems:'center',justifyContent:'flex-end',width:'100%'}}>
 <div onClick={()=>this.setState({add_user:true})} className="waves-effect waves-light nav-link Ripple-parent waves-effect waves-light"  style={{backgroundColor:'rgb(255, 255, 255, 0.1)'}} >
                  <MDBIcon className="black-text" icon="plus-square" />
                  <text className="black-text" style={{paddingLeft:10,fontSize:20}}>Add User</text>
                  </div>
 <div style={{display:'flex',justifyContent:'center'}}><img src={require('../../assets/abb_logo.png')} style={{height:40,width:130,margin:5}}/></div>
    
  
         
          
           </div>
   </div>
   <div >
       


{this.renderList()}
{this.renderAddModal()}
{this.renderEditModal()}



               </div>
          
</div>
 

      
   
     





    


    
 
 
               </div>
              
    );

    
  }
}
export default Users