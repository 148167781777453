import React, { Component } from "react";
import ReactTooltip from 'react-tooltip'
import { MDBNavbar, MDBDropdownItem,MDBDropdownMenu,  MDBIcon, MDBTable, MDBTableBody, MDBTableHead, MDBBtn,MDBRow,
  MDBCol,
  MDBDropdown,
  MDBDropdownToggle,
  MDBCardHeader, MDBModal, MDBModalBody, MDBInput, MDBModalFooter,MDBCollapse,MDBPopover, MDBPopoverBody, MDBTooltip,  } from 'mdbreact';
  import dashboardRoutes from "../../routes/dashboard"
  import swal from 'sweetalert'
  import { Grid, Row, Col,Image,ListGroup,ListGroupItem,Button,Form,FormControl,Checkbox,ControlLabel, } from "react-bootstrap";
import './case.css'
import YouTube from 'react-youtube';
import ScrollAnimation from 'react-animate-on-scroll';
import { HalfCircleSpinner } from 'react-epic-spinners'
   
import { Player } from 'video-react';
class Infra extends Component {
  constructor(props) {
    super(props);
  this.state={
    loading:false,info_heads:[],add_head:false,clients:[],
    new_name:"",new_des:"",new_hsn:"",new_type:"Select Cost Type",selected_id:"",
    selected_language:[],users:[],new_pass:'',add_user:false,
    new_name:'',new_email:'',new_password:'',edit_name:'',edit_email:'',edit_password:'',showAdded:false,
    languages:[],new_user_languages:[],edit_user:false,user_to_edit:null,
    edit_user_languages:[],superadmin:false,admin:false,super_pass:'',
    video_modal:false,video:'',india:true,bulgaria:false,youtube_modal:false,exit:false,nextText:'Case Studies',heading:[],data:[],logoutText:'Logout',showBanner:true,sub_header:[],videoText:'VIDEO',pressText:'PRESS RELEASE',leafletText:'CASE STUDY LEAFLET',infoText:'INFOGRAPH',addText:'Add Reference'
  }
  }
  exitApp(){
    var userAgent = navigator.userAgent.toLowerCase();
  if (userAgent.indexOf(' electron/') > -1) {
    const remote = window.require('electron').remote
    let w = remote.getCurrentWindow()
    w.close()
  }
  
  
  }
  renderExitModal(){
  
  
     
    if(this.state.exit==true){
  
  
    return(
      <MDBModal size='md' isOpen={this.state.exit} centered toggle={this.toggleExit} >
   
         
   <MDBModalBody style={{    display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',flexDirection:'column',paddingTop:'4vh',paddingBottom:'4vh'}}>
            <img onError={()=>this.setState({showAdded:true})} alt='Loading...' src={require('../../assets/Exit.jpg')} className='catGif'/>
            <p>Are you sure you want to exit this application?</p>
         <div style={{display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-evenly'}}>      <text onClick={()=>this.exitApp()} style={{backgroundColor:'#ff000f',color:'white'}} className='seriesText'>Exit</text>

            
            <text onClick={()=>this.setState({exit:false})} className='seriesText'>Cancel</text>

       
          </div>
           
            </MDBModalBody>

        </MDBModal>
    )
  }
  }
  
    
    toggleExit = () => {
     
      this.setState({
        exit: !this.state.exit,
       });
    }
  renderYouTubeModal(){


   
    if(this.state.youtube_modal==true){
  
  
    return(
      <MDBModal size='lg' isOpen={this.state.youtube_modal} toggle={this.toggleyoutube_modal} centered>
   
         
              <MDBModalBody>
              <YouTube
        videoId={this.state.video}
        opts={{
          height: '500',
      width: '100%',
      playerVars: { 
        autoplay: 1
      }
        }}
        onReady={this._onReady}
      />
  
              </MDBModalBody>
              <MDBModalFooter style={{padding:5}}>
              <Button className="confirm" style={{backgroundColor:'#FF000F'}}  onClick={()=>this.setState({youtube_modal:false})}>CLOSE</Button>
             
              
               
              </MDBModalFooter>
        </MDBModal>
    )
  }
  }
  toggleYoutube = () => {
   
    this.setState({
      youtube_modal: !this.state.youtube_modal,
     });
  }
  componentWillMount(){

  }
  renderVideoModal(){


   
    if(this.state.video_modal==true){
  
  
    return(
      <MDBModal size='lg' isOpen={this.state.video_modal} toggle={this.toggleVideo} >
   
         
              <MDBModalBody>
              <Player ref="player"
              autoPlay={true}
        playsInline
        poster="../../assets/abb_logo.png"
        src={this.state.video}
      />
 
  
              </MDBModalBody>
              <MDBModalFooter style={{padding:5}}>
              <Button className="confirm" style={{backgroundColor:'#FF000F'}}  onClick={()=>this.setState({video_modal:false})}>CLOSE</Button>
             
              
               
              </MDBModalFooter>
        </MDBModal>
    )
  }
  }

  toggleVideo = () => {
   
    this.setState({
      video_modal: !this.state.video_modal,
     });
  }
 

 



  componentDidMount(){
    this.getuserdata()
    
    var userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf(' electron/') > -1) {
       this.setState({showExit:true})
    }
  }
  renderExitButton(){
    if(this.state.showExit){
     return(<div
       className="sideInActive"
       
        onClick={this.toggleExit}                
       >
       <img onError={()=>this.setState({showAdded:true})} src={require('../../assets/Exit.jpg')} style={{width:35}}/>
       <text style={{marginTop:5}}>Exit</text>
       </div>)
    }
   
   } 
componentWillUnmount(){
  window.removeEventListener('onbeforeunload', this.handleWindowClose);

}
handleWindowClose(){
  window.addEventListener("beforeunload", (ev) => 
  {  
      ev.preventDefault();
      return ev.returnValue = 'Are you sure you want to close?';
  });
}
getVideo=async()=>{
  this.setState({
    loading:true
  })



var responseJson=[{"language":"English","language_id":1,"active":0,"key":1,"label":"English"},{"language":"German","language_id":2,"active":0,"key":2,"label":"German"},{"language":"Chinese","language_id":3,"active":0,"key":3,"label":"Chinese"},{"language":"Spanish","language_id":4,"active":0,"key":4,"label":"Spanish"},{"language":"French","language_id":5,"active":0,"key":5,"label":"French"}]

for(var v=0;v<responseJson.length;v++){
  responseJson[v].label=responseJson[v].language
  responseJson[v].value=responseJson[v].language_id
}
var defaultLanguage=window.localStorage.getItem('admindefault')
if(defaultLanguage==null){

 this.setState({
   languages:responseJson,

   selected_language:responseJson[0]
 })
}
else{
 this.setState({
   languages:responseJson,
 
   selected_language:JSON.parse(defaultLanguage)
 })
}
 
}

  


  
 

  selectLanguage(data,key){
    window.localStorage.removeItem('admindefault')
      window.localStorage.setItem('admindefault',JSON.stringify(data))
   
this.setState({
  selected_language:data
},()=>window.location.reload())
  }




  getuserdata=async()=>{
    this.setState({
      loading:true
    })
  
  
  
  var responseJson=[{"language":"English","language_id":1,"active":0,"key":1,"label":"English",selected:0},
  {"language":"German","language_id":2,"active":0,"key":2,"label":"German",selected:0},
  {"language":"Chinese","language_id":3,"active":0,"key":3,"label":"Chinese",selected:0},
  {"language":"Spanish","language_id":4,"active":0,"key":4,"label":"Spanish",selected:0},
  {"language":"French","language_id":5,"active":0,"key":5,"label":"French",selected:0}]
  
  for(var v=0;v<responseJson.length;v++){
    responseJson[v].label=responseJson[v].language
    responseJson[v].value=responseJson[v].language_id
  }
  var defaultLanguage=window.localStorage.getItem('admindefault')
  if(defaultLanguage==null){
    responseJson[0].selected=1
   this.setState({
     languages:responseJson,
  
     selected_language:responseJson[0]
   },()=>this.getContent())
  }
  else{
    
      if(JSON.parse(defaultLanguage).language=='German' || JSON.parse(defaultLanguage).language=='Deutsche' ||
      JSON.parse(defaultLanguage).language=='德语' || JSON.parse(defaultLanguage).language=='alemán' || JSON.parse(defaultLanguage).language=='allemand'){
       responseJson[0].language='Englisch'
       responseJson[1].language='Deutsche'
       responseJson[2].language='Chinesisch'
       responseJson[3].language='Spanisch'
       responseJson[4].language='Französisch'
      dashboardRoutes[0].name='Zuhause'
      dashboardRoutes[1].name='Ursachen'
      dashboardRoutes[2].name='Lösungen'
      dashboardRoutes[3].name='Wertversprechen'
      dashboardRoutes[4].name='Fußabdruck'
      dashboardRoutes[5].name='Fallstudien'
      
     this.setState({logoutText:'Ausloggen',nextText:'Fallstudien',pressText:'PRESSEMITTEILUNG',
     leafletText:'FALLSTUDIE-BLATT',addText:'Füge Referenz hinzu',closeText:'Schließen'})
  
    }
    else if(JSON.parse(defaultLanguage).language=='Chinese' || JSON.parse(defaultLanguage).language=='Chinesisch' ||
    JSON.parse(defaultLanguage).language=='中文' || JSON.parse(defaultLanguage).language=='chino' || JSON.parse(defaultLanguage).language=='chinois'){
    
      responseJson[0].language='英语'
      responseJson[1].language='德语'
      responseJson[2].language='中文'
      responseJson[3].language='西班牙语'
      responseJson[4].language='法国'
      dashboardRoutes[0].name='家'
      dashboardRoutes[1].name='原因'
      dashboardRoutes[2].name='解决方案'
      dashboardRoutes[3].name='价值主张'
      dashboardRoutes[4].name='脚印'
      dashboardRoutes[5].name='实例探究'
     
      this.setState({logoutText:'登出',nextText:'实例探究',pressText:'新闻稿',
      leafletText:'案例研究LEAFLET',addText:'添加参考'})
  
  
     }
     else if(JSON.parse(defaultLanguage).language=='Spanish' || JSON.parse(defaultLanguage).language=='Spanisch' ||
     JSON.parse(defaultLanguage).language=='西班牙语' || JSON.parse(defaultLanguage).language=='Español' || JSON.parse(defaultLanguage).language=='Espanol'){
      responseJson[0].language='Inglés'
      responseJson[1].language='alemán'
      responseJson[2].language='chino'
      responseJson[3].language='Español'
      responseJson[4].language='francés'
      dashboardRoutes[0].name='Casa'
        dashboardRoutes[1].name='Causas'
        dashboardRoutes[2].name='Soluciones'
        dashboardRoutes[3].name='Propuesta de valor'
        dashboardRoutes[4].name='Huella'
        dashboardRoutes[5].name='Estudios de caso'
     
      this.setState({logoutText:'Cerrar sesión',nextText:'Estudios de caso',videoText:'VÍDEO',pressText:'PRESIONE SOLTAR',leafletText:'ESTUDIO DE CASO',infoText:'Infografía',addText:'Añadir referencia'})
  
  
     }
     else if(JSON.parse(defaultLanguage).language=='French' || JSON.parse(defaultLanguage).language=='Französisch' ||
       JSON.parse(defaultLanguage).language=='法国' || JSON.parse(defaultLanguage).language=='francés' || JSON.parse(defaultLanguage).language=='français'){
        responseJson[0].language='Anglais'
        responseJson[1].language='allemand'
        responseJson[2].language='chinois'
        responseJson[3].language='Espanol'
        responseJson[4].language='français'
      dashboardRoutes[0].name='Accueil'
      dashboardRoutes[1].name='Les causes'
      dashboardRoutes[2].name='Solutions'
      dashboardRoutes[3].name='Proposition de valeur'
      dashboardRoutes[4].name='Empreinte'
      dashboardRoutes[5].name='Études de cas'
       
     
      this.setState({logoutText:'Connectez - Out',nextText:'Études de cas',videoText:'VIDÉO',
      pressText:'COMMUNIQUÉ DE PRESSE',leafletText:`NOTICE D'ÉTUDE DE CAS`,infoText:'INFOGRAPHIQUE',addText:'Ajouter une référence'})
  
  
     }
    console.log(JSON.parse(defaultLanguage),'selected_language')
    responseJson[JSON.parse(defaultLanguage).key-1].selected=1
   this.setState({
     languages:responseJson,
  
     selected_language:JSON.parse(defaultLanguage)
   },()=>this.getContent())
  }
   
  }
          getContent=async()=>{
            if(!navigator.onLine){
              var offline_content=window.localStorage.getItem('infra')
              if(offline_content==null){
                swal('Warning','Check your network and refresh','warning')
                this.setState({
                  loading:false
                })
              }
              else{
                this.setState({
                  data:JSON.parse(JSON.parse(offline_content).infratsructure),
                  sub_header:JSON.parse(JSON.parse(offline_content).sub_header),
                   loading:false
                  },()=>console.log(this.state.data))
                   
              }
                   }
                   else{
                    this.setState({loading:true})
                    let details = {
                      language_id:this.state.selected_language.language_id
                    };
                    let formBody = [];
                    for (let property in details) {
                     let encodedKey = encodeURIComponent(property);
                     let encodedValue = encodeURIComponent(details[property]);
                     formBody.push(encodedKey + "=" + encodedValue);
                    }
                    formBody = formBody.join("&");
                    console.log(details)
                    this.setState({
                      response: fetch('https://www.mobilitysolution.info/get_content_renewables', {
                        method: 'POST',
                        headers: {
                         
                          'Content-Type': 'application/x-www-form-urlencoded',
                          'Cache-Control': 'max-age=31536000'
                      },
                    body:formBody
                        
                      }).then((response) => response.json())
                      .then((responseJson)=>{
                       window.localStorage.removeItem('infra')
                       window.localStorage.setItem('infra',JSON.stringify(responseJson))
                      this.setState({
                        data:JSON.parse(responseJson.infratsructure),
                        sub_header:JSON.parse(responseJson.sub_header),
                         loading:false
                        },()=>console.log(this.state.data))
                          })
                          .catch((error) => {
                            var offline_content=window.localStorage.getItem('infra')
              if(offline_content==null){
                swal('Warning','Check your network and refresh','warning')
                this.setState({
                  loading:false
                })
              }
              else{
                this.setState({
                  data:JSON.parse(JSON.parse(offline_content).infratsructure),
                  sub_header:JSON.parse(JSON.parse(offline_content).sub_header),
                   loading:false
                  },()=>console.log(this.state.data))
                   
              }
                          console.log(error)
                              })
                             })
                   }
         
                 }


 


                 renderVideoButton(data,key){
          
                  if(data.video.length!=0){
                    return( <text onClick={()=>this.setState({video:data.video},this.toggleYoutube)} style={{marginRight:15}}   className='why'>
                                
                                 
                    {this.state.videoText}
                     
                     </text>  )
                  }
                }
                renderPressButton(data,key){
                 
                  if(data.press_release.length!=0){
                    return(  <text onClick={()=>this.openBro(data.press_release)} style={{marginRight:15}}  className='why'>
                                
                                 
                    {this.state.pressText}
                    
                    </text> )
                  }
                }
                openBro(filePath){
                  var userAgent = navigator.userAgent.toLowerCase();
                  if (userAgent.indexOf(' electron/') > -1) {
                    const {shell} = window.require('electron');
                    shell.openExternal(filePath);
                 
                  }
                  else{
                    window.open(filePath, "_blank")
                  }
                  
                }
                renderinfoButton(data,key){
                  
                  if(data.infograph.length!=0){
                    return(  <text style={{marginRight:15}} onClick={()=>this.openBro(data.infograph)}  className='why'>
                                
                                 
                    INFOGRAPH
                     
                     </text>  )
                  }
                }
                renderLeafletButton(data,key){
                  
                  if(data.leaflet.length!=0){
                    return(  <text style={{marginLeft:10,marginRight:10}} onClick={()=>this.openBro(data.leaflet)}   className='why'>
                      
                      
                    CASE STUDY LEAFLET
                     
                     </text>   )
                  }
                }
                renderVideoButtonMob(data,key){
          
                  if(data.video.length!=0){
                    return( <text onClick={()=>this.setState({video:data.video},this.toggleYoutube)} style={{marginRight:5,fontSize:12,paddingLeft:20,paddingRight:20}}   className='why'>
                                
                                 
                    {this.state.videoText}
                     
                     </text>  )
                  }
                }
                renderPressButtonMob(data,key){
                 
                  if(data.press_release.length!=0){
                    return(  <text onClick={()=>window.open(data.press_release, "_blank")} style={{marginRight:5,fontSize:12,paddingLeft:20,paddingRight:20}}  className='why'>
                                
                                 
                    {this.state.pressText}
                    
                    </text> )
                  }
                }
                renderinfoButtonMob(data,key){
                  
                  if(data.infograph.length!=0){
                    return(  <text style={{marginRight:5,fontSize:12,paddingLeft:20,paddingRight:20}} onClick={()=>window.open(data.infograph, "_blank")}  className='why'>
                                
                                 
                    {this.state.infoText}
                     
                     </text>  )
                  }
                }
                renderLeafletButtonMob(data,key){
                        
                  if(data.leaflet.length!=0){
                    return(  <text style={{marginRight:5,fontSize:12,paddingLeft:20,paddingRight:20}} onClick={()=>window.open(data.leaflet,'_blank')}   className='why'>
                      
                      
                    {this.state.leafletText}
                     
                     </text>   )
                  }
                }
                renderData2(){
                  if(this.state.loading==false && this.state.data.length!=0){
                    return(
                      <div className='subwindow'>    
                 
                 {this.state.data.map((prop, key) => {
                   return(
                    <div id='1' style={{display:'flex',flexDirection:'row',alignItems:'flex-start',justifyContent:'space-between',marginLeft: '4%',marginBottom:'7%',marginTop:'2%'}}>
                  
                  
                  <div style={{display:'flex',flexDirection:'column',marginLeft:'4%',width:'50%',textAlign:'justify'}}>
                  <div > 
                  
                  <h2  className='valueheading'>
                                
                  {prop.heading}
                               </h2>
                               
                               
                  </div>
                  <div><h3 className='valuedata2' style={{wordWrap:'break-word'}} >
                                
                                 
                                {prop.subheading}
                                
                                
                                             </h3> </div>
                  
                               
                               <p className='auto' style={{color:'black',paddingTop:5,paddingBottom:5,fontWeight:'bold'}}>{this.state.sub_header[0]}  </p> 
                              <ul style={{marginLeft:-20,marginBottom:15}}>
                              {/* {this.state.data.map((prop, key) => {
      
                              })} */}
                              {prop.customer_profile.map((propC, keyC) => {
                               
                              return( <li className='lightext' style={{fontSize:16}}>{propC}</li>)
      })}
                                
                               </ul>
                             
                               <p className='auto' >{this.state.sub_header[1]}  </p> 
                               {prop.challenges.map((propChal, keyChal) => {
                               
                               return( <li className='lightext' style={{fontSize:16}}>{propChal}</li>)
       })}
                               
                               
                              
                             
                               <p className='auto'  style={{color:'#ff000f',paddingTop:15,paddingBottom:5}}>{this.state.sub_header[2]}  </p> 
                               {prop.ABBsolution.map((propSol, keySol) => {
                               
                               return( <li className='lightext' style={{fontSize:16,}}>{propSol}</li>)
       })}
                               
                              
                               <p className='auto' style={{color:'#ff000f',paddingBottom:5,paddingTop:15}}>{this.state.sub_header[3]}  </p> 
                               {prop.benefits.map((propBen, keyBen) => {
                               
                               return(  <li className='lightext' style={{fontSize:16,}}>{propBen}</li>)
       })}
                              
                              <MDBRow style={{marginLeft:0,marginTop:10,marginRight:15}}> 
                             {this.renderVideoButton(prop,key)}
                                 {this.renderPressButton(prop,key)}
                                {this.renderinfoButton(prop,key)}
                                 {this.renderLeafletButton(prop,key)}
                  
                                  </MDBRow>
                               
                  </div>
                  <img onError={()=>this.setState({showAdded:true})} alt='Loading...' src={prop.image} style={{width:'40%'}}/>  
                  
                  </div>  
                   )
                 })
                 }
           
                  </div>  
                  )        
                  }
                  
                   
                }

renderDataMobile(){
  if(this.state.loading==false && this.state.data.length!=0){
    return(
      <div className='subMobile'> 
      {this.state.data.map((prop, key) => {
        return(
        <div id={key+1} style={{display:'flex',flexDirection:'column-reverse',alignItems:'flex-start',justifyContent:'space-between',marginLeft: '0%',marginBottom:'7%',marginTop:'0%'}}>
  
  
  <div style={{display:'flex',flexDirection:'column',margin:'5%',width:'90%',textAlign:'justify'}}>
  <div > 
  
  <h2  className='valueheading'>
                
  {prop.heading}
               </h2>
               
               
  </div>
  <div><h3 className='valuedata2' style={{wordWrap:'break-word'}} >
                
                 
                {prop.subheading}
                
                
                             </h3> </div>
  
               
              
                         
                             <p className='auto' style={{color:'black',paddingTop:5,paddingBottom:5,fontWeight:'bold'}}>{this.state.sub_header[0]}  </p> 
                        <ul style={{marginLeft:-25,marginBottom:15}}>
                        {/* {this.state.data.map((prop, key) => {

                        })} */}
                        {prop.customer_profile.map((propC, keyC) => {
                         
                        return( <li className='lightext' style={{fontSize:16}}>{propC}</li>)
})}
                          
                         </ul>
                       
                         <p className='auto' >{this.state.sub_header[1]}  </p> 
                         <ul style={{marginLeft:-25,marginBottom:15}}>
                         {prop.challenges.map((propChal, keyChal) => {
                         
                         return(   <li className='lightext' style={{fontSize:16}}>{propChal}</li>)
 })}
 </ul>
                         
                         
                        
                       
                         <p className='auto'  style={{color:'#ff000f',paddingTop:15,paddingBottom:5}}>{this.state.sub_header[2]}  </p> 
                         <ul style={{marginLeft:-25,marginBottom:15}}>  {prop.ABBsolution.map((propSol, keySol) => {
                         
                         return(   <li className='lightext' style={{fontSize:16,}}>{propSol}</li> )
 })}
 </ul>
                         
                        
                         <p className='auto' style={{color:'#ff000f',paddingBottom:5,paddingTop:15}}>{this.state.sub_header[3]}  </p> 
                         <ul style={{marginLeft:-25,marginBottom:15}}>  {prop.benefits.map((propBen, keyBen) => {
                         
                         return(    <li className='lightext' style={{fontSize:16,}}>{propBen}</li>
                         )
 })}</ul>
                        
 <MDBRow style={{marginLeft:0,marginTop:10,display:'flex',justifyContent:'center',alignItems:'center'}}> 
                       {this.renderVideoButtonMob(prop,key)}
                           {this.renderPressButtonMob(prop,key)}
                          {this.renderinfoButtonMob(prop,key)}
                          {this.renderLeafletButtonMob(prop,key)}
                           
            
                            </MDBRow>
               
  </div>
  <img onError={()=>this.setState({showAdded:true})} alt='Loading...' src={prop.image} style={{width:'100%'}}/>  
  
  </div>  
  )
      })
      }   
 
          

  </div>  
  )        
  }
  
   
}
paneDidMount = (node) => {    
  if(node) {      
    node.addEventListener("scroll", this.handleScroll.bind(this));      
  }
}

handleScroll = (event) => {    
  var node = event.target;
  const bottom = node.scrollHeight - node.scrollTop === node.clientHeight;
  if (bottom) {      
    console.log("BOTTOM REACHED:",bottom); 
    this.setState({showBanner:!this.isInViewport()})
  }    
}


renderScroll(){
  if(this.state.showBanner==true){
    return(<div className='scroll'  onClick={()=>document.getElementById('last').scrollIntoView()}><text style={{backgroundColor:'white'}} className='arrow'></text></div>)
  }

}
renderLanguages(){

  if(this.state.loading==false){
    if(this.state.languages.length>0){
      return(<div  
    
         
        
        style={{backgroundColor:'rgb(255, 255, 255, 0.1)',cursor:'pointer',display:'flex',alignItems:'center',marginRight:100,marginTop:'.5%'}}>
      <MDBIcon size="lg" style={{marginLeft:10,marginRight:20}}   className="grey-text menuBar" icon="bars" onClick={()=>this.setState({mobileMenu:!this.state.mobileMenu})}/>
      <MDBIcon size="lg" style={{marginLeft:10}} onClick={()=>this.setState({lang_mod:true})}   className="grey-text" icon="globe-africa" />
      <MDBDropdown className='languageDiv' >
     <MDBDropdownToggle caret color="#004080" size="md" >
     <text className='languageText'>{this.state.languages[this.state.selected_language.key-1].language}</text>
     </MDBDropdownToggle>
     <MDBDropdownMenu style={{margin:0,padding:0,borderRadius:0}}> 
     <MDBDropdownItem style={{backgroundColor:'#d2d2d2',color:'black',textAlign:'center',padding:12}}  >ENGLISH</MDBDropdownItem>
     {/* { (this.state.languages).map((data,key) => {
  
if(data.selected==0){
return ( 
  <MDBDropdownItem style={{backgroundColor:'#fff',color:'#6e6e6e',textAlign:'center',padding:12}} className='dropButton'  onClick={()=>this.selectLanguage(data)}>{data.language}</MDBDropdownItem>
      
       )
}
else{
return ( 
  <MDBDropdownItem style={{backgroundColor:'#d2d2d2',color:'black',textAlign:'center',padding:12}}  >{data.language}</MDBDropdownItem>
      
       )
}
  })
  } */}
  </MDBDropdownMenu>
  </MDBDropdown>
    
        </div>)
     }
   
  }
     
    }
renderoverLay(){
  if(this.state.mobileMenu){
  
    return (<div id='overlayCat' onClick={()=>this.setState({mobileMenu:false})}></div>)
  }
}
mobileMenu(){
  var style={opacity:1,backgroundColor:'#fff',height:'100vh',borderStyle:'solid',borderColor:'#d2d2d2',borderWidth:1,
  overflow:'auto',padding:'2.5%',display:'flex',flexDirection:'column',alignItems:'flex-start',paddingLeft:10,paddingRight:10}

 
  if(this.state.mobileMenu){
    return(
      <div className='mobileMenu'>
        <ScrollAnimation animateOnce={false}  animateIn="slideInLeft"  className='slideInLeft' animationOut="slideOutLeft" 
     isVisible={true}  style={style}>
         {dashboardRoutes.map((prop, key) => {
                  if(prop.path=='/case_study'){
                    return (
                     
                     <div
                     key={key}
      className="sideActiveMobile"
      
       onClick={()=>this.props.history.push(`${prop.path}`)}                
      >
      <img onError={()=>this.setState({showAdded:true})} alt='Loading...' src={require('../../assets/navbar/Casestudy.png')} alt='Loading...' style={{width:20,marginRight:10}}/>
      
      <text style={{marginTop:5,fontSize:12}}>{prop.name}</text>
      </div>
                     
                   );
                   return null;
                  }
                  else{
                    return (
                     
                     <div
      className="sideInActiveMobile"
      
       onClick={()=>this.props.history.push(`${prop.path}`)}                
      >
      <img onError={()=>this.setState({showAdded:true})} alt='Loading...' src={require('../../assets/navbar/'+prop.icon)} style={{width:20,marginRight:10}}/>
      <text style={{marginTop:5,fontSize:12}}>{prop.name}</text>
      </div>
                     
                   );
                   return null; 
                  }
                 

                })}
              
                </ScrollAnimation>
      </div>
    )
  }
}
toggleLang = () => {
   
  this.setState({
    lang_mod: !this.state.lang_mod,
   });
}
renderlangModal(){


   
  if(this.state.lang_mod==true){


  return(
    <MDBModal className='mobileLang' size='md' isOpen={this.state.lang_mod} toggle={this.toggleLang} centered>
 
       
            <MDBModalBody>
            {/* { (this.state.languages).map((data,key) => {
    
    if(data.selected==0){
     return ( 
       <MDBDropdownItem style={{backgroundColor:'#fff',color:'#6e6e6e',textAlign:'center',padding:12}} className='dropButton'  onClick={()=>this.selectLanguage(data)}>{data.language}</MDBDropdownItem>
           
            )
    }
    else{
     return ( 
       <MDBDropdownItem style={{backgroundColor:'#d2d2d2',color:'black',textAlign:'center',padding:12}}  >{data.language}</MDBDropdownItem>
           
            )
    }
       })
       } */}
       <MDBDropdownItem style={{backgroundColor:'#d2d2d2',color:'black',textAlign:'center',padding:12}}  >ENGLISH</MDBDropdownItem>
                       </MDBModalBody>
          
      </MDBModal>
  )
}
}
isInViewport(offset = 0) {
  if (!this.yourElement) return false;
  const top = this.yourElement.getBoundingClientRect().top;
  return (top + offset) >= 0 && (top - offset) <= window.innerHeight;
}
reload = ()=>{
  const current = this.props.location.pathname;
  this.props.history.replace(`/reload`);
     setTimeout(() => {
       this.props.history.replace(current);
     });
 }
showUpdateBar() {
  if(this.state.showAdded){
    
    
      return(
        <ScrollAnimation animateOnce={false}   animateIn="fadeIn"  className='fadeIn toast2' animationOut="fadeOut" 
         isVisible={true} >
       <div className="snack">Go online and <text onClick={()=>this.reload()} style={{cursor:'pointer',textDecoration:'underline'}}>click here</text> to refresh</div>
    </ScrollAnimation>
    
       
      )
    
 
  }
  }
  trigger() {

    setTimeout(() => { 
        this.setState({
            showAdded: false
        })
    }, 5000);
  }
  render() {
var that =this
    
    const sidebarBackground = {
      backgroundColor: "#1C2231"
    };
    if(this.state.loading){
      
    return(<div className="loadingSpin"><HalfCircleSpinner color="#ff000f"/></div>)
     
    }
    else{
    return (
      <div className="bodyDivCl"  onScroll={()=>this.setState({showBanner:!this.isInViewport()})} ref={that.paneDidMount}>

   <div
      id="sidebar"
      className="sideCat"
      data-color="#1C2231"
     
    >
      <div className="sidebar-background" style={sidebarBackground} />
      
      <div className="sidebar-wrapper" style={{display:'flex',flexDirection:'column',justifyContent:'flex-start',alignItems:'center',marginTop:10}}>
      {dashboardRoutes.map((prop, key) => {
                if(prop.path=='/case_study'){
                  return (
                   
                   <div
                   key={key}
    className="sideActive"
    
     onClick={()=>this.props.history.push(`${prop.path}`)}                
    >
    <img onError={()=>this.setState({showAdded:true})} alt='Loading...' src={require('../../assets/navbar/Casestudy_Hover.png')} style={{width:25}}/>
    
    <text style={{marginTop:5}}>{prop.name}</text>
    </div>
                   
                 );
                 return null;
                }
                else{
                  return (
                   
                   <div
    className="sideInActive"
    
     onClick={()=>this.props.history.push(`${prop.path}`)}                
    >
    <img onError={()=>this.setState({showAdded:true})} alt='Loading...' src={require('../../assets/navbar/'+prop.icon)} style={{width:25}}/>
    <text style={{marginTop:5}}>{prop.name}</text>
    </div>
                   
                 );
                 return null; 
                }
               
              })}
           {this.renderExitButton()}
      </div>
    </div>
{this.mobileMenu()}
      <div className="conTable">
 
 <div style={{backgroundColor:'white'}}>
    
 <div className='topMenu'>
 <div style={{display:'flex',justifyContent:'center'}}>{this.renderLanguages()}</div>
  
 <div style={{display:'flex',justifyContent:'center'}}><img onError={()=>this.setState({showAdded:true})} id='logo' alt='Loading...' src={require('../../assets/abb_logo.png')} className='abb_logo'/></div>
    
  
         
          
           </div>
   </div>
    
{this.renderData2()}

{this.renderDataMobile()}
<div id='last'></div>
<div className='nextCase' ref={(el) => this.yourElement = el}>
<text onClick={()=>this.props.history.push('case_study')} className='arrowRed'></text>
<h5 style={{marginBottom:0}}>{dashboardRoutes[5].name}</h5></div> 



</div>
 

      
   
     



{this.renderlangModal()}
{this.renderoverLay()}
    {this.renderScroll()}
 {this.renderExitModal()}
 {this.renderVideoModal()}
 {this.renderYouTubeModal()}
 {this.showUpdateBar()}
               </div>
              
    );
            }
    
  }
}
export default Infra